var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "product_module_pre" }, [
    _c(
      "div",
      { staticClass: "content_view", style: _vm.changeStyleContent() },
      _vm._l(_vm.obj.moduleList, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            class: _vm.changeClassContent(),
            style: _vm.changeStyle(item),
          },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v(" " + _vm._s(item.title) + " "),
            ]),
            item.text
              ? _c("div", { staticClass: "text" }, [
                  _vm._v(" " + _vm._s(item.text) + " "),
                ])
              : _vm._e(),
            _c(
              "el-carousel",
              {
                class: _vm.changeClass(item.categoryInfor.entryChartStyle),
                attrs: {
                  direction: "vertical",
                  autoplay: item.categoryInfor.dynamicEffect === 2,
                },
              },
              _vm._l(item.categoryInfor.shopGoodsPC, function (items) {
                return _c(
                  "el-carousel-item",
                  { key: items.goodsImg, staticClass: "img-item" },
                  _vm._l(items.list, function (value, indexss) {
                    return _c(
                      "div",
                      { key: "ss" + indexss, staticClass: "img-box" },
                      [
                        _c("img", { attrs: { src: value.goodsImg, alt: "" } }),
                        item.categoryInfor.entryChartStyle === 1
                          ? _c("div", { staticClass: "img-tips" }, [
                              _c("span", { staticClass: "img-tips-title" }, [
                                _vm._v(_vm._s(value.goodsName)),
                              ]),
                              _c("span", { staticClass: "img-tips-button" }, [
                                _vm._v("GO"),
                              ]),
                            ])
                          : _vm._e(),
                        item.categoryInfor.entryChartStyle !== 6 &&
                        item.categoryInfor.entryChartStyle !== 3 &&
                        (item.categoryInfor.groupType === 2 ||
                          item.categoryInfor.groupType === 3)
                          ? _c("div", { staticClass: "img-price" }, [
                              _vm._v(" ￥" + _vm._s(value.goodsPrice) + " "),
                            ])
                          : _vm._e(),
                        item.categoryInfor.entryChartStyle === 6 ||
                        item.categoryInfor.entryChartStyle === 3
                          ? _c("div", { staticClass: "img-price-more" }, [
                              _c("div", { staticClass: "title" }, [
                                _vm._v(" " + _vm._s(value.goodsName) + " "),
                              ]),
                              item.categoryInfor.groupType === 2 ||
                              item.categoryInfor.groupType === 3
                                ? _c("div", { staticClass: "price" }, [
                                    _vm._v(
                                      " ￥" + _vm._s(value.goodsPrice) + " "
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                )
              }),
              1
            ),
          ],
          1
        )
      }),
      0
    ),
    _c("div", { staticClass: "bt-drag-title" }, [
      _vm._v(" " + _vm._s(_vm.obj.showTitle) + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }