var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "scroll-news",
      style: {
        "background-color": _vm.obj.bgColor,
        "margin-bottom": _vm.obj.bMargin ? "10px" : "0",
        padding: _vm.obj.bPadding ? "20px 30px" : "20px 0",
      },
    },
    [
      _c(
        "div",
        { staticClass: "cont-box" },
        _vm._l(_vm.list, function (item, index) {
          return _c(
            "p",
            { key: index, style: { color: _vm.obj.remainColor } },
            [
              _c("span", [_vm._v(_vm._s(_vm.obj.beforeText))]),
              _c("span", { style: { color: _vm.obj.mainColor } }, [
                _vm._v("【重点文案】"),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.obj.afterText))]),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }