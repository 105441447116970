<template>
  <div class="live_list">


    <el-card
      class="live_card"
      v-for="(itemContent,index) in info.contentList"
      :key="index">
      <div slot="header" class="live_card_header">
        <div>
          <span style="font-weight: bold; font-size: 16px">容器 {{index + 1}}</span>
          <el-select
            class="select_box"
            v-model="itemContent.liveStatus"
            placeholder="请选容器状态"
            size="mini"
            @change="onChangeLiveStatus(itemContent)"
          >
            <el-option
              v-for="(item, i) in liveStateList"
              :key="i"
              :label="item.title"
              :value="item.type">
            </el-option>
          </el-select>
          <el-select
            class="select_box"
            v-model="itemContent.sortKey"
            size="mini"
            placeholder="请选择排序状态"
            @change="addLive(itemContent)">
            <el-option
              v-for="(item, i) in sortStateList"
              :key="i"
              :label="item.title"
              :value="item.type"
              :disabled="itemContent.liveStatus !== 2
                &&
                (item.type === 'watchNum' || item.type === 'gmvDesc')">
            </el-option>
          </el-select>
        </div>
        <div>
          <el-link
            class="live_card_header_btn"
            type="primary"
            :underline="false"
            :disabled="index === 0"
            @click="onMove(info.contentList, index, 'up')"
          >上移</el-link>
          <el-link
            class="live_card_header_btn"
            type="primary"
            :underline="false"
            :disabled="index === info.contentList.length - 1"
            @click="onMove(info.contentList, index, 'down')"
          >下移</el-link>
          <el-popconfirm
            confirmButtonText='确定'
            cancelButtonText='取消'
            icon="el-icon-info"
            iconColor="red"
            title="是否确认删除？"
            @onConfirm="deleteContainer(info.contentList, index)"
          >
            <el-link class="live_card_header_btn" slot="reference" type="danger" :underline="false">删除</el-link>
          </el-popconfirm>
        </div>
      </div>

      <el-row type="flex" align="top" :gutter="20" style="margin-bottom: 20px">
        <el-col :span="4" style="text-align: left; "><span style="color: red">*</span>直播：</el-col>
        <el-col :span="20">
          <div style="display: flex;">
            <el-input
              placeholder="请输入逗号隔开的直播间id"
              type="textarea"
              :rows="4"
              v-model.trim="itemContent.inputValue"
              size="mini"
              @input="itemContent.inputValue = itemContent.inputValue.replace(/，/g, ',').replace(/[^\d,]/g, '').replace(/(,)\1/g, ',').replace(/^,/, '')"
              @blur="itemContent.inputValue = [...new Set(itemContent.inputValue.replace(/,$/, '').split(','))].join();"
              ></el-input>
            <div>
              <el-button
                type="primary"
                size="mini"
                style="margin-left: 10px;"
                @click="addLive(itemContent)"
                :loading="btnLoading"
              >立即添加</el-button>
            </div>
          </div>
        </el-col>
      </el-row>

      <div class="live_card_list">
        <div
          class="live_card_line"
          v-for="(item,i) in itemContent.list"
          :key="i">
          <div class="left">
            <img :src="item.coverImageId" alt="">
            <div class="name">
              <div>{{item.title}}</div>
              <div>主播：{{item.actorName}}</div>
            </div>
          </div>
          <div class="center">
            <div>开始：{{formatDays(item.liveStartTime) || formatDays(item.realStartTime)}}</div>
            <div>结束：{{formatDays(item.liveEndTime) || formatDays(item.realEndTime)}}</div>
          </div>

          <el-popconfirm
            confirmButtonText='确认'
            cancelButtonText='取消'
            icon="el-icon-info"
            iconColor="red"
            title="是否确认删除？"
            @onConfirm="deleteLive(itemContent, i)"
          >
            <el-link type="danger" slot="reference" >删除</el-link>
          </el-popconfirm>
        </div>
      </div>
    </el-card>

    <div>
      <el-button
        type="primary"
        size="mini"
        style="margin-top: 10px;"
        @click="addContainer"
      >+ 直播容器</el-button>
    </div>

    <el-row type="flex" align="top" :gutter="20" style="margin: 20px 0">
      <el-col :span="7" style="text-align: left; "><span style="color: red">*</span>
        预约提醒
        <el-tooltip class="item" effect="dark" content="是否在前台展示预约提醒按钮" placement="top">
          <i class="el-icon-warning-outline"></i>
        </el-tooltip>
        ：
      </el-col>
      <el-col :span="16">
        <el-switch
          v-model="info.appointment"
          :active-value="1"
          :inactive-value="0"
          active-color="#1890ff"
          inactive-color="#d1d6dd"
          >
        </el-switch>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { LIVE_STATE_OPTION, LIVE_SORT_OPTION } from './data.js';
export default {
  name: '',
  components: {},
  props: {
    info: {
      type: Object,
      default: {

      }
    }
  },
  data () {
    return {
      btnLoading: false,
      // inputValue: '',

      liveStateList: LIVE_STATE_OPTION, // 直播状态
      sortStateList: LIVE_SORT_OPTION, // 直播排序状态
    }
  },
  computed: {},
  watch: {
  },
  created () {},
  mounted () {
    // this.inputValue = this.info.content;
  },
  methods: {
    // 添加容器
    addContainer() {
      if(this.info.contentList && this.info.contentList.length >= 5) {
        this.$message.error('最多添加5个容器')
        return
      }
      let params = {
        // liveStatus: '',
        sortKey: '',
        liveIds: '',
        inputValue: '',
        list: []
      };
      this.info.contentList.push(params);
    },
    // 立即添加按钮 & 排序修改时
    addLive(item) {
      if(!item.inputValue) {
        this.$message.warning('请输入直播id')
        return
      }
      this.btnLoading = true;
      this.$emit('liveListOnAdd', item)
    },
    // 修改直播状态
    onChangeLiveStatus(row) {
      if (row.liveStatus !== 2 && (row.sortKey === 'watchNum' || row.sortKey === 'gmvDesc')) {
        row.sortKey = null;
      }
    },
    deleteLive(row, i) {
      row.list.splice(i, 1);
      let idStr = row.list.map( item => item.id).join(',');
      row.liveIds = idStr;
      row.inputValue = idStr;
    },
    deleteContainer(row, i) {
      row.splice(i, 1)
    },
    // 移动
    onMove(arr, i ,type) {
      let item = arr.splice(i, 1);
      arr.splice(type === 'up' ? i-1 : i+1, 0, ...item);
    },
    clearBtnLoading() {
      this.btnLoading = false;
    },
    formatDays(time) {
      return time ? dayjs(Number(time)).format('YYYY-MM-DD HH:mm:ss') : ''
    },
  }
}
</script>

<style lang="scss">
.live_card {
  .el-card__body {
    padding: 10px;
  }
}
</style>
<style scoped lang="scss">
.live_list {
  padding: 10px 10px;
}
.live_card {
  margin-bottom: 10px;
  .live_card_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .select_box {
      margin-left: 10px;
      width: 130px;
    }
    .live_card_header_btn {
      margin-left: 4px;
    }
  }
  .live_card_list {
    max-height: 220px;
    overflow-y: auto;
    .live_card_line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6px;
      .left {
        display: flex;
        align-items: center;
        .name div{
          overflow:hidden;
          white-space:nowrap;
          text-overflow:ellipsis;
          width: 160px;
          line-height: 18px;
        }
        .name div:nth-of-type(1) {
          font-weight: bold;
        }
        img {
          width: 50px;
          height: 50px;
          margin-right: 10px;
        }
      }
    }
  }

}
</style>
