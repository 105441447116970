var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "static-img", style: _vm.style }, [
    _c("div", { staticClass: "static-img-slide" }, [
      _c(
        "div",
        { staticClass: "static-img-slide-box" },
        [
          _c("img", { attrs: { src: _vm.obj.list[0].img || _vm.preurl } }),
          _vm._l(_vm.obj.list[0].self, function (value, i) {
            return _c("Shade", {
              key: i,
              attrs: { value: value, title: i + 1 },
            })
          }),
        ],
        2
      ),
    ]),
    _c("div", { staticClass: "bt-drag-title" }, [
      _vm._v(" " + _vm._s(_vm.obj.showTitle) + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }