var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "addTitle" }, [
    _c("div", { staticClass: "add__title--name" }, [
      _c(
        "div",
        { staticClass: "add__name--name" },
        [
          _c("span", [_vm._v("名称")]),
          _c("el-input", {
            staticClass: "name__input",
            attrs: { placeholder: "请输入标题名称限20字以内", maxlength: "20" },
            model: {
              value: _vm.titleinfo.text,
              callback: function ($$v) {
                _vm.$set(_vm.titleinfo, "text", $$v)
              },
              expression: "titleinfo.text",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "add__name--type" },
        [
          _c("span", [_vm._v("链接")]),
          _c(
            "el-select",
            {
              staticClass: "name__select",
              attrs: { placeholder: "请选择" },
              on: { change: _vm.selectChange },
              model: {
                value: _vm.nameValue,
                callback: function ($$v) {
                  _vm.nameValue = $$v
                },
                expression: "nameValue",
              },
            },
            _vm._l(_vm.nameOptions, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.nameValue == 0,
              expression: "nameValue == 0",
            },
          ],
          staticClass: "add__name--link",
        },
        [
          _c("span", [_vm._v("链接地址")]),
          _c("el-input", {
            staticClass: "name__input",
            attrs: { placeholder: "请输入链接" },
            model: {
              value: _vm.titleinfo.link,
              callback: function ($$v) {
                _vm.$set(_vm.titleinfo, "link", $$v)
              },
              expression: "titleinfo.link",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }