var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        { attrs: { label: "跳转类型:" } },
        [
          _c(
            "el-select",
            {
              attrs: { disabled: _vm.canSetSelect },
              on: { change: _vm.changeContent },
              model: {
                value: _vm.imgInfo.type,
                callback: function ($$v) {
                  _vm.$set(_vm.imgInfo, "type", $$v)
                },
                expression: "imgInfo.type",
              },
            },
            _vm._l(_vm.selectOptions, function (item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
      !_vm.notShowInput.includes(_vm.imgInfo.type)
        ? _c(
            "el-form-item",
            { attrs: { label: "ID或链接:" } },
            [
              _vm.imgInfo.type === "coupon"
                ? _c("SearchSelectCoupon", {
                    attrs: { "not-query-exchange-coupon": 1 },
                    on: { change: _vm.setCouponKey },
                    model: {
                      value: _vm.imgInfo.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.imgInfo, "content", $$v)
                      },
                      expression: "imgInfo.content",
                    },
                  })
                : _c("el-input", {
                    staticClass: "input-w200",
                    on: { input: _vm.onInput },
                    model: {
                      value: _vm.imgInfo.content,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.imgInfo,
                          "content",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "imgInfo.content",
                    },
                  }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }