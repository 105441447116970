var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "five-img",
      style: {
        "background-color": _vm.obj.bgColor,
        "margin-bottom": _vm.obj.bMargin ? "10px" : "0",
        padding: _vm.obj.bPadding ? "20px 10px" : "20px 0",
      },
    },
    [
      _c(
        "div",
        {
          style: {
            "background-image": "url(" + _vm.obj.bgInfo.img + ")",
            "background-size": "100% 100%",
          },
        },
        [
          _c("div", { staticClass: "read-title" }),
          _c("div", { staticClass: "swiper-goods" }, [
            _c(
              "div",
              { staticClass: "swiper-goods-slide" },
              _vm._l(_vm.obj.list, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "swiper-goods-slide-item",
                    style: { width: "30%" },
                  },
                  [
                    _c("img", {
                      style: {
                        height: "150px",
                        "border-radius": "4px 4px 0 0",
                      },
                      attrs: {
                        src: [
                          item.coverImgUrl == ""
                            ? _vm.preurl
                            : item.coverImgUrl,
                        ],
                        width: "100%",
                      },
                    }),
                    _c("div", [
                      _c("p", [_vm._v(_vm._s(item.name))]),
                      _c("span", [_vm._v("￥" + _vm._s(item.salesPrice))]),
                      _c("p", { staticClass: "buy-now" }, [
                        _vm._v(" 立即购买 "),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "bt-drag-title" }, [
        _vm._v(" " + _vm._s(_vm.obj.title) + " "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }