var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "add__list addList" }, [
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "h2" }, [_vm._v(" 排序规则 ")]),
      _c(
        "div",
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.addlistinfo.sortType,
                callback: function ($$v) {
                  _vm.$set(_vm.addlistinfo, "sortType", $$v)
                },
                expression: "addlistinfo.sortType",
              },
            },
            [
              _c("el-radio", { attrs: { label: 1 } }, [_vm._v(" 综合 ")]),
              _c("el-radio", { attrs: { label: 2 } }, [_vm._v(" 销量 ")]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "content hei30" },
      [
        _c("div", { staticClass: "h2" }, [_vm._v(" 条件筛选 ")]),
        _c(
          "el-tabs",
          {
            staticStyle: { width: "100%" },
            model: {
              value: _vm.activeName,
              callback: function ($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName",
            },
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "指定区域馆", name: "first" } },
              [
                _c(
                  "div",
                  [
                    _c("el-cascader", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        props: _vm.siteOptions,
                        options: _vm.sitelist,
                        clearable: "",
                      },
                      on: { change: _vm.changeSite },
                      model: {
                        value: _vm.addlistinfo.siteList,
                        callback: function ($$v) {
                          _vm.$set(_vm.addlistinfo, "siteList", $$v)
                        },
                        expression: "addlistinfo.siteList",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "指定后台类目", name: "second" } },
              [
                _c(
                  "div",
                  [
                    _c("el-cascader", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        options: _vm.catList,
                        props: _vm.cateOptions,
                        clearable: "",
                      },
                      on: { change: _vm.changeCate },
                      model: {
                        value: _vm.addlistinfo.backendCategorys,
                        callback: function ($$v) {
                          _vm.$set(_vm.addlistinfo, "backendCategorys", $$v)
                        },
                        expression: "addlistinfo.backendCategorys",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c("el-tab-pane", { attrs: { label: "指定商家", name: "third" } }, [
              _c(
                "div",
                {
                  staticStyle: {
                    overflow: "hidden",
                    display: "flex",
                    "align-items": "flex-start",
                    margin: "0 10px",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "goods__name",
                      staticStyle: { width: "100px" },
                    },
                    [_c("p", [_vm._v("选择商家：")])]
                  ),
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder:
                        "输入商家id,可输入多个商家,多个商家id之间以英文“,”区分",
                    },
                    model: {
                      value: _vm.sellers,
                      callback: function ($$v) {
                        _vm.sellers = typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "sellers",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "20px" },
                      attrs: { size: "mini" },
                      on: { click: _vm.addSeller },
                    },
                    [_vm._v(" 添加商家 ")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "goods__list" }, [
                _c("div", { staticClass: "goods__name" }, [
                  _c("p", [_vm._v("已选：")]),
                ]),
                _c(
                  "div",
                  { staticClass: "list__content" },
                  [
                    _c(
                      "draggable",
                      {
                        attrs: { element: "div" },
                        model: {
                          value: _vm.addlistinfo.sellerIdList,
                          callback: function ($$v) {
                            _vm.$set(_vm.addlistinfo, "sellerIdList", $$v)
                          },
                          expression: "addlistinfo.sellerIdList",
                        },
                      },
                      _vm._l(
                        _vm.addlistinfo.sellerIdList,
                        function (item, index) {
                          return _c("ul", { key: index }, [
                            _c("li", [
                              _c("span", { staticClass: "item-id" }, [
                                _vm._v(_vm._s(item)),
                              ]),
                              _c(
                                "span",
                                {
                                  staticClass: "delet-btn",
                                  staticStyle: { margin: "0" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.delSeller(index)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]),
                          ])
                        }
                      ),
                      0
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }