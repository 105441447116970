<template>
  <div class="img-info">
    <el-form label-width="100px">
      <el-form-item label="上传图片:">
        <CutImgUp
          :url="imageUrlList"
          :auto-crop-width="750"
          :auto-crop-height="750"
          :max-num="1"
          @change="onSuccess"
        />
      </el-form-item>
      <!-- 跳转类型组件 -->
      <JumpType
        :img-info="imgInfo"
      />
    </el-form>
  </div>
</template>

<script>
/**
 * name: 图片上传模块
 * @param(imgData) 上传图片信息
 * @param(imgInfo.type) 跳转类型
 * @param(selectOptions) 跳转选项信息 首页--index 商品--goods 优惠券--coupon 链接--link 直播--live 商家店铺--store 城市馆--site 券包（批量领券）--coupackage
 */
import CutImgUp from '@/components/common/cutImgUp';
import JumpType from './jumpType';

import { SELECT_OPTIONS, NOT_SHOW_INPUT } from './data';

export default {
  name: 'img-info',
  components: { CutImgUp, JumpType },
  props: {
    cardtype: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        type: 'swiper',
      },
    },
    imgInfo: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        type: 1,
        content: '',
        img: '',
        width: '',
        height: '',
      },
    },
  },
  data() {
    return {
      selectOptions: SELECT_OPTIONS,
      notShowInput: NOT_SHOW_INPUT,
      canSetcouponbag: false,
      canSetSelect: false,
      couponbag: '', // 不能修改的优惠券id信息
      imageUrlList: [],
    };
  },
  mounted() {
    if (this.imgInfo.img !== '') {
      this.imageUrlList = [this.imgInfo.img];
    } else {
      this.imageUrlList = [];
    }
  },
  methods: {
    onSuccess(obj) {
      this.imgInfo.img = obj[0];
    },
  },
};
</script>

<style lang="scss" scoped>
  .img-info{
    padding: 5px 0;
    border-bottom: 1px dashed #eee;
  }
  .couponbag {
    position: relative;
    width: 246px;
    .annotation {
      position: absolute;
      left: -70px;
      top: 20px;
    }
  }
  .county-remark {
    width: 100%;
    padding-left: 30px;
    box-sizing: border-box;
    color: #666;
    margin-bottom: 30px;
  }
</style>
