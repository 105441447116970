<template>
  <div class="img-info">
    <el-form label-width="100px">
      <el-form-item label="上传图片:">
        <CutImgUp
          :url="imageUrlList"
          :auto-crop-width="750"
          :auto-crop-height="750"
          :max-num="1"
          @change="onSuccess"
        />
      </el-form-item>

      <div style="padding: 0 23px 23px">
        <el-tabs v-model="activeName">
          <el-tab-pane
            v-for="(item, i) in imgInfo.self"
            :key="i"
            :label="'区域' + (i + 1)"
            :name="'区域' + (i + 1)"
          >
            <el-card class="box-card">
              <!-- 跳转类型组件 -->
              <JumpType
                :img-info="item"
              />
              <!--删除按钮-->
              <div class="deleteBox">
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  @click="deleteSelf(i)"
                />
              </div>
            </el-card>
          </el-tab-pane>
        </el-tabs>
        <el-button
          size="mini"
          type="primary"
          @click="addSelf(imgInfo.self.length)"
        >
          添加区域
        </el-button>
      </div>

      <!--  -->
    </el-form>
  </div>
</template>

<script>
import CutImgUp from '@/components/common/cutImgUp';
import JumpType from './jumpType';

export default {
  name: 'img-info',
  components: { CutImgUp, JumpType },
  props: {
    cardtype: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        type: 'swiper',
      },
    },
    imgInfo: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        type: 1,
        content: '',
        img: '',
        width: '',
        height: '',
        self: [
          {
            top: 50,
            left: 0,
            width: 20,
            height: 20,
            type: 'goods',
            content: '',
            coupon_key: '',
            zIndex: 99,
          },
        ],
      },
    },
    // 优惠券
    // couponOptions: {
    //   type: Array,
    //   default: () => []
    // }
  },
  data() {
    return {
      activeName: '区域1',
      imgData: {
        fileList: [],
        imgCode: [],
      },
      imageUrlList: [],
    };
  },
  mounted() {
    if (this.imgInfo.img !== '') {
      this.imageUrlList = [this.imgInfo.img];
    } else {
      this.imgData.fileList = [];
    }
  },
  methods: {
    // 添加自定义区域
    addSelf(index) {
      if (index < 50) {
        let data = {
          top: 30,
          left: 0,
          width: 20,
          height: 20,
          type: 'goods',
          content: '',
        };
        this.imgInfo.self.push(data);
        this.activeName = `区域${index + 1}`;
      } else {
        this.$message({
          type: 'warning',
          message: '最多添加5个区域',
        });
      }
    },
    // 删除自定义区域
    deleteSelf(index) {
      this.$confirm(`此操作将删除区域${index + 1}, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.imgInfo.self.splice(index, 1);
          this.activeName = `区域${index < 1 ? 1 : index}`;
          this.$message({
            type: 'success',
            message: '删除成功!',
          });
        })
        .catch(() => {});
    },
    onSuccess(obj) {
      this.imgInfo.img = obj[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.img-info {
  padding: 5px 0;
  border-bottom: 1px dashed #eee;
}
.couponbag {
  position: relative;
  width: 246px;
  .annotation {
    position: absolute;
    left: -70px;
    top: 20px;
  }
}
.box-card {
  margin-bottom: 20px;
  .box-card-line {
    margin-bottom: 10px;
    .el-input-number {
      margin-right: 20px;
    }
  }
}
.county-remark {
  width: 100%;
  padding-left: 30px;
  box-sizing: border-box;
  color: #666;
  margin-bottom: 30px;
}
.deleteBox {
  text-align: right;
}
</style>
