var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "list-goods",
      style: {
        "border-bottom": _vm.obj.bMargin ? "10px solid" : "",
        "background-color": _vm.obj.bgColor,
        padding: _vm.obj.bPadding ? "16px 26px" : "16px 0",
      },
    },
    [
      _vm._t("default"),
      _vm._l(_vm.list, function (item, index) {
        return _c("div", { key: index, staticClass: "list-goods-item" }, [
          _c("img", {
            attrs: {
              src: item.goodsImage ? item.goodsImage.split(",")[0] : "",
            },
          }),
          _c("div", [
            _c("p", { staticClass: "good-name" }, [
              _vm._v(" " + _vm._s(item.goodsName) + " "),
            ]),
            _c("div", { staticStyle: { "padding-left": "0" } }, [
              _c("span", { staticClass: "group-count" }, [
                _vm._v(_vm._s(item.groupCount) + "人团"),
              ]),
              _c("span", { staticClass: "discount" }, [
                _vm._v("拼团立省" + _vm._s(item.discountFee) + "元"),
              ]),
            ]),
            _c("p", { staticClass: "good-price" }, [
              _vm._v(" ￥ " + _vm._s(item.sellPrice) + " "),
            ]),
          ]),
        ])
      }),
      _c("div", { staticClass: "bt-drag-title" }, [
        _vm._v(" " + _vm._s(_vm.obj.showTitle) + " "),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }