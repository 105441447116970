var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "product_module_tool" },
    [
      _c(
        "el-form",
        { attrs: { model: _vm.info, "label-width": "120px" } },
        [
          _c(
            "el-form-item",
            { staticClass: "name-box", attrs: { label: "模块样式：" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.info.resource,
                    callback: function ($$v) {
                      _vm.$set(_vm.info, "resource", $$v)
                    },
                    expression: "info.resource",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(" 一行一个 "),
                  ]),
                  _c("el-radio", { attrs: { label: 2 } }, [
                    _vm._v(" 一行两个 "),
                  ]),
                  _c("el-radio", { attrs: { label: 3 } }, [
                    _vm._v(" 一行三个 "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.info.moduleList, function (item, index) {
            return _c(
              "div",
              { key: index, staticClass: "list-item" },
              [
                _c("el-form-item", {
                  staticClass: "title",
                  attrs: {
                    "label-width": "100px",
                    label: "模块" + (index + 1) + "：",
                  },
                }),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "100px",
                      label: "主标题：",
                      placeholder: "请输入主标题",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: { maxlength: "6", "show-word-limit": "" },
                      model: {
                        value: item.title,
                        callback: function ($$v) {
                          _vm.$set(item, "title", $$v)
                        },
                        expression: "item.title",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "100px",
                      label: "副标题：",
                      placeholder: "请输入副标题",
                      maxlength: "12",
                      "show-word-limit": "",
                    },
                  },
                  [
                    _c("el-input", {
                      model: {
                        value: item.text,
                        callback: function ($$v) {
                          _vm.$set(item, "text", $$v)
                        },
                        expression: "item.text",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { "label-width": "100px", label: "模块图片：" } },
                  [
                    _c("loadImgOssUp", {
                      attrs: {
                        name: "a" + index,
                        url: item.titleImg ? [item.titleImg] : [],
                        "max-num": 1,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.uoload($event, item)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "100px",
                      label: "入口图：",
                      prop: "categoryId",
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          filterable: "",
                          remote: "",
                          "reserve-keyword": "",
                          placeholder: "请输入关键词搜索入口图",
                          "remote-method": _vm.remoteMethod,
                          loading: _vm.loading,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.changeCategory(item)
                          },
                        },
                        model: {
                          value: item.categoryId,
                          callback: function ($$v) {
                            _vm.$set(item, "categoryId", $$v)
                          },
                          expression: "item.categoryId",
                        },
                      },
                      _vm._l(_vm.categoryOptions, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c("el-form-item", {
                  staticClass: "title",
                  attrs: { "label-width": "120px", label: "下级页面配置：" },
                }),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "跳转链接：",
                      "label-width": "100px",
                      prop: "link",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入下级页面跳转链接" },
                      model: {
                        value: item.link,
                        callback: function ($$v) {
                          _vm.$set(item, "link", $$v)
                        },
                        expression: "item.link",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "action-move" }, [
                  index !== 0
                    ? _c(
                        "span",
                        {
                          staticClass: "actions_up",
                          on: {
                            click: function ($event) {
                              return _vm.upItem(item, index)
                            },
                          },
                        },
                        [_vm._v(" 上移 ")]
                      )
                    : _vm._e(),
                  index !== _vm.info.moduleList.length - 1
                    ? _c(
                        "span",
                        {
                          staticClass: "actions_down",
                          on: {
                            click: function ($event) {
                              return _vm.downItem(item, index)
                            },
                          },
                        },
                        [_vm._v(" 下移 ")]
                      )
                    : _vm._e(),
                ]),
                _vm.info.moduleList.length > 1
                  ? _c(
                      "span",
                      {
                        staticClass: "actions_b",
                        on: {
                          click: function ($event) {
                            return _vm.deleteitem(item, index)
                          },
                        },
                      },
                      [_vm._v(" 删除 ")]
                    )
                  : _vm._e(),
                _vm.info.moduleList.length === index + 1
                  ? _c(
                      "span",
                      {
                        staticClass: "actions_add",
                        on: {
                          click: function ($event) {
                            return _vm.add()
                          },
                        },
                      },
                      [_vm._v(" 添加 ")]
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }