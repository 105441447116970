<template>
  <div
    class="up-down-pre"
    :style="{'background-color': obj.bgColor, 'margin-bottom': obj.bMargin ? '10px' : '0', 'padding': obj.bPadding ? '20px 10px' : '20px 0'}"
  >
    <div class="up">
      <img
        :src="[obj.list[0].img == ''? preurl1 : obj.list[0].img]"
        alt=""
      >
      <img
        :src="[obj.list[1].img == ''? preurl1 : obj.list[1].img]"
        alt=""
      >
    </div>
    <div class="down">
      <img
        :src="[obj.list[2].img == ''? preurl2 : obj.list[2].img]"
        alt=""
      >
      <img
        :src="[obj.list[3].img == ''? preurl2 : obj.list[3].img]"
        alt=""
      >
      <img
        :src="[obj.list[4].img == ''? preurl2 : obj.list[4].img]"
        alt=""
      >
    </div>
    <div class="bt-drag-title">
      {{ obj.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'UpDownPre',
  props: {
    obj: {
      type: Object,
      default: {
        list: [
          { img: '' },
          { img: '' },
          { img: '' },
          { img: '' },
          { img: '' }
        ],
        bgColor: '#fff'
      }
    }
  },
  data() {
    return {
      preurl1: 'https://img.hzanchu.com/acimg/5fd796e2875986f707d4855de82e686e.png?x-oss-process=image/resize,w_300',
      preurl2: 'https://img.hzanchu.com/acimg/0361b69f4bc94733976c63f9696bafaa.png?x-oss-process=image/resize,w_300'

    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.up{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    img{
        width: 250px;
        height: 85px;
        padding: 0 5px;
    }
}
.down{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    img{
        width: 156px;
        height: 85px;
        padding: 0 5px;
    }
}
</style>
