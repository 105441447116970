<template>
  <div class="panel">
    <h1>内容编辑</h1>
    <h4 class="panelTitle">
      <b
        v-if="!inputShow"
        onselectstart="return false;"
        @dblclick="editTitle"
      >{{ info.title }}</b>
      <el-input
        v-if="inputShow"
        v-model="info.title"
        class="title__input"
        :autofocus="inputShow"
        @blur="blurName"
      />
      <span
        v-if="!info.isPageConfig"
        @click="delModule"
      >删除模块</span><i class="iconfont icon-cha"></i>
    </h4>
    <slot></slot>
  </div>
</template>

<script>
/**
 * name: 模块容器
 * method: delModule 删除模块
 * method: editTitle 编辑标题
 */
export default {
  name: 'panel',

  props: {
    info: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    },
  },

  data() {
    return {
      // 模块名称--编辑--true 可编辑， false 不可编辑
      inputShow: false,
    };
  },

  methods: {
    // 编辑模块标题
    editTitle(e) {
      this.inputShow = true;
    },
    // 编辑模块标题完成
    inputBlur() {
      this.inputShow = false;
    },
    // 删除模块
    delModule() {
      this.$emit('delModuleEvent');
    },
    blurName() {
      this.inputShow = false;
    },
  },
};
</script>

<style scoped lang="scss">
  .panel {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 4px;
    box-sizing: border-box;
    h1 {
      height: 40px;
      line-height: 40px;
      background: rgba(50, 65, 87, 1);
      text-align: center;
      color: #fff;
      font-size: 14px;
    }
  }

  .panelTitle {
    padding: 0 12px;
    font-size: 18px;
    height: 38px;
    line-height: 38px;
    color: #3C3C3C;
    border-bottom: 1px solid #eee;
    background: rgba(237, 237, 237, 1);
    margin: 14px 23px 0;
    border-radius: 6px;
    & i {
      float: right;
      font-size: 10px;
    }
    & b {
      font-weight: 500;
    }
    & span {
      float: right;
      margin-left: 4px;
      font-size: 16px;
      cursor: pointer;
    }
    .title__input {
      width: 200px;
    }
  }
</style>
