var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "map-button" }, [
    _c("div", { staticClass: "btn" }, [
      _c("img", { attrs: { src: _vm.obj.imageUrl } }),
    ]),
    _c("div", { staticClass: "bt-drag-title" }, [
      _vm._v(" " + _vm._s(_vm.obj.title) + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }