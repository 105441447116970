var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "swiper_skill_content",
      style: {
        "background-color": _vm.obj.bgColor,
        "margin-bottom": _vm.obj.bMargin ? "10px" : "0",
        padding: _vm.obj.bPadding ? "20px 10px" : "20px 0",
      },
    },
    [
      _c(
        "div",
        {
          style: {
            "background-image": "url(" + _vm.obj.bgInfo.img + ")",
            "background-size": "100% 100%",
          },
        },
        [
          _vm.obj.list.length > 0
            ? _c(
                "ul",
                { staticClass: "skill_goods_content" },
                [
                  _c(
                    "div",
                    { staticClass: "date_list" },
                    _vm._l(_vm.obj.list, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index + "time",
                          staticClass: "date_item",
                          style:
                            _vm.group_id == item.id
                              ? { "background-color": "red" }
                              : { "background-color": "#fff" },
                          on: {
                            click: function ($event) {
                              return _vm.changeTime(item.id)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.startTime) + " ")]
                      )
                    }),
                    0
                  ),
                  _vm._l(_vm.obj.list, function (item, index) {
                    return _c("li", { key: index }, [
                      _c(
                        "div",
                        { staticClass: "goods_list" },
                        _vm._l(item.goodsDetail, function (goods, index2) {
                          return _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.group_id == item.id,
                                  expression: "group_id == item.id",
                                },
                              ],
                              key: index2,
                              staticClass: "list_item",
                            },
                            [
                              _c("img", {
                                style: {
                                  height: "180px",
                                  "border-radius": "4px 4px 0 0",
                                },
                                attrs: {
                                  src: [
                                    goods.imageUrls.split(",")[0] == ""
                                      ? _vm.preurl
                                      : goods.imageUrls.split(",")[0] +
                                        "?x-oss-process=image/resize,w_200",
                                  ],
                                },
                              }),
                              _c("div", [
                                _c("p", { staticClass: "title" }, [
                                  _vm._v(" " + _vm._s(goods.name) + " "),
                                ]),
                                _c("p", { staticClass: "inventory" }, [
                                  _vm._v(" 仅剩" + _vm._s(goods.stock) + "件 "),
                                ]),
                                _c("p", { staticClass: "sell_price" }, [
                                  _vm._v(" ￥ " + _vm._s(goods.price) + " "),
                                  _c("span", { staticClass: "line_throw" }, [
                                    _vm._v(_vm._s(goods.marketPrice)),
                                  ]),
                                ]),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  }),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
      _c("div", { staticClass: "bt-drag-title" }, [
        _vm._v(" " + _vm._s(_vm.obj.title) + " "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }