var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "my-swiper",
      style: {
        "border-bottom": _vm.obj.bMargin ? "10px solid" : "",
        "border-color": _vm.obj.bgColor,
      },
    },
    [
      _c(
        "el-carousel",
        { attrs: { height: "250px" } },
        _vm._l(_vm.obj.list, function (item, index) {
          return _c("el-carousel-item", { key: index }, [
            _c("img", {
              attrs: {
                src: [
                  item.img == ""
                    ? _vm.preurl
                    : item.img + "?x-oss-process=image/resize,w_375",
                ],
              },
            }),
          ])
        }),
        1
      ),
      _c("div", { staticClass: "bt-drag-title" }, [
        _vm._v(" " + _vm._s(_vm.obj.title) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }