<template>
  <div
    class="static-img"
    :style="{'border-bottom': obj.bMargin?'10px solid':'','border-color': obj.bgColor,'padding': obj.bPadding?'1px 26px 1px 21px':'1px 5px 1px 0'}"
  >
    <div class="static-img-slide">
      <img
        v-for="(item,index) in obj.list"
        :key="index"
        :src="[item.img===''?preurl:item.img + '?x-oss-process=image/resize,w_375']"
        :style="{'min-height': obj.size}"
      >
    </div>
    <div class="bt-drag-title">
      {{ obj.title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    obj: {
      type: Object,
      default: () => ({
        size: '212px',
        bMargin: false,
        bgColor: '#fff',
        bPadding: false,
        title: '静态图',
        list: [{
          type: '',
          img: '',
          content: ''
        }]
      })
    }
  },
  data() {
    return {
      preurl: 'https://img.hzanchu.com/acimg/98967e322848c8cb4f979be43187af6f.jpg?x-oss-process=image/resize,w_300'
    }
  }
}
</script>

<style scoped lang="scss">
  .static-img{
    overflow: hidden;
    box-sizing:border-box;
    width:100%;
    .static-img-slide{
      overflow-x:auto;
      // display: flex;
      img{
        width: 100%;
        // margin:5px;
      }
    }
  }
</style>
