var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      style: { width: _vm.width },
      attrs: {
        disabled: _vm.disabled,
        remote: "",
        clearable: "",
        placeholder: "请选择优惠券",
        "remote-method": _vm.remoteMethod,
        loading: _vm.loading,
        size: _vm.size,
      },
      on: { change: _vm.changeSelect },
      model: {
        value: _vm.id,
        callback: function ($$v) {
          _vm.id = $$v
        },
        expression: "id",
      },
    },
    _vm._l(_vm.options, function (item, i) {
      return _c("el-option", {
        key: i,
        attrs: {
          label: `${item.couponName}（id=${item.couponId}）`,
          value: item.couponId,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }