var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "right-coupon" },
    [
      _c(
        "el-form",
        { attrs: { model: _vm.infos, "label-width": "120px" } },
        [
          _c(
            "el-form-item",
            { staticClass: "name-box", attrs: { label: "展示字段：" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.infos.resource,
                    callback: function ($$v) {
                      _vm.$set(_vm.infos, "resource", $$v)
                    },
                    expression: "infos.resource",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v("样式一：优惠劵名、领取按钮（适用于劵包）"),
                  ]),
                  _c("el-radio", { attrs: { label: 2 } }, [
                    _vm._v("样式二：优惠劵名、面额、使用条件、领取按钮"),
                  ]),
                  _c("el-radio", { attrs: { label: 4 } }, [
                    _vm._v("样式三：图片、优惠劵名、面额、使用条件"),
                  ]),
                  _c("el-radio", { attrs: { label: 3 } }, [
                    _vm._v(
                      "样式四：优惠劵类型、优惠劵名、面额、使用条件、劵有效期、领取按钮"
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "预约提醒：" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.infos.reserve,
                  callback: function ($$v) {
                    _vm.$set(_vm.infos, "reserve", $$v)
                  },
                  expression: "infos.reserve",
                },
              }),
              _c("p", { staticStyle: { color: "#999" } }, [
                _vm._v("您预约的￥优惠劵展示名称￥即将开抢"),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "优惠劵背景：" } },
            [
              _c("loadImgOssUp", {
                attrs: {
                  name: "videoMainPicUrl",
                  url: _vm.infos.bgImg ? [_vm.infos.bgImg] : [],
                  maxNum: 1,
                },
                on: {
                  change: function ($event) {
                    return _vm.uploadImg($event, "bgImg")
                  },
                },
              }),
              _c("div", { staticStyle: { width: "100%", color: "#999" } }, [
                _vm._v("请上传654*274尺寸的图片"),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-bottom": "50px" },
              attrs: { label: "优惠劵/劵包：" },
            },
            [
              _c(
                "el-select",
                {
                  attrs: { size: "mini", placeholder: "请选择" },
                  model: {
                    value: _vm.infos.useType,
                    callback: function ($$v) {
                      _vm.$set(_vm.infos, "useType", $$v)
                    },
                    expression: "infos.useType",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "优惠劵ID", value: 1 } }),
                  _c("el-option", { attrs: { label: "劵包ID", value: 2 } }),
                ],
                1
              ),
              _c("el-input", {
                attrs: {
                  placeholder: `请输入${
                    _vm.infos.useType === 1 ? "优惠劵" : "劵包"
                  }ID`,
                  type: "textarea",
                  rows: 4,
                  size: "mini",
                  clearable: "",
                },
                model: {
                  value: _vm.useTypeContent,
                  callback: function ($$v) {
                    _vm.useTypeContent = $$v
                  },
                  expression: "useTypeContent",
                },
              }),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: {
                    type: "primary",
                    size: "mini",
                    disabled: !_vm.useTypeContent,
                    loading: _vm.addLoad,
                  },
                  on: { click: _vm.addCoupon },
                },
                [_vm._v("添加")]
              ),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData,
                    "row-key": "id",
                    size: "mini",
                    border: "",
                  },
                },
                [
                  _c("el-table-column", { attrs: { label: "ID", prop: "id" } }),
                  _c("el-table-column", {
                    attrs: {
                      label: "优惠劵名称",
                      prop: "couponName",
                      width: "90",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "优惠劵展示名",
                      prop: "couponDisplayName",
                      width: "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("el-input", {
                              attrs: {
                                placeholder: row.couponName,
                                size: "mini",
                              },
                              model: {
                                value: row.couponDisplayName,
                                callback: function ($$v) {
                                  _vm.$set(row, "couponDisplayName", $$v)
                                },
                                expression: "row.couponDisplayName",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "类型", prop: "types" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "面额", prop: "discountsValue" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "使用条件", prop: "discountsCondition" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "有效期", prop: "times", width: "140" },
                  }),
                  _vm.infos.resource === 4
                    ? _c("el-table-column", {
                        attrs: {
                          label: "图片",
                          prop: "imageUrl",
                          width: "120",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  _c("upImageNew", {
                                    attrs: {
                                      imageList: row.imageUrl,
                                      type: "string",
                                    },
                                    on: {
                                      "update:imageList": function ($event) {
                                        return _vm.updateImageUrl(row, $event)
                                      },
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2203774839
                        ),
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "140" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row, $index }) {
                          return [
                            row.sort !== _vm.tableData.length - 1
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "hanle",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handle(row, "down")
                                      },
                                    },
                                  },
                                  [_vm._v("下移")]
                                )
                              : _vm._e(),
                            row.sort !== 0
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "hanle",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handle(row, "up")
                                      },
                                    },
                                  },
                                  [_vm._v("上移")]
                                )
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                staticClass: "hanle",
                                on: {
                                  click: function ($event) {
                                    return _vm.handle(row, "delete")
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }