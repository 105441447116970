<template>
  <!-- 频道页的商品模块组件 数据编辑页面 -->
  <div class="product_module_tool">
    <el-form
      :model="info"
      label-width="120px"
    >
      <el-form-item
        class="name-box"
        label="模块样式："
      >
        <el-radio-group v-model="info.resource">
          <el-radio :label="1">
            一行一个
          </el-radio>
          <el-radio :label="2">
            一行两个
          </el-radio>
          <el-radio :label="3">
            一行三个
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <div
        v-for="(item, index) in info.moduleList"
        :key="index"
        class="list-item"
      >
        <el-form-item
          class="title"
          label-width="100px"
          :label="'模块' + (index + 1) + '：'"
        />
        <!-- <el-form-item
          label-width="100px"
          class="name-box"
          label="模块样式："
        >
          <el-radio-group v-model="item.titleType">
            <el-radio :label="1">
              文字
            </el-radio>
            <el-radio :label="2">
              图片
            </el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item
          label-width="100px"
          label="主标题："
          placeholder="请输入主标题"
        >
          <el-input
            v-model="item.title"
            maxlength="6"
            show-word-limit
          />
        </el-form-item>
        <el-form-item
          label-width="100px"
          label="副标题："
          placeholder="请输入副标题"
          maxlength="12"
          show-word-limit
        >
          <el-input v-model="item.text" />
        </el-form-item>
        <el-form-item
          label-width="100px"
          label="模块图片："
        >
          <loadImgOssUp
            :name="'a' + index"
            :url="item.titleImg ? [item.titleImg] : []"
            :max-num="1"
            @change="uoload($event, item)"
          />
        </el-form-item>
        <el-form-item
          label-width="100px"
          label="入口图："
          prop="categoryId"
        >
          <el-select
            v-model="item.categoryId"
            filterable
            remote
            reserve-keyword
            placeholder="请输入关键词搜索入口图"
            :remote-method="remoteMethod"
            :loading="loading"
            style="width: 100%"
            @change="changeCategory(item)"
          >
            <el-option
              v-for="item in categoryOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          class="title"
          label-width="120px"
          label="下级页面配置："
        />
        <el-form-item
          label="跳转链接："
          label-width="100px"
          prop="link"
        >
          <el-input
            v-model="item.link"
            placeholder="请输入下级页面跳转链接"
          />
        </el-form-item>
        <div class="action-move">
          <span
            v-if="index !== 0"
            class=" actions_up"
            @click="upItem(item, index)"
          >
            上移
          </span>
          <span
            v-if="index !== info.moduleList.length - 1"
            class=" actions_down"
            @click="downItem(item, index)"
          >
            下移
          </span>
        </div>
        <!-- <i
          v-if="info.moduleList.length > 1"
          class="el-icon-minus actions_b"
          @click="deleteitem(item, index)"
        ></i>
        <i
          v-if="info.moduleList.length === index + 1"
          class="el-icon-plus actions_b"
          @click="add()"
        ></i> -->
        <span
          v-if="info.moduleList.length > 1"
          class="actions_b"
          @click="deleteitem(item, index)"
        >
          删除
        </span>
        <span
          v-if="info.moduleList.length === index + 1"
          class="actions_add"
          @click="add()"
        >
          添加
        </span>
      </div>
    </el-form>
  </div>
</template>

<script>
import loadImgOssUp from '@/components/common/loadImgOssUp';

export default {
  name: 'product-module-tool',
  components: { loadImgOssUp },
  props: {
    info: {
      type: Object,
      default: () => ({
        content: '',
        richBgColor: '#fff',
      }),
    },
  },
  data() {
    return {
      loading: false,
      categoryOptions: [],
    };
  },
  watch: {},
  mounted() {
    // 初始化数据
    this.getModuleList();
  },
  methods: {
    // 上移模块
    upItem(item, index) {
      const arr = this.info.moduleList;
      const temp = arr[index - 1];
      arr[index - 1] = item;
      arr[index] = temp;
      this.info.moduleList = [];
      this.info.moduleList = this.info.moduleList.concat(arr);
    },
    // 下移模块
    downItem(item, index) {
      const arr = this.info.moduleList;
      const temp = arr[index + 1];
      arr[index + 1] = item;
      arr[index] = temp;
      this.info.moduleList = [];
      this.info.moduleList = this.info.moduleList.concat(arr);
    },
    changeCategory(item) {
      this.$axios
        .get(this.$api.subjectChannel.entrance_preview, {
          params: {
            contentPageNum: 1,
            contentPageSize: 50,
            id: item.categoryId,
          },
        })
        .then((res) => {
          if (res.code === 0) {
            const newdata = item;
            newdata.categoryName = res.data.name;
            newdata.categoryInfor = {
              supplierName: res.data.name, // 入口名称
              principalType: res.data.appliedPosition, // 应用位置
              advertisingSpace: res.data.positionId, // 广告位
              entryChartStyle: res.data.entranceStyle, // 入口图样式 1一行一个 2一行两个 3一行三个 4一行四个 5一行五个 6一行六个
              groupType: res.data.groupType, // 分组类型 1.店铺 2.商品s
              shopGoods: res.data.contents.records.map((value) => {
                return {
                  goodsId: value.contentId, // 商品或者店铺id
                  goodsName: value.name, // 商品或者店铺名称
                  goodsImg: value.entranceImg, // 商品或者店铺图片
                  goodsPrice: res.data.groupType === 3 ? value.lowPromPrice : value.price, // 商品价格
                };
              }), // 店铺商品组
              shopGoodsPC: [], // 店铺商品组PC使用的数据
              dynamicEffect: res.data.dynamicEffect, // 动态效果
            };
            // 计算数组长度需要增加的数量
            const remainder = item.categoryInfor.shopGoods.length
            % item.categoryInfor.entryChartStyle;
            let num = remainder ? item.categoryInfor.entryChartStyle - remainder : 0;
            let data = item.categoryInfor.shopGoods.slice(0, num);
            newdata.categoryInfor.shopGoods = item.categoryInfor.shopGoods.concat(data);
            // console.log(num);
            let lang = item.categoryInfor.shopGoods.length / item.categoryInfor.entryChartStyle;
            for (let index = 0; index < lang; index += 1) {
              let list = {
                list: item.categoryInfor.shopGoods.slice(
                  index * item.categoryInfor.entryChartStyle,
                  (index + 1) * item.categoryInfor.entryChartStyle,
                ),
              };
              newdata.categoryInfor.shopGoodsPC = item.categoryInfor.shopGoodsPC.concat(list);
            }
            // 解决elementUI的bug  2个轮播的时候会出现bug  所以当数据为2的时候要复制一份数据进去
            if (newdata.categoryInfor.shopGoodsPC.length === 2) {
              newdata.categoryInfor.shopGoodsPC = newdata.categoryInfor.shopGoodsPC.concat(
                newdata.categoryInfor.shopGoodsPC,
              );
            }
          }
        });
    },
    // 添加模块
    add() {
      this.info.moduleList.push({
        titleType: 1,
        title: '',
        text: '',
        titleImg: '', // 标题图片
        categoryId: '', // 入口图
        categoryInfor: {}, // 入口图详情
        link: '', // 下级页面跳转链接
      });
    },
    // 删除模块
    deleteitem(item, index) {
      this.$confirm('是否确认删除该模块?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.info.moduleList.splice(index, 1);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // 上传图片
    uoload(urls, item) {
      const newdata = item;
      /* item.titleImg = urls[0]; */
      [newdata.titleImg] = urls;
      // this.checkdata()
    },
    // 搜索
    remoteMethod(query) {
      this.getModuleList(query);
    },
    // 获取模块列表
    getModuleList(name) {
      this.loading = true;
      this.$axios
        .get(this.$api.subjectChannel.entrance_page, {
          params: {
            currentPage: 1,
            pageSize: 20,
            appliedPosition: 2, // 应用位置 1广告 2专题  这里限制只要专题模板的
            name,
          },
        }).then((res) => {
          if (res.code === 0) {
            this.categoryOptions = [];
            let data = res.data.records.map((item) => {
              return {
                ...item,
                label: item.name,
                value: item.id,
              };
            });
            this.categoryOptions = this.categoryOptions.concat(data);
            // 把数组里的每一个入口图添加进选择数据源
            let souceData = this.info.moduleList.reduce((step, item) => {
              let newindex = this.categoryOptions.findIndex((value) => {
                return value.id === item.categoryId;
              });
              if (newindex === -1 && item.categoryId) {
                step.push({
                  label: item.categoryName,
                  value: item.categoryId,
                });
              }
              return step;
            }, []);
            // console.log(souceData);
            // 去重操作
            let uniqueSourceData = [...new Set(souceData.map((item) => JSON.stringify(item)))]
              .map((item) => JSON.parse(item));
            this.categoryOptions = this.categoryOptions.concat(uniqueSourceData);
            this.loading = false;
          } else {
            this.$message(res.error_msg);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.product_module_tool {
  .list-item {
    position: relative;
    background: #f5f5f5;
    width: calc(100% - 120px);
    margin-left: 60px;
    padding-right: 10px;
    border-radius: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;

    .title {
      margin-bottom: 0;
    }

    .el-icon-plus {
      position: absolute;
      right: -30px;
      bottom: 10px;
      font-size: 20px;
      color: #1890ff;
      cursor: pointer;
    }

    .el-icon-minus {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 20px;
      color: #1890ff;
      cursor: pointer;
    }
    .action-move{
      position: absolute;
      right: 55px;
      top: 10px;
      font-size: 20px;
      color: #1890ff;
      cursor: pointer;
    }
    .actions_add{
      position: absolute;
      right: -45px;
      bottom: 10px;
      font-size: 20px;
      color: #1890ff;
      cursor: pointer;
    }

    .actions_b {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 20px;
      color: #1890ff;
      cursor: pointer;
    }
  }
}
</style>
