var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "three-img-pre",
      style: {
        "background-color": _vm.obj.bgColor,
        "margin-bottom": _vm.obj.bMargin ? "10px" : "0",
        padding: _vm.obj.bPadding ? "20px 10px" : "20px 0",
      },
    },
    [
      _c("img", {
        attrs: {
          src: [_vm.obj.list[0].img == "" ? _vm.preurl1 : _vm.obj.list[0].img],
          alt: "",
        },
      }),
      _c("img", {
        attrs: {
          src: [_vm.obj.list[1].img == "" ? _vm.preurl1 : _vm.obj.list[1].img],
          alt: "",
        },
      }),
      _c("img", {
        attrs: {
          src: [_vm.obj.list[2].img == "" ? _vm.preurl1 : _vm.obj.list[2].img],
          alt: "",
        },
      }),
      _c("div", { staticClass: "bt-drag-title" }, [
        _vm._v(" " + _vm._s(_vm.obj.title) + " "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }