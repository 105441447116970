<template>
  <div class="rich-text-pre" :style="wrapSty">
    <div class="content" :style="contentSty" v-html="obj.content"></div>
  </div>
</template>

<script>
export default {
  props: {
    obj: {
      type: Object,
      default: () => ({
        content: ''
      }),
    },
  },
  computed: {
    wrapSty() {
        return {
            backgroundColor: this.obj.bgColor,
            padding: this.obj.bPadding ? '10px 12px' : 0
        }
    },
    contentSty() {
        return {
            backgroundColor: this.obj.richBgColor
        }
    }
  },
  mounted() {
    
  }
};
</script>

<style lang="scss" scoped>
.rich-text-pre {
  min-height: 64px;
  .content {
    padding: 10px;
    overflow: hidden;
    border-radius: 12px;
  }
}
</style>