<template>
  <div class="appointment_view" >
    <div style="padding-left: 100px; line-height: 18px">
      <!-- 预约组件的核心是下方的悬浮按钮，h5也只会展示悬浮按钮；<br>操作步骤：<br>1、编辑右边活动名称、上传状态图片；<br>2、拖拽到指定位置； -->
      注：1，预约组件下方必须是<b>静态图</b>或<b>自定义静态图</b>组件；<br>
      2，此高度不会在前端展示，只展示下方悬浮按钮；
    </div>
    <div class="vessel">

        <!-- <img
          class="btn"
          :style="{'top': item.style.top + 'px', 'right': item.style.right + 'px'}"

          :src="item.imageFront"
          alt=""> -->

      <!-- 兜底图片 -->
      <VueDragResize
        v-for="(item,i) in obj.list"
        :key="i"
        ref="VueDragResize"
        :isActive="false"
        :isDraggable="true"
        :isResizable="isResizable"
        :w="item.style.width"
        :h="item.style.height"
        :x="item.style.left"
        :y="item.style.top"
        :minw="10"
        :minh="10"
        :z="999"
        :snapToGrid="true"
        :gridX="5"
        :gridY="5"
        :parentW="375"
        @resizing="(e)=>resize(e,item)"
        @dragging="(e)=>resize(e,item)"
        @dragstop="(e)=>dragstop(e,item)">
          <img
            class=""
            :style="`width: 100%; height: 100%; border: ${item.imageFront[0] ? 0 : 1}px solid #000`"
            :src="item.imageFront[0]" alt="">
      </VueDragResize>

      <!-- <img
        class="btn"
        style="width: 100px; height: 100px;"
        v-if="!obj.list || obj.list.length === 0 || !obj.list[0].imageFront"
        src="https://wsnbh.oss-cn-hangzhou.aliyuncs.com/default/1666751306192.png?x-oss-process=image/resize,p_100" alt=""> -->
    </div>
    <!-- 左上角标题 -->
    <div class="bt-drag-title">
      {{ obj.showTitle }}
    </div>
  </div>
</template>

<script>
import VueDragResize from 'vue-drag-resize'
export default {
  components: {
    VueDragResize
  },
  props: {
    obj: {
      type: Object,
      default: () => ({

      })
    }
  },
  watch: {
    top(val) {

    }
  },
  data() {
    return {
      isResizable: true,
      width: 0,
      height: 0,
      top: 0,
      left: 0
    }
  },
  methods: {
    resize(newRect, row) {
      // this.width = newRect.width;
      // this.height = newRect.height;
      // this.top = newRect.top;
      // this.left = newRect.left;

      row.style.top = newRect.top;
      row.style.left = newRect.left;
      row.style.width = newRect.width;
      row.style.height = newRect.height;
    },
    dragstop(newRect, row) {
      // console.log(111111111)

      // if(newRect.left <= 0) {
      //   this.$refs['VueDragResize'].left = 0;
      //   // this.isResizable = false;
      // }else if(this.left >= 375) {
      //   this.$refs['VueDragResize'].left = 375;
      // }
      // if(this.top <= 0) {
      //   this.$refs['VueDragResize'].top = 0;
      // }
      // this.$refs['VueDragResize'].width = this.width + 'px';
      // this.$refs['VueDragResize'].height = this.height + 'px';
    }
  }
}
</script>

<style scoped lang="scss">
  .appointment_view {
    position: relative;
    width: 100%;
    height: 66px;
    .vessel {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 0;
      width: 0;
      .btn {
        position: absolute;
        top: 0;
        left: 0;
        // width: 50px;
        // height: 50px;
        z-index: 999;
        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
</style>
