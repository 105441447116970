var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "img-info" },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "100px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "上传图片:" } },
            [
              _c("CutImgUp", {
                attrs: {
                  url: _vm.imageUrlList,
                  "auto-crop-width": 750,
                  "auto-crop-height": 750,
                  "max-num": 1,
                },
                on: { change: _vm.onSuccess },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { padding: "0 23px 23px" } },
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                _vm._l(_vm.imgInfo.self, function (item, i) {
                  return _c(
                    "el-tab-pane",
                    {
                      key: i,
                      attrs: {
                        label: "区域" + (i + 1),
                        name: "区域" + (i + 1),
                      },
                    },
                    [
                      _c(
                        "el-card",
                        { staticClass: "box-card" },
                        [
                          _c("JumpType", { attrs: { "img-info": item } }),
                          _c(
                            "div",
                            { staticClass: "deleteBox" },
                            [
                              _c("el-button", {
                                attrs: {
                                  type: "danger",
                                  icon: "el-icon-delete",
                                  circle: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteSelf(i)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.addSelf(_vm.imgInfo.self.length)
                    },
                  },
                },
                [_vm._v(" 添加区域 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }