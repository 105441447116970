<template>
  <div class="video-info">
    <el-form label-width="100px">
      <el-form-item label="视频上传:">
        <UploadVideoOss
          :url="info.videoUrl"
          @success="videoSuccess"
        />
      </el-form-item>
      <el-form-item label="视频链接:">
        {{ info.videoUrl }}
      </el-form-item>
      <el-form-item label="视频封面图:">
        <CutImgUp
          :url="imageUrlList"
          :auto-crop-width="750"
          :auto-crop-height="397"
          :max-num="1"
          @change="onSuccess"
        />
      </el-form-item>
      <el-form-item label="视频高度:">
        <el-input-number
          v-model="info.videoHeight"
          size="mini"
          :min="190"
        />
        <p>*视频高度参数用于PC端活动页，移动端页面请使用默认值190</p>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
/**
 * name: 视频上传模块
 */
import UploadVideoOss from '@/components/common/uploadVideoOss/index.vue'
import CutImgUp from '@/components/common/cutImgUp'

export default {
  name: 'VideoInfo',
  components: {
    UploadVideoOss, CutImgUp
  },
  props: {
    info: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        videoId: '',
        coverURL: '',
        videoUrl: '',
        videoHeight: 190
      }
    }
  },
  data() {
    return {
      imgData: {
        fileList: [],
        imgCode: []
      },
      imageUrlList: []
    }
  },
  watch: {
    'imgData.fileList': function(val) {
      if (val.length > 0) {
        // 兼容上传组件imgCode中无url情况
        if (val[0].type) {
          this.info.coverURL = val[0].url
        } else {
          this.info.coverURL = val[0].response.data.url
        }
      } else {
        this.info.img = ''
      }
    }
  },
  mounted() {
    if (this.info.coverURL != '') {
      this.imageUrlList = [this.info.coverURL]
      // this.imgData.fileList.push({
      //   name: '图片',
      //   type: true,
      //   url: this.info.coverURL
      // })
    } else {
      this.imageUrlList = []
      // this.imgData.fileList = []
    }
  },
  methods: {
    // 获取视频id
    getVedioId(val) {
      this.info.videoId = val
    },
    // 获取视频详情
    getVdInfo() {
      this.$axios.get(this.$api.getVdUrl, {
        params: {
          video_id: this.info.videoId
        }
      }).then((res) => {
        let r = res
        if (r.error === 0) {
          this.info.coverURL = r.data.VideoBase.CoverURL
          this.info.videoUrl = r.data.PlayInfoList.PlayInfo[0].PlayURL
          // console.log(this.info)
          this.$message('获取视频信息成功')
        }
      })
    },
    videoSuccess(value) {
      this.info.videoUrl = value
      this.info.videoId = value
    },
    onSuccess(obj) {
      this.info.coverURL = obj[0]
    }

  }
}
</script>
<style lang="scss" scoped>
.video-info{
    width: calc(100% - 30px);
    // height: 550px;
    padding: 0 15px;
    padding-top: 15px;
}

</style>
