<template>
  <div
    class="swiper_skill_content"
    :style="{'background-color': obj.bgColor, 'margin-bottom': obj.bMargin ? '10px' : '0', 'padding': obj.bPadding ? '20px 10px' : '20px 0'}"
  >
    <div :style="{'background-image': 'url('+obj.bgInfo.img+')','background-size': '100% 100%'}">
      <ul
        v-if="obj.list.length > 0"
        class="skill_goods_content"
      >
        <div
          class="date_list"
        >
          <div
            v-for="(item,index) in obj.list"
            :key="index + 'time'"
            class="date_item"
            :style="group_id == item.id ? {'background-color': 'red'} : {'background-color': '#fff'}"
            @click="changeTime(item.id)"
          >
            {{ item.startTime }}
          </div>
        </div>
        <li
          v-for="(item,index) in obj.list"
          :key="index"
        >
          <div class="goods_list">
            <div
              v-for="(goods,index2) in item.goodsDetail"
              v-show="group_id == item.id"
              :key="index2"
              class="list_item"
            >
              <img
                :src="[goods.imageUrls.split(',')[0] ==''? preurl:goods.imageUrls.split(',')[0] + '?x-oss-process=image/resize,w_200']"
                :style="{'height': '180px','border-radius': '4px 4px 0 0'}"
              >
              <div>
                <p class="title">
                  {{ goods.name }}
                </p>
                <p class="inventory">
                  仅剩{{ goods.stock }}件
                </p>
                <p class="sell_price">
                  ￥ {{ goods.price }} <span class="line_throw">{{ goods.marketPrice }}</span>
                </p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="bt-drag-title">
      {{ obj.title }}
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'SkillImgPre',
  props: {
    obj: {
      type: Object,
      default: {
        list: [],
        group_id: '',
        bgColor: '#fff',
        bgInfo: { img: '', type: 'index', content: '', width: '', height: '' }
      }
    }
  },
  data() {
    return {
      preurl: 'https://img.hzanchu.com/acimg/07c0c4ef9f95df7bc8df6bbd4d2139e8.png?x-oss-process=image/resize,w_300',
      group_id: this.obj.group_id
    }
  },
  watch: {
    'obj.group_id': function(val) { // 监听props中的属性
      this.group_id = val
    }
  },
  mounted() {
  },

  methods: {
    dayFormat(val) {
      return dayjs(parseInt(val)).format('YYYY/MM/DD HH:mm:ss')
    },

    //
    changeTime(id) {
      this.group_id = id
    }

  }
}
</script>

<style lang="scss" scoped>
.swiper_skill_content {
  // overflow: hidden;
  padding: 0 20px;
  .skill_goods_content {
    // padding: 0 10px;
    .date_list{
      overflow-x: auto;
      display: flex;
      width: 100%;
      margin: 10px 0;
      padding: 0 10px;
      .date_item {
        flex-shrink: 0;
        width: 100px;
        height: 40px;
        line-height: 40px;
        overflow: hidden;
        text-align: center;
        margin-right: 10px;
        border-radius: 40px;
        border: 1px solid #333;
      }
    }

    .goods_list {
      width: 100%;
      display: flex;
      overflow-x: auto;
      // justify-content: space-around;
      // flex-wrap: wrap;

      .list_item {
        // flex: 1;
        // display: inline-block;
        padding: 0 0 10px;
        margin: 5px;
        background:#fff;
        width: 156px;
        border-radius: 5px;
        flex-shrink: 0;
        img {
          width: 100%;
          height: 180px;
        }

        div {
          padding: 0 10px;
          .title {
            font-size: 16px;
            font-weight: 600;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 20px;
          }
          .inventory {
            font-size: 16px;
            line-height: 30px;
            margin-bottom: 10px;
          }
          .sell_price {
            color:red;
            font-size: 16px;
            .line_throw{
              font-size: 14px;
              text-decoration:line-through;
              color: #999;
            }
          }
        }

      }

    }

  }

}

</style>
