<template>
  <div class="map-button">
    <div
      class="btn"
    >
      <img :src="obj.imageUrl">
    </div>
    <div class="bt-drag-title">
      {{ obj.title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    obj: {
      type: Object,
      default: () => ({
        imageUrl: '',
        list: []
      })
    }
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
.map-button {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  .btn {
    padding: 12px 16px;
    border-radius: 12px;
    img {
      width: 195px;
      height: 40px;
    }
  }
}
</style>
