var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "live_list" },
    [
      _vm._l(_vm.info.contentList, function (itemContent, index) {
        return _c(
          "el-card",
          { key: index, staticClass: "live_card" },
          [
            _c(
              "div",
              {
                staticClass: "live_card_header",
                attrs: { slot: "header" },
                slot: "header",
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "bold",
                          "font-size": "16px",
                        },
                      },
                      [_vm._v("容器 " + _vm._s(index + 1))]
                    ),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_box",
                        attrs: { placeholder: "请选容器状态", size: "mini" },
                        on: {
                          change: function ($event) {
                            return _vm.onChangeLiveStatus(itemContent)
                          },
                        },
                        model: {
                          value: itemContent.liveStatus,
                          callback: function ($$v) {
                            _vm.$set(itemContent, "liveStatus", $$v)
                          },
                          expression: "itemContent.liveStatus",
                        },
                      },
                      _vm._l(_vm.liveStateList, function (item, i) {
                        return _c("el-option", {
                          key: i,
                          attrs: { label: item.title, value: item.type },
                        })
                      }),
                      1
                    ),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_box",
                        attrs: { size: "mini", placeholder: "请选择排序状态" },
                        on: {
                          change: function ($event) {
                            return _vm.addLive(itemContent)
                          },
                        },
                        model: {
                          value: itemContent.sortKey,
                          callback: function ($$v) {
                            _vm.$set(itemContent, "sortKey", $$v)
                          },
                          expression: "itemContent.sortKey",
                        },
                      },
                      _vm._l(_vm.sortStateList, function (item, i) {
                        return _c("el-option", {
                          key: i,
                          attrs: {
                            label: item.title,
                            value: item.type,
                            disabled:
                              itemContent.liveStatus !== 2 &&
                              (item.type === "watchNum" ||
                                item.type === "gmvDesc"),
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-link",
                      {
                        staticClass: "live_card_header_btn",
                        attrs: {
                          type: "primary",
                          underline: false,
                          disabled: index === 0,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onMove(_vm.info.contentList, index, "up")
                          },
                        },
                      },
                      [_vm._v("上移")]
                    ),
                    _c(
                      "el-link",
                      {
                        staticClass: "live_card_header_btn",
                        attrs: {
                          type: "primary",
                          underline: false,
                          disabled: index === _vm.info.contentList.length - 1,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onMove(
                              _vm.info.contentList,
                              index,
                              "down"
                            )
                          },
                        },
                      },
                      [_vm._v("下移")]
                    ),
                    _c(
                      "el-popconfirm",
                      {
                        attrs: {
                          confirmButtonText: "确定",
                          cancelButtonText: "取消",
                          icon: "el-icon-info",
                          iconColor: "red",
                          title: "是否确认删除？",
                        },
                        on: {
                          onConfirm: function ($event) {
                            return _vm.deleteContainer(
                              _vm.info.contentList,
                              index
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "el-link",
                          {
                            staticClass: "live_card_header_btn",
                            attrs: {
                              slot: "reference",
                              type: "danger",
                              underline: false,
                            },
                            slot: "reference",
                          },
                          [_vm._v("删除")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-row",
              {
                staticStyle: { "margin-bottom": "20px" },
                attrs: { type: "flex", align: "top", gutter: 20 },
              },
              [
                _c(
                  "el-col",
                  { staticStyle: { "text-align": "left" }, attrs: { span: 4 } },
                  [
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("*"),
                    ]),
                    _vm._v("直播："),
                  ]
                ),
                _c("el-col", { attrs: { span: 20 } }, [
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入逗号隔开的直播间id",
                          type: "textarea",
                          rows: 4,
                          size: "mini",
                        },
                        on: {
                          input: function ($event) {
                            itemContent.inputValue = itemContent.inputValue
                              .replace(/，/g, ",")
                              .replace(/[^\d,]/g, "")
                              .replace(/(,)\1/g, ",")
                              .replace(/^,/, "")
                          },
                          blur: function ($event) {
                            itemContent.inputValue = [
                              ...new Set(
                                itemContent.inputValue
                                  .replace(/,$/, "")
                                  .split(",")
                              ),
                            ].join()
                          },
                        },
                        model: {
                          value: itemContent.inputValue,
                          callback: function ($$v) {
                            _vm.$set(
                              itemContent,
                              "inputValue",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "itemContent.inputValue",
                        },
                      }),
                      _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: {
                                type: "primary",
                                size: "mini",
                                loading: _vm.btnLoading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.addLive(itemContent)
                                },
                              },
                            },
                            [_vm._v("立即添加")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "live_card_list" },
              _vm._l(itemContent.list, function (item, i) {
                return _c(
                  "div",
                  { key: i, staticClass: "live_card_line" },
                  [
                    _c("div", { staticClass: "left" }, [
                      _c("img", { attrs: { src: item.coverImageId, alt: "" } }),
                      _c("div", { staticClass: "name" }, [
                        _c("div", [_vm._v(_vm._s(item.title))]),
                        _c("div", [_vm._v("主播：" + _vm._s(item.actorName))]),
                      ]),
                    ]),
                    _c("div", { staticClass: "center" }, [
                      _c("div", [
                        _vm._v(
                          "开始：" +
                            _vm._s(
                              _vm.formatDays(item.liveStartTime) ||
                                _vm.formatDays(item.realStartTime)
                            )
                        ),
                      ]),
                      _c("div", [
                        _vm._v(
                          "结束：" +
                            _vm._s(
                              _vm.formatDays(item.liveEndTime) ||
                                _vm.formatDays(item.realEndTime)
                            )
                        ),
                      ]),
                    ]),
                    _c(
                      "el-popconfirm",
                      {
                        attrs: {
                          confirmButtonText: "确认",
                          cancelButtonText: "取消",
                          icon: "el-icon-info",
                          iconColor: "red",
                          title: "是否确认删除？",
                        },
                        on: {
                          onConfirm: function ($event) {
                            return _vm.deleteLive(itemContent, i)
                          },
                        },
                      },
                      [
                        _c(
                          "el-link",
                          {
                            attrs: { slot: "reference", type: "danger" },
                            slot: "reference",
                          },
                          [_vm._v("删除")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ],
          1
        )
      }),
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              staticStyle: { "margin-top": "10px" },
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.addContainer },
            },
            [_vm._v("+ 直播容器")]
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticStyle: { margin: "20px 0" },
          attrs: { type: "flex", align: "top", gutter: 20 },
        },
        [
          _c(
            "el-col",
            { staticStyle: { "text-align": "left" }, attrs: { span: 7 } },
            [
              _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
              _vm._v(" 预约提醒 "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "是否在前台展示预约提醒按钮",
                    placement: "top",
                  },
                },
                [_c("i", { staticClass: "el-icon-warning-outline" })]
              ),
              _vm._v(" ： "),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 16 } },
            [
              _c("el-switch", {
                attrs: {
                  "active-value": 1,
                  "inactive-value": 0,
                  "active-color": "#1890ff",
                  "inactive-color": "#d1d6dd",
                },
                model: {
                  value: _vm.info.appointment,
                  callback: function ($$v) {
                    _vm.$set(_vm.info, "appointment", $$v)
                  },
                  expression: "info.appointment",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }