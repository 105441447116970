<!-- 》》》》》》》》》》》》运营后台、商家后台、滨水生活运营、滨水生活运营商家代码同步《《《《《《《《《《《《 -->
<template>
  <div class="subject-main">
    <page-header>
      <div class="center">
        <div>
          <span class="margin-r100">活动名称：{{ subjectName }}</span>
          <span>活动链接：{{ currentlink }}</span>
          <el-button
            type="primary"
            style="margin-left: 20px"
            size="mini"
            @click="qrShow = true;"
          >
            预 览
          </el-button>
        </div>
        <div>
          <el-button
            type="primary"
            style="float: right; margin: 0 20px"
            size="mini"
            :loading="subjectBtnLoading"
            @click="submitSubject"
          >
            保 存
          </el-button>
          <el-button
            style="float: right"
            size="mini"
            @click="backGo"
          >
            取 消
          </el-button>
        </div>
      </div>
    </page-header>
    <div class="edit-subject">
      <!-- 左侧 模块区 -->
      <div class="subject-left">
        <div class="sub-cont-title">
          选择要添加模块拖动到预览区
        </div>
        <div class="sub-cont-content">
          <draggable
            v-model="contorlList"
            element="div"
            :group="{name: 'description', pull: 'clone', put: false}"
            :sort="false"
          >
            <div
              v-for="(item, index) in contorlList"
              :key="index"
              class="sub-cont-box"
            >
              <img
                :src="item.src"
                width="100%"
              /> {{ item.name }}
            </div>
          </draggable>
        </div>
      </div>
      <!-- 中间 预览区 -->
      <div class="subject-content">
        <div class="preview">
          <div
            class="preview-title"
            @click="selectPage"
          >
            {{ subjectName }}
            <i
              class="setting-btn el-icon-setting"
              @click="selectPage"
            ></i>
          </div>
          <div
            class="preview-content"
            :style="setStyle"
          >
            <draggable
              v-model="contentList"
              class="list-group"
              element="div"
              animation="200"
              group="description"
              ghost-class="ghost"
              :move="onMove"
              @add="addModel"
            >
              <div
                v-for="(item, index) in contentList"
                :key="item.sort"
                :ref="'previewname'"
                :class="['preview-item', currentIndex === item.sort && 'active-model']"
                @click="selectPreview(item)"
              >
                <div class="preview-item-move-box">
                  <div>
                    <el-button
                      type="primary"
                      icon="el-icon-caret-top"
                      size="mini"
                      @click="movePreview(item, index, 'up')"
                    />
                  </div>
                  <div>
                    <el-button
                      type="primary"
                      icon="el-icon-caret-bottom"
                      size="mini"
                      @click="movePreview(item, index, 'down')"
                    />
                  </div>
                  <div>
                    <el-button
                      type="danger"
                      icon="el-icon-delete"
                      size="mini"
                      @click="delTool"
                    />
                  </div>
                </div>
                <preview-coupon
                  v-if="item.type === 'coupon'"
                  :obj="item"
                />
                <w-swiper
                  v-if="item.type === 'swiper'"
                  :obj="item"
                />
                <static-img
                  v-if="item.type === 'staticImg'"
                  :obj="item"
                />
                <static-self-img
                  v-if="item.type === 'staticSelfImg'"
                  :obj="item"
                />
                <swiper-goods
                  v-if="item.type === 'swiperGoods'"
                  :obj="item"
                />
                <list-goods
                  v-if="item.type === 'listGoods'"
                  :obj="item"
                  b-padding
                >
                  <div
                    v-if="goodsListLoading"
                    class="cateGoodsLoding"
                  >
                    <i class="el-icon-loading"></i>
                  </div>
                </list-goods>
                <more-goods
                  v-if="item.type === 'moreGoods'"
                  :obj="item"
                  b-padding
                >
                  <div
                    v-if="moreGoodsLoading"
                    class="cateGoodsLoding"
                  >
                    <i class="el-icon-loading"></i>
                  </div>
                </more-goods>
                <group-goods-pre
                  v-if="item.type === 'groupGoods'"
                  :obj="item"
                  b-padding
                >
                  <div
                    v-if="goodsListLoading"
                    class="cateGoodsLoding"
                  >
                    <i class="el-icon-loading"></i>
                  </div>
                </group-goods-pre>
                <list-prom-goods
                  v-if="item.type === 'listGoodsProm'"
                  :obj="item"
                  b-padding
                >
                  <div
                    v-if="goodsPromListLoading"
                    class="cateGoodsLoding"
                  >
                    <i class="el-icon-loading"></i>
                  </div>
                </list-prom-goods>
                <sub-title
                  v-if="item.type === 'title'"
                  :obj="item"
                />
                <cate-goods
                  v-if="item.type === 'cateGoods'"
                  :obj="item"
                >
                  <div
                    v-if="cateGoodsLoding"
                    class="cateGoodsLoding"
                  >
                    <i class="el-icon-loading"></i>
                  </div>
                </cate-goods>
                <cate-tab
                  v-if="item.type === 'cateTab'"
                  :obj="item"
                >
                  <div
                    v-if="cateTabLoding"
                    class="cateGoodsLoding"
                  >
                    <i class="el-icon-loading"></i>
                  </div>
                </cate-tab>
                <navigation-goods
                  v-if="item.type === 'navigationGoods'"
                  :obj="item"
                >
                  <div
                    v-if="navGoodsLoding"
                    class="navGoodsLoding"
                  >
                    <i class="el-icon-loading"></i>
                  </div>
                </navigation-goods>
                <three-img-pre
                  v-if="item.type === 'imgMode1'"
                  :obj="item"
                />
                <left-right-pre
                  v-if="item.type === 'imgMode2'"
                  :obj="item"
                />
                <up-down-pre
                  v-if="item.type === 'imgMode3'"
                  :obj="item"
                />
                <four-img-pre
                  v-if="item.type === 'imgMode4'"
                  :obj="item"
                />
                <two-img-pre
                  v-if="item.type === 'imgMode5'"
                  :obj="item"
                />
                <four-img-hor-pre
                  v-if="item.type === 'imgMode6'"
                  :obj="item"
                />
                <!-- 秒杀多图预览 -->
                <list-goods-skill
                  v-if="item.type === 'listGoodsSkill'"
                  :key="item + 'a'"
                  :obj="item"
                />
                <!-- 楼层专题模板 -->

                <five-img
                  v-if="item.type === 'imgMode10'"
                  :obj="item"
                />
                <three-img-hor-pre
                  v-if="item.type === 'imgMode12'"
                  :obj="item"
                />
                <video-pre
                  v-if="item.type === 'video'"
                  :obj="item"
                />
                <!--首页列表-->
                <home-row
                  v-if="item.type === 'homeRow'"
                  :obj="item"
                />
                <!-- 首页频道 -->
                <home-channel
                  v-if="item.type === 'homeChannel'"
                  :obj="item"
                />
                <!-- 消息滚动 -->
                <scroll-news
                  v-if="item.type === 'scrollNews'"
                  :obj="item"
                />
                <!-- 导航跳转按钮 -->
                <map-button
                  v-if="item.type === 'mapButton'"
                  :obj="item"
                />
                <!-- 搜索框 -->
                <Search
                  v-if="item.type === 'search'"
                  :obj="item"
                />
                <!-- 富文本 -->
                <RichTextPre
                  v-if="item.type === 'richText'"
                  :obj="item"
                />
                <!-- 商品模块组件 -->
                <productModulePre
                  v-if="item.type === 'productModule'"
                  :obj="item"
                />
                <!-- 频道页列表组件 -->
                <styleListPre
                  v-if="item.type === 'styleList'"
                  :obj="item"
                />
                <!-- 加购凑单组件 -->
                <scrapeTogetherPre
                  v-if="item.type === 'scrapeTogether'"
                  :obj="item"
                />
                <!-- 预约组件 -->
                <AppointmentPre
                  v-if="item.type === 'appointment'"
                  :obj="item"
                />
                <!-- 直播列表 -->
                <LiveList
                  v-if="item.type === 'liveList'"
                  :obj="item"
                />
                <!-- 横向滑动广告图 -->
                <horizontalSlideAd
                  v-if="item.type === 'horizontalSlideAd'"
                  :obj="item"
                />
              </div>
            </draggable>
          </div>
        </div>
      </div>

      <!-- 右侧工具栏 -->
      <div
        v-if="toolShow"
        class="subject-right"
      >
        <goods-panel
          :info="contentInfo"
          @delModuleEvent="delTool"
        >
          <!-- 页面编辑  背景颜色和背景图片 -->
          <page-tool
            v-if="pageShow"
            :info="contentInfo"
          />
          <!-- 样式工具栏 -->
          <tool-bar
            :content-info="contentInfo"
            :tool-type="toolType"
            @columnChange="columnChange"
          />
          <!-- 优惠券组件 -->
          <right-coupon
            v-if="contentInfo.type === 'coupon'"
            ref="coupon"
            :infos="contentInfo"
          />
          <!-- 消息滚动 -->
          <scroll-cont
            v-if="contentInfo.type === 'scrollNews'"
            :news-data="contentInfo"
          />
          <!-- 首页列表 -->
          <page-list
            v-if="contentInfo.type === 'homeRow'"
            :addrow="contentInfo"
          />
          <!-- 首页频道 -->
          <template v-if="contentInfo.type === 'homeChannel'">
            <div
              v-for="(item, index) in contentInfo.list"
              :key="index + 'a'"
            >
              <page-channel
                :addchannel="contentInfo"
                :img-info="item"
                :key-num="index"
              />
            </div>
          </template>
          <!-- 轮播图信息 -->
          <template v-if="contentInfo.type === 'swiper'">
            <div
              v-for="(item, index) in contentInfo.list"
              :key="item.img + index + 'b'"
            >
              <num-show :pic-num="index + 1">
                <img-info
                  ref="swiperinfo"
                  :cardtype="contentInfo"
                  :img-info="item"
                />

                <num-controller
                  :show-num="index"
                  :limit-num="1"
                  @addGoodEvent="addModule(index, 'swiper')"
                  @delGoodEvent="delModule(index, 'swiper')"
                />
              </num-show>
            </div>
          </template>
          <!-- 静态图信息 -->
          <template v-if="contentInfo.type === 'staticImg'">
            <div
              v-for="(item, index) in contentInfo.list"
              :key="item.img + index + 'c'"
            >
              <num-show :pic-num="index + 1">
                <img-info
                  :cardtype="contentInfo"
                  :img-info="item"
                />
                <num-controller
                  :show-num="index"
                  :limit-num="1"
                  @addGoodEvent="addModule(index, 'staticImg')"
                  @delGoodEvent="delModule(index, 'staticImg')"
                />
              </num-show>
            </div>
          </template>
          <!-- 自定义静态图信息 -->
          <template v-if="contentInfo.type === 'staticSelfImg'">
            <div
              v-for="(item, index) in contentInfo.list"
              :key="index + 'd'"
            >
              <num-show :pic-num="index + 1">
                <img-Self-info
                  :cardtype="contentInfo"
                  :img-info="item"
                />
              </num-show>
            </div>
          </template>
          <add-goods-drag
            v-if="contentInfo.type === 'swiperGoods'"
            :addscroll="contentInfo"
          />
          <!-- 列表商品 -->
          <add-list
            v-if="contentInfo.type === 'listGoods'"
            :addlistinfo="contentInfo"
          />
          <group-goods
            v-if="contentInfo.type === 'groupGoods'"
            :addlistinfo="contentInfo"
          />
          <more-list
            v-if="contentInfo.type === 'moreGoods'"
            :addlistinfo="contentInfo"
          />
          <!-- 大促排序列表商品 -->
          <add-goods-prom
            v-if="contentInfo.type === 'listGoodsProm'"
            :addlistinfo="contentInfo"
          />
          <!-- 标题 -->
          <add-title
            v-if="contentInfo.type === 'title'"
            :titleinfo="contentInfo"
          />
          <!-- 电梯类目 -->
          <template v-if="contentInfo.type === 'cateTab'">
            <div
              v-for="(item, index) in contentInfo.list"
              :key="index + 'e'"
            >
              <add-cate-tab
                :category-num="`${index + 1}`"
                :goods-list-info="item"
                :model-list="contentList"
              />
              <num-controller
                :show-num="index"
                :limit-num="2"
                @addGoodEvent="addModule(index, 'cateTab')"
                @delGoodEvent="delModule(index, 'cateTab')"
              />
            </div>
          </template>
          <!-- 类目 -->
          <template v-if="contentInfo.type === 'cateGoods'">
            <div
              v-for="(item, index) in contentInfo.list"
              :key="item.imgInfo? item.imgInfo.img + index : index+'f'"
            >
              <add-cate
                :category-num="`${index + 1}`"
                :goods-list-info="item"
              />
              <num-controller
                :show-num="index"
                :limit-num="2"
                @addGoodEvent="addModule(index, 'cateGoods')"
                @delGoodEvent="delModule(index, 'cateGoods')"
              />
            </div>
          </template>
          <!-- 图文导航 -->
          <template v-if="contentInfo.type === 'navigationGoods'">
            <div
              v-for="(item, index) in contentInfo.list"
              :key="item.imgInfo? item.imgInfo.img + index : index+'f'"
            >
              <add-navigation
                :category-num="`${index + 1}`"
                :goods-list-info="item"
              />
              <num-controller
                :show-num="index"
                :limit-num="1"
                @addGoodEvent="addModule(index, 'navigationGoods')"
                @delGoodEvent="delModule(index, 'navigationGoods')"
              />
            </div>
          </template>
          <!-- 三图模块 -->
          <template v-if="contentInfo.type === 'imgMode1'">
            <div
              v-for="(item, index) in contentInfo.list"
              :key="index + 'g'"
            >
              <img-info
                :cardtype="contentInfo"
                :img-info="item"
              />
            </div>
          </template>
          <!-- 左右图模块 -->
          <template v-if="contentInfo.type === 'imgMode2'">
            <div
              v-for="(item, index) in contentInfo.list"
              :key="index + 'h'"
            >
              <img-info
                :cardtype="contentInfo"
                :img-info="item"
              />
            </div>
          </template>
          <!-- 上下图模块 -->
          <template v-if="contentInfo.type === 'imgMode3'">
            <div
              v-for="(item, index) in contentInfo.list"
              :key="index + 'i'"
            >
              <img-info
                :cardtype="contentInfo"
                :img-info="item"
              />
            </div>
          </template>
          <!-- 四图模块 -->
          <template v-if="contentInfo.type === 'imgMode4'">
            <div
              v-for="(item, index) in contentInfo.list"
              :key="index + 'j'"
            >
              <img-info
                :cardtype="contentInfo"
                :img-info="item"
              />
            </div>
          </template>
          <!-- 横向三图模块 -->
          <template v-if="contentInfo.type === 'imgMode12'">
            <div
              v-for="(item, index) in contentInfo.list"
              :key="index + 'k'"
            >
              <img-info
                :cardtype="contentInfo"
                :img-info="item"
              />
            </div>
          </template>
          <!-- 横向四图模块 -->
          <template v-if="contentInfo.type === 'imgMode6'">
            <div
              v-for="(item, index) in contentInfo.list"
              :key="index + 'l'"
            >
              <img-info
                :cardtype="contentInfo"
                :img-info="item"
              />
            </div>
          </template>
          <!-- 两图模块 -->
          <template v-if="contentInfo.type === 'imgMode5'">
            <div
              v-for="(item, index) in contentInfo.list"
              :key="index + 'm'"
            >
              <img-info
                :cardtype="contentInfo"
                :img-info="item"
              />
            </div>
          </template>
          <!-- 秒杀多图横向模块 -->
          <template v-if="contentInfo.type === 'listGoodsSkill'">
            <!-- <img-info
                  :cardtype="contentInfo"
                  :img-info="contentInfo.bgInfo"
                /> -->
            <add-goods-skill
              :key="contentInfo + 'a'"
              :addscroll="contentInfo"
            />
          </template>
          <!-- 五图模块 -->
          <template v-if="contentInfo.type === 'imgMode10'">
            <img-info
              :cardtype="contentInfo"
              :img-info="contentInfo.bgInfo"
            />
            <add-goods :addscroll="contentInfo" />
          </template>
          <video-info
            v-if="contentInfo.type === 'video'"
            :info="contentInfo"
          />
          <mapButtonInfo
            v-if="contentInfo.type === 'mapButton'"
            :info="contentInfo"
          />
          <!-- 搜索 -->
          <searchInfo
            v-if="contentInfo.type === 'search'"
            :img-info="contentInfo"
          />
          <!-- 富文本 -->
          <RichText
            v-if="contentInfo.type === 'richText'"
            :info="contentInfo"
          />
          <!-- 商品模块组件 -->
          <productModuleTool
            v-if="contentInfo.type === 'productModule'"
            :info="contentInfo"
          />
          <!-- 频道页列表组件 -->
          <styleListTool
            v-if="contentInfo.type === 'styleList'"
            :info="contentInfo"
          />
          <!-- 加购凑单组件 -->
          <scrapeTogetherTool
            v-if="contentInfo.type === 'scrapeTogether'"
            :info="contentInfo"
          />
          <!-- 预约组件 -->
          <template v-if="contentInfo.type === 'appointment'">
            <AppointmentTool :info="contentInfo" />
          </template>
          <!-- 直播列表 -->
          <LiveListTool
            v-if="contentInfo.type === 'liveList'"
            ref="refLiveList"
            :info="contentInfo"
            @liveListOnAdd="liveListOnAdd"
          />
          <!-- 横向滑动广告图 -->
          <template v-if="contentInfo.type === 'horizontalSlideAd'">
            <div
              v-for="(item, index) in contentInfo.list"
              :key="item.img + index"
            >
              <num-show :pic-num="index + 1">
                <img-info
                  ref="horizontalSlideAdInfo"
                  :cardtype="contentInfo"
                  :img-info="item"
                />

                <num-controller
                  :show-num="index"
                  :limit-num="1"
                  @addGoodEvent="addModule(index, 'horizontalSlideAd')"
                  @delGoodEvent="delModule(index, 'horizontalSlideAd')"
                />
              </num-show>
            </div>
          </template>
        </goods-panel>
      </div>
      <QrCode
        :value="currentlink"
        :qr-show="qrShow"
        @hideQr="qrShow = $event"
      />
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { CONTOR_LIST, TOOL_OBJ } from './data';
import verifyAllList from './verify';

// 中间--预览区组件
import listGoodsSkill from '@/components/subject/preview/listGoodsSkill';
import wSwiper from '@/components/subject/preview/wSwiper';
import staticImg from '@/components/subject/preview/staticImg';
import staticSelfImg from '@/components/subject/preview/staticSelfImg';
import swiperGoods from '@/components/subject/preview/swiperGoods';
import listGoods from '@/components/subject/preview/listGoods';
import moreGoods from '@/components/subject/preview/moreGoods';
import listPromGoods from '@/components/subject/preview/listGoodsProm.vue';
import subTitle from '@/components/subject/preview/subTitle';
import cateTab from '@/components/subject/preview/cateTab';
import cateGoods from '@/components/subject/preview/cateGoods';
import navigationGoods from '@/components/subject/preview/navigationGoods'; // 图文导航
import upDownPre from '@/components/subject/preview/upDownPre';
import leftRightPre from '@/components/subject/preview/leftRightPre';
import threeImgPre from '@/components/subject/preview/threeImgPre';
import twoImgPre from '@/components/subject/preview/twoImgPre';
import fourImgPre from '@/components/subject/preview/fourImgPre';
import threeImgHorPre from '@/components/subject/preview/threeImgHorPre'; // 横向并列三图
import fourImgHorPre from '@/components/subject/preview/fourImgHorPre'; // 横向并列四图
import fiveImg from '@/components/subject/preview/fiveImg';
import videoPre from '@/components/subject/preview/videoPre';
import homeRow from '@/components/subject/preview/homeRow';
import homeChannel from '@/components/subject/preview/homeChannel';
import scrollNews from '@/components/subject/preview/scrollNews';
import mapButton from '@/components/subject/preview/mapButton';
import Search from '@/components/subject/preview/search';
import groupGoodsPre from '@/components/subject/preview/groupGoods'; // 拼团商品添加组件
import RichTextPre from './components/RichTextPre.vue'; // 富文本组件
import AppointmentPre from '@/components/subject/preview/appointmentPre'; // 预约组件
import LiveList from '@/components/subject/preview/liveList'; // 直播列表
import horizontalSlideAd from '@/components/subject/preview/horizontalSlideAd.vue'; // 横向滑动广告图
import previewCoupon from '@/components/subject/preview/coupon.vue'; // 优惠券组件
import productModulePre from '@/components/subject/preview/productModulePre.vue'; // 商品模块组件
import styleListPre from '@/components/subject/preview/styleListPre.vue'; // 频道页列表组件
import scrapeTogetherPre from '@/components/subject/preview/scrapeTogetherPre.vue'; // 加购凑单组件

// 右侧--工具栏区组件
import pageTool from '@/components/subject/rightTool/pageTool';
import goodsPanel from '@/components/subject/rightTool/goodsPanel';
import toolBar from '@/components/subject/rightTool/toolBar';
import numController from '@/components/subject/rightTool/numController'; // 增加减少图片
import numShow from '@/components/subject/rightTool/numShow'; // 图片顺序显示
// 秒杀横向多图模块
import addGoodsSkill from '@/components/subject/rightTool/addGoodsSkill';
// 大促列表排序
import addGoodsProm from '@/components/subject/rightTool/addGoodsProm';
import addGoods from '@/components/subject/rightTool/addGoods'; // 轮播商品添加组件
import addGoodsDrag from '@/components/subject/rightTool/addGoodsDrag'; // 轮播商品添加组件
import addList from '@/components/subject/rightTool/addList'; // 列表商品添加组件
import moreList from '@/components/subject/rightTool/moreList'; // 推荐商品添加组件
import groupGoods from '@/components/subject/rightTool/groupGoods'; // 拼团商品添加组件

import addCateTab from '@/components/subject/rightTool/addCateTab'; // 类目添加组件
import addCate from '@/components/subject/rightTool/addCate'; // 类目添加组件
// 图文导航添加组件
import addNavigation from '@/components/subject/rightTool/addNavigation';
import addTitle from '@/components/subject/rightTool/addTitle'; // 标题添加组件
import imgInfo from '@/components/subject/rightTool/imgInfo'; // 图片上传模块组件
import imgSelfInfo from '@/components/subject/rightTool/imgSelfInfo'; // 自定义图片上传模块组件
import videoInfo from '@/components/subject/rightTool/videoInfo'; // 视频上传模块组件
import pageList from '@/components/subject/rightTool/pageList'; // 首页列表添加组件
import pageChannel from '@/components/subject/rightTool/pageChannel'; // 首页频道添加组件
import scrollCont from '@/components/subject/rightTool/scrollCont';
import mapButtonInfo from '@/components/subject/rightTool/mapButtonInfo';
import searchInfo from '@/components/subject/rightTool/searchInfo';
import RichText from './components/RichText.vue'; // 富文本组件
import QrCode from '@/components/common/QRCode';
import AppointmentTool from '@/components/subject/rightTool/appointmentTool'; // 预约组件
import LiveListTool from '@/components/subject/rightTool/liveListTool'; // 预约组件
import rightCoupon from '@/components/subject/rightTool/coupon.vue'; // 优惠券组件
import productModuleTool from '@/components/subject/rightTool/productModuleTool.vue'; // 商品模块组件
import styleListTool from '@/components/subject/rightTool/styleListTool.vue'; // 频道页列表组件
import scrapeTogetherTool from '@/components/subject/rightTool/scrapeTogetherTool.vue'; // 加购凑单组件

export default {
  name: 'subject-detail',
  components: {
    previewCoupon,
    rightCoupon,
    RichText,
    RichTextPre,
    listGoodsSkill, // 秒杀中间预览
    draggable,
    wSwiper,
    staticImg,
    staticSelfImg,
    swiperGoods,
    listGoods,
    moreGoods,
    listPromGoods, // 大促排序列表
    subTitle,
    cateTab,
    cateGoods,
    navigationGoods, // 图文导航
    upDownPre,
    leftRightPre,
    threeImgPre,
    threeImgHorPre,
    twoImgPre,
    fourImgPre,
    fourImgHorPre,
    fiveImg,
    videoPre,
    homeRow,
    homeChannel,
    scrollNews,
    goodsPanel,
    toolBar,
    numController,
    numShow,
    addGoods,
    addGoodsProm, // 大促排序右侧编辑
    addGoodsSkill, // 秒杀右侧编辑
    addGoodsDrag,
    addList,
    moreList,
    addTitle,
    addCateTab,
    addCate,
    addNavigation, // 图文导航组件
    imgInfo,
    imgSelfInfo,
    videoInfo,
    QrCode,
    pageList,
    pageChannel,
    scrollCont,
    mapButton,
    mapButtonInfo,
    Search,
    searchInfo,
    groupGoodsPre,
    groupGoods,
    AppointmentPre, // 预约组件-预览
    AppointmentTool, // 预约组件-工具
    LiveList, // 直播列表-预览
    LiveListTool, // 直播列表-工具
    horizontalSlideAd,
    pageTool, // 页面内容配置
    productModulePre, // 商品模块组件-预览
    productModuleTool, // 商品模块组件-工具
    styleListPre, // 频道页列表组件-预览
    styleListTool, // 频道页列表组件-工具
    scrapeTogetherPre, // 加购凑单组件-预览
    scrapeTogetherTool, // 加购凑单组件-工具
  },
  data() {
    return {
      currentlink: '', // 正式链接
      subjectName: '', // 模板名称
      qrShow: false,
      preview_left: '',
      contorlList: CONTOR_LIST,
      contentList: [],
      // 模块显示
      toolShow: false,
      // 轮播图
      contentInfo: {},
      currentIndex: '',
      toolType: {},
      swiperNum: 0, // 轮播模块数量
      cateNum: 0, // 类目数量
      homeRowNum: 0, // 首页列表数量
      // 社区分类选项
      cateOptions: [],
      // 社区前台固定分类
      defaultCateOptions: [],
      subjectLoading: false,
      cateGoodsLoding: false, // 类目loading
      cateTabLoding: false, // loading
      navGoodsLoding: false, // 类目loading
      goodsListLoading: false, // 商品列表loading
      moreGoodsLoading: false, // 推荐商品列表
      goodsPromListLoading: false,
      subjectInfo: '', // 专题信息
      pageShow: false, // 首页内容配置
      pageConfig: {
        title: '页面内容配置',
        isPageConfig: true,
        config: {
          backgroundImage: '',
          backgroundColor: '',
        },
      }, // 首页内容配置
    };
  },
  computed: {
    // 背景样式
    setStyle() {
      return {
        backgroundColor: this.pageConfig.config.backgroundColor,
        backgroundImage: `url(${this.pageConfig.config.backgroundImage})`,
        backgroundSize: '100% auto',
        backgroundRepeat: 'no-repeat',
      };
    },
    listString() {
      return JSON.stringify(this.list, null, 2);
    },
    list2String() {
      return JSON.stringify(this.list2, null, 2);
    },
    subjectBtnLoading() {
      return this.cateGoodsLoding || this.goodsListLoading || this.subjectLoading;
    },
  },
  watch: {

  },
  beforeCreate() {
    sessionStorage.swiper_id = 0;
    sessionStorage.static_img_id = 0;
    sessionStorage.swiper_goods_id = 0;
    sessionStorage.list_goods_id = 0;
    sessionStorage.sub_title_id = 0;
  },
  mounted() {
    this.currentlink = this.$route.query.currentlink;
    this.getInfo();
  },
  methods: {
    addModel(e) {
      // 添加的位置索引
      const index = e.newIndex;
      // 获取初始化数据
      const obj = _.cloneDeep(this.contentList[index].obj);
      // 获取组件类型
      const { type, title } = obj;
      // 获取类型组件个数
      const sameList = this.contentList.filter((item) => item.type === type);
      const num = sameList.length + 1;
      const sort = Math.floor(Math.random() * 10e16);
      const processedData = {
        ...obj,
        sort,
        cateId: `${type}${num}`,
        showTitle: `${title}${num}`,
      };
      this.contentList.splice(index, 1, processedData);
      // 选中
      this.selectPreview(processedData);
      // 滚动到可视区
      this.$nextTick(() => {
        const el = document.querySelector('.active-model');
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      });
    },
    columnChange(type) {
      this.$refs?.coupon?.setDefaultImageBg?.(type);
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    // 选择页面配置
    selectPage() {
      // 同样使用浅拷贝来处理数据的传递
      this.contentInfo = this.pageConfig;
      this.toolShow = false;
      this.toolType = {};
      this.$nextTick(() => {
        this.toolShow = true;
        this.pageShow = true;
      });
    },
    // 选择模块
    selectPreview(item) {
      this.pageShow = false;
      this.toolShow = false;
      this.contentInfo = item;
      this.currentIndex = item.sort;
      const toolObj = TOOL_OBJ;
      this.toolType = toolObj[item.type];
      this.$nextTick(() => {
        this.toolShow = true;
      });
    },
    // 添加子模块
    addModule(index, type) {
      let data = {};
      if (type === 'swiper' || type === 'staticImg') {
        data = {
          type: 'goods',
          img: '',
          content: '',
          coupon_key: '',
          appContent: {
            page: '',
            content: '',
          },
        };
      } else if (type === 'cateTab') {
        data = {
          type: '',
          categoryNum: '', // 类目顺序
          name: '', // 类目名称
          goods_list: [],
          content: '',
          imgInfo: {
            type: 1,
            content: '',
            img: '',
            width: '',
            height: '',
          },
        };
      } else if (type === 'cateGoods') {
        data = {
          type: '',
          categoryNum: '', // 类目顺序
          name: '', // 类目名称
          goods_list: [],
          content: '',
          imgInfo: {
            type: 1,
            content: '',
            img: '',
            width: '',
            height: '',
          },
        };
      } else if (type === 'navigationGoods') {
        if (this.contentInfo.list.length < 10) {
          data = {
            type: '',
            categoryNum: '', // 类目顺序
            name: '', // 类目名称
            goods_list: [],
            content: '',
            imgInfo: {
              type: 1,
              content: '',
              img: '',
              width: '',
              height: '',
            },
          };
        } else {
          this.$message.warning('最多添加10个导航');
          return;
        }
      } else if (type === 'staticSelfImg') {
        data = {
          img: '',
          content: '',
          coupon_key: '',
          appContent: {
            page: '',
            content: '',
          },
          self: [
            {
              top: 0,
              left: 0,
              width: 20,
              height: 50,
              type: 'goods',
              content: '',
              zIndex: 99,
            },
          ],
        };
      } else if (type === 'horizontalSlideAd') {
        if (this.contentInfo.list.length < 20) {
          data = {
            type: 'goods',
            img: '',
            content: '',
            coupon_key: '',
            appContent: {
              page: '',
              content: '',
            },
          };
        } else {
          this.$message.warning('最多添加20个横向滑动广告图');
          return;
        }
      }
      this.contentInfo.list.push(data);
    },
    // 删除
    delModule(index, type) {
      this.contentInfo.list.splice(index, 1);
    },
    // 删除模块
    delTool() {
      this.$confirm('此操作将删除该模块, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.toolShow = false;
          this.contentList.forEach((value, index) => {
            if (value.type === 'swiper') {
              this.swiperNum = 0;
            } else if (value.type === 'cateGoods') {
              this.cateNum = 0;
            } else if (value.type === 'homeRow') {
              this.homeRowNum = 0;
            }
            if (value.sort === this.currentIndex) {
              this.contentList.splice(index, 1);
            }
          });
          this.$message({
            type: 'success',
            message: '删除成功!',
          });
        })
        .catch(() => {
        });
    },
    // 获取商品数据
    getGoodsData(index) {
      const that = this;
      const url = that.$api.tradePluginTemplate.getListGoods;
      const iArr = JSON.parse(index);
      const params = {
        type: this.$route.query.type === 'site' ? 2 : 1, // 运营专题模板默认1，展馆专题配置为2
        index: iArr,
        sort: iArr[0],
        templateId: _.get(that, '$route.query.id', ''),
        page: 1, // 后台不做分页
      };
      this.goodsListLoading = true;
      that.$axios
        .post(url, { ...params })
        .then((res) => {
          that.goodsListLoading = false;
          const code = _.get(res, 'code', '');
          const rows = _.get(res, 'data', {});
          if (code === 0) {
            iArr.forEach((item) => {
              const t = parseInt(item, 10);
              rows.forEach((ele, i) => {
                if (ele.index === item) {
                  that.contentList[t].list = ele.tradeGoodsTemplateDtoList || [];
                }
              });
            });
          }
        })
        .catch((error) => {
          this.goodsListLoading = false;
        });
    },
    // 获取类目组件商品
    getCateGoodsData(index) {
      const that = this;
      const url = that.$api.tradePluginTemplate.getListCateGoods;
      const t = parseInt(index, 10);
      const params = {
        type: this.$route.query.type === 'site' ? 2 : 1, // 运营专题模板默认1，展馆专题配置为2
        index: [t],
        sort: t,
        templateId: _.get(that, '$route.query.id', ''),
      };
      this.cateGoodsLoding = true;
      that.$axios
        .post(url, { ...params })
        .then((res) => {
          that.cateGoodsLoding = false;
          const code = _.get(res, 'code', '');
          const rows = _.get(res, 'data', []);
          if (code === 0) {
            rows.forEach((item, i) => {
              that.contentList[t].list[i]
                && that.$set(
                  that.contentList[t].list[i],
                  'goods_list',
                  item.tradeGoodsTemplateDtoList,
                );
            });
          }
        })
        .catch((error) => {
          this.cateGoodsLoding = false;
        });
    },
    // 获取数据
    async getInfo() {
      this.$axios
        .get(this.$api.tradePluginTemplate.detail, {
          params: {
            id: this.$route.query.id,
          },
        })
        .then(async (res) => {
          let r = res;
          let jsonList;
          if (r.code === 0) {
            this.subjectInfo = r.data;
            if (r.data.jsonString) {
              jsonList = JSON.parse(r.data.jsonString);
              let listArr = [];
              jsonList.forEach((val, index) => {
                // 兼容搜索组件的老数据
                if (val.type === 'search' && !val?.searchType) {
                  this.$set(val, 'searchType', 2);
                  this.$set(val, 'searchText', '');
                }
                if (val.type === 'listGoods') {
                  listArr.push(index);
                }
                if (val.type === 'swiper') {
                  this.swiperNum = 1;
                }
                if (val.type === 'cateGoods') {
                  if (val.list.length > 0) {
                    val.list.forEach((val2) => {
                      if (!val2.type) {
                        val2.type = '';
                      }
                      if (!val2.content) {
                        val2.content = '';
                      }
                    });
                  }
                  this.cateNum = 1;
                  // 通过接口获取类目组件的商品信息
                  this.getCateGoodsData(index);
                }
                if (val.type === 'homeRow') {
                  this.homeRowNum = 1;
                }
                // 初始化直播列表
                if (val.type === 'liveList' && Array.isArray(val.contentList)) {
                  val.contentList.forEach((item) => {
                    this.liveListOnAdd(item);
                  });
                }
                // 模块组件获取入口图数据回填
                if (val.type === 'productModule') {
                  val.moduleList.forEach((item) => {
                    this.addCategoryInfor(item);
                  });
                }
              });
              if (
                jsonList.length > 0
                && (jsonList[jsonList.length - 1].type === 'active'
                  || jsonList[jsonList.length - 1].type === 'site')
              ) {
                let config = jsonList.splice(jsonList.length - 1, 1);
                this.pageConfig.config.backgroundColor = config[0]?.backgroundColor || '';
                this.pageConfig.config.backgroundImage = config[0]?.backgroundImage || '';
              }
              // console.log(jsonList, "list... ");
              this.contentList = jsonList;
              // 通过接口获取列表商品组件的商品信息
              if (listArr.length > 0) {
                this.getGoodsData(JSON.stringify(listArr));
              }

              // 富文本获取下json内容
              const richTextObj = this.contentList.find(
                (e) => e.type === 'richText',
              );

              if (richTextObj && richTextObj.content && richTextObj.content.startsWith('http')) {
                const jsonUrl = richTextObj.content;
                richTextObj.content = '';
                const richTextContent = await this.getRichTextJson(
                  jsonUrl,
                );

                richTextObj.content = richTextContent;
              }
            }
            this.subjectName = r.data.name;
            // 初始化的时候要展示整个页面的背景图配置
            this.selectPage();
          }
        });
    },
    getRichTextJson(url) {
      return new Promise((resolve, reject) => {
        this.$axios.get(url).then((res) => {
          if (res && res.data) {
            resolve(res.data);
          }
        }).catch((err) => {
          reject(err);
        });
      });
    },
    // 生成富文本文件并上传
    uploadRichText(str) {
      const JsonData = {
        data: str,
      };

      const file = new File(
        [JSON.stringify(JsonData)],
        `richTextJson_${Date.now()}.json`,
      );

      const params = new FormData();
      params.append('multipartFile', file);

      return new Promise((resolve, reject) => {
        const options = {
          url: this.$api.nb_common.file_upload,
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: params,
        };

        this.$axios(options)
          .then((res) => {
            if (res.code === 0) {
              resolve(res.data.fileUrl);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 保存
    async submitSubject() {
      let _this = this;
      // 数据校验
      await verifyAllList(this.contentList);
      let arr = _.cloneDeep(this.contentList);
      for (let item of arr) {
        // 列表商品
        if (item.type === 'listGoods') {
          item.list.forEach((items, i) => {
            // 保存时只存商品id
            item.list[i] = {
              goods_id: items.goodsId,
            };
          });
          item.pageCtrl = {
            open: item.isShowMore ? 1 : 0,
            page_size: item.pageSize,
          };
          // console.log(item)
        }
        // 类目商品
        if (item.type === 'cateGoods') {
          item.list.forEach((items, i) => {
            if (items.goods_list.length > 0) {
              items.goods_list.forEach((j, k) => {
                // 保存时只存商品id
                items.goods_list[k] = {
                  goods_id: j.goodsId,
                };
              });
            }
          });
          item.pageCtrl = {
            open: item.isShowMore ? 1 : 0,
            page_size: item.pageSize,
          };
        }
        // 直播组件，清除列表，只保存ids
        if (item.type === 'liveList') {
          for (let liveItem of item.contentList) {
            delete liveItem.inputValue;
            delete liveItem.list;
          }
          item.pageCtrl = {
            open: item.isShowMore ? 1 : 0,
            page_size: item.pageSize,
          };
        }
        // 频道页模块组件
        if (item.type === 'productModule') {
          // 不要把商品列表的数据传给后端
          for (let liveItem of item.moduleList) {
            liveItem.categoryInfor = {};
          }
        }
        // 加购凑单组件，保存条件校验
        if (item.type === 'scrapeTogether') {
          let scrapeTogetherList = arr.filter((e) => {
            return e.type === 'scrapeTogether';
          });
          // 如果加购组件有多个才校验时间是否冲突
          if (scrapeTogetherList.length > 1) {
            // 先对时间段按照起始时间进行排序
            scrapeTogetherList.sort((a, b) => a?.validTime[0] - b?.validTime[0]);
          }
        }
      }

      // 添加页面基础信息
      const {
        name, showName, activityId, type, id,
      } = this.subjectInfo;
      const config = {
        name,
        showName,
        activityId,
        type,
        id,
        backgroundColor: this.pageConfig.config.backgroundColor,
        backgroundImage: this.pageConfig.config.backgroundImage,
      };
      const lastData = arr[arr.length - 1];
      if (lastData.type === 'active' || lastData.type === 'site') {
        arr.pop();
      }
      arr.push(config);

      // 如果有富文本上传富文本获取链接
      const richTextObj = arr.find((e) => e.type === 'richText');
      const richTextStr = richTextObj && richTextObj.content;

      if (richTextObj && richTextStr) {
        const richTextOss = await this.uploadRichText(richTextStr);
        richTextObj.content = richTextOss;
      }
      await this.$confirm('是否确认保存？');
      // 保存按钮loading
      this.subjectLoading = true;
      this.$axios({
        method: 'post',
        url: this.$api.tradePluginTemplate.edit,
        data: {
          id: _this.$route.query.id,
          jsonString: JSON.stringify(arr),
        },
      }).then((res) => {
        let r = res;
        if (r.code === 0) {
          this.$message.success('操作成功！');
          this.$router.go(0);
        }
      }).finally(() => {
        this.subjectLoading = false;
      });
    },
    // 取消
    backGo() {
      this.$confirm('编辑未保存, 是否继续退出?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          this.$router.go(-1);
        })
        .catch(() => {});
    },
    // 上下移动
    movePreview(item, index, direction) {
      if (direction === 'up') {
        if (index === 0) {
          return;
        }
        this.contentList.splice(index - 1, 0, item);
        this.contentList.splice(index + 1, 1);
      } else if (direction === 'down') {
        if (index === this.contentList.length - 1) {
          return;
        }
        this.contentList.splice(index + 2, 0, item);
        this.contentList.splice(index, 1);
      }
      this.$nextTick(() => {
        // this.$refs.previewname[this.contentList.indexOf(item)].scrollTop=0;
        window.scrollTop = 0;
        console.log(this.$refs.previewname[this.contentList.indexOf(item)]);
      });
    },
    // 请求查询直播列表api，ids：1,2,3
    getLiveList(row) {
      return new Promise((resove) => {
        this.$axios
          .post(this.$api.live.live_list_by_id, {
            connectSymbol: ',',
            liveIds: row.inputValue || row.liveIds,
            tempId: this.$route.query.id,
            pageSize: 300,
            currentPage: 1,
            sortKey: row.sortKey,
          })
          .then((res) => {
            this.$refs.refLiveList
              && this.$refs.refLiveList.clearBtnLoading();
            let r = res;
            if (r.code === 0 && r.data) {
              resove(r.data.records);
            } else {
              this.$message.error('无有效直播间');
            }
          });
      });
    },
    // 直播列表工具添加直播按钮
    async liveListOnAdd(item) {
      console.log(item);
      let apiList = await this.getLiveList(item);
      let inputId = apiList.map((item) => item.id).join(',');
      this.$set(item, 'liveIds', inputId); // id回填
      this.$set(item, 'inputValue', inputId); // 输入框回填
      this.$set(item, 'list', apiList); // 列表回填
      this.$refs.refLiveList && this.$refs.refLiveList.clearBtnLoading(); // 隐藏loading
    },
    // 获取模块入口图列表
    addCategoryInfor(item) {
      this.$axios
        .get(this.$api.subjectChannel.entrance_detail_c, {
          params: {
            contentPageNum: 1,
            contentPageSize: 99,
            id: item.categoryId,
          },
        })
        .then((res) => {
          if (res.code === 0) {
            const newdata = item;
            newdata.categoryInfor = {
              supplierName: res.data.name, // 入口名称
              principalType: res.data.appliedPosition, // 应用位置
              advertisingSpace: res.data.positionId, // 广告位
              entryChartStyle: res.data.entranceStyle, // 入口图样式 1一行一个 2一行两个 3一行三个 4一行四个 5一行五个 6一行六个
              shopGoods: res.data.contents.records.map((value) => {
                return {
                  goodsId: value.contentId, // 商品或者店铺id
                  goodsName: '测试', // 商品或者店铺名称
                  goodsImg: value.entranceImg, // 商品或者店铺图片
                  goodsPrice: '待定', // 商品价格
                };
              }), // 店铺商品组
              shopGoodsPC: [], // 店铺商品组PC使用的数据
              dynamicEffects: 2, // 动态效果
            };
            // 计算数组长度需要增加的数量
            const remainder = item.categoryInfor.shopGoods.length
            % item.categoryInfor.entryChartStyle;
            let num = remainder ? item.categoryInfor.entryChartStyle - remainder : 0;
            let data = item.categoryInfor.shopGoods.slice(0, num);
            newdata.categoryInfor.shopGoods = item.categoryInfor.shopGoods.concat(data);
            // console.log(num);
            let lang = item.categoryInfor.shopGoods.length / item.categoryInfor.entryChartStyle;
            for (
              let index = 0;
              index
        < lang;
              index += 1
            ) {
              let list = {
                list: item.categoryInfor.shopGoods.slice(
                  index * item.categoryInfor.entryChartStyle,
                  (index + 1) * item.categoryInfor.entryChartStyle,
                ),
              };
              newdata.categoryInfor.shopGoodsPC = item.categoryInfor.shopGoodsPC.concat(list);
            }
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
$card-box-shadow: 0 0 0 2px #2589ff, 0 2px 50px 5px rgb(50 59 77 / 50%);
$tool-box-shadow: 0 0 28px 0 #ccc;

.subject-main {
  height: calc(100vh - 50px);
  overflow: hidden;
}
.center {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.edit-subject {
  position: relative;
  display: flex;
  height: calc(100vh - 109px);
  box-sizing: border-box;
  .subject-left {
    width: 190px;
    height: calc(100% - 30px);
    box-shadow: $tool-box-shadow;
    margin: 15px 0 15px 15px;
    border-radius: 10px;
    overflow: auto;
    .sub-cont-title {
      font-size: 12px;
      font-family: PingFangSC-Regular;
      color: #fff;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background: rgba(50, 65, 87, 1);
    }
    .sub-cont-content {
      background: #fff;
      padding: 10px;
      font-size: 12px;
      .sub-cont-box {
        padding: 4px;
        margin-bottom: 18px;
        cursor: pointer;
        font-size: 14px;
      }
      .sub-cont-box:hover {
        -webkit-box-shadow: 0 2px 12px 0 rgb(0 0 0 / 20%);
        box-shadow: 0 2px 12px 0 rgb(0 0 0 / 20%);
        z-index: 998;
      }
    }
  }
  .subject-content {
    height: 100%;
    width: calc(100% - 190px);
    overflow-y: scroll;
    padding: 15px 0 15px 40px;
  }
  .preview {
    min-height: 600px;
    padding-bottom: 100px;
    cursor: pointer;
    width: 500px;
    border-radius: 15px;
    box-shadow: $tool-box-shadow;
    .preview-title {
      position: relative;
      height: 68px;
      line-height: 68px;
      background: #fff;
      text-align: center;
      border-radius: 15px 15px 0 0;
      .setting-btn {
        position: absolute;
        top: 10px;
        right: -60px;
        font-size: 20px;
        background: #fff;
        box-shadow: $tool-box-shadow;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
      }
    }
    .preview-content {
      position: relative;
      min-height: 600px;
      border: 1px solid #ccc;
    }
  }
  .subject-right {
    position: absolute;
    top: 15px;
    right: 30px;
    width: 541px;
    max-height: calc(100% - 30px);
    z-index: 999;
    box-shadow: $tool-box-shadow;
    border-radius: 10px;
    overflow: auto;
  }
}
.preview-item {
  position: relative;
  width: 100%;
  min-height: 64px;
  box-sizing: border-box;
  .preview-item-move-box {
    display: none;
    position: absolute;
    left: 100%;
    top: 0;
    margin-left: 4px;
    > div {
      margin-bottom: 5px;
    }
  }
}
.active-model {
  box-shadow: $card-box-shadow;
  z-index: 999;
  .preview-item-move-box {
    display: block;
  }
}
.preview-item:hover {
  box-shadow: $card-box-shadow;
  z-index: 998;
}
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  box-shadow: $card-box-shadow;
}
.list-group {
  min-height: 600px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
.arrow-move {
  position: absolute;
  right: -50px;
  top: 0;
  width: 50px;
  height: 50px;
}
.cateGoodsLoding {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.cateGoodsLoding i {
  width: 30px;
  height: 30px;
  font-size: 30px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  color: #fff;
}
</style>
