//  》》》》》》》》》》》》运营后台、商家后台、滨水生活运营、滨水生活运营商家代码同步《《《《《《《《《《《《

import { Message } from 'element-ui';
// 校验
export default (array) => {
  const parmise = new Promise((resolve) => {
    if (array.length === 0) {
      Message.error('请丰富一下内容。');
      return;
    }
    for (const item of array) {
      // 查看更多按钮
      if (item.isShowMore) {
        if (!item.pageSize) {
          Message.error(`${item.showTitle}-组件请输入分页数量`);
          return;
        }
        if (!item.imgInfo?.img) {
          Message.error(`${item.showTitle}-组件请上传查看更多按钮图片`);
          return;
        }
      }
      // 首页列表模块
      if (item.type === 'homeRow') {
        for (const el of item.list) {
          if (el.title === '' || el.subTitle === '') {
            Message.error(`${item.showTitle}-组件请完善主标题和副标题`);
            return;
          }
        }
      }
      // 视频组件
      if (item.type === 'video') {
        if (item.coverURL === '' || item.videoId === '') {
          Message.error(`${item.showTitle}-组件请上传视频和视频封面图`);
          return;
        }
      }
      // 商品列表
      if (item.type === 'listGoods' || item.type === 'listGoodsProm') {
        if (item.list.length === 0) {
          Message.error(`${item.showTitle}-组件至少添加一个商品`);
          return;
        }
      }
      //  静态图
      if (item.type === 'staticImg') {
        for (let i = 0; i < item.list.length; i++) {
          let items = item.list[i];
          if (items.type === 'distribution' && items.distributionType === 2 && (!items.distributionId || items.distributionId === '')) {
            Message.error(`${item.showTitle}-组件请完善图${i + 1}的渠道ID`);
            return;
          }
          // 判断小程序页面地址
          if (items.type === 'distribution' && items.distributionType === 2 && (!items.distributionUrl || items.distributionUrl === '')) {
            Message.error(`${item.showTitle}-组件请完善区域${i + 1}的小程序页面地址`);
            return;
          }
        }
      }
      // 自定义静态图
      if (item.type === 'staticSelfImg') {
        for (let i = 0; i < item.list[0]?.self.length; i++) {
          let items = item.list[0].self[i];
          // 判断渠道ID
          if (items.type === 'distribution' && items.distributionType === 2 && (!items.distributionId || items.distributionId === '')) {
            Message.error(`${item.showTitle}-组件请完善区域${i + 1}的渠道ID`);
            return;
          }
          // 判断小程序页面地址
          if (items.type === 'distribution' && items.distributionType === 2 && (!items.distributionUrl || items.distributionUrl === '')) {
            Message.error(`${item.showTitle}-组件请完善区域${i + 1}的小程序页面地址`);
            return;
          }
        }
      }
      // 类目商品
      if (item.type === 'cateGoods') {
        for (let i = 0; i < item.list.length; i++) {
          let items = item.list[i];
          if (items.type === 'distribution' && items.distributionType === 2 && (!items.distributionId || items.distributionId === '')) {
            Message.error(`${item.showTitle}-组件请完善第${i + 1}行的渠道ID`);
            return;
          }
          // 判断小程序页面地址
          if (items.type === 'distribution' && items.distributionType === 2 && (!items.distributionUrl || items.distributionUrl === '')) {
            Message.error(`${item.showTitle}-组件请完善区域${i + 1}的小程序页面地址`);
            return;
          }
          if (items.goods_list.length === 0) {
            Message.error(`${item.showTitle}-组件至少添加一个商品`);
            return;
          }
        }
      }
      // 直播组件，清除列表，只保存ids
      if (item.type === 'liveList') {
        if (!item.contentList || item.contentList.length === 0) {
          Message.error(`${item.showTitle}-组件请完善组件`);
          return;
        }
        for (let liveItem of item.contentList) {
          let i = item.contentList.indexOf(liveItem);
          if (liveItem.liveStatus !== '' && liveItem.liveStatus !== 0 && !liveItem.liveStatus) {
            Message.error(`${item.showTitle}-组件请完善-容器${i + 1}的直播状态`);
            return;
          }
          if (!liveItem.sortKey) {
            Message.error(`${item.showTitle}-组件请完善-容器${i + 1}的排序状态`);
            return;
          }
          if (!liveItem.list || liveItem.list.length === 0) {
            Message.error(`${item.showTitle}-组件请完善-容器${i + 1}的内容`);
            return;
          }
        }
      }
      // 优惠券
      if (item.type === 'coupon') {
        if (!item.list.length) {
          Message.error('优惠劵模块请添加优惠券');
          return;
        }
        let pass = true;
        if (item.resource === 4) {
          for (const i of (item.list ?? [])) {
            if (!i.imageUrl) {
              pass = false;
              break;
            }
          }
        }
        if (!pass) {
          Message.error(`${item.showTitle}-组件展示字段样式三情况下的每一个优惠券必须带有图片`);
          return;
        }
      }
      // 频道页模块组件
      if (item.type === 'productModule') {
        for (let inedx = 0; inedx < item.moduleList.length; inedx++) {
          if (!item.moduleList[inedx].categoryId) {
            Message.error(`${item.showTitle}-组件请完善商品店铺模块组件-模块${inedx + 1}的入口图`);
            return;
          }
          if (!item.moduleList[inedx].link) {
            Message.error(`${item.showTitle}-组件请完善商品店铺模块组件-模块${inedx + 1}的跳转链接`);
            return;
          }
        }
      }
      // 频道页列表组件，保存条件校验
      if (item.type === 'styleList') {
        if (item.resource === 1) { // 1、列表
          if (!item.categoryId) {
            Message.error(`${item.showTitle}-组件请完善商品列表组件-样式`);
            return;
          }
        } else { // 标签
          for (let inedx = 0; inedx < item.categoryIdList.length; inedx++) {
            if (!item.categoryIdList[inedx].categoryId) {
              Message.error(`${item.showTitle}-组件请完善商品列表组件-模块${inedx + 1}的样式`);
              return;
            }
            if (item.categoryIdList[inedx].titleType === 1 && !item.categoryIdList[inedx].title) {
              Message.error(`${item.showTitle}-组件请完善商品列表组件-模块${inedx + 1}的标题`);
              return;
            }
          }
        }
      }
      // 加购凑单组件，保存条件校验
      if (item.type === 'scrapeTogether') {
        let scrapeTogetherList = array.filter((e) => {
          return e.type === 'scrapeTogether';
        });
        // 如果加购组件有多个才校验时间是否冲突
        if (scrapeTogetherList.length > 1) {
          // 先对比是否存在未选时间的
          for (let i = 1; i < scrapeTogetherList.length; i++) {
            if (!scrapeTogetherList[i]?.validTime) {
              Message.error('加购凑单组件存在重叠时间段!');
              return;
            }
            if (!scrapeTogetherList[i].associatedActivityID) {
              Message.error(`第${i + 1}个加购凑单组件没选活动!`);
              return;
            }
            if (scrapeTogetherList[i].resource === 1 && !scrapeTogetherList[i].AdditionalPurchaseThreshold) {
              Message.error(`第${i + 1}个加购凑单组件没填加购门槛!`);
              return;
            }
            if (scrapeTogetherList[i].resource === 1 && !scrapeTogetherList[i].discountCoupon) {
              Message.error(`第${i + 1}个加购凑单组件没填优惠券ID!`);
              return;
            }
          }
          // 遍历数组，检查是否有重叠的时间段
          for (let i = 1; i < scrapeTogetherList.length; i++) {
            if (scrapeTogetherList[i]?.validTime[0] < scrapeTogetherList[i - 1]?.validTime[1]) {
              // 存在重叠时间段
              Message.error('加购凑单组件存在重叠时间段');
              return;
            }
          }
        } else {
          if (!item.associatedActivityID) {
            Message.error('加购凑单组件没选活动!');
            return;
          }
          if (item.resource === 1 && !item.AdditionalPurchaseThreshold) {
            Message.error('加购凑单组件没填加购门槛!');
            return;
          }
          if (item.resource === 1 && !item.discountCoupon) {
            Message.error('加购凑单组件没填优惠券ID!');
            return;
          }
        }
      }
      // 搜索组件
      if (item.type === 'search') {
        if (item.promGroupList && item.promGroupList.length > 20) {
          Message.error(`${item.showTitle}-组件：最多输入20个活动商品分组ID`);
          return;
        }
        if (item.componentGroupList && item.componentGroupList.length > 20) {
          Message.error(`${item.showTitle}-组件：最多输入20个频道选品分组ID`);
          return;
        }
      }
    }
    resolve();
  });
  return parmise;
};
