var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "swiper mySwiper",
        style: {
          "margin-bottom": _vm.obj.bMargin ? "10px solid" : "0",
          "background-color": _vm.obj.bgColor,
          padding: _vm.obj.bPadding ? "10px 12px" : 0,
        },
      },
      [
        _c(
          "div",
          { staticClass: "swiper-wrapper" },
          _vm._l(_vm.obj.list, function (item, index) {
            return _c("div", { key: index, staticClass: "swiper-slide" }, [
              _c("img", {
                attrs: {
                  src: [
                    item.img == ""
                      ? _vm.preurl
                      : item.img + "?x-oss-process=image/resize,w_375",
                  ],
                },
              }),
            ])
          }),
          0
        ),
      ]
    ),
    _c("div", { staticClass: "bt-drag-title" }, [
      _vm._v(" " + _vm._s(_vm.obj.showTitle) + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }