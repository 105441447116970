<template>
  <div
    class="swiper-goods"
    :style="{'border-bottom': obj.bMargin?'10px solid':'','background-color': obj.bgColor,'padding': obj.bPadding?'1px 26px 1px 21px':'1px 5px 1px 0'}"
  >
    <div class="swiper-goods-slide">
      <div
        v-for="(item,index) in obj.list"
        :key="index"
        class="swiper-goods-slide-item"
        :style="{'width': obj.list.length>3?'37%':'30%'}"
      >
        <img
          :src="item.coverImgUrl ? item.coverImgUrl.split(',')[0] : ''"
          :style="{'height': obj.list.length>3?'186px':'150px'}"
          width="100%"
        >
        <div>
          <p>{{ item.name }}</p>
          <span>￥{{ item.salesPrice }}</span>
        </div>
      </div>
    </div>
    <div class="bt-drag-title">
      {{ obj.title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    obj: {
      type: Object,
      default: {
        list: [],
				 	bMargin: false,
				 	bgColor: '#fff',
				 	bPadding: false,
				 	title: '轮播商品'
      }
    }
  },
  data() {
    return {
      preurl: 'https://img.hzanchu.com/acimg/fe8ac09d7174f1b218e89b2c3f2bf000.jpg?x-oss-process=image/resize,w_300'
    }
  }
}
</script>

<style scoped lang="scss">
  .swiper-goods{
      overflow: hidden;
      box-sizing:border-box;
      width:100%;
      .swiper-goods-slide{
        overflow-x: auto;
        display: flex;
        display: -webkit-box;
        .swiper-goods-slide-item{
          margin:5px;
          background:#fff;
          div{
            box-sizing:border-box;
            padding:5px;
            p{
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              font-size:14px;
            }
            span{
              color:red
            }
          }
        }
      }
    }
</style>
