<template>
  <div
    class="list-goods"
    :style="{'border-bottom': obj.bMargin?'10px solid':'','background-color': obj.bgColor,'padding': obj.bPadding?'16px 26px':'16px 0'}"
  >
    <slot />
    <div
      v-for="(item,index) in list"
      :key="index"
      class="list-goods-item"
    >
      <img :src="item.goodsImage ? item.goodsImage.split(',')[0] : ''">
      <div>
        <p class="good-name">
          {{ item.goodsName }}
        </p>
        <div style="padding-left: 0">
          <span class="group-count">{{ item.groupCount }}人团</span>
          <span class="discount">拼团立省{{ item.discountFee }}元</span>
        </div>
        <p class="good-price">
          ￥ {{ item.sellPrice }}
        </p>
      </div>
    </div>
    <div class="bt-drag-title">
      {{ obj.showTitle }}
    </div>
  </div>
</template>

<script>import handleClipboard from '@/utils/clipboard'

/*
  list: 数据
  bMargin: 是否有行边距   默认没有
  bgColor: 行边距的颜色   默认#fff
  bPadding: 边缘  默认没有
  {
  bMargin:false,
  bgColor:#fff,
  bPadding:false,
  list:[]
  }
  * **/
export default {
  props: {
    obj: {
      type: Object,
      default: () => ({
        bMargin: false,
        bgColor: '#fff',
        bPadding: false,
        title: '拼团商品',
        goodsIdStr: ''
      })
    }
  },
  data() {
    return {
      list: [],
      preurl: 'https://img.hzanchu.com/acimg/e21aab713b254226eb46882a65baf1c2.jpg?x-oss-process=image/resize,w_300'
    }
  },
  watch: {
    'obj.goodsIdStr': {
      handler(val) {
        // console.log(this.obj)
        // console.log('goodsid:', val)
        if (!val) {
          this.list = []
        } else {
          this.getGoodsList()
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getGoodsList() {
      this.$axios.post(this.$api.tradePluginTemplate.groupGoods, {
        goodsIdStr: this.obj.goodsIdStr,
        pageSize: this.obj.goodsIdStr.split(',').length,
        currentPage: 1
      }).then(res => {
        this.list = res.data.records || []
      })
    }
  }
}
</script>

<style scoped lang="scss">
.list-goods{
  box-sizing: border-box;
  display: flex;
  flex-wrap:wrap;
  justify-content:space-between;
  .list-goods-item{
    width:220px;
    background:#fff;
    margin-bottom:10px;
    img{
      width:220px;
      height:220px;
    }
    .good-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .group-count {
      background-color: red;
      color: #fff;
      border: 1px solid red;
      padding: 0 6px;
    }
    .discount {
      border: 1px solid red;
    }
    .good-price {
      font-size: 20px;
      color: red;
      margin-top: 10px;
    }
    div{
      box-sizing:border-box;
      padding:10px 12px;
      p{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        font-size:14px;
      }
      span{
        color:red
      }
    }
  }
}
</style>
