<template>
  <div
    class="home-row"
    :style="{'background-color': obj.bgColor, 'margin-bottom': obj.bMargin ? '10px' : '0', 'padding': obj.bPadding ? '20px 10px' : '20px 0'}"
  >
    <div
      v-for="(item, index) in obj.list"
      :key="index"
      class="home-row-item"
      :style="{borderRight: (index === 3 ? 'none' : '1px solid #ccc')}"
    >
      <h2 :style="{'color': obj.rowTitleColor}">
        {{ item.title }}
      </h2>
      <p :style="{'color': obj.subTitleColor}">
        {{ item.subTitle }}
      </p>
    </div>
    <div class="bt-drag-title">
      {{ obj.title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    obj: {
      type: Object,
      default: () => ({
        title: '首页列表模块',
        list: []
      })
    }
  },
  data() {
    return {
    }
  }
}
</script>

<style scoped lang="scss">
.home-row {
  width: 100%;
  display: flex;
  height: 100px;
  padding: 20px 0;
  box-sizing: border-box;
  .home-row-item {
    flex: 1;
    height: 100%;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    h2 {
      width: 100%;
      font-weight: bolder;
      margin-bottom: 5px;
    }
    p {
      width: 100%;
      color: #999;
    }
  }
}
</style>
