var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "panel" },
    [
      _c("h1", [_vm._v("内容编辑")]),
      _c(
        "h4",
        { staticClass: "panelTitle" },
        [
          !_vm.inputShow
            ? _c(
                "b",
                {
                  attrs: { onselectstart: "return false;" },
                  on: { dblclick: _vm.editTitle },
                },
                [_vm._v(_vm._s(_vm.info.title))]
              )
            : _vm._e(),
          _vm.inputShow
            ? _c("el-input", {
                staticClass: "title__input",
                attrs: { autofocus: _vm.inputShow },
                on: { blur: _vm.blurName },
                model: {
                  value: _vm.info.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.info, "title", $$v)
                  },
                  expression: "info.title",
                },
              })
            : _vm._e(),
          !_vm.info.isPageConfig
            ? _c("span", { on: { click: _vm.delModule } }, [_vm._v("删除模块")])
            : _vm._e(),
          _c("i", { staticClass: "iconfont icon-cha" }),
        ],
        1
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }