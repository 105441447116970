<template>
<div class="right-coupon">
  <el-form :model="infos" label-width="120px">
    <el-form-item class="name-box" label="展示字段：">
      <el-radio-group v-model="infos.resource">
        <el-radio :label="1">样式一：优惠劵名、领取按钮（适用于劵包）</el-radio>
        <el-radio :label="2">样式二：优惠劵名、面额、使用条件、领取按钮</el-radio>
        <el-radio :label="4">样式三：图片、优惠劵名、面额、使用条件</el-radio>
        <el-radio :label="3">样式四：优惠劵类型、优惠劵名、面额、使用条件、劵有效期、领取按钮</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="预约提醒：">
      <el-switch
        v-model="infos.reserve"
      >
      </el-switch>
      <p style="color: #999">您预约的￥优惠劵展示名称￥即将开抢</p>
    </el-form-item>
    <el-form-item label="优惠劵背景：">
      <loadImgOssUp
        name="videoMainPicUrl" 
        :url="infos.bgImg ? [infos.bgImg] : []" :maxNum="1"
        @change="uploadImg($event, 'bgImg')" />
      <div style="width: 100%; color: #999">请上传654*274尺寸的图片</div>
    </el-form-item>

    <!-- <el-form-item label="操作按钮图片：">
      <loadImgOssUp
        name="videoMainPicUrl" 
        :url="infos.btnImg ? [infos.btnImg] : []" :maxNum="1"
        @change="uploadImg($event, 'btnImg')" />
      <div style="width: 100%; color: #999">请上传654*274尺寸的图片</div>
    </el-form-item> -->
    
    <el-form-item label="优惠劵/劵包：" style="margin-bottom: 50px">
      <el-select v-model="infos.useType" size="mini" placeholder="请选择">
        <el-option label="优惠劵ID" :value="1"></el-option>
        <el-option label="劵包ID" :value="2"></el-option>
      </el-select>
      <el-input
        :placeholder="`请输入${infos.useType === 1 ? '优惠劵': '劵包'}ID`"
        v-model="useTypeContent" type="textarea" :rows="4" size="mini" clearable>
      </el-input>
      <el-button type="primary" size="mini" style="margin-bottom: 10px;" :disabled="!useTypeContent" :loading="addLoad" @click="addCoupon">添加</el-button>
      <el-table :data="tableData" row-key="id" size="mini" border style="width: 100%" >
        <el-table-column label="ID" prop="id"></el-table-column>
        <el-table-column label="优惠劵名称" prop="couponName" width="90"></el-table-column>
        <el-table-column label="优惠劵展示名" prop="couponDisplayName" width="100">
          <template #default="{ row }" >
            <el-input v-model="row.couponDisplayName" :placeholder="row.couponName" size="mini"  />
          </template>
        </el-table-column>
        <el-table-column label="类型" prop="types"></el-table-column>
        <el-table-column label="面额" prop="discountsValue"></el-table-column>
        <el-table-column label="使用条件" prop="discountsCondition"></el-table-column>
        <el-table-column label="有效期" prop="times" width="140"></el-table-column>
        <el-table-column label="图片" prop="imageUrl" width="120" v-if="infos.resource === 4">
          <template #default="{ row }">
            <upImageNew :imageList="row.imageUrl" @update:imageList="updateImageUrl(row, $event)" type="string" />
          </template>
        </el-table-column>
        <el-table-column label="操作" width="140">
          <template #default="{row, $index}">
            <span v-if="row.sort !== tableData.length - 1" class="hanle" @click="handle(row, 'down')">下移</span>
            <span v-if="row.sort !== 0" class="hanle" @click="handle(row, 'up')">上移</span>
            <span class="hanle" @click="handle(row, 'delete')">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </el-form-item>

    <!-- <el-form-item label="设置：">
      <div class="item">
        <div class="title">优惠劵未到领取时间</div>
        <div class="con">
          <el-radio v-model="infos.showTime" :label="1">展示领取时间</el-radio>
        </div>
      </div>
      <div class="item">
        <div class="title">优惠劵已抢光</div>
        <div class="con">
          <el-checkbox v-model="infos.showLootAll">展示抢光</el-checkbox>
          <el-radio v-model="infos.lootAll" label="1">排序置底</el-radio>
          <el-radio v-model="infos.lootAll" label="2">自动隐藏</el-radio>
        </div>
      </div>
    </el-form-item> -->
  </el-form>
</div>
</template>

<script>
import loadImgOssUp from '@/components/common/loadImgOssUp';
import upImageNew from '@/components/common/upImageNew/index.vue';
import {lazy, formatTimes, qsort} from 'ldx-utils';
import dayjs from 'dayjs';

export default {
  components: {loadImgOssUp, upImageNew},
  props: {
    infos: {type: Object, default: () => ({})}
  },
  data() {
    this.types = {1: '平台券', 2: '商家券'};
    return {
      tableData: [],
      useTypeContent: '',
      addLoad: false
    };
  },
  mounted() {
    this.tableData = this.infos.list;
    if (!this.infos.bgImg) {
      this.setDefaultImageBg(this.infos.column)
    }
    this.useTypeContent = this.tableData.map(item => item.id).join('\n');
    
    this.tableData = this.tableData.map(item => {
      let couponType = item.couponType
      if (!item.couponType) {
        // 由于0目前本版本是有问题的所以替换1
        couponType = 1
      }
      item.types = this.types[couponType];
      return item
    })
  },
  methods: {
    setDefaultImageBg(type){
      const images = {
        1: 'https://nb-img.hzanchu.com/h5/nbh_admin/img/%E4%B8%80%E6%8E%92%E4%B8%80bg.png',
        2: 'https://nb-img.hzanchu.com/h5/nbh_admin/img/%E4%B8%80%E6%8E%92%E4%BA%8Cbg.png',
        3: 'https://nb-img.hzanchu.com/h5/nbh_admin/img/%E4%B8%80%E6%8E%92%E4%B8%89bg.png'
      }
      this.infos.bgImg = images[type]
    },
    updateImageUrl(row, imageUrl) {
      row.imageUrl = imageUrl
      this.infos.list = this.tableData;
    },
    handle(row, type) {
      const index = row.sort;
      let tableData = this.tableData;

      if (type === 'down') {
        tableData[index].sort++;
        tableData[index + 1].sort--;
      } else if (type === 'up') {
        tableData[index].sort--;
        tableData[index - 1].sort++;
      } else if (type === 'delete') {
        tableData = tableData.filter(item => item.sort !== index);
        tableData.forEach((item, index)=> {
          item.sort = index;
        });
      }

      this.tableData = qsort(tableData, 'sort').reverse();
      this.infos.list = this.tableData;
      this.useTypeContent = this.tableData.map(item => item.id).join('\n');
    },
    uploadImg(res, name) {
      this.infos[name] = res[0] || '';
    },
    addCoupon() {
      this.addLoad = true;

      lazy.call(this, async () => {
        let url;
        const params = {
          currentPage: 1,
          pageSize: 10000
        };
        if (this.infos.useType === 1) {
          params.couponIds = this.useTypeContent.replace(/\n/g, ',');
          url = this.$api.couponTemplate.templateQueryByCouponIds;
        } else {
          params.couponPackageIds = this.useTypeContent.replace(/\n/g, ',');
          url = this.$api.coupon.queryByCouponPackageIds;
        }

        this.addLoad = false;
        const res = await this.$axios.get(url, {params});
        if (res.code === 0) {
          for (const itemRes of res.data ?? []) {
            // 新增需要替换原来的数据
            for (const itemTable of this.tableData ?? []) {
              if ((itemRes.id && (itemTable.id === itemRes.id)) || (itemRes.couponId && (itemRes.couponId === itemTable.couponId))) {
                itemRes.imageUrl = itemTable.imageUrl
                itemRes.couponDisplayName = itemTable.couponDisplayName
              }
            }
          }
          this.tableData = res.data
          this.tableData.forEach((item, index)=> {
            item.id = item.id ?? item.couponId
            item.sort = index;
            let couponType = item.couponType
            if (!item.couponType) {
              // 由于0目前本版本是有问题的所以替换1
              couponType = 1
            }
            item.types = this.types[couponType];
            item.times = formatTimes(dayjs, item.giveOutStartTime) + ' - ' + formatTimes(dayjs, item.giveOutEndTime);
            item.imageUrl = item.imageUrl ? item.imageUrl : item.headPic
          });
          this.infos.list = this.tableData;
        }
      });
    }
  }
}
</script>

<style lang="scss">
.right-coupon{
  padding: 20px 10px 50px 0;
  .type-table{padding: 0 10px; margin-bottom: 20px;}
  .item{line-height: 24px; margin-bottom: 15px;}
  .el-radio__label, .el-checkbox__label{font-size: 12px;}
  .name-box{
    .el-radio{display: flex; align-items: center; margin-bottom: 5px;}
    .el-radio__label{white-space: initial; line-height: 20px;}
  }
  .hanle{margin: 0 5px; cursor: pointer;}
}
</style>