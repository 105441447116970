<template>
  <div class="scroll-cont">
    <div class="input-box">
      <span>滚动文案:</span>
      <el-input
        v-model="newsData.beforeText"
        placeholder="请输入重点文案前的内容"
        class="input-text"
      />
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import qs from 'qs';
import { strLimit } from '@/assets/js/common';
import CutImgUp from '@/components/common/cutImgUp';
// 上下滚动消息模块
export default {
  name: 'scroll-cont',
  components: { draggable, CutImgUp },
  props: {
    newsData: {
      type: Object,
      default: () => ({
        mainColor: '#000',
        remainColor: '#000',
        beforeText: '',
        afterText: '',
        lineNum: 1,
        goodsList: [],
        info: '',
        backImg: '',
      }),
    },
  },
  data() {
    return {
      dragOptions: {
        group: 'name',
      },
      imageUrlList: [],
    };
  },
  mounted() {
    const that = this;
    if (this.newsData.backImg !== '') {
      this.imageUrlList = [this.newsData.backImg];
    } else {
      this.imageUrlList = [];
    }
    const goodsList = _.get(that, 'newsData.goodsList', []);
    if (goodsList && goodsList.length > 0) {
      const arr = that.newsData.goodsList.map((item, index) => {
        return item.goodsId;
      });
      that.newsData.info = arr.join(',');
    }
  },
  methods: {
    // 限制显示字数
    strShow(item) {
      if (item == null) {
        //
      } else {
        return strLimit(item, 16);
      }
    },
    // 添加商品
    addGoods() {
      let that = this;
      if (that.newsData.info === '') {
        that.$message('请输入商品id');
      } else {
        const array = that.newsData.info.split(',');
        // 去重
        const temp = [];
        for (let i = 0; i < array.length; i++) {
          if (!temp.includes(array[i])) {
            temp.push(array[i]);
          }
        }
        that.$axios.post(
          that.$api.tradePluginTemplate.selectGoods,
          qs.stringify({
            goodsIds: temp.join(','),
          }),
        ).then((res) => {
          const data = res;
          if (data.code === 0) {
            if (data.data.length === 0) {
              that.$message('找不到该id对应的商品');
              return null;
            }
            const arr = [...that.newsData.goodsList, ...data.data];
            that.newsData.goodsList = arr;
            that.newsData.info = '';
          }
        });
      }
    },
    // 删除商品
    delGoods(index) {
      this.newsData.goodsList.splice(index, 1);
      const arr = this.newsData.goodsList.map((item2, index2) => {
        return item2.goodsId;
      });
      this.newsData.info = arr.join(',');
    },
    onSuccess(obj) {
      this.newsData.backImg = obj[0];
    },
  },
};
</script>

<style scoped lang="scss">
.scroll-cont {
  position: relative;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}
.input-box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  &>span {
    display: inline-block;
    width: 90px;
    text-align: right;
    color: #8e8e8e;
    padding-right: 10px;
    box-sizing: border-box;
  }
}
.tool-item {
  padding:10px 0 10px 10px;
  & span {
    padding-right: 10px;
  }
  .tool__content {
    width: 145px;
    height: 40px;
    display: inline-block;
  }
  .vertical-spacing {
    width: 100px;
  }
  .tool-bg {
    width: 100px;
    display: inline-block;
  }
  .tool-border {
    width: 100px;
  }
  .color__show {
    width: 32px;
    height: 32px;
    margin-left: 4px;
    border-radius: 4px;
    display: inline-block;
    vertical-align: top;
    border: 1px solid #979797;
    background:rgba(238,238,238,1);
    .color__content {
      width: 22px;
      height: 22px;
      margin: 5px;
    }
  }
  .type__name {
    display: inline-block;
    width: 90px;
    text-align: right;
    color:rgba(142,142,142,1);
  }
}
.list-content{
  width: 360px;
  height: 140px;
  border: 1px solid #D9DDE1;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 4px;
  ul {
    width: 100%;
    li {
      display: flex;
      cursor: move;
    }
  }
  & p {
    width: 200px;
    height: 20px;
    display: inline-block;
    margin-right: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  & span{
    display: inline-block;
    cursor: pointer;
  }
  .item-id {
    width: 50px;
  }
}
.delet-btn {
  color: red;
  cursor: pointer;
}
</style>
