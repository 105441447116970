var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "list-goods",
      style: {
        "border-bottom": _vm.obj.bMargin ? "10px solid" : "",
        "background-color": _vm.obj.bgColor,
        padding: _vm.obj.bPadding ? "16px 26px" : "16px 0",
      },
    },
    [
      _vm._t("default"),
      _vm._l(_vm.obj.list, function (item, index1) {
        return _c(
          "div",
          { key: index1, staticClass: "list-goods-content" },
          _vm._l(item.goodsDetail, function (goods, index2) {
            return _c("div", { key: index2, staticClass: "list-goods-item" }, [
              _c("img", { attrs: { src: goods.imageUrls.split(",")[0] } }),
              _c("div", [
                _c("p", { staticClass: "good-name" }, [
                  _vm._v(" " + _vm._s(goods.name) + " "),
                ]),
                _c("p", { staticClass: "goods-sale" }, [
                  _vm._v(" 已抢" + _vm._s(goods.robbedStock) + "件 "),
                ]),
                _c("p", { staticClass: "sell_price" }, [
                  _vm._v(" ￥ " + _vm._s(goods.price) + " "),
                  _c("span", { staticClass: "line_throw" }, [
                    _vm._v(_vm._s(goods.marketPrice)),
                  ]),
                ]),
              ]),
            ])
          }),
          0
        )
      }),
      _c("div", { staticClass: "bt-drag-title" }, [
        _vm._v(" " + _vm._s(_vm.obj.title) + " "),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }