var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "video-pre",
      style: {
        "border-bottom": _vm.obj.bMargin ? "10px solid" : "",
        "border-color": _vm.obj.bgColor,
        padding: _vm.obj.bPadding ? "1px 26px 1px 21px" : "1px 5px 1px 0",
      },
    },
    [
      _c("img", {
        staticClass: "post-img",
        attrs: {
          src: [
            _vm.obj.coverURL == ""
              ? _vm.preurl
              : _vm.obj.coverURL + "?x-oss-process=image/resize,w_300",
          ],
        },
      }),
      _c("div", { staticClass: "bt-drag-title" }, [
        _vm._v(" " + _vm._s(_vm.obj.title) + " "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }