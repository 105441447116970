<template>
  <div class="addTitle">
    <!-- 标题-名称 -->
    <div class="add__title--name">
      <div class="add__name--name">
        <span>名称</span>
        <el-input
          v-model="titleinfo.text"
          placeholder="请输入标题名称限20字以内"
          class="name__input"
          maxlength="20"
        />
      </div>
      <div class="add__name--type">
        <span>链接</span>
        <el-select
          v-model="nameValue"
          placeholder="请选择"
          class="name__select"
          @change="selectChange"
        >
          <el-option
            v-for="item in nameOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div
        v-show="nameValue == 0"
        class="add__name--link"
      >
        <span>链接地址</span>
        <el-input
          v-model="titleinfo.link"
          placeholder="请输入链接"
          class="name__input"
        />
      </div>
    </div>
  </div>
</template>

<script>
/**
	 * name: 标题模块
	 * param: titleinfo.text 标题名称
	 * param: nameValue 链接内容
	 */
export default {
  name: 'AddTitle',

  props: {
    titleinfo: {
      type: Object,
      default: {
        text: '',
        link: ''
      }
    }
  },
  data() {
    return {
      nameValue: 0,
      nameOptions: [{
        label: '有',
        value: 0
      },
      {
        label: '无',
        value: 1
      }
      ]
    }
  },
  mounted() {
    if (this.titleinfo.link == '') {
      this.nameValue = 1
    }
  },
  methods: {
    selectChange() {
      if (this.nameValue == 1) {
        this.titleinfo.link = ''
      }
    }
  }

}
</script>

<style lang="scss" scoped>
  .addTitle {
    width: 100%;
    height: 400px;
    .add__title--name {
      width: 470px;
      height: 160px;
      background: #fff;
      .add__name--name {
        width: 100%;
        margin-top: 25px;
        & span {
          width: 100px;
          display: inline-block;
          text-align: right;
          margin-right: 10px;
        }
        .name__input {
          width: 300px;
        }
      }
      .add__name--type {
        width: 100%;
        margin-top: 25px;
        & span {
          width: 100px;
          display: inline-block;
          margin-right: 10px;
          text-align: right;
        }
        .name__select {
          width: 300px;
        }
      }
      .add__name--link {
        width: 100%;
        margin-top: 25px;
        & span {
          width: 100px;
          display: inline-block;
          margin-right: 10px;
          text-align: right;
        }
        .name__input {
          width: 300px;
        }
      }
    }
    .add__title--goods {
      .add__goods--name {
        width: 100%;
        margin-top: 25px;
        & span {
          width: 100px;
          display: inline-block;
          margin-right: 10px;
          text-align: right;
        }
        .goods__input {
          width: 300px;
        }
      }
      .add__goods--type {
        width: 100%;
        margin-top: 25px;
        & span {
          width: 100px;
          display: inline-block;
          margin-right: 10px;
          text-align: right;
        }
        .goods__select {
          width: 300px;
        }
      }
      .add__goods--link {
        width: 100%;
        margin-top: 25px;
        & span {
          width: 100px;
          display: inline-block;
          margin-right: 10px;
          text-align: right;
        }
        .goods__input {
          width: 300px;
        }
      }
    }
  }
</style>
