var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-home" },
    _vm._l(_vm.addrow.list, function (item, index) {
      return _c("div", { key: index, staticClass: "page-list" }, [
        _c("div", { staticClass: "list-num" }, [
          _vm._v(" " + _vm._s(`列表${index + 1}`) + " "),
        ]),
        _c(
          "div",
          { staticClass: "input-box title" },
          [
            _c("span", [_vm._v("主标题")]),
            _c("el-input", {
              staticClass: "input-text",
              attrs: {
                placeholder: "请输入主标题长度限制4个字符",
                maxlength: "4",
              },
              model: {
                value: item.title,
                callback: function ($$v) {
                  _vm.$set(item, "title", $$v)
                },
                expression: "item.title",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "input-box subtitle" },
          [
            _c("span", [_vm._v("副标题")]),
            _c("el-input", {
              staticClass: "input-text",
              attrs: {
                placeholder: "请输入副标题长度限制5个字符",
                maxlength: "5",
              },
              model: {
                value: item.subTitle,
                callback: function ($$v) {
                  _vm.$set(item, "subTitle", $$v)
                },
                expression: "item.subTitle",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "input-box subtitle" },
          [
            _c("span", [_vm._v("展示类型")]),
            _c(
              "el-select",
              {
                attrs: { placeholder: "请选择类型" },
                on: { change: (el) => _vm.changeType(el, index) },
                model: {
                  value: item.unique,
                  callback: function ($$v) {
                    _vm.$set(item, "unique", $$v)
                  },
                  expression: "item.unique",
                },
              },
              _vm._l(_vm.optionsList, function (child) {
                return _c("el-option", {
                  key: child.value,
                  attrs: { label: child.label, value: child.value },
                })
              }),
              1
            ),
          ],
          1
        ),
        item.unique === "0"
          ? _c("div", { staticClass: "input-box goods-list" }, [
              _c("span", { staticStyle: { "margin-right": "20px" } }, [
                _vm._v("商品"),
              ]),
              _c(
                "div",
                { staticClass: "list-content" },
                [
                  _c(
                    "draggable",
                    {
                      attrs: { element: "div" },
                      model: {
                        value: item.goodsList,
                        callback: function ($$v) {
                          _vm.$set(item, "goodsList", $$v)
                        },
                        expression: "item.goodsList",
                      },
                    },
                    _vm._l(item.goodsList, function (item2, index2) {
                      return _c("ul", { key: index2 }, [
                        _c("li", [
                          _c("span", { staticClass: "item-id" }, [
                            _vm._v(_vm._s(item2.goodsId)),
                          ]),
                          _c("p", [_vm._v(_vm._s(_vm.strShow(item2.name)))]),
                          _c(
                            "span",
                            { class: [item2.isOnSale === 1 ? "" : "red"] },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item2.isOnSale === 1 ? "已上架" : "已下架"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "delet-btn",
                              staticStyle: { margin: "0" },
                              on: {
                                click: function ($event) {
                                  return _vm.delGoods(index, index2)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        item.unique === "0"
          ? _c(
              "div",
              { staticClass: "input-box goods-input" },
              [
                _c("el-input", {
                  staticStyle: {
                    width: "240px",
                    float: "left",
                    "margin-left": "40px",
                  },
                  attrs: {
                    type: "text",
                    placeholder:
                      "输入商品id,可输入多个商品,多个商品id之间以英文“,”区分",
                    size: "mini",
                  },
                  on: {
                    input: (e) => {
                      _vm.goodsInfo[index].info = e.replace(/[^0-9,]/g, "")
                    },
                  },
                  model: {
                    value: _vm.goodsInfo[index].info,
                    callback: function ($$v) {
                      _vm.$set(_vm.goodsInfo[index], "info", $$v)
                    },
                    expression: "goodsInfo[index].info",
                  },
                }),
                _c(
                  "el-button",
                  {
                    staticStyle: { float: "left", "margin-left": "10px" },
                    attrs: { size: "mini" },
                    on: {
                      click: function ($event) {
                        return _vm.addGoods(index)
                      },
                    },
                  },
                  [_vm._v(" 立即添加 ")]
                ),
              ],
              1
            )
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }