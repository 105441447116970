var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "add__goods" }, [
    _vm._m(0),
    _c("div", { staticClass: "goods__list" }, [
      _c(
        "div",
        { staticClass: "list__content" },
        _vm._l(_vm.addscroll.list, function (item, index) {
          return _c("ul", { key: index }, [
            _c("li", [
              _c("span", { staticClass: "item-id" }, [
                _vm._v(_vm._s(item.goodsId)),
              ]),
              _c("p", [_vm._v(_vm._s(_vm.strShow(item.name)))]),
              _c("span", { class: [item.isOnSale === 1 ? "" : "red"] }, [
                _vm._v(_vm._s(item.isOnSale === 1 ? "已上架" : "已下架")),
              ]),
              _c(
                "span",
                {
                  staticClass: "delet-btn",
                  staticStyle: { margin: "0" },
                  on: {
                    click: function ($event) {
                      return _vm.delGoods(index)
                    },
                  },
                },
                [_vm._v("删除")]
              ),
            ]),
          ])
        }),
        0
      ),
      _c("div", { staticClass: "list__add" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.goodsInfo,
              expression: "goodsInfo",
            },
          ],
          attrs: {
            type: "text",
            placeholder:
              "输入商品id,可输入多个商品,多个商品id之间以英文“,”区分",
          },
          domProps: { value: _vm.goodsInfo },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.addGoods.apply(null, arguments)
            },
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.goodsInfo = $event.target.value
              },
              (e) => {
                _vm.goodsInfo = e.replace(/[^0-9,]/g, "")
              },
            ],
          },
        }),
        _vm._v(" "),
        _c("span", { on: { click: _vm.addGoods } }, [_vm._v(" 立即添加")]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "goods__name" }, [
      _c("p", [_vm._v("商品：")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }