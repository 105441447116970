<template>
  <div class="map-button">
    <div class="tool-item">
      <div class="tool-label">
        上传图片:
      </div>
      <div class="tool-value">
        <CutImgUp
          :url="imageUrlList"
          :auto-crop-width="750"
          :auto-crop-height="750"
          :max-num="1"
          @change="onSuccess"
        />
      </div>
    </div>
    <div class="tool-item">
      <div class="tool-label">
        跳转类型:
      </div>
      <div class="tool-value">
        <el-radio-group v-model="info.jumpType">
          <el-radio
            label="1"
          >
            地图
          </el-radio>
          <el-radio
            label="2"
          >
            路线
          </el-radio>
        </el-radio-group>
      </div>
    </div>
    <template v-if="info.jumpType === '1'">
      <div class="tool-item">
        <div class="tool-label">
          经度:
        </div>
        <div class="tool-value">
          <el-input v-model="info.longitude" />
        </div>
      </div>
      <div class="tool-item">
        <div class="tool-label">
          纬度:
        </div>
        <div class="tool-value">
          <el-input v-model="info.latitude" />
        </div>
      </div>
    </template>

    <div
      v-else
      class="tool-item"
    >
      <div class="tool-label">
        路线ID:
      </div>
      <div class="tool-value">
        <el-input v-model="info.routeId" />
      </div>
    </div>
  </div>
</template>

<script>
import CutImgUp from '@/components/common/cutImgUp'
export default {
  components: { CutImgUp },
  props: {
    info: {
      type: Object,
      default: () => ({
        imageUrl: '',
        jumpType: '1',
        longitude: '',
        latitude: '',
        routeId: ''
      })
    }
  },
  data() {
    return {
      imageUrlList: []
    }
  },
  mounted() {
    console.log(this.info)
    if (this.info.imageUrl !== '') {
      this.imageUrlList = [this.info.imageUrl]
    } else {
      this.imageUrlList = []
    }
  },
  methods: {
    onSuccess(obj) {
      this.info.imageUrl = obj[0]
    }
  }
}
</script>

<style lang="scss" scoped>
  .map-button{
    padding: 5px 0;
    border-bottom: 1px dashed #eee;
    .tool-item {
      display: flex;
      align-items: center;
      padding: 10px 0 10px 10px;
      .tool-label {
        width: 90px;
        text-align: right;
        color: #8e8e8e;
        padding-right: 10px;
        flex-shrink: 0;
      }
      .tool-value {
        display: flex;
        align-items: center;
        .color__show {
            width: 32px;
            height: 32px;
            margin-left: 4px;
            border-radius: 4px;
            display: inline-block;
            vertical-align: top;
            border: 1px solid #979797;
            background:rgba(238,238,238,1);
            .color__content {
                width: 22px;
                height: 22px;
                margin: 5px;
            }
        }
      }
    }
  }
</style>
