var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "appointment_view" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "vessel" },
      _vm._l(_vm.obj.list, function (item, i) {
        return _c(
          "VueDragResize",
          {
            key: i,
            ref: "VueDragResize",
            refInFor: true,
            attrs: {
              isActive: false,
              isDraggable: true,
              isResizable: _vm.isResizable,
              w: item.style.width,
              h: item.style.height,
              x: item.style.left,
              y: item.style.top,
              minw: 10,
              minh: 10,
              z: 999,
              snapToGrid: true,
              gridX: 5,
              gridY: 5,
              parentW: 375,
            },
            on: {
              resizing: (e) => _vm.resize(e, item),
              dragging: (e) => _vm.resize(e, item),
              dragstop: (e) => _vm.dragstop(e, item),
            },
          },
          [
            _c("img", {
              style: `width: 100%; height: 100%; border: ${
                item.imageFront[0] ? 0 : 1
              }px solid #000`,
              attrs: { src: item.imageFront[0], alt: "" },
            }),
          ]
        )
      }),
      1
    ),
    _c("div", { staticClass: "bt-drag-title" }, [
      _vm._v(" " + _vm._s(_vm.obj.showTitle) + " "),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticStyle: { "padding-left": "100px", "line-height": "18px" } },
      [
        _vm._v(" 注：1，预约组件下方必须是"),
        _c("b", [_vm._v("静态图")]),
        _vm._v("或"),
        _c("b", [_vm._v("自定义静态图")]),
        _vm._v("组件；"),
        _c("br"),
        _vm._v(" 2，此高度不会在前端展示，只展示下方悬浮按钮； "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }