<template>
  <div class="add__list addCate">
    <!-- 类目名称 -->
    <div class="category__name">
      <span>类目{{ categoryNum }}:</span>
      <el-input
        v-model.trim="goodsListInfo.name"
        placeholder="请输入类目名称，限10字以内"
        class="category__input"
        maxlength="10"
        size="mini"
      />
    </div>
    <div class="category_jump_type">
      <span>类目跳转组件:</span>
      <el-select
        v-model="goodsListInfo.cateId"
        @change="changeContent"
      >
        <el-option
          v-for="(item, index) in modelList"
          :key="index"
          :label="item.showTitle"
          :value="item.cateId"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
/**
 * 类目组件343
 * @module AddCate
 * @example <b> addCate 组件</b>
 */
import { strLimit } from '@/assets/js/common'
import draggable from 'vuedraggable'
import loadImg from '@/components/common/loadImg'
import md5 from 'md5-js'
import { SELECT_OPTIONS, NOT_SHOW_INPUT } from './data'
import qs from 'qs'

export default {
  name: 'AddList',
  components: { draggable, loadImg },

  /**
 * 类目组件
 * @prop {Object} goodsListInfo 商品列表
 * @prop {String} categoryNum 类目序号
 */
  props: {
    goodsListInfo: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        type: '',
        categoryNum: '', // 类目顺序
        name: '', // 类目名称
        content: '',
        cateId: '',
      }
    },
    modelList: {
      type: Array,
      default: []
    },
    categoryNum: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      toast: '', // 输入框提示
      imgData: {
        fileList: [],
        imgCode: []
      },
      selectOptions: SELECT_OPTIONS,
      notShowInput: NOT_SHOW_INPUT,
      addLoading: false,
      canSetSelect: false,
      couponbag: '' // 不能修改的优惠券id信息
    }
  },
  watch: {
    modelList: {
      handler(item) {
        console.log(item,'模块列表111')
      },
      // deep: true,
      // immediate: true
    }
  },
  mounted() {
  },

  methods: {
    changeContent(val) {
      console.log(val,8888)
    },
  }
}
</script>

<style lang="scss" scoped>
.add__list {
    width: calc(100% - 30px);
    // height: 355px;
    // margin: 0 15px;
    padding: 0 15px;
    padding-top: 15px;
    .category__name{
        & span {
          width: 78px;
          height: 36px;
          color: #8E8E8E;
          margin-right: 6px;
          text-align: right;
          display: inline-block;
        }
        .category__input {
            width: 300px;
        }

    }
}
.delet-btn {
  color: red;
  cursor: pointer;
}
.category_jump_type {
  & > span {
    width: 78px;
    height: 36px;
    color: #8E8E8E;
    margin-right: 6px;
    text-align: right;
    display: inline-block;
  }
}
.category_jump_content {
  display: flex;
  margin-bottom: 10px;
  & > span {
    width: 78px;
    height: 36px;
    color: #8E8E8E;
    margin-right: 6px;
    text-align: right;
    display: inline-block;
    line-height: 36px;
  }
}
.couponbag {
  position: relative;
  width: 246px;
  .annotation {
    position: absolute;
    left: -70px;
    top: 20px;
  }
}
.county-remark {
  width: 100%;
  padding-left: 30px;
  box-sizing: border-box;
  color: #666;
  margin-bottom: 30px;
}
</style>
