var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-home" },
    [
      _c("div", { staticClass: "list-num" }, [
        _vm._v(" " + _vm._s(`频道${_vm.keyNum + 1}`) + " "),
      ]),
      _c(
        "div",
        { staticClass: "input-box" },
        [
          _c("span", [_vm._v("跳转类型")]),
          _c(
            "el-select",
            {
              on: { change: _vm.changeContent },
              model: {
                value: _vm.imgInfo.type,
                callback: function ($$v) {
                  _vm.$set(_vm.imgInfo, "type", $$v)
                },
                expression: "imgInfo.type",
              },
            },
            _vm._l(_vm.selectOptions, function (type, i) {
              return _c("el-option", {
                key: i,
                attrs: { label: type.label, value: type.value },
              })
            }),
            1
          ),
        ],
        1
      ),
      ["link", "goods"].includes(_vm.imgInfo.type)
        ? _c(
            "div",
            { staticClass: "input-box" },
            [
              _c("span", [_vm._v("商品ID/链接")]),
              _c("el-input", {
                staticClass: "input-text",
                model: {
                  value: _vm.imgInfo.link,
                  callback: function ($$v) {
                    _vm.$set(_vm.imgInfo, "link", $$v)
                  },
                  expression: "imgInfo.link",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.keyNum === 0 ||
      _vm.keyNum === 2 ||
      (_vm.keyNum === 1 && _vm.addchannel.list[0].label === "") ||
      (_vm.keyNum === 3 && _vm.addchannel.list[2].label === "")
        ? [
            _c(
              "div",
              { staticClass: "input-box" },
              [
                _c("span", [_vm._v("频道标题")]),
                _c("el-input", {
                  staticClass: "input-text",
                  attrs: {
                    placeholder: "请输入频道标题长度限制4个字符",
                    maxlength: "4",
                  },
                  model: {
                    value: _vm.imgInfo.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.imgInfo, "title", $$v)
                    },
                    expression: "imgInfo.title",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "input-box" },
              [
                _c("span", [_vm._v("字体颜色")]),
                _c("el-input", {
                  staticClass: "input-text",
                  attrs: { placeholder: "请输入色值" },
                  model: {
                    value: _vm.imgInfo.titleColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.imgInfo, "titleColor", $$v)
                    },
                    expression: "imgInfo.titleColor",
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.keyNum === 0 || _vm.keyNum === 2
        ? [
            _c(
              "div",
              { staticClass: "input-box" },
              [
                _c("span", [_vm._v("频道标签")]),
                _c("el-input", {
                  staticClass: "input-text",
                  attrs: {
                    placeholder: "请输入频道标签长度限制6个字符",
                    maxlength: "6",
                  },
                  model: {
                    value: _vm.imgInfo.label,
                    callback: function ($$v) {
                      _vm.$set(_vm.imgInfo, "label", $$v)
                    },
                    expression: "imgInfo.label",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "input-box" },
              [
                _c("span", [_vm._v("字体颜色")]),
                _c("el-input", {
                  staticClass: "input-text",
                  attrs: { placeholder: "请输入色值" },
                  model: {
                    value: _vm.imgInfo.labelColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.imgInfo, "labelColor", $$v)
                    },
                    expression: "imgInfo.labelColor",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "input-box" },
              [
                _c("span", [_vm._v("背景颜色")]),
                _c("el-input", {
                  staticClass: "input-text",
                  attrs: { placeholder: "请输入色值" },
                  model: {
                    value: _vm.imgInfo.labelBack,
                    callback: function ($$v) {
                      _vm.$set(_vm.imgInfo, "labelBack", $$v)
                    },
                    expression: "imgInfo.labelBack",
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.keyNum === 0 ||
      _vm.keyNum === 2 ||
      (_vm.keyNum === 1 && _vm.addchannel.list[0].label === "") ||
      (_vm.keyNum === 3 && _vm.addchannel.list[2].label === "")
        ? [
            _vm.keyNum === 0 ||
            _vm.keyNum === 2 ||
            (_vm.keyNum === 1 && _vm.addchannel.list[0].subTitle.length < 5) ||
            (_vm.keyNum === 3 && _vm.addchannel.list[2].subTitle.length < 5)
              ? _c(
                  "div",
                  { staticClass: "input-box" },
                  [
                    _c("span", [_vm._v("频道副标题")]),
                    _c("el-input", {
                      staticClass: "input-text",
                      attrs: {
                        placeholder: `请输入频道副标题长度限制${_vm.maxLength}个字符`,
                        maxlength: _vm.maxLength,
                      },
                      model: {
                        value: _vm.imgInfo.subTitle,
                        callback: function ($$v) {
                          _vm.$set(_vm.imgInfo, "subTitle", $$v)
                        },
                        expression: "imgInfo.subTitle",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "input-box" },
              [
                _c("span", [_vm._v("字体颜色")]),
                _c("el-input", {
                  staticClass: "input-text",
                  attrs: { placeholder: "请输入色值" },
                  model: {
                    value: _vm.imgInfo.subTitleColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.imgInfo, "subTitleColor", $$v)
                    },
                    expression: "imgInfo.subTitleColor",
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c("div", { staticClass: "input-box" }, [
        _c("span", [_vm._v("封面图")]),
        _c(
          "div",
          { staticClass: "choose-img" },
          [
            _c(
              "el-radio",
              {
                attrs: { label: "1" },
                model: {
                  value: _vm.imgInfo.imgType,
                  callback: function ($$v) {
                    _vm.$set(_vm.imgInfo, "imgType", $$v)
                  },
                  expression: "imgInfo.imgType",
                },
              },
              [_vm._v(" 上传图片 ")]
            ),
            _c(
              "el-radio",
              {
                attrs: { label: "2", disabled: _vm.imgInfo.type === "live" },
                model: {
                  value: _vm.imgInfo.imgType,
                  callback: function ($$v) {
                    _vm.$set(_vm.imgInfo, "imgType", $$v)
                  },
                  expression: "imgInfo.imgType",
                },
              },
              [_vm._v(" 商品ID ")]
            ),
          ],
          1
        ),
      ]),
      _c("div", [
        _c(
          "div",
          [
            _vm.imgInfo.imgType === "1"
              ? _c("CutImgUp", {
                  attrs: {
                    url: _vm.imageUrlList,
                    "auto-crop-width": 750,
                    "auto-crop-height": 750,
                    "max-num": 1,
                  },
                  on: { change: _vm.onSuccess },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm.imgInfo.imgType === "2"
          ? _c("div", [
              _c(
                "div",
                { staticClass: "get-good-img" },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入一个商品ID" },
                    model: {
                      value: _vm.imgInfo.imgGoodsId,
                      callback: function ($$v) {
                        _vm.$set(_vm.imgInfo, "imgGoodsId", $$v)
                      },
                      expression: "imgInfo.imgGoodsId",
                    },
                  }),
                  _c("button", { on: { click: _vm.addGoods } }, [
                    _vm._v(" 立即添加 "),
                  ]),
                ],
                1
              ),
              _c(
                "p",
                {
                  staticStyle: {
                    "margin-top": "10px",
                    "padding-bottom": "20px",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s((_vm.goodInfo[0] && _vm.goodInfo[0].name) || "") +
                      " "
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }