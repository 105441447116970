<template>
  <div class="img-info">
    <el-form label-width="100px">
      <el-form-item label="上传图片:">
        <CutImgUp
          :url="imageUrlList"
          :auto-crop-width="750"
          :auto-crop-height="750"
          :max-num="1"
          @change="onSuccess"
        />
      </el-form-item>
      <el-form-item label="点击区域:">
        热区
        <!-- <div class="box-card-line">
          上下：<el-input-number
            v-model="imgInfo.top"
            size="mini"
            :min="0"
            :max="100"
            label="描述文字"
          />
          左右：<el-input-number
            v-model="imgInfo.left"
            size="mini"
            :min="0"
            :max="100"
            label="描述文字"
          />
        </div>
        <div class="box-card-line">
          宽度：<el-input-number
            v-model="imgInfo.width"
            size="mini"
            :min="0"
            :max="100"
            label="描述文字"
          />
          高度：<el-input-number
            v-model="imgInfo.height"
            size="mini"
            :min="0"
            :max="100"
            label="描述文字"
          />
        </div>
        <div class="box-card-line">
          区域层级（默认99）：<el-input-number
            v-model="imgInfo.zIndex"
            size="mini"
            :min="1"
            :max="999999"
            label="描述文字"
          />
        </div> -->
      </el-form-item>
      <el-form-item label="跳转类型:">
        <span>搜索页</span>
      </el-form-item>
      <!-- <div class="label">
        搜索商品范围设置:
      </div>
      <el-form-item label="活动ID">
        <el-input
          v-model="imgInfo.promIdList"
          placeholder="输入活动id,可输入多个活动,多个活动id之间以英文“,”区分"
        />
      </el-form-item>
      <el-form-item label="所属展馆">
        <el-cascader
          v-model="imgInfo.siteIdList"
          :options="pavilionOptions"
          :show-all-levels="false"
          :props="{multiple: true, checkStrictly: true, label: 'siteName',
                   value: 'siteId', emitPath: false}"
          clearable
        />
      </el-form-item> -->
    </el-form>
  </div>
</template>

<script>
import md5 from 'md5-js';
import { SELECT_OPTIONS, NOT_SHOW_INPUT } from './data';
import CutImgUp from '@/components/common/cutImgUp';

export default {
  name: 'img-info',
  components: { CutImgUp },
  props: {
    imgInfo: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        title: '搜索',
        type: 'search',
        bMargin: false,
        size: '212px',
        bgColor: '#fff',
        bPadding: false,
        img: '',
        content: '',
        coupon_key: '',
        top: 0,
        left: 0,
        width: 20,
        height: 50,
        zIndex: 99,
        promIdList: '',
        siteIdList: [],
        storeId: 0,
      },
    },
  },
  data() {
    return {
      activeName: '区域1',
      imgData: {
        fileList: [],
        imgCode: [],
      },
      selectOptions: SELECT_OPTIONS,
      notShowInput: NOT_SHOW_INPUT,
      canSetcouponbag: false,
      canSetSelect: false,
      imageUrlList: [],
      pavilionOptions: [],
    };
  },
  mounted() {
    if (this.imgInfo.img !== '') {
      this.imageUrlList = [this.imgInfo.img];
    } else {
      this.imgData.fileList = [];
    }
    this.getSiteInfo();
  },
  methods: {
    changeContent(val) {
      this.imgInfo.content = '';
      if (val === 'coupackage') {
        this.imgInfo.content = this.couponbag;
      }
    },
    // 添加自定义区域
    addSelf(index) {
      if (index < 5) {
        let data = {
          top: 0,
          left: index * 20,
          width: 20,
          height: 50,
          type: 'goods',
          content: '',
          zIndex: 99,
        };
        this.imgInfo.self.push(data);
        this.activeName = `区域${index + 1}`;
      } else {
        this.$message({
          type: 'warning',
          message: '最多添加5个区域',
        });
      }
    },
    // 删除自定义区域
    deleteSelf(index) {
      this.$confirm(`此操作将删除区域${index + 1}, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.imgInfo.self.splice(index, 1);
          this.activeName = `区域${index < 1 ? 1 : index}`;
          this.$message({
            type: 'success',
            message: '删除成功!',
          });
        })
        .catch(() => {});
    },
    onSuccess(obj) {
      this.imgInfo.img = obj[0];
    },
    // 获取展馆列表信息
    getSiteInfo() {
      let that = this;
      that.$axios.get(that.$api.promApply.all_site_list, {
        params: {
          siteType: 1,
        },
      }).then((res) => {
        if (res.code === 0) {
          let str = JSON.stringify(res.data);
          str = str.replaceAll('[]', '""');
          that.pavilionOptions = JSON.parse(str);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.img-info {
  padding: 5px 0;
  border-bottom: 1px dashed #eee;
}
.couponbag {
  position: relative;
  width: 246px;
  .annotation {
    position: absolute;
    left: -70px;
    top: 20px;
  }
}
.box-card {
  margin-bottom: 20px;
  .box-card-line {
    margin-bottom: 10px;
    .el-input-number {
      margin-right: 20px;
    }
  }
}
.county-remark {
  width: 100%;
  padding-left: 30px;
  box-sizing: border-box;
  color: #666;
  margin-bottom: 30px;
}
.deleteBox {
  text-align: right;
}
.label {
  font-size: 14px;
  padding-left: 10px;
  margin-bottom: 10px;
}
</style>
