var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tool-wrapper" }, [
    _c(
      "div",
      { staticClass: "tool" },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.toolType.toolSize,
                expression: "toolType.toolSize",
              },
            ],
            staticClass: "tool-item",
          },
          [
            _c("span", { staticClass: "type__name" }, [_vm._v("尺寸:")]),
            _c(
              "div",
              { staticClass: "tool__content" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "vertical-spacing",
                    attrs: { placeholder: "请选择" },
                    model: {
                      value: _vm.contentInfo.size,
                      callback: function ($$v) {
                        _vm.$set(_vm.contentInfo, "size", $$v)
                      },
                      expression: "contentInfo.size",
                    },
                  },
                  _vm._l(_vm.sizeOptions, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.toolType.toolVertical,
                expression: "toolType.toolVertical",
              },
            ],
            staticClass: "tool-item",
          },
          [
            _c("span", { staticClass: "type__name" }, [_vm._v("行间距:")]),
            _c(
              "div",
              { staticClass: "tool__content" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "vertical-spacing",
                    attrs: { placeholder: "请选择" },
                    model: {
                      value: _vm.contentInfo.bMargin,
                      callback: function ($$v) {
                        _vm.$set(_vm.contentInfo, "bMargin", $$v)
                      },
                      expression: "contentInfo.bMargin",
                    },
                  },
                  _vm._l(_vm.verticalSpacingOptions, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.toolType.toolBg,
                expression: "toolType.toolBg",
              },
            ],
            staticClass: "tool-item",
          },
          [
            _c("span", { staticClass: "type__name" }, [_vm._v("背景色:")]),
            _c(
              "div",
              { staticClass: "tool__content" },
              [
                _c("el-input", {
                  staticClass: "tool-bg",
                  model: {
                    value: _vm.contentInfo.bgColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.contentInfo, "bgColor", $$v)
                    },
                    expression: "contentInfo.bgColor",
                  },
                }),
                _c("div", { staticClass: "color__show" }, [
                  _c("div", {
                    staticClass: "color__content",
                    style: { background: _vm.contentInfo.bgColor },
                  }),
                ]),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.toolType.toolBorder,
                expression: "toolType.toolBorder",
              },
            ],
            staticClass: "tool-item",
          },
          [
            _c("span", { staticClass: "type__name" }, [_vm._v("边缘:")]),
            _c(
              "div",
              { staticClass: "tool__content" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "tool-border",
                    attrs: { placeholder: "请选择" },
                    model: {
                      value: _vm.contentInfo.bPadding,
                      callback: function ($$v) {
                        _vm.$set(_vm.contentInfo, "bPadding", $$v)
                      },
                      expression: "contentInfo.bPadding",
                    },
                  },
                  _vm._l(_vm.borderCurrentOptions, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.toolType.toolText,
                expression: "toolType.toolText",
              },
            ],
            staticClass: "tool-item",
          },
          [
            _c("span", { staticClass: "type__name" }, [_vm._v("字体色:")]),
            _c(
              "div",
              { staticClass: "tool__content" },
              [
                _c("el-input", {
                  staticClass: "tool-bg",
                  model: {
                    value: _vm.contentInfo.color,
                    callback: function ($$v) {
                      _vm.$set(_vm.contentInfo, "color", $$v)
                    },
                    expression: "contentInfo.color",
                  },
                }),
                _c("div", { staticClass: "color__show" }, [
                  _c("div", {
                    staticClass: "color__content",
                    style: { background: _vm.contentInfo.color },
                  }),
                ]),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.toolType.toolTitle,
                expression: "toolType.toolTitle",
              },
            ],
            staticClass: "tool-item",
          },
          [
            _c("span", { staticClass: "type__name" }, [_vm._v("背景色:")]),
            _c(
              "div",
              { staticClass: "tool__content" },
              [
                _c("el-input", {
                  staticClass: "tool-bg",
                  model: {
                    value: _vm.contentInfo.titleBgColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.contentInfo, "titleBgColor", $$v)
                    },
                    expression: "contentInfo.titleBgColor",
                  },
                }),
                _c("div", { staticClass: "color__show" }, [
                  _c("div", {
                    staticClass: "color__content",
                    style: { background: _vm.contentInfo.titleBgColor },
                  }),
                ]),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.toolType.toolTitleText,
                expression: "toolType.toolTitleText",
              },
            ],
            staticClass: "tool-item",
          },
          [
            _c("span", { staticClass: "type__name" }, [_vm._v("字体色:")]),
            _c(
              "div",
              { staticClass: "tool__content" },
              [
                _c("el-input", {
                  staticClass: "tool-bg",
                  model: {
                    value: _vm.contentInfo.titleColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.contentInfo, "titleColor", $$v)
                    },
                    expression: "contentInfo.titleColor",
                  },
                }),
                _c("div", { staticClass: "color__show" }, [
                  _c("div", {
                    staticClass: "color__content",
                    style: { background: _vm.contentInfo.titleColor },
                  }),
                ]),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.toolType.toolNav,
                expression: "toolType.toolNav",
              },
            ],
            staticClass: "tool-item",
          },
          [
            _c("span", { staticClass: "type__name" }, [_vm._v("导航栏颜色:")]),
            _c(
              "div",
              { staticClass: "tool__content" },
              [
                _c("el-input", {
                  staticClass: "tool-bg",
                  model: {
                    value: _vm.contentInfo.navBgColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.contentInfo, "navBgColor", $$v)
                    },
                    expression: "contentInfo.navBgColor",
                  },
                }),
                _c("div", { staticClass: "color__show" }, [
                  _c("div", {
                    staticClass: "color__content",
                    style: { background: _vm.contentInfo.navBgColor },
                  }),
                ]),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.toolType.toolNavText,
                expression: "toolType.toolNavText",
              },
            ],
            staticClass: "tool-item",
          },
          [
            _c("span", { staticClass: "type__name" }, [_vm._v("文字颜色:")]),
            _c(
              "div",
              { staticClass: "tool__content" },
              [
                _c("el-input", {
                  staticClass: "tool-bg",
                  model: {
                    value: _vm.contentInfo.navColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.contentInfo, "navColor", $$v)
                    },
                    expression: "contentInfo.navColor",
                  },
                }),
                _c("div", { staticClass: "color__show" }, [
                  _c("div", {
                    staticClass: "color__content",
                    style: { background: _vm.contentInfo.navColor },
                  }),
                ]),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.toolType.toolSelect,
                expression: "toolType.toolSelect",
              },
            ],
            staticClass: "tool-item",
          },
          [
            _c("span", { staticClass: "type__name" }, [_vm._v("选中颜色:")]),
            _c(
              "div",
              { staticClass: "tool__content" },
              [
                _c("el-input", {
                  staticClass: "tool-bg",
                  model: {
                    value: _vm.contentInfo.selectBgColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.contentInfo, "selectBgColor", $$v)
                    },
                    expression: "contentInfo.selectBgColor",
                  },
                }),
                _c("div", { staticClass: "color__show" }, [
                  _c("div", {
                    staticClass: "color__content",
                    style: { background: _vm.contentInfo.selectBgColor },
                  }),
                ]),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.toolType.toolSelectText,
                expression: "toolType.toolSelectText",
              },
            ],
            staticClass: "tool-item",
          },
          [
            _c("span", { staticClass: "type__name" }, [_vm._v("文字颜色:")]),
            _c(
              "div",
              { staticClass: "tool__content" },
              [
                _c("el-input", {
                  staticClass: "tool-bg",
                  model: {
                    value: _vm.contentInfo.selectColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.contentInfo, "selectColor", $$v)
                    },
                    expression: "contentInfo.selectColor",
                  },
                }),
                _c("div", { staticClass: "color__show" }, [
                  _c("div", {
                    staticClass: "color__content",
                    style: { background: _vm.contentInfo.selectColor },
                  }),
                ]),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.toolType.toolRow,
                expression: "toolType.toolRow",
              },
            ],
            staticClass: "tool-item",
          },
          [
            _c("span", { staticClass: "type__name" }, [_vm._v("列数:")]),
            _c(
              "div",
              { staticClass: "tool__content" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "tool-border",
                    attrs: { placeholder: "请选择" },
                    on: {
                      change: function ($event) {
                        return _vm.$emit("columnChange", $event)
                      },
                    },
                    model: {
                      value: _vm.contentInfo.column,
                      callback: function ($$v) {
                        _vm.$set(_vm.contentInfo, "column", $$v)
                      },
                      expression: "contentInfo.column",
                    },
                  },
                  _vm._l(
                    _vm.columnArr.filter(
                      (_, i) => !(_vm.contentInfo.type === "liveList" && i > 1)
                    ),
                    function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }
                  ),
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.toolType.toolRow2,
                expression: "toolType.toolRow2",
              },
            ],
            staticClass: "tool-item",
          },
          [
            _c("span", { staticClass: "type__name" }, [_vm._v("列数:")]),
            _c(
              "div",
              { staticClass: "tool__content" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "tool-border",
                    attrs: { placeholder: "请选择" },
                    on: {
                      change: function ($event) {
                        return _vm.$emit("columnChange", $event)
                      },
                    },
                    model: {
                      value: _vm.contentInfo.column,
                      callback: function ($$v) {
                        _vm.$set(_vm.contentInfo, "column", $$v)
                      },
                      expression: "contentInfo.column",
                    },
                  },
                  _vm._l(2, function (item) {
                    return _c("el-option", {
                      key: item,
                      attrs: { label: item + "列", value: item },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.toolType.homeRowTitle,
                expression: "toolType.homeRowTitle",
              },
            ],
            staticClass: "tool-item",
          },
          [
            _c("span", { staticClass: "type__name" }, [
              _vm._v("主标题字体色:"),
            ]),
            _c(
              "div",
              { staticClass: "tool__content" },
              [
                _c("el-input", {
                  staticClass: "tool-bg",
                  model: {
                    value: _vm.contentInfo.rowTitleColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.contentInfo, "rowTitleColor", $$v)
                    },
                    expression: "contentInfo.rowTitleColor",
                  },
                }),
                _c("div", { staticClass: "color__show" }, [
                  _c("div", {
                    staticClass: "color__content",
                    style: { background: _vm.contentInfo.rowTitleColor },
                  }),
                ]),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.toolType.toolHeight,
                expression: "toolType.toolHeight",
              },
            ],
            staticClass: "tool-item",
          },
          [
            _c("span", { staticClass: "type__name" }, [
              _vm._v("模块高度(px):"),
            ]),
            _c(
              "div",
              { staticClass: "tool__content" },
              [
                _c("el-input", {
                  staticClass: "tool-bg",
                  model: {
                    value: _vm.contentInfo.rowtoolHeight,
                    callback: function ($$v) {
                      _vm.$set(_vm.contentInfo, "rowtoolHeight", $$v)
                    },
                    expression: "contentInfo.rowtoolHeight",
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.toolType.homeRowSubtitle,
                expression: "toolType.homeRowSubtitle",
              },
            ],
            staticClass: "tool-item",
          },
          [
            _c("span", { staticClass: "type__name" }, [
              _vm._v("副标题字体色:"),
            ]),
            _c(
              "div",
              { staticClass: "tool__content" },
              [
                _c("el-input", {
                  staticClass: "tool-bg",
                  model: {
                    value: _vm.contentInfo.subTitleColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.contentInfo, "subTitleColor", $$v)
                    },
                    expression: "contentInfo.subTitleColor",
                  },
                }),
                _c("div", { staticClass: "color__show" }, [
                  _c("div", {
                    staticClass: "color__content",
                    style: { background: _vm.contentInfo.subTitleColor },
                  }),
                ]),
              ],
              1
            ),
          ]
        ),
        _vm.toolType.showMoreBtn || _vm.toolType.showGoodsSize
          ? _c("Paging", {
              attrs: {
                item: {
                  isShowMore: _vm.contentInfo.isShowMore,
                  pageSize: _vm.contentInfo.pageSize,
                  image: _vm.contentInfo.imgInfo?.img,
                },
              },
              on: { change: _vm.changePaging },
            })
          : _vm._e(),
        _vm.toolType.showBuyBtn &&
        _vm.contentInfo.type !== "listGoodsSkill" &&
        _vm.contentInfo.column == 1
          ? _c("div", { staticClass: "tool-item" }, [
              _c("span", { staticClass: "type__name" }, [
                _vm._v("售卖按钮文案: "),
              ]),
              _c(
                "div",
                { staticClass: "tool__content" },
                [
                  _c("el-input", {
                    staticClass: "tool-bg",
                    model: {
                      value: _vm.contentInfo.imgInfoOfBuy.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.contentInfo.imgInfoOfBuy, "content", $$v)
                      },
                      expression: "contentInfo.imgInfoOfBuy.content",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm.toolType.showBuyBtn &&
        _vm.contentInfo.type !== "listGoodsSkill" &&
        _vm.contentInfo.column == 1
          ? _c(
              "div",
              { staticClass: "more-btn" },
              [
                _c(
                  "span",
                  { staticClass: "type__name" },
                  [
                    _vm._v("销售价前图片 "),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "tip-box",
                        staticStyle: { "margin-left": "0px" },
                        attrs: {
                          effect: "dark",
                          content: "图片尺寸：68x32",
                          placement: "top-start",
                        },
                      },
                      [_c("i", { staticClass: "el-icon-warning-outline" })]
                    ),
                    _vm._v(":"),
                  ],
                  1
                ),
                _c("CutImgUp", {
                  attrs: {
                    url: _vm.titleImgListOfSellPrice,
                    "auto-crop-width": 68,
                    "auto-crop-height": 32,
                    fixed: false,
                    "max-num": 1,
                  },
                  on: { change: _vm.onSuccessOfSellPrice },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.toolType.showBuyBtn &&
        _vm.contentInfo.type !== "listGoodsSkill" &&
        _vm.contentInfo.column == 1
          ? _c(
              "div",
              { staticClass: "more-btn" },
              [
                _c(
                  "span",
                  { staticClass: "type__name" },
                  [
                    _vm._v("划线价前图片 "),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "tip-box",
                        staticStyle: { "margin-left": "0px" },
                        attrs: {
                          effect: "dark",
                          content: "图片尺寸：40x20",
                          placement: "top-start",
                        },
                      },
                      [_c("i", { staticClass: "el-icon-warning-outline" })]
                    ),
                    _vm._v(":"),
                  ],
                  1
                ),
                _c("CutImgUp", {
                  attrs: {
                    url: _vm.titleImgListOfDeletePrice,
                    "auto-crop-width": 68,
                    "auto-crop-height": 32,
                    fixed: false,
                    "max-num": 1,
                  },
                  on: { change: _vm.onSuccessOfDeletePrice },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.contentInfo.type === "listGoodsSkill" && _vm.toolType.showTitleImg
          ? _c(
              "div",
              { staticClass: "more-btn" },
              [
                _c(
                  "span",
                  {
                    staticClass: "type__name",
                    staticStyle: { width: "75.8px", "text-align": "right" },
                  },
                  [_vm._v("标题图片:")]
                ),
                _c("CutImgUp", {
                  attrs: {
                    url: _vm.tImgList,
                    "auto-crop-width": 750,
                    "auto-crop-height": 69,
                    "fixed-box": true,
                    "max-num": 1,
                  },
                  on: { change: _vm.onSuccessTImg },
                }),
                _c(
                  "span",
                  {
                    staticStyle: {
                      transform: "translateY(85px)",
                      color: "#8e8e8e",
                    },
                  },
                  [_vm._v("建议尺寸：750像素*69像素")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.contentInfo.type === "listGoodsSkill" && _vm.toolType.showOtherColor
          ? _vm._l(_vm.colorList, function (item, index) {
              return _c("div", { key: index, staticClass: "tool-item" }, [
                _c(
                  "span",
                  {
                    staticClass: "type__name",
                    staticStyle: { width: "100px" },
                  },
                  [_vm._v(_vm._s(item.label) + ":")]
                ),
                _c(
                  "div",
                  { staticClass: "tool__content" },
                  [
                    _c("el-input", {
                      staticClass: "tool-bg",
                      model: {
                        value: _vm.contentInfo[item.key],
                        callback: function ($$v) {
                          _vm.$set(_vm.contentInfo, item.key, $$v)
                        },
                        expression: "contentInfo[item.key]",
                      },
                    }),
                    _c("div", { staticClass: "color__show" }, [
                      _c("div", {
                        staticClass: "color__content",
                        style: { background: _vm.contentInfo[item.key] },
                      }),
                    ]),
                  ],
                  1
                ),
              ])
            })
          : _vm._e(),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.toolType.toolAutoplay,
                expression: "toolType.toolAutoplay",
              },
            ],
            staticClass: "tool-item",
          },
          [
            _c("span", { staticClass: "type__name" }, [_vm._v("自动滚动:")]),
            _c("el-switch", {
              attrs: { "active-value": true, "inactive-value": false },
              model: {
                value: _vm.contentInfo.autoplay,
                callback: function ($$v) {
                  _vm.$set(_vm.contentInfo, "autoplay", $$v)
                },
                expression: "contentInfo.autoplay",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.toolType.toolValidTime,
                expression: "toolType.toolValidTime",
              },
            ],
            staticClass: "tool-item",
          },
          [
            _c("span", { staticClass: "type__name" }, [_vm._v("有效时间:")]),
            _c("el-date-picker", {
              attrs: {
                type: "datetimerange",
                "range-separator": "至",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期",
                size: "mini",
                "value-format": "timestamp",
              },
              on: { change: _vm.onTimeChange },
              model: {
                value: _vm.contentInfo.validTime,
                callback: function ($$v) {
                  _vm.$set(_vm.contentInfo, "validTime", $$v)
                },
                expression: "contentInfo.validTime",
              },
            }),
          ],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }