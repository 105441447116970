var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "numController" }, [
    _c("i", { staticClass: "el-icon-plus add", on: { click: _vm.addGood } }),
    _c("i", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showNum >= _vm.limitNum,
          expression: "showNum >= limitNum",
        },
      ],
      staticClass: "el-icon-minus reduce",
      on: { click: _vm.delGood },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }