var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "add__list addList" }, [
    _c("div", { staticClass: "goods__list" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "list__content" },
        [
          _c(
            "draggable",
            {
              attrs: { element: "div" },
              model: {
                value: _vm.list,
                callback: function ($$v) {
                  _vm.list = $$v
                },
                expression: "list",
              },
            },
            _vm._l(_vm.list, function (item, index) {
              return _c("ul", { key: index }, [
                _c("li", [
                  _c("span", { staticClass: "item-id" }, [
                    _vm._v(_vm._s(item.goodsId)),
                  ]),
                  _c("p", [_vm._v(_vm._s(_vm.strShow(item.goodsName)))]),
                  _c(
                    "span",
                    {
                      staticClass: "delet-btn",
                      staticStyle: { margin: "0" },
                      on: {
                        click: function ($event) {
                          return _vm.delGoods(index)
                        },
                      },
                    },
                    [_vm._v("删除")]
                  ),
                ]),
              ])
            }),
            0
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "goods__input" }, [
      _c(
        "div",
        { staticClass: "list__add" },
        [
          _c(
            "el-select",
            {
              staticClass: "list__add--type",
              attrs: { placeholder: "请选择", size: "mini" },
              model: {
                value: _vm.goodsValue,
                callback: function ($$v) {
                  _vm.goodsValue = $$v
                },
                expression: "goodsValue",
              },
            },
            _vm._l(_vm.goodsOptions, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.goodsValue === 0,
                  expression: "goodsValue === 0",
                },
              ],
              staticStyle: {
                overflow: "hidden",
                display: "flex",
                "align-items": "flex-end",
                margin: "0 10px",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 4,
                  placeholder:
                    "输入商品id,可输入多个商品,多个商品id之间以英文“,”区分",
                },
                on: {
                  input: (e) => {
                    _vm.goodsInfo = e.replace(/[^0-9,]/g, "")
                  },
                },
                model: {
                  value: _vm.goodsInfo,
                  callback: function ($$v) {
                    _vm.goodsInfo = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "goodsInfo",
                },
              }),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { size: "mini", loading: _vm.addLoading },
                  on: { click: _vm.addGoods },
                },
                [_vm._v(" 立即添加 ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.goodsValue !== 0,
                  expression: "goodsValue !== 0",
                },
              ],
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "list__add--list",
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.activityValue,
                    callback: function ($$v) {
                      _vm.activityValue = $$v
                    },
                    expression: "activityValue",
                  },
                },
                _vm._l(_vm.activityOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _c(
                "el-button",
                {
                  staticStyle: { width: "90px" },
                  on: {
                    click: function ($event) {
                      return _vm.addActivityGoods(_vm.activityValue)
                    },
                  },
                },
                [_vm._v(" 立即添加 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "list__select" }),
    ]),
    _c("p", { staticClass: "add__toast" }, [
      _vm._v(" " + _vm._s(_vm.toast) + " "),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "goods__name" }, [
      _c("p", [_vm._v("商品：")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }