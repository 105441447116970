<template>
  <div class="coupon-component" :style="{'background-color': obj.bgColor, 'padding': obj.bPadding ? '16px 26px' : '16px 0'}">
    <div
      class="column"
      :class="'column_' + obj.column"
      :style="{marginRight: index === list.length - 1 ? '0' : '10px'}"
      v-for="(column, index) in list" :key="index"
    >
      <div
        v-for="item in column" :key="item.id"
        class="item-box"
        :style="{backgroundImage: obj.bgImg ? `url(${obj.bgImg})` : 'none'}"
      >
        <div v-if="obj.resource === 4" class="style4">
          <div class="content-container">
            <div class="image-content" v-if="item.imageUrl" :style="{
              backgroundImage: `url(${item.imageUrl})`
            }"></div>
            <div class="content">
              <div class="content-main">
                <div class="price">¥{{item.discountsValue}}</div>
                <div class="title">满{{item.discountsCondition}}可用</div>
              </div>
              <div class="content-title" v-if="obj.column === 1">
                {{item.couponDisplayName ? item.couponDisplayName : item.couponName}}
              </div>
            </div>
          </div>
          <div class="content-title" v-if="obj.column !== 1">
            {{item.couponDisplayName ? item.couponDisplayName : item.couponName}}
          </div>
          <div class="handle-box border content-button">
            <el-button
              type="warning" size="mini" round
              :style="{backgroundImage: obj.btnImg ? `url(${obj.btnImg})` : 'none'}"
            >立刻领取</el-button>
            <div v-if="obj.showLootAll" class="over">抢光了</div>
          </div>
        </div>
        <template v-else>
          <div class="content-box border">
            <div class="top">
              <el-tag v-if="obj.resource === 3 && item.types" type="danger" size="mini">{{item.types}}</el-tag>
              <div class="title">{{item.couponDisplayName ? item.couponDisplayName :  item.couponName}}</div>
            </div>
            <div v-if="obj.resource !== 1 && item.couponType === 2" class="con">
              <div class="price">¥{{item.discountsValue}}</div>
              <div class="title">满{{item.discountsCondition}}可用</div>
            </div>
            <div v-if="obj.resource === 3" class="tip">{{item.times}}</div>
          </div>
          <div class="handle-box border">
            <el-button
              type="warning" size="mini" round
              :style="{backgroundImage: obj.btnImg ? `url(${obj.btnImg})` : 'none'}"
            >立刻领取</el-button>
            <div v-if="obj.showLootAll" class="over">抢光了</div>
          </div>
        </template>
      </div>
    </div>
    <div class="bt-drag-title">
      {{ obj.showTitle }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    obj: {type: Object, default: () => ({})}
  },
  computed: {
    columnWidth() {
      return parseInt(98 / this.obj.column) + '%';
    },
    list() {
      const arr = [];
      for (let i = 0; i < this.obj.column; i++) {
        arr[i] = [];
      }
      this.obj.list.forEach((item, index) => {
        const i = index % this.obj.column;
        arr[i].push(item);
      });
      return arr;
    }
  }
}
</script>

<style lang="scss">
.coupon-component{
  display: flex; min-height: 64px;
  .border{border: 1px solid #dcdfe6; border-radius: 5px;}
  .item-box{margin-bottom: 15px; background-size: 100% 100%; color: #000; background-color: #ccc;}
  .column{flex: 1;}
  .column_1{
    width: 100%; margin: 0 auto;
    .item-box{display: flex;}
    .top, .con{display: flex; align-items: center; margin-bottom: 5px;}
    .style4 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 10px;
      .content-container {
        display: flex;
        min-height: 54px;
        .image-content {
          width: 54px;
          height: 54px;
          background-size: 100% 100%;
          flex-shrink: 0;
          border-radius: 8px;
        }
        .content {
          display: flex;
          flex-direction: column;
          flex-shrink: 0;
          .content-main {
            display: flex;
            
            align-items: center;
            .price {
              margin: 0;
              font-size: 20px;
              font-weight: bold;
            }
            .title {
              font-size: 12px;
              margin-left: 7px;
            }
          }
        }
      }
      .content-title {
        flex-direction: flex-start;
        margin-top: 11px;
        font-size: 14px;
        font-weight: bold;
        display: flex;
      }
    }
  }
  .column_2, .column_3{
    position: relative; text-align: center;
    .item-box{margin-right: 10px;}
    .el-tag{position: absolute; right: 1px; top: 1px;}
    .top{margin: 30px 0 10px 0;}
    .price, .con{margin-bottom: 10px;}
    .style4 {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      padding: 10px;
      .content-container {
        display: flex;
        width: 100%;
        flex-grow: 1;
        min-height: 54px;
        .image-content {
          width: 54px;
          height: 54px;
          flex-grow: 1;
          background-size: 100% 100%;
          flex-shrink: 0;
          border-radius: 8px;
        }
        .content {
          display: flex;
          flex-direction: column;
          .content-main {
            display: flex;
            align-items: center;
            flex-direction: column;
            .price {
              margin: 0;
              font-size: 20px;
              font-weight: bold;
            }
            .title {
              font-size: 12px;
              margin-top: 10px;
              margin-left: 7px;
            }
          }
        }
      }
      .content-title {
        flex-direction: flex-start;
        margin-top: 11px;
        font-size: 14px;
        margin-bottom: 10px;
        font-weight: bold;
        display: flex;
      }
    }
  }
  .content-box{
    flex: auto; padding: 10px;
    display: flex; flex-direction: column; justify-content: center;
  }
  .handle-box{
    display: flex; flex-direction: column; justify-content: center; padding: 10px; position: relative;
    .over{
      position: absolute; width: 50px; height: 50px; line-height: 50px; border-radius: 50%; text-align: center; color: #999999;
      left: 50%; top: 50%; margin: -25px 0 0 -25px; border: 1px solid #dcdfe6; background: #fff; transform: rotate(35deg);
    }
    .el-button{background-size: 100% 100%;}
  }
  .el-tag{margin-right: 10px;}
  .price{margin-right: 10px; font-weight: bold; font-size: 18px;}
}
</style>