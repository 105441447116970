<template>
  <div
    class="static-img"
    :style="{'border-bottom': obj.bMargin?'10px solid':'','border-color': obj.bgColor,'padding': obj.bPadding?'1px 26px 1px 21px':'0'}"
  >
    <div
      ref="selfParent"
      class="static-img-slide"
    >
      <div
        class="static-img-slide-box"
      >
        <img
          v-if="obj?.searchType !== 1"
          :src="[obj.img===''?preurl:obj.img]"
        />
        <el-input
          v-if="obj?.searchType === 1"
          :placeholder="obj.searchText"
          class="input-with-select"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
          />
        </el-input>
        <Shade
          v-if="obj?.searchType !== 1"
          title="点击区域热区"
          :value="obj"
        />
      </div>
    </div>
    <div class="bt-drag-title">
      {{ obj.showTitle }}
    </div>
  </div>
</template>

<script>
import Shade from './staticSelfImg/Shade';

export default {
  components: {
    Shade,
  },
  props: {
    obj: {
      type: Object,
      default: () => ({
        title: '搜索',
        type: 'search',
        bMargin: false,
        size: '212px',
        bgColor: '#fff',
        bPadding: false,
        img: '',
        content: '',
        coupon_key: '',
        top: 0,
        left: 0,
        width: 20,
        height: 50,
        zIndex: 99,
        promIdList: '',
        siteIdList: [],
      }),
    },
  },
  data() {
    return {
      preurl: 'https://img.hzanchu.com/acimg/98967e322848c8cb4f979be43187af6f.jpg?x-oss-process=image/resize,w_300',
      isDown: false,
      x: 0,
      y: 0,
      l: 0,
      t: 0,
    };
  },
  mounted() {
    if (!this.obj?.searchType) {
      this.obj.searchType = 2;
    }
  },
  methods: {

  },
};
</script>

<style scoped lang="scss">
  .static-img{
    overflow: hidden;
    box-sizing:border-box;
    width:100%;
    .static-img-slide{
      overflow:hidden;
      // display: flex;
      .static-img-slide-box {
        position: relative;
        width: 100%;
        .input-with-select{
          margin-top: 30px;
        }
        img{
          width: 100%;
          // margin:5px;
        }
      }
    }
  }
</style>
