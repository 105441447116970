<template>
  <div class="add__list addList">
    <!-- 商品列表展示和删除部分 -->
    <div class="goods__list">
      <div class="goods__name">
        <p>商品：</p>
      </div>
      <div class="list__content">
        <draggable
          v-model="list"
          element="div"
        >
          <ul
            v-for="(item, index) in list"
            :key="index"
          >
            <li>
              <span class="item-id">{{ item.goodsId }}</span>
              <p>{{ strShow(item.goodsName) }}</p>
              <span
                class="delet-btn"
                style="margin: 0;"
                @click="delGoods(index)"
              >删除</span>
            </li>
          </ul>
        </draggable>
      </div>
    </div>
    <!-- 商品添加部分 -->
    <div class="goods__input">
      <div class="list__add">
        <!-- 商品和活动类别选择 -->
        <el-select
          v-model="goodsValue"
          placeholder="请选择"
          size="mini"
          class="list__add--type"
        >
          <el-option
            v-for="item in goodsOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <!-- 商品id输入框 -->
        <div
          v-show="goodsValue === 0"
          style="overflow:hidden;display:flex;align-items:flex-end;margin: 0 10px;"
        >
          <el-input
            v-model.trim="goodsInfo"
            type="textarea"
            :rows="4"
            placeholder="输入商品id,可输入多个商品,多个商品id之间以英文“,”区分"
            @input="(e)=>{ goodsInfo = e.replace(/[^0-9,]/g, '') }"
          />
          <el-button
            style="margin-left:10px;"
            size="mini"
            :loading="addLoading"
            @click="addGoods"
          >
            立即添加
          </el-button>
          <!-- <span > </span> -->
        </div>
        <!-- 活动商品选择框 -->
        <div v-show="goodsValue !== 0">
          <el-select
            v-model="activityValue"
            placeholder="请选择"
            class="list__add--list"
          >
            <el-option
              v-for="item in activityOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-button
            style="width: 90px;"
            @click="addActivityGoods(activityValue)"
          >
            立即添加
          </el-button>
        </div>
      </div>
      <div class="list__select"></div>
    </div>
    <p class="add__toast">
      {{ toast }}
    </p>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import qs from 'qs';
import { strLimit } from '@/assets/js/common';

export default {
  name: 'group-goods',
  components: { draggable },

  props: {
    addlistinfo: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        goodsIdStr: '',
      },
    },
  },

  data() {
    return {
      toast: '', // 输入框提示
      goodsOptions: [
        {
          value: 0,
          label: '商品',
        },
        // {
        //   value: 1,
        //   label: '满减送'
        // }
      ],
      activityOptions: [],
      goodsValue: 0,
      goodsInfo: '', // 商品id
      activityValue: '', // 活动id
      goodsList: [], // 商品列表
      addLoading: false,
      list: [],
    };
  },
  watch: {
    'addlistinfo.goodsIdStr': {
      immediate: true,
      handler(value) {
        this.goodsInfo = value;
        this.getGoodsList();
      },

    },
    // 'addlistinfo': {
    //   deep: true,
    //   immediate: true,
    //   handler: function(val) {
    //     console.log(val)
    //     let arr = []
    //     val.list.forEach((i) => {
    //       arr.push(i.goodsId)
    //     })
    //     this.goodsInfo = arr.join(',')
    //     this.addlistinfo.goodsIdStr = arr.join(',')
    //   }
    // },
    list: {
      deep: true,
      handler(val) {
        const arr = val.map((item) => item.goodsId).join(',');
        this.goodsInfo = arr;
        this.addlistinfo.goodsIdStr = arr;
      },
    },
  },

  mounted() {
    // console.log(this.addlistinfo)
  },

  methods: {
    getList() {
      let that = this;
      this.$axios.post(this.$api.subject.getmjslist).then((res) => {
        let data = res;
        if (data.code === 0) {
          data.data.forEach((value, index) => {
            let info = {
              label: value.prom_name,
              value: value.prom_id,
            };
            that.activityOptions.push(info);
          });
        }
      });
    },
    // 添加商品
    addGoods() {
      let that = this;
      if (this.goodsInfo === '') {
        this.toast = '请填写商品信息';
      } else {
        let array = this.goodsInfo.split(',');
        // 去重
        let temp = [];
        for (let i = 0; i < array.length; i++) {
          if (temp.indexOf(array[i]) === -1) {
            temp.push(array[i]);
          }
        }
        this.goodsInfo = temp.join(',');
        if (temp.length > 100) {
          this.toast = '最多添加100个拼团商品';
          return;
        }
        this.addLoading = true;
        this.$axios({
          method: 'post',
          url: that.$api.tradePluginTemplate.groupGoods,
          data: {
            goodsIdStr: this.goodsInfo,
            pageSize: temp.length,
            currentPage: 1,
          },
        }).then((res) => {
          that.addLoading = false;
          let data = res;
          if (data.code === 0 || data.data.records.length === 0) {
            if (!data.data) {
              that.$message('找不到该id对应的拼团商品');
            } else {
              this.list = data.data.records;
              // that.addlistinfo.list = data.data.records
              that.addlistinfo.goodsIdStr = data.data.records.map((item) => item.goodsId).join(',');
            }
          }
        });
      }
    },
    // 删除商品
    delGoods(num) {
      this.list.splice(num, 1);
      let arr = this.list.map((item) => item.goodsId);
      this.addlistinfo.goodsIdStr = arr.join(',');
      this.goodsInfo = arr.join(',');
    },
    // 添加满减送活动商品
    addActivityGoods(value) {
      let that = this;
      if (this.activityValue === '') {
        this.toast = '请填写商品信息';
      } else {
        this.$axios.post(that.$api.subject.addgoodsbymjs, {
          prom_id: that.activityValue,
        }).then((res) => {
          let data = res;
          if (data.code === 0) {
            if (data.data.length === 0) {
              that.$message('找不到该id对应的商品');
            }
            if (that.addlistinfo.list.length === 0) {
              that.addlistinfo.list = data.data;
            } else {
              let arr = data.data;
              data.data.forEach((value, index) => {
                let id = value.goods_id;
                that.addlistinfo.list.forEach((val) => {
                  if (id === val.goods_id) {
                    arr.splice(index);
                    that.$message('已为你过滤掉重复商品');
                  }
                });
              });
              arr.forEach((value) => {
                that.addlistinfo.list.push(value);
              });
            }
            that.activityValue = '';
          }
        }).catch((err) => {
          console.error(err);
        });
      }
    },
    // 限制显示字数
    strShow(item) {
      if (item == null) {
        //
      } else {
        return strLimit(item, 16);
      }
    },
    // 筛选重复商品
    filterGoods(item) {
      let goodsRule = item.split(',');
    },
    getGoodsList() {
      this.$axios.post(this.$api.tradePluginTemplate.groupGoods, {
        goodsIdStr: this.addlistinfo.goodsIdStr,
        pageSize: this.addlistinfo.goodsIdStr.split(',').length,
        currentPage: 1,
      }).then((res) => {
        this.list = res.data.records || [];
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add__list {
    width: calc(100% - 30px);
    // height: 355px;
    // margin: 0 15px;
    padding: 15px;
    .category__name{
        & span {
            width: 92px;
            height: 36px;
            color: #8E8E8E;
            padding-right: 4px;
            text-align: right;
            display: inline-block;
        }
        .category__input {
            width: 300px;
        }

    }
    .goods__list {
        height: 230px;
        .goods__name{
            width: 82px;
            height: 100px;
            float: left;
            & p{
                text-align: right;
                color: #8E8E8E;
                overflow: hidden;
            }
        }
        .list__content{
            float: left;
            width: 370px;
            height: 220px;
            border:1px solid rgba(151,151,151,1);
            overflow-y: scroll;
            overflow-x: hidden;
            border-radius:2px;
            ul > li {
              display: flex;
              cursor: move;
            }
            & p {
                width: 200px;
                height: 20px;
                display: inline-block;
                margin-right: 10px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            & span{
              display: inline-block;
              cursor: pointer;
              margin-right: 10px;
              white-space: nowrap;
            }
            .item-id {
              width: 50px;
            }
        }
    }
    .goods__input {
        // height: 36px;
        width: 100%;
        .list__add{
            margin-top: 10px;
            width: 100%;
            // height: 34px;
            display: flex;
            // align-items: center;
            .list__add--type{
                width: 90px;
                float: left;
            }
            & input {
                width: 300px;
                height: 34px;
                float: left;
                border: 1px solid #979797;
            }
            & input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
                font-size: 12px;
            }
             & input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                font-size: 12px;
            }
             & input::-moz-placeholder { /* Mozilla Firefox 19+ */
                font-size: 12px;
            }
             & input:-ms-input-placeholder { /* Internet Explorer 10-11 */
                font-size: 12px;
            }
            & span {
                width: 80px;
                height: 28px;
                line-height: 28px;
                text-align: center;
                float: left;
                border: 1px solid #979797;
                border-left: none;
                background:rgba(240,240,240,1);

            }
        }
        .add__toast {
            color: #FF3301;
        }
        .list__add--list {
            width: 290px;
            float: left;
            margin-right: 4px;
        }

    }
}
.delet-btn {
  color: red;
  cursor: pointer;
}
</style>
