var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "img-info" },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "100px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "上传图片:" } },
            [
              _c("CutImgUp", {
                attrs: {
                  url: _vm.imageUrlList,
                  "auto-crop-width": 750,
                  "auto-crop-height": 750,
                  "max-num": 1,
                },
                on: { change: _vm.onSuccess },
              }),
            ],
            1
          ),
          _c("JumpType", { attrs: { "img-info": _vm.imgInfo } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }