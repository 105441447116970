var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "scroll-cont" }, [
    _c(
      "div",
      { staticClass: "input-box" },
      [
        _c("span", [_vm._v("滚动文案:")]),
        _c("el-input", {
          staticClass: "input-text",
          attrs: { placeholder: "请输入重点文案前的内容" },
          model: {
            value: _vm.newsData.beforeText,
            callback: function ($$v) {
              _vm.$set(_vm.newsData, "beforeText", $$v)
            },
            expression: "newsData.beforeText",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }