// 跳转类型
const SELECT_OPTIONS = [
  // { label: '首页', value: 'index' },
  { label: '商品', value: 'goods' },
  { label: '链接', value: 'link' },
  { label: '优惠券', value: 'coupon' }
  // { label: '券包（批量领券）', value: 'coupackage' },
  // { label: '直播', value: 'live' },
  // { label: '直播列表', value: 'liveList' },
  // { label: '秒杀', value: 'seckill' },
  // { label: '拼团列表', value: 'spellGroup' },
  // { label: '商家店铺', value: 'store' },
  // { label: '市级馆', value: 'site' },
  // { label: '县级馆', value: 'county' },
  // { label: '第三方应用', value: 'goExternalURL' },
  // { label: '农民说', value: 'farmerSaid' },
  // { label: '分享有礼', value: 'shareGift' }
]
// 不需要输入框的类型
const NOT_SHOW_INPUT = [
  'index', 'liveList', 'spellGroup', 'seckill', 'farmerSaid', 'shareGift'
]

export {
  SELECT_OPTIONS,
  NOT_SHOW_INPUT
}
