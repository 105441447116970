var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "sub-title",
      style: { background: _vm.obj.bgColor, color: _vm.obj.color },
    },
    [
      _vm._v(" " + _vm._s(_vm.obj.text) + " "),
      _c("div", { staticClass: "bt-drag-title" }, [
        _vm._v(" " + _vm._s(_vm.obj.title) + " "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }