var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-tool" }, [
    _c("div", { staticClass: "tool-item" }, [
      _c("span", { staticClass: "type__name" }, [_vm._v("背景色:")]),
      _c(
        "div",
        { staticClass: "tool__content" },
        [
          _c("el-input", {
            staticClass: "tool-bg",
            model: {
              value: _vm.info.config.backgroundColor,
              callback: function ($$v) {
                _vm.$set(_vm.info.config, "backgroundColor", $$v)
              },
              expression: "info.config.backgroundColor",
            },
          }),
          _c("div", { staticClass: "color__show" }, [
            _c("div", {
              staticClass: "color__content",
              style: { background: _vm.info.config.backgroundColor },
            }),
          ]),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "tool-item" }, [
      _c("span", { staticClass: "type__name" }, [_vm._v("背景图:")]),
      _c(
        "div",
        { staticClass: "tool__content-img" },
        [
          _c("CutImgUp", {
            attrs: {
              url: _vm.backImgList,
              "auto-crop-width": 750,
              "auto-crop-height": 750,
              "max-num": 1,
            },
            on: { change: _vm.onSuccess },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }