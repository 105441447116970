<template>
  <div
    ref="shadeRef"
    class="static-img-slide-box-a"
    :style="style"
    @mousedown="onmousedownShade"
  >
    {{ title }}
    <div
      class="round"
      @click.stop
      @mousedown="onmousedownRound"
    ></div>
  </div>
</template>

<script setup>
import { ref, computed, getCurrentInstance } from 'vue';

const { proxy } = getCurrentInstance();

const props = defineProps({
  value: {
    type: Object,
    default: () => ({}),
  },
  fatherSize: {
    type: Object,
    default: () => ({}),
  },
  title: {
    type: [Number, String],
    default: '',
  },
});

const style = computed(() => {
  return {
    top: `${props.value.top}%`,
    left: `${props.value.left}%`,
    width: `${props.value.width}%`,
    height: `${props.value.height}%`,
  };
});

const shadeRef = ref(null);
const x = ref(0);
const y = ref(0);
const l = ref(0);
const t = ref(0);
// 小圆初始位置
const boxLeftToWindow = ref(0);
const boxTopToWindow = ref(0);

// 移动事件
const onmousemoveShade = (e) => {
  const nx = e.clientX;
  const ny = e.clientY;
  const nl = nx - (x.value - l.value);
  const nt = ny - (y.value - t.value);
  const parentWidth = proxy.$parent.$el.offsetWidth;
  const parentHeight = proxy.$parent.$el.offsetHeight;
  const maxLeft = Math.round((1 - (shadeRef.value.offsetWidth / proxy.$parent.$el.offsetWidth)) * 100);
  const maxTop = Math.round((1 - (shadeRef.value.offsetHeight / proxy.$parent.$el.offsetHeight)) * 100);
  props.value.left = Math.round((nl / parentWidth) * 100);
  props.value.top = Math.round((nt / parentHeight) * 100);
  if (props.value.left <= 0) {
    props.value.left = 0;
  } else if (props.value.left >= maxLeft) {
    props.value.left = maxLeft;
  }
  if (props.value.top <= 0) {
    props.value.top = 0;
  } else if (props.value.top >= maxTop) {
    props.value.top = maxTop;
  }
};
// // 鼠标按下
const onmousedownShade = (e) => {
  e.preventDefault();
  e.stopPropagation();
  x.value = e.clientX;
  y.value = e.clientY;
  l.value = shadeRef.value.offsetLeft;
  t.value = shadeRef.value.offsetTop;
  window.addEventListener('mousemove', onmousemoveShade);
};

// 移动小圆
const onmousemoveRound = (e) => {
  const parentWidth = proxy.$parent.$el.offsetWidth;
  const parentHeight = proxy.$parent.$el.offsetHeight;
  props.value.width = Math.round((e.clientX - boxLeftToWindow.value) / parentWidth * 100);
  props.value.height = Math.round((e.clientY - boxTopToWindow.value) / parentHeight * 100);
};
// 按下圆
const onmousedownRound = (e) => {
  e.preventDefault();
  e.stopPropagation();
  // 记录初始位置
  boxLeftToWindow.value = e.clientX - shadeRef.value.offsetWidth;
  boxTopToWindow.value = e.clientY - shadeRef.value.offsetHeight;
  window.addEventListener('mousemove', onmousemoveRound);
};
// 抬起鼠标
window.addEventListener('mouseup', (e) => {
  e.preventDefault();
  e.stopPropagation();
  window.removeEventListener('mousemove', onmousemoveRound);
  window.removeEventListener('mousemove', onmousemoveShade);
});
</script>

<style scoped lang="scss">
.static-img-slide-box-a {
  position: absolute;
  border: 1px solid #2589ff;
  color: #2589ff;
  font-size: 18px;
  line-height: 20px;
  background: rgba(75, 172, 204, 0.3);
  z-index: 99;
  cursor: move;
  width: 100px;
  height: 100px;
  left: 100px;
  top: 100px;

  .round {
    position: absolute;
    bottom: -5px;
    right: -5px;
    width: 10px;
    height: 10px;
    background: #fff;
    border: 1px solid #2589ff;
    border-radius: 50%;
    cursor: pointer;
  }
}
</style>
