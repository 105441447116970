<template>
  <div
    class="three-img-pre"
    :style="{'background-color': obj.bgColor, 'margin-bottom': obj.bMargin ? '10px' : '0', 'padding': obj.bPadding ? '20px 10px' : '20px 0'}"
  >
    <img
      :src="[obj.list[0].img == ''? preurl1 : obj.list[0].img]"
      alt=""
    />
    <img
      :src="[obj.list[1].img == ''? preurl1 : obj.list[1].img]"
      alt=""
    />
    <img
      :src="[obj.list[2].img == ''? preurl1 : obj.list[2].img]"
      alt=""
    />
    <div class="bt-drag-title">
      {{ obj.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'three-img-hor-pre',
  props: {
    obj: {
      type: Object,
      default: {
        list: [
          { img: '' },
          { img: '' },
          { img: '' },
        ],
        bgColor: '#fff',
      },
    },
  },
  data() {
    return {
      preurl1: 'https://img.hzanchu.com/acimg/0361b69f4bc94733976c63f9696bafaa.png?x-oss-process=image/resize,w_300',
    };
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.three-img-pre{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px 0;
    img{
        width: 156px;
        height: 85px;
        padding: 0 5px;
    }
}

</style>
