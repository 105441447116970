var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cate-goods", style: { background: _vm.obj.titleBgColor } },
    [
      _vm._t("default"),
      _c(
        "div",
        {
          staticClass: "cate-nav",
          style: { background: _vm.obj.navBgColor, color: _vm.obj.navColor },
        },
        _vm._l(_vm.obj.list, function (item, index) {
          return _c(
            "a",
            {
              key: index,
              style: {
                background:
                  _vm.selectIndex === index
                    ? _vm.obj.selectBgColor
                    : _vm.obj.navBgColor,
                color:
                  _vm.selectIndex === index
                    ? _vm.obj.selectColor
                    : _vm.obj.navColor,
              },
              attrs: { href: `#cate${index}` },
              on: {
                click: function ($event) {
                  _vm.selectIndex = index
                },
              },
            },
            [_vm._v(_vm._s(item.name))]
          )
        }),
        0
      ),
      _c("div", { staticClass: "bt-drag-title" }, [
        _vm._v(" " + _vm._s(_vm.obj.showTitle) + " "),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }