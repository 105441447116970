<template>
  <div
    class="video-pre"
    :style="{'border-bottom': obj.bMargin?'10px solid':'','border-color': obj.bgColor,'padding': obj.bPadding?'1px 26px 1px 21px':'1px 5px 1px 0'}"
  >
    <img
      :src="[obj.coverURL==''?preurl:obj.coverURL + '?x-oss-process=image/resize,w_300']"
      class="post-img"
    >
    <div class="bt-drag-title">
      {{ obj.title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    obj: {
      type: Object,
      default: {
        size: '212px',
				 	bMargin: false,
				 	bgColor: '#fff',
				 	bPadding: false,
				 	title: '视频模块',
				 	list: [{
				 		type: '',
				 		img: '',
				 		content: ''
				 	}]
      }
    }
  },
  data() {
    return {
      preurl: 'https://img.hzanchu.com/acimg/d712ee0805c6e8cbf104198abfbe277c.png'
    }
  }
}
</script>

<style scoped lang="scss">
  .video-pre{
    overflow-x: hidden;
    box-sizing:border-box;
    width:100%;
    height: 210px;
    position: relative;
  }
  .video-content{
    width: 100%;
    height: 200px;
  }
  .post-img{
    position: absolute;
    left: 0;
    top: 0;
    width: 500px;
    height: 200px;
  }
</style>
