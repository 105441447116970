<template>
  <div
    class="five-img"
    :style="{'background-color': obj.bgColor, 'margin-bottom': obj.bMargin ? '10px' : '0', 'padding': obj.bPadding ? '20px 10px' : '20px 0'}"
  >
    <div :style="{'background-image': 'url('+obj.bgInfo.img+')','background-size': '100% 100%'}">
      <div class="read-title"></div>
      <div class="swiper-goods">
        <div class="swiper-goods-slide">
          <div
            v-for="(item,index) in obj.list"
            :key="index"
            class="swiper-goods-slide-item"
            :style="{'width': '30%'}"
          >
            <img
              :src="[item.coverImgUrl==''?preurl:item.coverImgUrl ]"
              :style="{'height': '150px','border-radius': '4px 4px 0 0'}"
              width="100%"
            />
            <div>
              <p>{{ item.name }}</p>
              <span>￥{{ item.salesPrice }}</span>
              <p class="buy-now">
                立即购买
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bt-drag-title">
      {{ obj.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'three-img-pre',
  props: {
    obj: {
      type: Object,
      default: {
        list: [],
        bgColor: '#fff',
        bgInfo: {
          img: '', type: 'index', content: '', width: '', height: '',
        },
      },
    },
  },
  data() {
    return {
      preurl1: 'https://img.hzanchu.com/acimg/07c0c4ef9f95df7bc8df6bbd4d2139e8.png?x-oss-process=image/resize,w_300',
    };
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.read-title{
    height: 88px;
}
.swiper-goods{
    overflow: hidden;
    box-sizing:border-box;
    width:100%;
    background: rgba(0,0,0,0);
    min-height: 150px;
    .swiper-goods-slide{
        overflow-x: auto;
        display: flex;
        display: -webkit-box;
        .swiper-goods-slide-item{
            margin:5px;
            background:#fff;
            border-radius: 4px;
            div{
                box-sizing:border-box;
                padding:5px;
                height: 80px;
                position: relative;
                p{
                    height: 18px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    font-size:14px;
                }
                span{
                    color:red;
                    text-align: center;
                    display: block;
                }
            }
        }
    }
}
.buy-now{
    color: #fff;
    text-align: center;
    width: 130px;
    height:26px!important;
    line-height: 26px;
    position: absolute;
    bottom: 8px;
    left: 10px;
    font-size: 14px;
    background:rgba(38,130,109,1);
    border-radius:4px;
}

</style>
