var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rich-text" },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "120px" } },
        [
          _c("el-form-item", { attrs: { label: "富文本背景色:" } }, [
            _c(
              "div",
              { staticClass: "tool__content" },
              [
                _c("el-input", {
                  staticClass: "tool-bg",
                  model: {
                    value: _vm.info.richBgColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.info, "richBgColor", $$v)
                    },
                    expression: "info.richBgColor",
                  },
                }),
                _c("div", { staticClass: "color__show" }, [
                  _c("div", {
                    staticClass: "color__content",
                    style: { background: _vm.info.richBgColor },
                  }),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "edit-box" }, [
        _c(
          "div",
          { staticClass: "editor-outer" },
          [
            _c("Toolbar", {
              staticClass: "editor-tool",
              attrs: {
                editor: _vm.editor,
                "default-config": _vm.toolbarConfig,
                mode: _vm.mode,
              },
            }),
            _c("Editor", {
              staticClass: "editor-content",
              staticStyle: { height: "400px", "overflow-y": "hidden" },
              attrs: { "default-config": _vm.editorConfig, mode: _vm.mode },
              on: { onCreated: _vm.onCreated },
              model: {
                value: _vm.info.content,
                callback: function ($$v) {
                  _vm.$set(_vm.info, "content", $$v)
                },
                expression: "info.content",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }