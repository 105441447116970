var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "coupon-component",
      style: {
        "background-color": _vm.obj.bgColor,
        padding: _vm.obj.bPadding ? "16px 26px" : "16px 0",
      },
    },
    [
      _vm._l(_vm.list, function (column, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "column",
            class: "column_" + _vm.obj.column,
            style: {
              marginRight: index === _vm.list.length - 1 ? "0" : "10px",
            },
          },
          _vm._l(column, function (item) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "item-box",
                style: {
                  backgroundImage: _vm.obj.bgImg
                    ? `url(${_vm.obj.bgImg})`
                    : "none",
                },
              },
              [
                _vm.obj.resource === 4
                  ? _c("div", { staticClass: "style4" }, [
                      _c("div", { staticClass: "content-container" }, [
                        item.imageUrl
                          ? _c("div", {
                              staticClass: "image-content",
                              style: {
                                backgroundImage: `url(${item.imageUrl})`,
                              },
                            })
                          : _vm._e(),
                        _c("div", { staticClass: "content" }, [
                          _c("div", { staticClass: "content-main" }, [
                            _c("div", { staticClass: "price" }, [
                              _vm._v("¥" + _vm._s(item.discountsValue)),
                            ]),
                            _c("div", { staticClass: "title" }, [
                              _vm._v(
                                "满" + _vm._s(item.discountsCondition) + "可用"
                              ),
                            ]),
                          ]),
                          _vm.obj.column === 1
                            ? _c("div", { staticClass: "content-title" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.couponDisplayName
                                        ? item.couponDisplayName
                                        : item.couponName
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _vm.obj.column !== 1
                        ? _c("div", { staticClass: "content-title" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.couponDisplayName
                                    ? item.couponDisplayName
                                    : item.couponName
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "handle-box border content-button" },
                        [
                          _c(
                            "el-button",
                            {
                              style: {
                                backgroundImage: _vm.obj.btnImg
                                  ? `url(${_vm.obj.btnImg})`
                                  : "none",
                              },
                              attrs: {
                                type: "warning",
                                size: "mini",
                                round: "",
                              },
                            },
                            [_vm._v("立刻领取")]
                          ),
                          _vm.obj.showLootAll
                            ? _c("div", { staticClass: "over" }, [
                                _vm._v("抢光了"),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  : [
                      _c("div", { staticClass: "content-box border" }, [
                        _c(
                          "div",
                          { staticClass: "top" },
                          [
                            _vm.obj.resource === 3 && item.types
                              ? _c(
                                  "el-tag",
                                  { attrs: { type: "danger", size: "mini" } },
                                  [_vm._v(_vm._s(item.types))]
                                )
                              : _vm._e(),
                            _c("div", { staticClass: "title" }, [
                              _vm._v(
                                _vm._s(
                                  item.couponDisplayName
                                    ? item.couponDisplayName
                                    : item.couponName
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm.obj.resource !== 1 && item.couponType === 2
                          ? _c("div", { staticClass: "con" }, [
                              _c("div", { staticClass: "price" }, [
                                _vm._v("¥" + _vm._s(item.discountsValue)),
                              ]),
                              _c("div", { staticClass: "title" }, [
                                _vm._v(
                                  "满" +
                                    _vm._s(item.discountsCondition) +
                                    "可用"
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.obj.resource === 3
                          ? _c("div", { staticClass: "tip" }, [
                              _vm._v(_vm._s(item.times)),
                            ])
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        { staticClass: "handle-box border" },
                        [
                          _c(
                            "el-button",
                            {
                              style: {
                                backgroundImage: _vm.obj.btnImg
                                  ? `url(${_vm.obj.btnImg})`
                                  : "none",
                              },
                              attrs: {
                                type: "warning",
                                size: "mini",
                                round: "",
                              },
                            },
                            [_vm._v("立刻领取")]
                          ),
                          _vm.obj.showLootAll
                            ? _c("div", { staticClass: "over" }, [
                                _vm._v("抢光了"),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
              ],
              2
            )
          }),
          0
        )
      }),
      _c("div", { staticClass: "bt-drag-title" }, [
        _vm._v(" " + _vm._s(_vm.obj.showTitle) + " "),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }