var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "home-channel",
      style: {
        "background-color": _vm.obj.bgColor,
        "margin-bottom": _vm.obj.bMargin ? "10px" : "0",
        padding: _vm.obj.bPadding ? "20px 10px" : "20px 0",
      },
    },
    [
      _vm._l(_vm.obj.list, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "home-row-item",
            style: { "border-right": index === 1 ? "1px solid #ccc" : "none" },
          },
          [
            _c("div", { staticClass: "title-box" }, [
              _c("h2", { style: { color: item.titleColor } }, [
                _vm._v(" " + _vm._s(item.title) + " "),
                index === 0 || index === 2
                  ? _c(
                      "span",
                      {
                        style: {
                          "background-color": item.labelBack,
                          color: item.labelColor,
                        },
                      },
                      [_vm._v(" " + _vm._s(item.label) + " ")]
                    )
                  : _vm._e(),
              ]),
              _c("p", { style: { color: item.subTitleColor } }, [
                _vm._v(" " + _vm._s(item.subTitle) + " "),
              ]),
            ]),
            _c("div", { staticClass: "img-box" }, [
              _c("img", {
                attrs: {
                  src: item.img === "" ? _vm.preurl1 : item.img,
                  alt: "频道图",
                },
              }),
            ]),
          ]
        )
      }),
      _c("div", { staticClass: "bt-drag-title" }, [
        _vm._v(" " + _vm._s(_vm.obj.title) + " "),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }