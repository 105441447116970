<template>
  <div
    class="static-img"
    :style="style"
  >
    <div class="static-img-slide">
      <div
        class="static-img-slide-box"
      >
        <img :src="obj.list[0].img || preurl" />
        <Shade
          v-for="(value, i) in obj.list[0].self"
          :key="i"
          :value="value"
          :title="i + 1"
        />
      </div>
    </div>
    <div class="bt-drag-title">
      {{ obj.showTitle }}
    </div>
  </div>
</template>

<script>
import Shade from './Shade';

export default {
  components: {
    Shade,
  },
  props: {
    obj: {
      type: Object,
      default: () => ({
        size: '212px',
        bMargin: false,
        bgColor: '#fff',
        bPadding: false,
        title: '静态图',
        list: [{
          type: '',
          img: '',
          content: '',
          self: [
            {
              top: 0,
              left: 0,
              width: 20,
              height: 50,
              type: 'goods',
              content: '',
            },
          ],
        }],
      }),
    },
  },
  data() {
    return {
      preurl: 'https://img.hzanchu.com/acimg/98967e322848c8cb4f979be43187af6f.jpg',
      isDown: false,
      fatherSize: {},
    };
  },
  computed: {
    style() {
      return {
        'background-color': this.obj.bgColor,
        'padding-bottom': this.obj.bMargin ? '10px' : 0,
        'padding-left': this.obj.bPadding ? '20px' : 0,
        'padding-right': this.obj.bPadding ? '20px' : 0,
      };
    },
  },
  mounted() {

  },
  methods: {

  },
};
</script>

<style scoped lang="scss">
  .static-img{
    overflow: hidden;
    box-sizing:border-box;
    width:100%;
    .static-img-slide{
      overflow:hidden;
      .static-img-slide-box {
        position: relative;
        width: 100%;
        img{
          width: 100%;
          display: block;
        }
      }
    }
  }
</style>
