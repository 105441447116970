var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cate-goods", style: { background: _vm.obj.titleBgColor } },
    [
      _vm._t("default"),
      _c(
        "div",
        {
          staticClass: "cate-nav",
          style: { background: _vm.obj.navBgColor, color: _vm.obj.navColor },
        },
        _vm._l(_vm.obj.list, function (item, index) {
          return _c(
            "a",
            {
              key: index,
              style: {
                background:
                  _vm.selectIndex === index
                    ? _vm.obj.selectBgColor
                    : _vm.obj.navBgColor,
                color:
                  _vm.selectIndex === index
                    ? _vm.obj.selectColor
                    : _vm.obj.navColor,
              },
              attrs: { href: `#cate${index}` },
              on: {
                click: function ($event) {
                  _vm.selectIndex = index
                },
              },
            },
            [_vm._v(_vm._s(item.name))]
          )
        }),
        0
      ),
      _vm._l(_vm.obj.list, function (item, index) {
        return _c("div", { key: index, staticClass: "cate-content-box" }, [
          item.imgInfo && item.imgInfo.img !== ""
            ? _c("div", { staticClass: "cate-title-img" }, [
                _c("img", { attrs: { src: item.imgInfo.img, alt: "" } }),
              ])
            : _c(
                "div",
                {
                  staticClass: "cate-title",
                  style: { color: _vm.obj.titleColor },
                  attrs: { id: `cate${index}` },
                },
                [_vm._v(" " + _vm._s(item.name) + " ")]
              ),
          _c(
            "div",
            { staticClass: "cate-goods-content" },
            _vm._l(item.goods_list, function (goods, index2) {
              return _c(
                "div",
                { key: index2, staticClass: "cate-goods-item" },
                [
                  _c("img", {
                    attrs: {
                      src: goods.coverImgUrl
                        ? goods.coverImgUrl.split(",")[0]
                        : "",
                    },
                  }),
                  _c("div", [
                    _c("p", { staticClass: "good-name" }, [
                      _vm._v(" " + _vm._s(goods.name) + " "),
                    ]),
                    _c("p", { staticClass: "good-price" }, [
                      _vm._v(" ￥ " + _vm._s(goods.salesPrice) + " "),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
        ])
      }),
      _c("div", { staticClass: "bt-drag-title" }, [
        _vm._v(" " + _vm._s(_vm.obj.title) + " "),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }