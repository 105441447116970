var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      ref: "shadeRef",
      staticClass: "static-img-slide-box-a",
      style: _setup.style,
      on: { mousedown: _setup.onmousedownShade },
    },
    [
      _vm._v(" " + _vm._s(_vm.title) + " "),
      _c("div", {
        staticClass: "round",
        on: {
          click: function ($event) {
            $event.stopPropagation()
          },
          mousedown: _setup.onmousedownRound,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }