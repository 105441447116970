<template>
  <div
    class="sub-title"
    :style="{'background': obj.bgColor,'color': obj.color}"
  >
    {{ obj.text }}
    <div class="bt-drag-title">
      {{ obj.title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    obj: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
				 	bgColor: '#fff',
				 	color: '#000',
				 	text: '',
				 	link: '',
				 	title: '标题'
      }
    }
  }
}
</script>

<style scoped>
  .sub-title{
    height:50px;
    text-align:center;
    line-height:50px;
  }
</style>
