<template>
  <div
    class="cate-goods"
    :style="{'background': obj.titleBgColor}"
  >
    <slot />
    <div
      class="cate-nav"
      :style="{'background': obj.navBgColor,'color': obj.navColor}"
    >
      <a
        v-for="(item,index) in obj.list"
        :key="index"
        :href="`#cate${index}`"
        :style="{'background': selectIndex === index ? obj.selectBgColor : obj.navBgColor, 'color': selectIndex === index ? obj.selectColor : obj.navColor}"
        @click="selectIndex=index"
      >{{ item.name }}</a>
    </div>

    <div class="bt-drag-title">
      {{ obj.showTitle }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    obj: {
      type: Object,
      default: () => ({
        list: [{
          name: '',
          cateId: ''
        }],
        navBgColor: '#fff',
        navColor: '#000',
        selectBgColor: '#fff',
        selectColor: '#67C23A',
        titleBgColor: '#fff',
        titleColor: '#000',
        title: '商品类目'
      })
    }
  },
  data() {
    return {
      preurl: 'https://img.hzanchu.com/acimg/4c3150d4a2606a92b0788495f2a8ff0b.jpg',
      selectIndex: 0
    }
  }
}
</script>

<style scoped lang="scss">
  .cate-goods{
    .cate-nav{
      display: flex;
      height:64px;
      justify-content:center;
      align-items:center;
      a{
        flex:1;
        height:100%;
        text-align: center;
        line-height:64px;
      }
    }
    .cate-title{
      width:94%;
      background:#fff;
      margin: 0 auto;
      margin-bottom:5px;
      margin-top: 10px;
      text-align: center;
      height:40px;
      line-height:40px;
    }
    .cate-title-img {
      width: 100%;
      img {
        width: 100%;
        display: block;
      }
    }
    
  }
</style>
