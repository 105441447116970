var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.qrShow
    ? _c(
        "div",
        {
          staticClass: "qrcode-cover",
          on: {
            click: function ($event) {
              return _vm.$emit("hideQr", false)
            },
          },
        },
        [_c("QrcodeVue", { attrs: { value: _vm.value, size: _vm.size } })],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }