<template>
  <div>
    <el-form-item label="跳转类型:">
      <el-select
        v-model="imgInfo.type"
        :disabled="canSetSelect"
        @change="changeContent"
      >
        <el-option
          v-for="(item, index) in selectOptions"
          :key="index"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </el-form-item>
    <!-- 跳转链接 -->
    <el-form-item
      v-if="!(notShowInput.includes(imgInfo.type))"
      label="ID或链接:"
    >
      <SearchSelectCoupon
        v-if="imgInfo.type === 'coupon'"
        v-model="imgInfo.content"
        :not-query-exchange-coupon="1"
        @change="setCouponKey"
      />
      <!--ID或链接-->
      <el-input
        v-else
        v-model.trim="imgInfo.content"
        class="input-w200"
        @input="onInput"
      />
    </el-form-item>
  </div>
</template>

<script>
/**
 * name: 自定义静态图--上传图片
 * @param(imgData) 上传图片信息
 * @param(imgInfo.type) 跳转类型
 * @param(selectOptions) 跳转选项信息 首页--index 商品--goods 优惠券--coupon 链接--link 直播--live 商家店铺--store 城市馆--site 券包（批量领券）--coupackage
 */
import md5 from 'md5-js';
import { SELECT_OPTIONS, NOT_SHOW_INPUT } from './data';
import UpImage from '@/components/common/upImage/index';
import SearchSelectCoupon from '@/components/common/searchSelectCoupon';

export default {
  name: '',
  components: {
    UpImage,
    SearchSelectCoupon,
  },
  props: {
    imgInfo: {
      type: Object,
      default: () => {},
    },
    // 优惠券
    couponOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectOptions: SELECT_OPTIONS,
      notShowInput: NOT_SHOW_INPUT,
      canSetcouponbag: false,
      canSetSelect: false,
      couponbag: '', // 不能修改的优惠券id信息
      distributionImageList: [], // 分销列表-背景图片
    };
  },
  computed: {},
  watch: {
    // 分销列表-修改图片
    distributionImageList: {
      handler(val) {
        [this.imgInfo.distributionImage] = val;
      },
    },
  },
  created() { },
  mounted() {
    // 判断是否已经有优惠券id
    if (this.imgInfo.type === 'coupackage' && this.imgInfo.content) {
      this.canSetcouponbag = true;
      this.canSetSelect = true;
      this.couponbag = this.imgInfo.content;
    } else if (this.imgInfo.type === 'distribution') {
      this.distributionImageList = this.imgInfo.distributionImage
        ? [this.imgInfo.distributionImage] : [];
    }
  },
  methods: {
    changeContent(val) {
      this.$set(this.imgInfo, 'content', '');
      if (val === 'coupackage') {
        this.imgInfo.content = this.couponbag;
      }
    },
    // 优惠券专有属性
    setCouponKey(val) {
      this.imgInfo.coupon_key = md5(md5(`${val}zpqmal*571`));
    },
    changeStore(row) {
      console.log(row);
      if (row) {
        this.imgInfo.storeName = row.storeName;
      } else {
        this.imgInfo.storeName = '';
      }
    },
    onInput(e) {
      const { type } = this.imgInfo;
      let value = e;
      if (type === 'goods') {
        value = value.replace(/[^0-9]/g, '');
      }
      this.imgInfo.content = value;
    },
  },
};
</script>

<style lang="scss" scoped>
  .img-info{
    padding: 5px 0;
    border-bottom: 1px dashed #eee;
  }
  .couponbag {
    position: relative;
    width: 246px;
    .annotation {
      position: absolute;
      left: -70px;
      top: 20px;
    }
  }
  .county-remark {
    width: 100%;
    padding-left: 30px;
    box-sizing: border-box;
    color: #666;
    margin-bottom: 30px;
  }
  .flex {
    display: flex;
  }
</style>
