<template>
  <div class="rich-text">
    <el-form label-width="120px">
      <el-form-item label="富文本背景色:">
        <div class="tool__content">
          <el-input
            v-model="info.richBgColor"
            class="tool-bg"
          />
          <div class="color__show">
            <div
              class="color__content"
              :style="{ background: info.richBgColor }"
            ></div>
          </div>
        </div>
      </el-form-item>
    </el-form>

    <div class="edit-box">
      <div class="editor-outer">
        <Toolbar
          class="editor-tool"
          :editor="editor"
          :default-config="toolbarConfig"
          :mode="mode"
        />
        <Editor
          v-model="info.content"
          class="editor-content"
          style="height: 400px; overflow-y: hidden"
          :default-config="editorConfig"
          :mode="mode"
          @onCreated="onCreated"
        />
      </div>
    </div>
  </div>
</template>

<script>
import '@wangeditor/editor/dist/css/style.css';
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';

export default {
  components: {
    Editor,
    Toolbar,
  },
  props: {
    info: {
      type: Object,
      default: () => ({
        content: '',
        richBgColor: '#fff',
      }),
    },
  },
  data() {
    return {
      editor: null,
      toolbarConfig: {
        excludeKeys: ['emotion'],
      },
      mode: 'default',
      editorConfig: {
        placeholder: '请输入内容...',
        MENU_CONF: {
          uploadImage: {
            server: this.$api.nb_common.file_upload,
            base64LimitSize: 5 * 1024,
            fieldName: 'multipartFile',
            customInsert(res, insertFn) {
              const { fileUrl } = res.data;
              insertFn(fileUrl, '', fileUrl);
            },
          },
        },
      },
    };
  },
  mounted() {
    console.log(this.info);
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      // console.log(111, this.editor.getAllMenuKeys())
    },
    changeContent(val) {
      this.imgInfo.content = '';
      if (val === 'coupackage') {
        this.imgInfo.content = this.couponbag;
      }
    },
    // 优惠券专有属性
    setCouponKey(val, i) {
      this.imgInfo.self[i].coupon_key = md5(md5(`${val}zpqmal*571`));
    },
    // 添加自定义区域
    addSelf(index) {
      if (index < 50) {
        let data = {
          top: 50,
          left: 0,
          width: 20,
          height: 20,
          type: 'goods',
          content: '',
          zIndex: 99,
        };
        this.imgInfo.self.push(data);
        this.activeName = `区域${index + 1}`;
      } else {
        this.$message({
          type: 'warning',
          message: '最多添加5个区域',
        });
      }
    },
    // 删除自定义区域
    deleteSelf(index) {
      this.$confirm(`此操作将删除区域${index + 1}, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.imgInfo.self.splice(index, 1);
          this.activeName = `区域${index < 1 ? 1 : index}`;
          this.$message({
            type: 'success',
            message: '删除成功!',
          });
        })
        .catch(() => {});
    },
    onSuccess(obj) {
      this.imgInfo.img = obj[0];
    },
    // 获取展馆列表信息
    getSiteInfo() {
      let that = this;
      that.$axios.get(that.$api.siteArea.listTest, {
        params: {
          siteType: 1,
        },
      }).then((res) => {
        if (res.code === 0) {
          that.pavilionOptions = res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.rich-text {
  padding: 10px 0;
  .tool__content {
    width: 145px;
    height: 40px;
    display: inline-block;
    .tool-bg {
      width: 100px;
      display: inline-block;
    }
    .color__show {
      width: 32px;
      height: 32px;
      margin-left: 4px;
      border-radius: 4px;
      display: inline-block;
      vertical-align: top;
      border: 1px solid #979797;
      background: rgba(238, 238, 238, 1);
      .color__content {
        width: 22px;
        height: 22px;
        margin: 5px;
      }
    }
  }
  .edit-box {
    padding: 0 15px;
    .editor-outer {
      border: 1px solid #ccc;
      border-radius: 3px;
    }
    .editor-tool {
      border-bottom: 1px solid #ccc;
      padding: 8px 0;
    }
  }
}
</style>

<style scoped>
.rich-text .editor >>> .ql-editor {
  height: 300px;
}
</style>
