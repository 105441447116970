var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "swiper-goods",
      style: {
        "border-bottom": _vm.obj.bMargin ? "10px solid" : "",
        "background-color": _vm.obj.bgColor,
        padding: _vm.obj.bPadding ? "1px 26px 1px 21px" : "1px 5px 1px 0",
      },
    },
    [
      _c(
        "div",
        { staticClass: "swiper-goods-slide" },
        _vm._l(_vm.obj.list, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "swiper-goods-slide-item",
              style: { width: _vm.obj.list.length > 3 ? "37%" : "30%" },
            },
            [
              _c("img", {
                style: { height: _vm.obj.list.length > 3 ? "186px" : "150px" },
                attrs: {
                  src: item.coverImgUrl ? item.coverImgUrl.split(",")[0] : "",
                  width: "100%",
                },
              }),
              _c("div", [
                _c("p", [_vm._v(_vm._s(item.name))]),
                _c("span", [_vm._v("￥" + _vm._s(item.salesPrice))]),
              ]),
            ]
          )
        }),
        0
      ),
      _c("div", { staticClass: "bt-drag-title" }, [
        _vm._v(" " + _vm._s(_vm.obj.title) + " "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }