<template>
  <div
    class="my-swiper"
    :style="{'border-bottom': obj.bMargin?'10px solid':'','border-color': obj.bgColor}"
  >
    <el-carousel height="250px">
      <!-- <div class="swiper-slide"> -->
      <el-carousel-item
        v-for="(item,index) in obj.list"
        :key="index"
      >
        <img :src="[item.img == ''? preurl : item.img + '?x-oss-process=image/resize,w_375']">
      </el-carousel-item>
      <!-- </div> -->
    </el-carousel>
    <div class="bt-drag-title">
      {{ obj.title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    obj: {
      type: Object,
      default: {
        list: [{
				 		type: '',
				 		content: '',
				 		img: ''
				 	}],
				 	bMargin: false,
				 	bgColor: '#fff',
				 	title: '轮播图'
      }
    }
  },
  data() {
    return {
      preurl: 'https://img.hzanchu.com/acimg/6f1349a8e55de0e942fb5509475f0521.jpg?x-oss-process=image/resize,w_300'
    }
  }
}
</script>
<style scoped lang="scss">
  .my-swiper{
    overflow: hidden;
    position: relative;
    width:100%;
    height:250px;
    img{
      width:100%;
      height:100%;
    }
  }
</style>
