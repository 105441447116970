<template>
  <div class="right-tool-paging">
    <el-form
      :inline="true"
    >
      <el-form-item
        label="分页:"
        label-width="80px"
      >
        <el-switch
          v-model="pageItem.isShowMore"
          @change="changeItem"
        />
        <div class="orange">
          不分页最多可展示200条数据
        </div>
      </el-form-item>
      <el-form-item
        v-if="pageItem.isShowMore"
        label="分页数量:"
      >
        <el-input-number
          v-model="pageItem.pageSize"
          class="w100"
          :min="1"
          :max="9999"
          :controls="false"
          @change="changeItem"
        />
      </el-form-item>
      <el-form-item
        v-if="pageItem.isShowMore"
        label="查看更多图片:"
      >
        <UpImage
          :image-list="pageItem.imageList"
          @change="changeItem"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import UpImage from '@/components/common/upImage/index';

const props = defineProps({
  item: {
    type: Object,
    default: () => {
      return {};
    },
  },
});
const emit = defineEmits(['change']);

const pageItem = ref({
  isShowMore: false,
  pageSize: 10,
  image: '',
  imageList: [],
});

const changeItem = () => {
  pageItem.value.image = pageItem.value.imageList[0];
  emit('change', pageItem.value);
};

watch(() => props.item, (row) => {
  if (!row) return;
  pageItem.value = {
    ...row,
    imageList: row.image ? [row.image] : [],
  };
}, {
  deep: true,
  immediate: true,
});
</script>

<style scoped lang="scss">
.right-tool-paging {
  width: 100%;
  padding: 0 20px;
  :deep(.el-form-item){
    margin: 0;
    .el-form-item__label {
      color: #8e8e8e;
    }
  }
}
</style>
