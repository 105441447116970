<template>
  <div
    class="left-right-pre"
    :style="{'background-color': obj.bgColor, 'margin-bottom': obj.bMargin ? '10px' : '0', 'padding': obj.bPadding ? '20px 10px' : '20px 0'}"
  >
    <div class="left">
      <img
        :src="[obj.list[0].img == ''? preurl1 : obj.list[0].img + '?x-oss-process=image/resize,w_200']"
        alt=""
      >
    </div>
    <div class="right">
      <img
        :src="[obj.list[1].img == ''? preurl2 : obj.list[1].img + '?x-oss-process=image/resize,w_200']"
        alt=""
      >
      <img
        :src="[obj.list[2].img == ''? preurl2 : obj.list[2].img + '?x-oss-process=image/resize,w_200']"
        alt=""
      >
      <img
        :src="[obj.list[3].img == ''? preurl2 : obj.list[3].img + '?x-oss-process=image/resize,w_200']"
        alt=""
      >
      <img
        :src="[obj.list[4].img == ''? preurl2 : obj.list[4].img + '?x-oss-process=image/resize,w_200']"
        alt=""
      >
    </div>
    <div class="bt-drag-title">
      {{ obj.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeftRightPre',
  props: {
    obj: {
      type: Object,
      default: {
        list: [
          { img: '' },
          { img: '' },
          { img: '' },
          { img: '' },
          { img: '' }
        ],
        bgColor: '#fff'
      }
    }
  },
  data() {
    return {
      preurl1: 'https://img.hzanchu.com/acimg/f4026603d09ee77aaffef15061092364.png?x-oss-process=image/resize,w_300',
      preurl2: 'https://img.hzanchu.com/acimg/0361b69f4bc94733976c63f9696bafaa.png?x-oss-process=image/resize,w_300'
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.left-right-pre{
    display: flex;
}
.left{
    width: 166px;
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    img{
        width: 155px;
        height: 185px;
        padding: 0 5px;
    }
}
.right{
    width: calc(100% - 166px);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    img{
        width: 156px;
        height: 85px;
        padding:5px;
    }
}
</style>
