var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "static-img",
      style: {
        "border-bottom": _vm.obj.bMargin ? "10px solid" : "",
        "border-color": _vm.obj.bgColor,
        padding: _vm.obj.bPadding ? "1px 26px 1px 21px" : "0",
      },
    },
    [
      _c("div", { ref: "selfParent", staticClass: "static-img-slide" }, [
        _c(
          "div",
          { staticClass: "static-img-slide-box" },
          [
            _vm.obj?.searchType !== 1
              ? _c("img", {
                  attrs: {
                    src: [_vm.obj.img === "" ? _vm.preurl : _vm.obj.img],
                  },
                })
              : _vm._e(),
            _vm.obj?.searchType === 1
              ? _c(
                  "el-input",
                  {
                    staticClass: "input-with-select",
                    attrs: { placeholder: _vm.obj.searchText },
                  },
                  [
                    _c("el-button", {
                      attrs: { slot: "append", icon: "el-icon-search" },
                      slot: "append",
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.obj?.searchType !== 1
              ? _c("Shade", {
                  attrs: { title: "点击区域热区", value: _vm.obj },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "bt-drag-title" }, [
        _vm._v(" " + _vm._s(_vm.obj.showTitle) + " "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }