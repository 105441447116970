var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "numshow" },
    [
      _c("div", { staticClass: "num" }, [
        _vm._v(" 图" + _vm._s(_vm.picNum) + " "),
      ]),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }