<template>
  <div
    class="four-img-pre"
    :style="{'background-color': obj.bgColor, 'margin-bottom': obj.bMargin ? '10px' : '0', 'padding': obj.bPadding ? '20px 10px' : '20px 0'}"
  >
    <img
      :src="[obj.list[0].img == ''? preurl1 : obj.list[0].img + '?x-oss-process=image/resize,w_200']"
      alt=""
    >
    <img
      :src="[obj.list[1].img == ''? preurl1 : obj.list[1].img + '?x-oss-process=image/resize,w_200']"
      alt=""
    >
    <img
      :src="[obj.list[2].img == ''? preurl1 : obj.list[2].img + '?x-oss-process=image/resize,w_200']"
      alt=""
    >
    <img
      :src="[obj.list[3].img == ''? preurl1 : obj.list[3].img + '?x-oss-process=image/resize,w_200']"
      alt=""
    >
    <div class="bt-drag-title">
      {{ obj.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThreeImgPre',
  props: {
    obj: {
      type: Object,
      default: {
        list: [
          { img: '' },
          { img: '' },
          { img: '' },
          { img: '' }
        ],
        bgColor: '#fff'
      }
    }
  },
  data() {
    return {
      preurl1: 'https://img.hzanchu.com/acimg/949da7cc8a09356854e231894fe6259e.png'
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.four-img-pre{
    display: flex;
    width: 100%;
    img{
        width: 100px;
        flex: 1;
        padding:5px;
    }
}

</style>
