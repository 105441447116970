<template>
  <div class="page-home">
    <div class="list-num">
      {{ `频道${keyNum+1}` }}
    </div>
    <div class="input-box">
      <span>跳转类型</span>
      <el-select
        v-model="imgInfo.type"
        @change="changeContent"
      >
        <el-option
          v-for="(type, i) in selectOptions"
          :key="i"
          :label="type.label"
          :value="type.value"
        />
      </el-select>
    </div>
    <div
      v-if="['link','goods'].includes(imgInfo.type)"
      class="input-box"
    >
      <span>商品ID/链接</span>
      <el-input
        v-model="imgInfo.link"
        class="input-text"
      />
    </div>
    <template v-if="(keyNum === 0 || keyNum === 2 || (keyNum === 1 && addchannel.list[0].label === '') || (keyNum === 3 && addchannel.list[2].label === ''))">
      <div class="input-box">
        <span>频道标题</span>
        <el-input
          v-model="imgInfo.title"
          placeholder="请输入频道标题长度限制4个字符"
          class="input-text"
          maxlength="4"
        />
      </div>
      <div class="input-box">
        <span>字体颜色</span>
        <el-input
          v-model="imgInfo.titleColor"
          placeholder="请输入色值"
          class="input-text"
        />
      </div>
    </template>
    <template v-if="keyNum === 0 || keyNum === 2">
      <div class="input-box">
        <span>频道标签</span>
        <el-input
          v-model="imgInfo.label"
          placeholder="请输入频道标签长度限制6个字符"
          class="input-text"
          maxlength="6"
        />
      </div>
      <div class="input-box">
        <span>字体颜色</span>
        <el-input
          v-model="imgInfo.labelColor"
          placeholder="请输入色值"
          class="input-text"
        />
      </div>
      <div class="input-box">
        <span>背景颜色</span>
        <el-input
          v-model="imgInfo.labelBack"
          placeholder="请输入色值"
          class="input-text"
        />
      </div>
    </template>
    <template v-if="(keyNum === 0 || keyNum === 2 || (keyNum === 1 && addchannel.list[0].label === '') || (keyNum === 3 && addchannel.list[2].label === ''))">
      <div
        v-if="keyNum === 0 || keyNum === 2 || (keyNum === 1 && addchannel.list[0].subTitle.length < 5) || (keyNum === 3 && addchannel.list[2].subTitle.length < 5)"
        class="input-box"
      >
        <span>频道副标题</span>
        <el-input
          v-model="imgInfo.subTitle"
          :placeholder="`请输入频道副标题长度限制${maxLength}个字符`"
          class="input-text"
          :maxlength="maxLength"
        />
      </div>
      <div class="input-box">
        <span>字体颜色</span>
        <el-input
          v-model="imgInfo.subTitleColor"
          placeholder="请输入色值"
          class="input-text"
        />
      </div>
    </template>
    <div class="input-box">
      <span>封面图</span>
      <div class="choose-img">
        <el-radio
          v-model="imgInfo.imgType"
          label="1"
        >
          上传图片
        </el-radio>
        <el-radio
          v-model="imgInfo.imgType"
          label="2"
          :disabled="imgInfo.type === 'live'"
        >
          商品ID
        </el-radio>
      </div>
    </div>
    <div>
      <div>
        <CutImgUp
          v-if="imgInfo.imgType === '1'"
          :url="imageUrlList"
          :auto-crop-width="750"
          :auto-crop-height="750"
          :max-num="1"
          @change="onSuccess"
        />
      </div>
      <div
        v-if="imgInfo.imgType === '2'"
      >
        <div class="get-good-img">
          <el-input
            v-model="imgInfo.imgGoodsId"
            placeholder="请输入一个商品ID"
          />
          <button @click="addGoods">
            立即添加
          </button>
        </div>
        <p style="margin-top: 10px;padding-bottom: 20px;">
          {{ (goodInfo[0] && goodInfo[0].name) || '' }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import CutImgUp from '@/components/common/cutImgUp'
import qs from 'qs'

// 首页频道模块
export default {
  name: 'PageChannel',
  components: { CutImgUp },
  props: {
    addchannel: {
      type: Object,
      default: () => ({
        list: []
      })

    },
    imgInfo: {
      type: Object,
      default: () => ({})
    },
    keyNum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      // 跳转选项
      selectOptions: [
        { label: '商品', value: 'goods' },
        { label: '链接', value: 'link' },
        { label: '直播', value: 'live' },
        { label: '低价实惠', value: '1' },
        // { label: '新品尝鲜', value: '2' },
        { label: '农博榜单', value: '3' },
        { label: '农博金奖', value: '4' },
        { label: '扶贫助农', value: '5' },
        { label: '一村一品', value: '6' },
        { label: '知名品牌', value: 'brand' }
      ],
      maxLength: 8,
      goodInfo: [],
      imageUrlList: []

    }
  },
  watch: {
    'imgInfo.imgType': function() {
      this.imgInfo.img = ''
      this.imgInfo.imgGoodsId = ''
    },
    'imgInfo.label': function(oldVal, newVal) {
      if (this.imgInfo.label === '') {
        this.imgInfo.labelColor = ''
        this.imgInfo.labelBack = ''
        // this.maxLength = 8
      } else {
        // this.maxLength = 5
      }
      if (this.keyNum === 0) {
        this.addchannel.list[1].title = ''
        this.addchannel.list[1].titleColor = ''
        this.addchannel.list[1].subTitle = ''
        this.addchannel.list[1].subTitleColor = ''
      }
      if (this.keyNum === 2) {
        this.addchannel.list[3].title = ''
        this.addchannel.list[3].titleColor = ''
        this.addchannel.list[3].subTitle = ''
        this.addchannel.list[3].subTitleColor = ''
      }
    },
    'maxLength': function() {
      this.imgInfo.subTitle = ''
    },
    'addchannel.list': {
      handler: function(val) {
        if (val[0].subTitle.length > 4 && val[1].subTitle.length > 0) {
          val[1].subTitle = ''
        }
        if (val[2].subTitle.length > 4 && val[3].subTitle.length > 0) {
          val[3].subTitle = ''
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.imgInfo.img !== '') {
      this.imageUrlList = [this.imgInfo.img]
    } else {
      this.imageUrlList = []
    }
  },
  methods: {
    changeContent(value) {
      this.imgInfo.link = ''
      if (value === 'live') {
        this.imgInfo.imgType = '1'
      }
    },
    addGoods() {
      let that = this
      if (that.imgInfo.imgGoodsId === '') {
        that.$message('请填写商品信息')
        that.goodInfo = []
        that.imgInfo.img = ''
      } else if (that.imgInfo.imgGoodsId.split(',').length > 1) {
        that.$message('只允许输入一个商品id')
        that.goodInfo = []
        that.imgInfo.img = ''
      } else {
        that.$axios.post(that.$api.tradePluginTemplate.selectGoods,
          qs.stringify({
            goodsIds: that.imgInfo.imgGoodsId
          })).then(function(res) {
          const data = res
          if (data.code === 0) {
            if (data.data.length === 0) {
              that.goodInfo = []
              that.imgInfo.img = ''
              that.$message('找不到该id对应的商品')
            } else {
              that.goodInfo = data.data
              that.imgInfo.imgInfo = data.data[0].image
            }
          }
        })
      }
    },
    onSuccess(obj) {
      this.imgInfo.img = obj[0]
    }
  }
}
</script>

<style scoped lang="scss" >
.page-home {
  position: relative;
  padding: 60px 20px 20px 20px;
  width: 100%;
  box-sizing: border-box;
}
.page-home:after {
  content: "";
  width: calc(100% - 30px);
  height: 1px;
  left: 15px;
  bottom: 0;
  position: absolute;
  background: #ededed;
}
.input-box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  span {
    margin-right: 10px;
    white-space: nowrap;
  }
}
.list-num {
  width: 60px;
  line-height: 28px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  padding-left: 5px;
  font-size: 12px;
  margin-bottom: 15px;
  background: rgba(0,153,255,1);
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
}
.get-good-img{
  display:flex;
  button{
    margin-left: 20px;
    width: 100px;
    cursor: pointer;
  }
}
</style>
