<template>
  <div class="numshow">
    <div class="num">
      图{{ picNum }}
    </div>
    <slot />
  </div>
</template>

<script>
/**
 * renxy
 * name 图片顺序标志
 * @param(picNum) 顺序数字
 */
export default {
  name: 'Numshow',
  props: ['picNum']
}
</script>

<style scoped lang="scss" >
.numshow {
    border-bottom: 10px solid #EDF2F6;
    .num {
        width: 47px;
        height: 30px;
        color: #fff;
        line-height: 30px;
        margin-left: 23px;
        margin-top: 10px;
        padding-left: 13px;
        background: #0099FF;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
    }
}

</style>
