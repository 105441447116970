<template>
  <div
    class="list-goods"
    :style="{'border-bottom': obj.bMargin?'10px solid':'','background-color': obj.bgColor,'padding': obj.bPadding?'16px 26px':'16px 0'}"
  >
    <slot />
    <div
      v-for="(item,index1) in obj.list"
      :key="index1"
      class="list-goods-content"
    >
      <div
        v-for="(goods,index2) in item.goodsDetail"
        :key="index2"
        class="list-goods-item"
      >
        <img :src="goods.imageUrls.split(',')[0]">
        <div>
          <p class="good-name">
            {{ goods.name }}
          </p>
          <p class="goods-sale">
            已抢{{ goods.robbedStock }}件
          </p>
          <p class="sell_price">
            ￥ {{ goods.price }} <span class="line_throw">{{ goods.marketPrice }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="bt-drag-title">
      {{ obj.title }}
    </div>
  </div>
</template>

<script>
/*
  list: 数据
  bMargin: 是否有行边距   默认没有
  bgColor: 行边距的颜色   默认#fff
  bPadding: 边缘  默认没有
  {
  bMargin:false,
  bgColor:#fff,
  bPadding:false,
  list:[]
  }
  * **/
export default {
  props: {
    obj: {
      type: Object,
      default: () => ({
        bMargin: false,
        bgColor: '#fff',
        bPadding: false,
        title: '列表商品',
        list: []
      })
    }
  },
  data() {
    return {
      preurl: 'https://img.hzanchu.com/acimg/e21aab713b254226eb46882a65baf1c2.jpg'
    }
  },
  watch() {

  },
  mounted() {
    console.log('list:', this.obj.list)
  }
}
</script>

<style scoped lang="scss">
.list-goods{

  .list-goods-content {
    box-sizing: border-box;
    display: flex;
    padding: 0 20px;
    flex-wrap:wrap;
    justify-content:space-between;
  }
  .list-goods-item{
    width:220px;
    background:#fff;
    margin-bottom:10px;
    img{
      width:220px;
      height:220px;
    }
    .good-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 20px;
    }
    .goods-sale {
      margin-top: 10px;
      font-size: 18px;
      color: #333;
    }
    .sell_price {
      margin-top: 10px;
      color:red;
      font-size: 20px;
      .line_throw{
        font-size: 14px;
        text-decoration:line-through;
        color: #999;
      }
    }
    div{
      box-sizing:border-box;
      padding:10px 12px;
      p{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        font-size:14px;
      }
      span{
        color:red
      }
    }
  }
}
</style>
