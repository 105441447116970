<template>
  <div class="add__list addList">
    <div class="content">
      <div class="h2">
        排序规则
      </div>
      <div>
        <el-radio-group v-model="addlistinfo.sortType">
          <el-radio :label="1">
            综合
          </el-radio>
          <el-radio :label="2">
            销量
          </el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="content hei30">
      <div class="h2">
        条件筛选
      </div>
      <el-tabs
        v-model="activeName"
        style="width: 100%"
      >
        <el-tab-pane
          label="指定区域馆"
          name="first"
        >
          <div>
            <!-- <ChangeSite @change="changeSite" /> -->
            <el-cascader
              v-model="addlistinfo.siteList"
              style="width: 100%"
              :props="siteOptions"
              :options="sitelist"
              clearable
              @change="changeSite"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane
          label="指定后台类目"
          name="second"
        >
          <div>
            <el-cascader
              v-model="addlistinfo.backendCategorys"
              style="width: 100%"
              :options="catList"
              :props="cateOptions"
              clearable
              @change="changeCate"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane
          label="指定商家"
          name="third"
        >
          <div
            style="
              overflow: hidden;
              display: flex;
              align-items: flex-start;
              margin: 0 10px;
            "
          >
            <div
              class="goods__name"
              style="width: 100px"
            >
              <p>选择商家：</p>
            </div>
            <el-input
              v-model.trim="sellers"
              type="textarea"
              placeholder="输入商家id,可输入多个商家,多个商家id之间以英文“,”区分"
            />
            <el-button
              size="mini"
              style="margin-left: 20px"
              @click="addSeller"
            >
              添加商家
            </el-button>
          </div>
          <div class="goods__list">
            <div class="goods__name">
              <p>已选：</p>
            </div>
            <div class="list__content">
              <draggable
                v-model="addlistinfo.sellerIdList"
                element="div"
              >
                <ul
                  v-for="(item, index) in addlistinfo.sellerIdList"
                  :key="index"
                >
                  <li>
                    <span class="item-id">{{ item }}</span>
                    <span
                      class="delet-btn"
                      style="margin: 0"
                      @click="delSeller(index)"
                    >删除</span>
                  </li>
                </ul>
              </draggable>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import qs from 'qs';

export default {
  name: 'add-list',
  components: { draggable },
  props: {
    addlistinfo: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => ({
        sortType: 1,
        siteList: [],
        backendCategorys: [],
        sellerIdList: [],
      }),
    },
  },

  data() {
    return {
      radio: 1,
      catList: [],
      optionList: [],
      cateOptions: {
        checkStrictly: true,
        label: 'name',
        value: 'id',
        multiple: true,
      },
      activeName: 'first',
      goodsInfo: '', // 商品id
      sitelist: [],
      siteOptions: {
        checkStrictly: true,
        label: 'siteName',
        value: 'siteId',
      },
      sellers: '',
    };
  },

  watch: {},
  created() {
    this.getCatList();
    this.getSiteInfo();
    // this.sellers = this.addlistinfo.sellerIdList.join(',')
  },

  methods: {
    // 筛选地区
    changeSite(value) {
      this.addlistinfo.siteList = value;
    },
    // 筛选类目
    changeCate(value) {
      this.addlistinfo.backendCategorys = value;
    },
    // 获得类目数据
    getCatList(id, resolve) {
      this.$axios({
        method: 'get',
        url: this.$api.backendCategory.backend_category,
        // params: {
        //   parentId: id,
        //   level: id ? '' : 0,
        //   status: 1
        // }
      }).then((res) => {
        if (res.code === 0) {
          this.catList = res.data;
        }
      });
    },
    addSeller() {
      this.addlistinfo.sellerIdList = this.sellers
        .split(',')
        .filter((item) => item !== '');
    },
    delSeller(index) {
      this.addlistinfo.sellerIdList.splice(index, 1);
      let arr = [];
      this.addlistinfo.list.forEach((i) => {
        arr.push(i.goodsId);
      });
      this.sellers = arr.join(',');
    },
    // 获取展馆列表信息
    getSiteInfo() {
      this.$axios.get(this.$api.siteArea.listTest, {
        params: {
          siteType: 1,
        },
      }).then((res) => {
        if (res.code === 0) {
          res.data.forEach((element) => {
            if (element.children.length) {
              element.children.forEach((items) => {
                if (items.children.length) {
                  items.children.forEach((item) => {
                    if (!item.children.length) {
                      delete item.children;
                    }
                  });
                } else {
                  delete items.children;
                }
              });
            } else {
              delete element.children;
            }
          });
          this.sitelist = res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add__list {
  width: calc(100% - 30px);
  // height: 355px;
  // margin: 0 15px;
  padding: 15px;
  .content {
    width: 100%;
    .h2 {
      padding: 10px 0;
      font-size: 16px;
    }
  }
  .hei30 {
    min-height: 300px;
  }
  .absol {
    position: absolute;
    top: 40px;
    right: 30px;
  }
  .hei75 {
    height: 75px;
  }
}

.goods__name {
    width: 82px;
    float: left;
    & p {
      text-align: right;
      color: #8e8e8e;
      overflow: hidden;
    }
  }

.goods__list {
  margin-top: 10px;
  height: 230px;
  .list__content {
    float: left;
    width: 370px;
    height: 220px;
    border: 1px solid rgba(151, 151, 151, 1);
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 2px;
    ul > li {
      display: flex;
      cursor: move;
    }
    & p {
      width: 200px;
      height: 20px;
      display: inline-block;
      margin-right: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    & span {
      display: inline-block;
      cursor: pointer;
      margin-right: 10px;
      white-space: nowrap;
    }
  }
}
</style>
