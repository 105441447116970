var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "scrape-together-pre" }, [
    _c(
      "div",
      { staticClass: "box" },
      [
        _vm.obj?.resource === 1
          ? _c("i", { staticClass: "el-icon-s-shop" })
          : _vm._e(),
        _vm.obj?.resource === 1
          ? _c("span", [
              _vm._v(
                " 加购满" +
                  _vm._s(_vm.obj?.AdditionalPurchaseThreshold) +
                  "元，即可获得" +
                  _vm._s(_vm.obj?.discountCouponNum) +
                  "元优惠劵 "
              ),
            ])
          : _vm._e(),
        _vm.obj?.resource === 2
          ? _c("span", { staticClass: "together" }, [
              _vm._v(
                "活动期每满" +
                  _vm._s(_vm.obj?.discountsCondition) +
                  "-" +
                  _vm._s(_vm.obj?.discountsValue) +
                  "，快来选购吧"
              ),
            ])
          : _vm._e(),
        _vm.obj?.resource === 1
          ? _c("el-button", [_vm._v(" 领券 ")])
          : _vm._e(),
        _vm.obj?.resource === 2
          ? _c("el-button", [_vm._v(" 购物车结算 ")])
          : _vm._e(),
      ],
      1
    ),
    _c("div", { staticClass: "bt-drag-title" }, [
      _vm._v(" " + _vm._s(_vm.obj.showTitle) + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }