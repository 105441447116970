<template>
  <!-- 频道页的商品模块组件 -->
  <div class="product_module_pre">
    <div
      class="content_view"
      :style="changeStyleContent()"
    >
      <div
        v-for="(item, index) in obj.moduleList"
        :key="index"
        :class="changeClassContent()"
        :style="changeStyle(item)"
      >
        <div class="title">
          {{ item.title }}
        </div>
        <div
          v-if="item.text"
          class="text"
        >
          {{ item.text }}
        </div>
        <el-carousel
          :class="changeClass(item.categoryInfor.entryChartStyle)"
          direction="vertical"
          :autoplay="item.categoryInfor.dynamicEffect === 2"
        >
          <el-carousel-item
            v-for="items in item.categoryInfor.shopGoodsPC"
            :key="items.goodsImg"
            class="img-item"
          >
            <div
              v-for="(value, indexss) in items.list"
              :key="'ss' + indexss"
              class="img-box"
            >
              <img
                :src="value.goodsImg"
                alt=""
              />
              <div
                v-if="item.categoryInfor.entryChartStyle === 1"
                class="img-tips"
              >
                <span class="img-tips-title">{{ value.goodsName }}</span>
                <span class="img-tips-button">GO</span>
              </div>
              <div
                v-if="
                  item.categoryInfor.entryChartStyle !== 6
                    && item.categoryInfor.entryChartStyle !== 3
                    && (item.categoryInfor.groupType === 2 || item.categoryInfor.groupType === 3)
                "
                class="img-price"
              >
                ￥{{ value.goodsPrice }}
              </div>
              <div
                v-if="
                  item.categoryInfor.entryChartStyle === 6
                    || item.categoryInfor.entryChartStyle === 3
                "
                class="img-price-more"
              >
                <div class="title">
                  {{ value.goodsName }}
                </div>
                <div
                  v-if="item.categoryInfor.groupType === 2 || item.categoryInfor.groupType === 3"
                  class="price"
                >
                  ￥{{ value.goodsPrice }}
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- 左上角标题 -->
    <div class="bt-drag-title">
      {{ obj.showTitle }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'product-module-pre',
  components: {},
  props: {
    obj: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      setIntervalList: this.obj.moduleList.map(() => {
        return {
          start: 0,
        };
      }),
      settime: null,
    };
  },
  watch: {},
  mounted() {
    // 判断是否有商品店铺模块且是否选择了模块入口图
    if (this.obj.moduleList.length > 0 && this.obj.moduleList[0].categoryId) {
      this.obj.moduleList.forEach((item) => {
        this.geiItemInfor(item);
      });
    }
  },
  destroyed() {
    // this.$eventBus.$off('eventBus_setInterval');
    // this.settime = null;
  },
  methods: {
    // 获取商品店铺模块的样式
    geiItemInfor(item) {
      this.$axios
        .get(this.$api.subjectChannel.entrance_preview, {
          params: {
            contentPageNum: 1,
            contentPageSize: 99,
            id: item.categoryId,
          },
        })
        .then((res) => {
          if (res.code === 0) {
            const newdata = item;
            newdata.categoryName = res.data.name;
            newdata.categoryInfor = {
              supplierName: res.data.name, // 入口名称
              principalType: res.data.appliedPosition, // 应用位置
              advertisingSpace: res.data.positionId, // 广告位
              entryChartStyle: res.data.entranceStyle, // 入口图样式 1一行一个 2一行两个 3一行三个 4一行四个 5一行五个 6一行六个
              groupType: res.data.groupType, // 分组类型 1.店铺 2.商品s
              shopGoods: res.data.contents.records.map((value) => {
                return {
                  goodsId: value.contentId, // 商品或者店铺id
                  goodsName: value.name, // 商品或者店铺名称
                  goodsImg: value.entranceImg, // 商品或者店铺图片
                  goodsPrice: res.data.groupType === 3 ? value.lowPromPrice : value.price, // 商品价格
                };
              }), // 店铺商品组
              shopGoodsPC: [], // 店铺商品组PC使用的数据
              dynamicEffect: res.data.dynamicEffect, // 动态效果
            };
            // 计算数组长度需要增加的数量
            const remainder = item.categoryInfor.shopGoods.length
            % item.categoryInfor.entryChartStyle;
            let num = remainder ? item.categoryInfor.entryChartStyle - remainder : 0;
            let data = item.categoryInfor.shopGoods.slice(0, num);
            newdata.categoryInfor.shopGoods = item.categoryInfor.shopGoods.concat(data);
            // console.log(num);
            let lang = item.categoryInfor.shopGoods.length / item.categoryInfor.entryChartStyle;
            for (let index = 0; index < lang; index += 1) {
              let list = {
                list: item.categoryInfor.shopGoods.slice(
                  index * item.categoryInfor.entryChartStyle,
                  (index + 1) * item.categoryInfor.entryChartStyle,
                ),
              };
              newdata.categoryInfor.shopGoodsPC = item.categoryInfor.shopGoodsPC.concat(list);
            }
            // 解决elementUI的bug  2个轮播的时候会出现bug  所以当数据为2的时候要复制一份数据进去
            if (newdata.categoryInfor.shopGoodsPC.length === 2) {
              newdata.categoryInfor.shopGoodsPC = newdata.categoryInfor.shopGoodsPC.concat(
                newdata.categoryInfor.shopGoodsPC,
              );
            }
          }
        });
    },
    // 一行几个模块
    changeClassContent() {
      let classs = 'content_view_item';
      if (this.obj.resource === 1) {
        classs += ' type-one';
      }
      if (this.obj.resource === 2) {
        classs += ' type-tow';
      }
      if (this.obj.resource === 3) {
        classs += ' type-three';
      }
      return classs;
    },
    // 设置基础样式
    changeStyleContent() {
      let style = '';
      if (this.obj.bgColor) {
        style += `background-color:${this.obj.bgColor};`;
      }
      if (this.obj.bMargin) {
        style += 'margin: 12px;';
      }
      if (this.obj.bPadding) {
        style += 'padding: 12px;';
      }
      return style;
    },
    // 根据类型判断添加style
    changeStyle(item) {
      if (item.titleImg) {
        return `background-image:url(${item.titleImg})`;
      }
      // } if (item.bgColor) {
      //   console.log(item.bgColor);
      //   return `background-color:${item.bgColor}`;
      // }
      return '';
    },
    // 根据类型判断添加class
    changeClass(type) {
      if (type === 1) {
        return 'content-img img-one';
      }
      if (type === 2) {
        return 'content-img img-tow';
      }
      if (type === 3) {
        return 'content-img img-three';
      }
      if (type === 4) {
        return 'content-img img-four';
      }
      if (type === 6) {
        return 'content-img img-six';
      }
      return '';
    },
  },
};
</script>

<style scoped lang="scss">
.product_module_pre {
  background: #f7f5f5;
  .type-one {
      width: 100%;
      .img-one {
        ::v-deep .el-carousel__container {
          height: 220px;
        }
      }
      .img-tow {
        ::v-deep .el-carousel__container {
          height: 120px;
        }
      }
      .img-three {
        ::v-deep .el-carousel__container {
          height: 200px;
        }
      }
      .img-four {
        :deep(.el-carousel__container)  {
          height: 100px;
        }
      }
      .img-six {
        ::v-deep .el-carousel__container {
          height: 420px;
        }
      }
    }
    .type-tow {
      width: 48.5%;
      .img-one {
        ::v-deep .el-carousel__container {
          height: 100px;
        }
        .img-price {
            font-size: 16px !important;
            padding: 0 5px !important;
          }
      }
      .img-tow {
        ::v-deep .el-carousel__container {
          height: 100px;
        }
      }
      .img-three {
        ::v-deep .el-carousel__container {
          height: 120px;
        }
      }
      .img-four {
        ::v-deep .el-carousel__container {
          height: 60px;
        }
      }
      .img-six {
        ::v-deep .el-carousel__container {
          height: 320px;
        }
      }
    }
    .type-three {
      width: 31.5%;
      .img-one {
        ::v-deep .el-carousel__container {
          height: 70px;
        }
        .img-tips {
            .img-tips-title {
              display: none !important;
            }
            .img-tips-button {
              display: block;
              margin-top: 5px;
            }
          }
          .img-price {
            font-size: 10px !important;
            padding: 0 5px !important;
          }
      }
      .img-tow {
        ::v-deep .el-carousel__container {
          height: 60px;
        }
      }
      .img-three {
        ::v-deep .el-carousel__container {
          height: 120px;
        }
      }
      .img-four {
        ::v-deep .el-carousel__container {
          height: 50px;
        }
      }
      .img-six {
        ::v-deep .el-carousel__container {
          height: 240px;
        }
      }
    }
  .content_view {
    border-radius: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // padding: 10px;
    // background: #f7f5f5;

    .content_view_item {
      border-radius: 10px;
      padding: 10px;
      overflow: hidden;
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: 10px;
      background-color: #f4f4f4;
      .el-carousel__item {
        height: 100%;
      }

      .title {
        height: 30px;
        font-size: 16px;
        font-weight: 500;
        color: #111111;
        line-height: 30px;
      }

      .text {
        height: 25px;
        font-size: 14px;
        font-weight: 400;
        color: #111111;
        line-height: 25px;
      }
      .content-img {
      position: relative;
      width: 100%;
      overflow: hidden;
      ::v-deep .el-carousel__indicators{
        display: none;
      }
      .img-item {
        width: 100%;
        .img-box {
          position: relative;
          .img-price {
            position: absolute;
            bottom: 4px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 10px;
            font-weight: 500;
            color: #ff0000;
            line-height: 14px;
            background: #ffebea;
            border-radius: 7px;
            padding: 0 5px;
          }
          .img-price-more {
            position: absolute;
            bottom: 4px;
            left: 0;
            .title {
              padding: 3px 5px 0;
              font-size: 14px;
              font-weight: 400;
              color: #111111;
              line-height: 18px;
              height: 36px;
              overflow: hidden;
            }
            .price {
              margin-top: 10px;
              font-size: 16px;
              font-weight: bold;
              color: #ff4743;
              line-height: 17px;
            }
          }
          img {
            width: 100%;
            height: 100%;
            border-radius: 12px;
          }
        }
      }
    }
    .img-one {
      .img-item {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .img-box {
          height: 100%;
          width: 100%;
          display: flex;
          .img-price {
            position: absolute;
            bottom: 4px;
            left: 23%;
            margin-top: 10px;
            font-size: 24px;
            color: #ff4743;
            padding: 5px 10px;
            line-height: 24px;
            border-radius: 7px;
          }
          img {
            height: 100%;
            width: 50%;
          }
          .img-tips {
            padding: 0 10px;
            // display: flex;
            // flex-wrap: wrap;
            .img-tips-title {
              overflow: hidden;
              display: block;
              width: 100%;
              font-size: 12px;
              font-weight: 400;
              color: #111111;
              line-height: 17px;
              max-height: 34px;
              margin-bottom: 10px;
            }
            .img-tips-button {
              border-radius: 12px;
              border: 1px solid #999999;
              padding: 3px 15px;
              font-weight: 600;
              color: #333333;
            }
          }
        }
      }
    }
    .img-tow {
      .img-item {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .img-box {
          width: 48%;
          height: 100%;
        }
      }
    }
    .img-three {
      .img-item {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .img-box {
          width: 30%;
          margin-bottom: 10px;
          height: 100%;
          img {
            width: 100%;
            height: calc(100% - 70px);
          }
        }
      }
    }
    .img-four {
      .img-item {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .img-box {
          width: 23%;
          height: 100%;
        }
      }
    }
    .img-six {
      .img-item {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .img-box {
          width: 31%;
          margin-bottom: 10px;
          height: calc(50% - 20px);
          img {
            width: 100%;
            height: calc(100% - 70px);
          }
        }
      }
    }
    }

  }
}
</style>
