var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "add_navigation_list addNavigation" }, [
    _c(
      "div",
      { staticClass: "category_title_img" },
      [
        _c("span", [_vm._v("标题图片:")]),
        _c("CutImgUp", {
          attrs: {
            url: _vm.imageUrlList,
            "auto-crop-width": 750,
            "auto-crop-height": 750,
            "max-num": 1,
          },
          on: { change: _vm.onSuccess },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "category__name" },
      [
        _c("span", [_vm._v("导航" + _vm._s(_vm.categoryNum) + ":")]),
        _c("el-input", {
          staticClass: "category__input",
          attrs: {
            placeholder: "请输入导航标题，限10字以内",
            maxlength: "10",
            size: "mini",
          },
          model: {
            value: _vm.goodsListInfo.name,
            callback: function ($$v) {
              _vm.$set(
                _vm.goodsListInfo,
                "name",
                typeof $$v === "string" ? $$v.trim() : $$v
              )
            },
            expression: "goodsListInfo.name",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "category__name" },
      [
        _c("span", [_vm._v("分组:")]),
        _c(
          "el-select",
          {
            staticStyle: { width: "300px" },
            attrs: { filterable: "", size: "mini" },
            model: {
              value: _vm.goodsListInfo.group_id,
              callback: function ($$v) {
                _vm.$set(_vm.goodsListInfo, "group_id", $$v)
              },
              expression: "goodsListInfo.group_id",
            },
          },
          _vm._l(_vm.groupOptions, function (item, index) {
            return _c("el-option", {
              key: index,
              attrs: { label: item.groupingTitle, value: item.id },
            })
          }),
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "category__name" },
      [
        _c("span", [_vm._v("数量:")]),
        _c("el-input", {
          staticClass: "category__input",
          attrs: {
            placeholder: "请输入导航标题，限10字以内",
            maxlength: "10",
            size: "mini",
          },
          model: {
            value: _vm.goodsListInfo.number,
            callback: function ($$v) {
              _vm.$set(
                _vm.goodsListInfo,
                "number",
                typeof $$v === "string" ? $$v.trim() : $$v
              )
            },
            expression: "goodsListInfo.number",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "nav_btn" },
      [
        _c(
          "el-button",
          {
            attrs: { size: "mini", type: "primary", loading: _vm.addLoading },
            on: { click: _vm.addGoods },
          },
          [_vm._v(" 立即添加商品 ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }