var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "add__goods_prop" },
    [
      _c(
        "el-button",
        {
          attrs: { type: "primary", size: "small" },
          on: { click: _vm.addGroup },
        },
        [_vm._v(" 添加大促分组 ")]
      ),
      _c(
        "div",
        { staticClass: "m_b_10" },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.outerGroupTableData, border: "" } },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "大促ID", "min-width": "110" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "groupingTitle",
                  label: "大促活动名称",
                  "min-width": "110",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "分组操作", width: "110", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            ref: "group" + scope.$index,
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.toDeleteGroup(
                                  scope.$index,
                                  _vm.outerGroupTableData
                                )
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.innerGroupVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                width: "30%",
                title: "添加大促",
                visible: _vm.innerGroupVisible,
                "close-on-click-modal": false,
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.innerGroupVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "50%", marginBottom: "20px" },
                      attrs: {
                        placeholder: "请输入活动名称",
                        size: "mini",
                        clearable: "",
                      },
                      model: {
                        value: _vm.promName,
                        callback: function ($$v) {
                          _vm.promName =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "promName",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: {
                          click: function ($event) {
                            return _vm.getGroupList()
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.groupLoading,
                          expression: "groupLoading",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.innerGroupTableData,
                        border: "",
                        "row-key": _vm.getGroupRowKey,
                        "element-loading-text": "拼命加载中",
                      },
                      on: {
                        "selection-change": _vm.handleGroupSelectionChange,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          width: "55",
                          align: "center",
                          "reserve-selection": true,
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "id",
                          label: "大促ID",
                          "min-width": "110",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "groupingTitle",
                          label: "大促活动名称",
                          "min-width": "110",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.groupPagination.page,
                      "page-sizes": [10, 20, 30, 50],
                      "page-size": _vm.groupPagination.page_count,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.groupPagination.item_total,
                    },
                    on: {
                      "size-change": _vm.handleGroupSizeChange,
                      "current-change": _vm.handleGroupCurrentChange,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.onGroupSure },
                    },
                    [_vm._v(" 确 定 ")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }