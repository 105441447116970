// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.47.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.right-coupon {\n  padding: 20px 10px 50px 0;\n}\n.right-coupon .type-table {\n  padding: 0 10px;\n  margin-bottom: 20px;\n}\n.right-coupon .item {\n  line-height: 24px;\n  margin-bottom: 15px;\n}\n.right-coupon .el-radio__label, .right-coupon .el-checkbox__label {\n  font-size: 12px;\n}\n.right-coupon .name-box .el-radio {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  margin-bottom: 5px;\n}\n.right-coupon .name-box .el-radio__label {\n  white-space: initial;\n  line-height: 20px;\n}\n.right-coupon .hanle {\n  margin: 0 5px;\n  cursor: pointer;\n}", ""]);
// Exports
exports.locals = {
	"theme": "#30B08F",
	"menuBg": "#304156",
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
