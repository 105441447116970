<template>
  <div
    class="list-goods"
    :style="{'border-bottom': obj.bMargin?'10px solid':'','background-color': obj.bgColor,'padding': obj.bPadding?'16px 26px':'16px 0'}"
  >
    <slot></slot>
    <div
      v-for="(item) in list"
      :key="item.id"
      class="list-goods-item"
    >
      <img :src="item.imageUrls ? item.imageUrls.split(',')[0] : ''" />
      <div>
        <p class="good-name">
          {{ item.name }}
        </p>
        <p class="good-price">
          ￥ {{ item.price }}
        </p>
      </div>
    </div>
    <div class="bt-drag-title">
      {{ obj.showTitle }}
    </div>
  </div>
</template>

<script>
/*
  list: 数据
  bMargin: 是否有行边距   默认没有
  bgColor: 行边距的颜色   默认#fff
  bPadding: 边缘  默认没有
  {
  bMargin:false,
  bgColor:#fff,
  bPadding:false,
  list:[]
  }
  * * */
export default {
  props: {
    obj: {
      type: Object,
      default: () => ({
        pageSize: '10',
        bMargin: false,
        bgColor: '#fff',
        bPadding: false,
        title: '推荐商品',
        sortType: 1,
        siteList: [],
        backendCategorys: [],
        sellerIdList: [],
      }),
    },
  },
  data() {
    return {
      list: [],
      preurl: 'https://img.hzanchu.com/acimg/e21aab713b254226eb46882a65baf1c2.jpg?x-oss-process=image/resize,w_300',
    };
  },
  watch: {
    'obj.sortType': function (val) {
      this.getGoodsList();
    },
    'obj.siteList': function (val) {
      this.getGoodsList();
    },
    'obj.backendCategorys': function (prev, old) {
      console.log(prev);
      if (prev.length !== old.length) {
        this.getGoodsList();
      }
    },
    'obj.sellerIdList': function (val) {
      this.getGoodsList();
    },
  },
  mounted() {
    this.getGoodsList();
  },
  methods: {
    getGoodsList() {
      this.$axios.post(this.$api.selectGoodsInfo, {
        pageSize: Number(this.obj.pageSize),
        startPage: 0,
        sortType: this.obj.sortType === 1 ? '' : 'soldDESC',
        sellerIdList: this.obj.sellerIdList,
        sellerProvince: this.obj.siteList[0] || '',
        sellerCity: this.obj.siteList[1] || '',
        sellerDistinct: this.obj.siteList[2] || '',
        backendCategorys: this.obj.backendCategorys.map((item) => item[item.length - 1]),
      }).then((res) => {
        if (res.code !== 0) {
          return;
        }
        this.list = res.data;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.list-goods{
  box-sizing: border-box;
  display: flex;
  flex-wrap:wrap;
  justify-content:space-between;
  .list-goods-item{
    width:220px;
    background:#fff;
    margin-bottom:10px;
    img{
      width:220px;
      height:220px;
    }
    .good-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .good-price {
      font-size: 20px;
      color: red;
      margin-top: 10px;
    }
    div{
      box-sizing:border-box;
      padding:10px 12px;
      p{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        font-size:14px;
      }
      span{
        color:red
      }
    }
  }
}
</style>
