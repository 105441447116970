var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "appointmentTool" },
    [
      _c(
        "el-row",
        {
          staticStyle: { "margin-bottom": "20px" },
          attrs: { type: "flex", align: "middle", gutter: 20 },
        },
        [
          _c(
            "el-col",
            { staticStyle: { "text-align": "left" }, attrs: { span: 5 } },
            [
              _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
              _vm._v("预约活动："),
            ]
          ),
          _c(
            "el-col",
            { attrs: { span: 19 } },
            [
              _c("el-input", {
                staticStyle: { width: "360px" },
                attrs: {
                  maxlength: "20",
                  "show-word-limit": "",
                  size: "small",
                  placeholder: "请输入活动名称",
                },
                model: {
                  value: _vm.info.list[_vm.activeIndex].activityName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.info.list[_vm.activeIndex],
                      "activityName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "info.list[activeIndex].activityName",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticStyle: { "margin-bottom": "20px" },
          attrs: { type: "flex", align: "top", gutter: 20 },
        },
        [
          _c(
            "el-col",
            { staticStyle: { "text-align": "left" }, attrs: { span: 5 } },
            [
              _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
              _vm._v("状态图片："),
            ]
          ),
          _c(
            "el-col",
            { attrs: { span: 19 } },
            [
              _c(
                "el-col",
                { staticStyle: { "text-align": "left" }, attrs: { span: 12 } },
                [
                  _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                    _vm._v("预约前图片"),
                  ]),
                  _c("UpImage", {
                    attrs: {
                      imageList: _vm.info.list[_vm.activeIndex].imageFront,
                    },
                    on: {
                      "update:imageList": function ($event) {
                        return _vm.$set(
                          _vm.info.list[_vm.activeIndex],
                          "imageFront",
                          $event
                        )
                      },
                      "update:image-list": function ($event) {
                        return _vm.$set(
                          _vm.info.list[_vm.activeIndex],
                          "imageFront",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { "text-align": "left" }, attrs: { span: 12 } },
                [
                  _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                    _vm._v("预约后图片"),
                  ]),
                  _c("UpImage", {
                    attrs: {
                      imageList: _vm.info.list[_vm.activeIndex].imageBack,
                    },
                    on: {
                      "update:imageList": function ($event) {
                        return _vm.$set(
                          _vm.info.list[_vm.activeIndex],
                          "imageBack",
                          $event
                        )
                      },
                      "update:image-list": function ($event) {
                        return _vm.$set(
                          _vm.info.list[_vm.activeIndex],
                          "imageBack",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticStyle: { "margin-bottom": "20px" },
          attrs: { type: "flex", align: "middle", gutter: 20 },
        },
        [
          _c(
            "el-col",
            { staticStyle: { "text-align": "left" }, attrs: { span: 8 } },
            [_vm._v("需要用户关注公众号：")]
          ),
          _c(
            "el-col",
            { attrs: { span: 16 } },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#1890ff",
                  "inactive-color": "#d1d6dd",
                  "active-value": 1,
                  "inactive-value": 0,
                },
                model: {
                  value:
                    _vm.info.list[_vm.activeIndex].needFollowOfficialAccounts,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.info.list[_vm.activeIndex],
                      "needFollowOfficialAccounts",
                      $$v
                    )
                  },
                  expression:
                    "info.list[activeIndex].needFollowOfficialAccounts",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticStyle: { "margin-bottom": "20px" },
          attrs: { type: "flex", align: "top", gutter: 20 },
        },
        [
          _c(
            "el-col",
            { staticStyle: { "text-align": "left" }, attrs: { span: 6 } },
            [
              _c("span", { staticStyle: { color: "red" } }, [_vm._v(" ")]),
              _vm._v("消息提醒："),
            ]
          ),
          _c("el-col", { attrs: { span: 18 } }, [
            _c(
              "div",
              [
                _c("el-switch", {
                  attrs: {
                    "active-color": "#1890ff",
                    "inactive-color": "#d1d6dd",
                    "active-value": 1,
                    "inactive-value": 0,
                  },
                  model: {
                    value: _vm.info.list[_vm.activeIndex].isNotify,
                    callback: function ($$v) {
                      _vm.$set(_vm.info.list[_vm.activeIndex], "isNotify", $$v)
                    },
                    expression: "info.list[activeIndex].isNotify",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm.info.list[_vm.activeIndex].isNotify === 1
        ? _c(
            "div",
            [
              _c(
                "el-row",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { type: "flex", align: "top", gutter: 20 },
                },
                [
                  _c("el-col", {
                    staticStyle: { "text-align": "left" },
                    attrs: { span: 6 },
                  }),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.myMsgType,
                            callback: function ($$v) {
                              _vm.myMsgType = $$v
                            },
                            expression: "myMsgType",
                          },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("el-checkbox", { attrs: { label: "1" } }, [
                                _vm._v("站内信服务通知"),
                              ]),
                              _c("el-checkbox", { attrs: { label: "2" } }, [
                                _vm._v("push"),
                              ]),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "14px",
                                    color: "#F59A23",
                                    margin: "10px 0 15px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "您预约的XX活动将于￥活动开始时间￥开始~"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("el-checkbox", { attrs: { label: "3" } }, [
                                _vm._v(" 微信公众号 "),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { type: "flex", align: "middle", gutter: 20 },
                },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { span: 6 },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v("活动开始时间："),
                    ]
                  ),
                  _c("el-col", { attrs: { span: 18 } }, [
                    _c(
                      "div",
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "datetime",
                            "value-format": "timestamp",
                            placeholder: "请选择活动开始时间",
                            size: "mini",
                          },
                          model: {
                            value:
                              _vm.info.list[_vm.activeIndex].activityStartTime,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.info.list[_vm.activeIndex],
                                "activityStartTime",
                                $$v
                              )
                            },
                            expression:
                              "info.list[activeIndex].activityStartTime",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { type: "flex", align: "middle", gutter: 20 },
                },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { span: 6 },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v("提醒时间："),
                    ]
                  ),
                  _c("el-col", { attrs: { span: 18 } }, [
                    _c(
                      "div",
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "datetime",
                            "value-format": "timestamp",
                            placeholder: "请选择提醒时间",
                            size: "mini",
                          },
                          model: {
                            value: _vm.info.list[_vm.activeIndex].notifyTime,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.info.list[_vm.activeIndex],
                                "notifyTime",
                                $$v
                              )
                            },
                            expression: "info.list[activeIndex].notifyTime",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { type: "flex", align: "middle", gutter: 20 },
                },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { span: 6 },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v("消息跳转类型："),
                    ]
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "220px" },
                          attrs: {
                            size: "mini",
                            placeholder: "请选择消息跳转类型",
                          },
                          model: {
                            value: _vm.info.list[_vm.activeIndex].jumpType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.info.list[_vm.activeIndex],
                                "jumpType",
                                $$v
                              )
                            },
                            expression: "info.list[activeIndex].jumpType",
                          },
                        },
                        _vm._l(_vm.selectOptions, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.notShowInput.includes(
                _vm.info.list[_vm.activeIndex].jumpType
              )
                ? _c(
                    "el-row",
                    {
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: { type: "flex", align: "middle", gutter: 20 },
                    },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "left" },
                          attrs: { span: 6 },
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v("跳转ID或链接："),
                        ]
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "220px" },
                            attrs: {
                              size: "mini",
                              placeholder: "请输入消息跳转ID或链接",
                            },
                            model: {
                              value: _vm.info.list[_vm.activeIndex].jumpValue,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.info.list[_vm.activeIndex],
                                  "jumpValue",
                                  $$v
                                )
                              },
                              expression: "info.list[activeIndex].jumpValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }