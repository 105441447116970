<template>
  <!-- 加购凑单组件 -->
  <div class="style-list-tool">
    <div class="style-tips">
      <span class="name-box-tip">1、有效时间不填默认永久有效;</span>
      <span class="name-box-tip">2、组件默认吸底，一个页面只能有一个生效的凑单组件;</span>
      <span class="name-box-tip">3、使用带有凑单组件的专题模板时，需要在链接后拼上[&reloadAfterPop=1]。</span>
    </div>
    <el-form
      :model="info"
      label-width="120px"
    >
      <el-form-item
        class="name-box"
        label="应用场景："
      >
        <el-radio-group v-model="info.resource">
          <el-radio :label="1">
            凑单领券
          </el-radio>
          <el-radio :label="2">
            凑单满减
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label-width="100px"
        label="关联活动："
        prop="categoryId"
      >
        <el-select
          v-model="info.associatedActivityID"
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键词搜索活动"
          :remote-method="remoteMethod"
          :loading="loading"
          style="width: 90%"
          @change="changeCategory"
        >
          <el-option
            v-for="item in categoryOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <div v-if="info.resource == 1">
        <el-form-item
          label-width="100px"
          label="加购门槛："
          show-word-limit
        >
          <el-input
            v-model="info.AdditionalPurchaseThreshold"
            style="width: 90%"
            placeholder="请输入加购门槛"
          >
            <template slot="suffix">
              ￥
            </template>
          </el-input>
        </el-form-item>
        <el-form-item
          class="name-box"
          label="券类型："
        >
          <el-radio-group v-model="info.couponType">
            <el-radio :label="2">
              券
            </el-radio>
            <el-radio :label="1">
              券包
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label-width="100px"
          label="获得优惠券："
          show-word-limit
        >
          <el-input
            v-model="info.discountCoupon"
            style="width: 90%"
            placeholder="请输入券包ID"
            @input="input"
          />
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
// import loadImgOssUp from '@/components/common/loadImgOssUp';

export default {
  name: 'style-list-tool',
  components: { },
  props: {
    info: {
      type: Object,
      default: () => ({
        content: '',
        richBgColor: '#fff',
      }),
    },
  },
  data() {
    return {
      loading: false, //
      loadingList: false,
      categoryOptionsList: [],
      categoryOptions: [],
    };
  },
  watch: {},
  mounted() {
    // console.log(this.info);
    // 初始化活动列表
    this.getStyle();
  },
  methods: {
    // 搜索活动
    remoteMethod(query) {
      this.getStyle(query);
    },
    // 选中活动
    changeCategory(item) {
      // console.log(item);
      // 获取活动ID对应的名字，方便前端回显查看
      let data = this.categoryOptions.filter((items) => {
        return items.value === item;
      })[0];
      this.info.associatedActivityName = data.label;
      this.info.discountsCondition = data.discountsCondition;
      this.info.discountsValue = data.discountsValue;
      console.log(data);
    },
    // 搜索活动
    remoteMethodList(query) {
      this.getStyle(query);
    },
    // // 选中样式
    // changeCategoryList(item) {
    //   // console.log(item);
    //   // 获取样式ID对应的名字，方便前端回显查看
    //   let newData = item;
    //   newData.categoryName = this.categoryOptionsList.filter((items) => {
    //     return items.value === item.categoryId;
    //   })[0].label;
    // },
    // 获取活动的列表
    getStyle(title) {
      this.shoploading = true;
      this.shoploadingList = true;
      this.$axios
        .get(this.$api.tradeProm.activity_list, {
          params: {
            currPage: 1,
            pageSize: 20,
            type: 6, // 6、跨店满减
            schedule: 4,
            status: 1,
            title,
          },
        })
        .then((res) => {
          if (res.code === 0) {
            this.categoryOptions = [];
            this.categoryOptionsList = [];
            let data = res.data.records.map((item) => {
              return {
                ...item,
                label: item.title,
                value: item.id,
              };
            });
            this.categoryOptions = this.categoryOptions.concat(data);
            this.categoryOptionsList = this.categoryOptionsList.concat(data);
            this.shoploading = false;
            this.shoploadingList = false;
            // 将已选的数据加上方便回显
            // 选中的时候查看筛选数据源种是否有这条数据  没有的话根据ID查找数据源种的数据塞进去
            let index = this.categoryOptions.findIndex(
              (item) => item.value === this.info.categoryId,
            );
            if (this.info.categoryId && index === -1) {
              this.categoryOptions = this.categoryOptions.concat({
                label: this.info.categoryName,
                value: this.info.categoryId,
              });
            }

            // if (this.info.associatedActivityID) {
            //   // 判断所选的样式是否已存在数据源里面
            //   let newData = this.info.categoryIdList.filter((item) => {
            //     return this.categoryOptionsList.findIndex((items) => items.value
            //     === item.categoryId) === -1;
            //   });
            //   let list = newData.map((item) => {
            //     return {
            //       label: item.categoryName,
            //       value: item.categoryId,
            //     };
            //   });
            //   // 去重操作
            //   let uniqueSourceData = [...new Set(list.map((item) => JSON.stringify(item)))]
            //     .map((item) => JSON.parse(item));
            //   this.categoryOptionsList = this.categoryOptionsList.concat(uniqueSourceData);
            // }
          } else {
            this.$message(res.error_msg);
          }
        });
    },
    // 获取优惠券详情
    input(id) {
      // 优惠券
      if (this.info.couponType === 2) {
        this.$axios({
          url: this.$api.coupon.template_detail,
          params: {
            id,
          },
        })
          .then((res) => {
            if (res.code !== 0) return;
            this.info.discountCouponNum = res.data.discountsValue;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        // 优惠券包
        this.$axios({
          url: this.$api.coupon.package_detail,
          params: {
            id,
          },
        })
          .then((res) => {
            if (res.code !== 0) return;
            console.log(res.data.couponDtoList);
            this.info.discountCouponNum = res.data.couponDtoList.reduce(
              (prev, cur) => {
                console.log(cur.discountsValue);
                return prev + Number(cur.discountsValue);
              },
              0,
            );
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.style-list-tool {
  .style-tips{
    display: flex;
    flex-wrap: wrap;
    padding: 10px 20px;
    .name-box-tip{
      color: #F59A23;
      font-size: 14px;
      // font-weight: bold;
      line-height: 24px;
    }
  }

  .list-item {
    position: relative;
    background: #f5f5f5;
    width: calc(100% - 120px);
    margin-left: 60px;
    padding-right: 10px;
    border-radius: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;

    .title {
      margin-bottom: 0;
    }

    .el-icon-plus {
      position: absolute;
      right: -30px;
      bottom: 10px;
      font-size: 20px;
      color: #1890ff;
      cursor: pointer;
    }
    .actions_add {
      position: absolute;
      right: -45px;
      bottom: 10px;
      font-size: 20px;
      color: #1890ff;
      cursor: pointer;
    }

    .actions_b {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 20px;
      color: #1890ff;
      cursor: pointer;
    }
    .action-move {
      position: absolute;
      right: 55px;
      top: 10px;
      font-size: 20px;
      color: #1890ff;
      cursor: pointer;
    }
    .name-box-tip {
      color: #f59a23;
    }
  }
}
</style>
