<template>
  <!-- 加购凑单组件 -->
  <div
    class="scrape-together-pre"
  >
    <div class="box">
      <i
        v-if="obj?.resource === 1"
        class="el-icon-s-shop"
      ></i>
      <span v-if="obj?.resource === 1">
        加购满{{ obj?.AdditionalPurchaseThreshold }}元，即可获得{{ obj?.discountCouponNum }}元优惠劵
      </span>
      <span
        v-if="obj?.resource === 2"
        class="together"
      >活动期每满{{ obj?.discountsCondition }}-{{ obj?.discountsValue }}，快来选购吧</span>
      <el-button v-if="obj?.resource === 1">
        领券
      </el-button>
      <el-button v-if="obj?.resource === 2">
        购物车结算
      </el-button>
    </div>
    <!-- 左上角标题 -->
    <div class="bt-drag-title">
      {{ obj.showTitle }}
    </div>
  </div>
</template>

<script>
// 样式模板
export default {
  name: 'scrape-together-pre',
  components: { },
  props: {
    obj: {
      type: Object,
      default: () => ({
        content: '',
      }),
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.scrape-together-pre {
  background: #f7f5f5;
  // min-height: 64px;
  ::v-deep .el-tabs__nav-wrap::after{
    display: none;
  }
  .box{
    height: 60px;
    background: #efefef;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    ::v-deep .el-icon-s-shop{
      color: red;
      font-size: 40px;
      // background: #f5f7f8;
    }
    .together{
      margin-left: 100px
    }
  }
}
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 2px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(219, 6, 6, 0.4);
  background: #f5f7f8;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  // border-radius: 10px;
  background: #fff;
  cursor: pointer;
}
</style>
