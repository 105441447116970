<template>
  <div class="page-tool">
    <!-- 页面配置背景色 -->
    <div class="tool-item">
      <span class="type__name">背景色:</span>
      <div class="tool__content">
        <el-input
          v-model="info.config.backgroundColor"
          class="tool-bg"
        />
        <div class="color__show">
          <div
            class="color__content"
            :style="{background: info.config.backgroundColor}"
          ></div>
        </div>
      </div>
    </div>
    <!-- 页面配置背景图 -->
    <div class="tool-item">
      <span class="type__name">背景图:</span>
      <div class="tool__content-img">
        <CutImgUp
          :url="backImgList"
          :auto-crop-width="750"
          :auto-crop-height="750"
          :max-num="1"
          @change="onSuccess"
        />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * name: 页面内容配置
 */

import CutImgUp from '@/components/common/cutImgUp';

export default {
  name: 'page-tool',
  components: { CutImgUp },
  props: {
    info: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => ({
        config: {},
      }),
    },
  },
  data() {
    return {
      // 模块名称--编辑--true 可编辑， false 不可编辑
      inputShow: false,
    };
  },
  computed: {
    backImgList() {
      return this.info.config?.backgroundImage ? [this.info.config?.backgroundImage] : [];
    },
    backgroundColor() {
      return info.config?.backgroundColor ? info.config?.backgroundColor : '';
    },
  },

  methods: {
    onSuccess(res) {
      // console.log(res)
      this.info.config.backgroundImage = res[0];
      // this.$emit('onSuccess', res)
    },
  },
};
</script>

<style scoped lang="scss">
  .panel {
    width: 522px;
    background: #fff;
    // border: 1px solid #eee;
    border-radius: 4px;
    box-sizing: border-box;
    .tool-item {
        padding:10px 0 10px 25px;
        & span {
            padding-right: 10px;
        }
        .tool__content {
            width: 400px;
            height: 40px;
            display: inline-block;
        }
        .tool-bg {
            width: 300px;
            display: inline-block;
        }
        .tool-border {
            width: 100px;
        }
        .color__show {
            width: 34px;
            height: 34px;
            margin-left: 8px;
            border-radius: 4px;
            margin-top: 2px;
            display: inline-block;
            vertical-align: top;
            border: 1px solid #979797;
            background:rgba(238,238,238,1);
            .color__content {
                width: 22px;
                height: 22px;
                margin: 5px;
            }
        }
        .tool__content-img{
        width: 400px;
        height: 100px;
        display: inline-block;
       }
    }
  }
</style>
