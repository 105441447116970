<template>
  <div class="page-home">
    <div
      v-for="(item, index) in addrow.list"
      :key="index"
      class="page-list"
    >
      <div class="list-num">
        {{ `列表${index+1}` }}
      </div>
      <div class="input-box title">
        <span>主标题</span>
        <el-input
          v-model="item.title"
          placeholder="请输入主标题长度限制4个字符"
          class="input-text"
          maxlength="4"
        />
      </div>
      <div class="input-box subtitle">
        <span>副标题</span>
        <el-input
          v-model="item.subTitle"
          placeholder="请输入副标题长度限制5个字符"
          class="input-text"
          maxlength="5"
        />
      </div>
      <div class="input-box subtitle">
        <span>展示类型</span>
        <el-select
          v-model="item.unique"
          placeholder="请选择类型"
          @change="(el) => changeType(el, index)"
        >
          <el-option
            v-for="child in optionsList"
            :key="child.value"
            :label="child.label"
            :value="child.value"
          />
        </el-select>
      </div>
      <!-- 展示和删除部分 -->
      <div
        v-if="item.unique === '0'"
        class="input-box goods-list"
      >
        <span style="margin-right: 20px;">商品</span>
        <div class="list-content">
          <draggable
            v-model="item.goodsList"
            element="div"
          >
            <ul
              v-for="(item2, index2) in item.goodsList"
              :key="index2"
            >
              <li>
                <span class="item-id">{{ item2.goodsId }}</span>
                <p>{{ strShow(item2.name) }}</p>
                <span :class="[item2.isOnSale === 1 ? '' : 'red']">
                  {{ item2.isOnSale === 1 ? '已上架' : '已下架' }}
                </span>
                <span
                  class="delet-btn"
                  style="margin: 0;"
                  @click="delGoods(index, index2)"
                >删除</span>
              </li>
            </ul>
          </draggable>
        </div>
      </div>
      <!-- 商品添加部分 -->
      <div
        v-if="item.unique === '0'"
        class="input-box goods-input"
      >
        <el-input
          v-model="goodsInfo[index].info"
          type="text"
          placeholder="输入商品id,可输入多个商品,多个商品id之间以英文“,”区分"
          size="mini"
          style="width:240px;float:left;margin-left: 40px;"
          @input="(e)=>{ goodsInfo[index].info = e.replace(/[^0-9,]/g, '') }"
        />
        <el-button
          size="mini"
          style="float:left;margin-left:10px;"
          @click="addGoods(index)"
        >
          立即添加
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 首页列表编辑组件
 */
import draggable from 'vuedraggable';
import qs from 'qs';
import { strLimit } from '@/assets/js/common';

export default {
  name: 'page-list',
  components: {
    draggable,
  },
  props: {
    addrow: {
      type: Object,
      default: () => ({
        list: [
          {
            title: '', subTitle: '', goodsList: [], unique: '0',
          },
          {
            title: '', subTitle: '', goodsList: [], unique: '0',
          },
          {
            title: '', subTitle: '', goodsList: [], unique: '0',
          },
          {
            title: '', subTitle: '', goodsList: [], unique: '0',
          },
        ],
      }),
    },
  },
  data() {
    return {
      goodsInfo: [
        { info: '' },
        { info: '' },
        { info: '' },
        { info: '' },
      ],
      optionsList: [
        { label: '无', value: '0' },
        { label: '猜你喜欢', value: '50' },
        // { label: '知名品牌', value: '51' },
        // { label: '精品民宿', value: '52' },
        { label: '乡村旅游', value: '53' },
        { label: '新品尝鲜', value: '54' },
        { label: '博友推荐', value: '55' },
      ],
      isShowGoods: true,
    };
  },
  watch: {
    addrow: {
      deep: true,
      immediate: true,
      handler(val) {
        val.list.forEach((item, index) => {
          const arr = item.goodsList.map((item2, index2) => {
            return item2.goodsId;
          });
          this.goodsInfo[index].info = arr.join(',');
        });
      },
    },
  },
  mounted() {
    // const that = this
    // that.addrow.list.forEach((item, index) => {
    //   const arr = item.goodsList.map((item2, index2) => {
    //     return item2.goods_id
    //   })
    //   that.goodsInfo[index].info = arr.join(',')
    // })
  },
  methods: {
    // 切换展示类型
    changeType(val, i) {
      if (val) {
        this.isShowGoods = false;
      } else {
        this.isShowGoods = true;
      }
    },
    // 限制显示字数
    strShow(item) {
      if (item == null) {
        //
      } else {
        return strLimit(item, 16);
      }
    },
    // 添加商品
    addGoods(num) {
      let that = this;
      if (that.goodsInfo[num].info === '') {
        that.$message('请输入商品id');
      } else {
        const array = that.goodsInfo[num].info.split(',');
        // 去重
        const temp = [];
        for (let i = 0; i < array.length; i++) {
          if (temp.indexOf(array[i]) === -1) {
            temp.push(array[i]);
          }
        }
        that.goodsInfo[num].info = temp.join(',');
        that.$axios.post(
          that.$api.tradePluginTemplate.selectGoods,
          qs.stringify({
            goodsIds: that.goodsInfo[num].info,
          }),
        ).then((res) => {
          const data = res;
          if (data.code === 0) {
            if (data.data.length === 0) {
              that.$message('找不到该id对应的商品');
              return null;
            }
            that.addrow.list[num].goodsList = data.data;
          }
        });
      }
    },
    // 删除商品
    delGoods(num, index) {
      this.addrow.list[num].goodsList.splice(index, 1);
      this.addrow.list.forEach((item, index) => {
        const arr = item.goodsList.map((item2, index2) => {
          return item2.goodsId;
        });
        this.goodsInfo[index].info = arr.join(',');
      });
    },
  },
};
</script>

<style scoped lang="scss">
.page-list {
  position: relative;
  padding: 60px 20px 20px 20px;
  width: 100%;
  box-sizing: border-box;
}
.page-list:after {
  content: "";
  width: calc(100% - 30px);
  height: 1px;
  left: 15px;
  bottom: 0;
  position: absolute;
  background: #ededed;
}
.input-box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  span {
    margin-right: 10px;
    white-space: nowrap;
  }
}
.list-content{
  width: 360px;
  height: 140px;
  border: 1px solid #D9DDE1;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 4px;
  ul {
    width: 100%;
    li {
      display: flex;
    }
  }
  & p {
    width: 200px;
    height: 20px;
    display: inline-block;
    margin-right: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  & span{
    display: inline-block;
    cursor: pointer;
  }
  .item-id {
    width: 50px;
  }
}
.list-num {
  width: 60px;
  line-height: 28px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  padding-left: 5px;
  font-size: 12px;
  margin-bottom: 15px;
  background: rgba(0,153,255,1);
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
}
.delet-btn {
  color: red;
  cursor: pointer;
}
</style>
