var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "add__list addCate" }, [
    _c(
      "div",
      { staticClass: "category__name" },
      [
        _c("span", [_vm._v("类目" + _vm._s(_vm.categoryNum) + ":")]),
        _c("el-input", {
          staticClass: "category__input",
          attrs: {
            placeholder: "请输入类目名称，限10字以内",
            maxlength: "10",
            size: "mini",
          },
          model: {
            value: _vm.goodsListInfo.name,
            callback: function ($$v) {
              _vm.$set(
                _vm.goodsListInfo,
                "name",
                typeof $$v === "string" ? $$v.trim() : $$v
              )
            },
            expression: "goodsListInfo.name",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "category_jump_type" },
      [
        _c("span", [_vm._v("类目跳转组件:")]),
        _c(
          "el-select",
          {
            on: { change: _vm.changeContent },
            model: {
              value: _vm.goodsListInfo.cateId,
              callback: function ($$v) {
                _vm.$set(_vm.goodsListInfo, "cateId", $$v)
              },
              expression: "goodsListInfo.cateId",
            },
          },
          _vm._l(_vm.modelList, function (item, index) {
            return _c("el-option", {
              key: index,
              attrs: { label: item.showTitle, value: item.cateId },
            })
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }