var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "style-list-tool" },
    [
      _vm._m(0),
      _c(
        "el-form",
        { attrs: { model: _vm.info, "label-width": "120px" } },
        [
          _c(
            "el-form-item",
            { staticClass: "name-box", attrs: { label: "应用场景：" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.info.resource,
                    callback: function ($$v) {
                      _vm.$set(_vm.info, "resource", $$v)
                    },
                    expression: "info.resource",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(" 凑单领券 "),
                  ]),
                  _c("el-radio", { attrs: { label: 2 } }, [
                    _vm._v(" 凑单满减 "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                "label-width": "100px",
                label: "关联活动：",
                prop: "categoryId",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "90%" },
                  attrs: {
                    filterable: "",
                    remote: "",
                    "reserve-keyword": "",
                    placeholder: "请输入关键词搜索活动",
                    "remote-method": _vm.remoteMethod,
                    loading: _vm.loading,
                  },
                  on: { change: _vm.changeCategory },
                  model: {
                    value: _vm.info.associatedActivityID,
                    callback: function ($$v) {
                      _vm.$set(_vm.info, "associatedActivityID", $$v)
                    },
                    expression: "info.associatedActivityID",
                  },
                },
                _vm._l(_vm.categoryOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.info.resource == 1
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "100px",
                        label: "加购门槛：",
                        "show-word-limit": "",
                      },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "90%" },
                          attrs: { placeholder: "请输入加购门槛" },
                          model: {
                            value: _vm.info.AdditionalPurchaseThreshold,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.info,
                                "AdditionalPurchaseThreshold",
                                $$v
                              )
                            },
                            expression: "info.AdditionalPurchaseThreshold",
                          },
                        },
                        [_c("template", { slot: "suffix" }, [_vm._v(" ￥ ")])],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "name-box", attrs: { label: "券类型：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.info.couponType,
                            callback: function ($$v) {
                              _vm.$set(_vm.info, "couponType", $$v)
                            },
                            expression: "info.couponType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v(" 券 "),
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v(" 券包 "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "100px",
                        label: "获得优惠券：",
                        "show-word-limit": "",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "90%" },
                        attrs: { placeholder: "请输入券包ID" },
                        on: { input: _vm.input },
                        model: {
                          value: _vm.info.discountCoupon,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "discountCoupon", $$v)
                          },
                          expression: "info.discountCoupon",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "style-tips" }, [
      _c("span", { staticClass: "name-box-tip" }, [
        _vm._v("1、有效时间不填默认永久有效;"),
      ]),
      _c("span", { staticClass: "name-box-tip" }, [
        _vm._v("2、组件默认吸底，一个页面只能有一个生效的凑单组件;"),
      ]),
      _c("span", { staticClass: "name-box-tip" }, [
        _vm._v(
          "3、使用带有凑单组件的专题模板时，需要在链接后拼上[&reloadAfterPop=1]。"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }