var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "live_list",
      style: {
        "border-bottom": _vm.obj.bMargin ? "10px solid" : "",
        "background-color": _vm.obj.bgColor,
        padding: _vm.obj.bPadding ? "16px 26px" : "16px 0",
      },
    },
    [
      _vm._t("default"),
      !(_vm.obj.contentList && _vm.obj.contentList.length > 0)
        ? _c("img", {
            staticStyle: { width: "100%" },
            attrs: {
              src: "https://img.hzanchu.com/acimg/d712ee0805c6e8cbf104198abfbe277c.png",
              alt: "",
            },
          })
        : _vm._e(),
      _vm._l(_vm.obj.contentList, function (value, index) {
        return _c("div", { key: index }, [
          _c("div", { staticClass: "live_list_title" }, [
            _vm._v(
              "容器" +
                _vm._s(index + 1) +
                " : " +
                _vm._s(_vm.getLiveState(value.liveStatus)) +
                "列表（" +
                _vm._s(_vm.getLiveSort(value.sortKey)) +
                "）"
            ),
          ]),
          _vm.obj.column == 1
            ? _c(
                "div",
                { staticClass: "live_list_one" },
                _vm._l(value.list, function (item, i) {
                  return _c("div", { key: i, staticClass: "live_card" }, [
                    _c("img", {
                      staticClass: "live_img",
                      attrs: { src: item.coverImageId, alt: "" },
                    }),
                    _c("div", { staticClass: "live_content" }, [
                      _c("div", { staticClass: "top" }, [
                        _c("p", [_vm._v(_vm._s(item.title))]),
                        _c("div", [_vm._v(_vm._s(item.actorName))]),
                      ]),
                      _c("div", { staticClass: "bottom" }, [
                        _c("img", {
                          staticClass: "header_img",
                          attrs: { src: item.avatarImagePath, alt: "" },
                        }),
                        _vm.obj.appointment === 1
                          ? _c("div", { staticClass: "live_content_btn" }, [
                              _vm._v("立即预约"),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ])
                }),
                0
              )
            : _vm._e(),
          _vm.obj.column > 1
            ? _c(
                "div",
                { staticClass: "live_list_two" },
                _vm._l(value.list, function (item, i) {
                  return _c("div", { key: i, staticClass: "live_card" }, [
                    _c("div", { staticClass: "live_header" }, [
                      _c("img", {
                        staticClass: "live_img",
                        attrs: { src: item.coverImageId, alt: "" },
                      }),
                      _c("img", {
                        staticClass: "header_img",
                        attrs: { src: item.avatarImagePath, alt: "" },
                      }),
                    ]),
                    _c("div", { staticClass: "live_content" }, [
                      _c("div", { staticClass: "live_content_font" }, [
                        _c("p", [_vm._v(_vm._s(item.title))]),
                        _c("div", [_vm._v(_vm._s(item.actorName))]),
                      ]),
                      _vm.obj.appointment === 1
                        ? _c("div", { staticClass: "live_content_btn" }, [
                            _vm._v("预约"),
                          ])
                        : _vm._e(),
                    ]),
                  ])
                }),
                0
              )
            : _vm._e(),
        ])
      }),
      _c("div", { staticClass: "bt-drag-title" }, [
        _vm._v(" " + _vm._s(_vm.obj.showTitle) + " "),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }