var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "add__list addCate" },
    [
      _c(
        "div",
        { staticClass: "category__name" },
        [
          _c("span", [_vm._v("类目" + _vm._s(_vm.categoryNum) + ":")]),
          _c("el-input", {
            staticClass: "category__input",
            attrs: {
              placeholder: "请输入类目名称，限10字以内",
              maxlength: "10",
              size: "mini",
            },
            model: {
              value: _vm.goodsListInfo.name,
              callback: function ($$v) {
                _vm.$set(
                  _vm.goodsListInfo,
                  "name",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "goodsListInfo.name",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "category_title_img" },
        [
          _c("span", [_vm._v("标题图片:")]),
          _c("CutImgUp", {
            attrs: {
              url: _vm.imageUrlList,
              "auto-crop-width": 750,
              "auto-crop-height": 750,
              "max-num": 1,
            },
            on: { change: _vm.onSuccess },
          }),
        ],
        1
      ),
      _c(
        "el-form",
        { attrs: { "label-width": "100px" } },
        [
          _c("div", { staticStyle: { margin: "0 0 10px 30px" } }, [
            _vm._v(" 注：标题可设置跳转类型 "),
          ]),
          _c("JumpType", { attrs: { "img-info": _vm.goodsListInfo } }),
        ],
        1
      ),
      _c("div", { staticClass: "goods__list" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "list__content" },
          [
            _c(
              "draggable",
              {
                attrs: { element: "div" },
                model: {
                  value: _vm.goodsListInfo.goods_list,
                  callback: function ($$v) {
                    _vm.$set(_vm.goodsListInfo, "goods_list", $$v)
                  },
                  expression: "goodsListInfo.goods_list",
                },
              },
              _vm._l(_vm.goodsListInfo.goods_list, function (item, index) {
                return _c("ul", { key: index }, [
                  _c("li", [
                    _c("span", { staticClass: "item-id" }, [
                      _vm._v(_vm._s(item.goodsId)),
                    ]),
                    _c("p", [_vm._v(_vm._s(_vm.strShow(item.name)))]),
                    _c("span", { class: [item.isOnSale === 1 ? "" : "red"] }, [
                      _vm._v(_vm._s(item.isOnSale === 1 ? "已上架" : "已下架")),
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "delet-btn",
                        staticStyle: { margin: "0" },
                        on: {
                          click: function ($event) {
                            return _vm.delGoods(index)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]),
                ])
              }),
              0
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "goods__input" }, [
        _c(
          "div",
          { staticClass: "list__add" },
          [
            _c(
              "el-select",
              {
                staticClass: "list__add--type",
                attrs: { placeholder: "请选择", size: "mini" },
                model: {
                  value: _vm.goodsValue,
                  callback: function ($$v) {
                    _vm.goodsValue = $$v
                  },
                  expression: "goodsValue",
                },
              },
              _vm._l(_vm.goodsOptions, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.goodsValue === 0,
                    expression: "goodsValue === 0",
                  },
                ],
              },
              [
                _c("el-input", {
                  staticStyle: { width: "240px" },
                  attrs: {
                    type: "textarea",
                    rows: 4,
                    placeholder:
                      "输入商品id,可输入多个商品,多个商品id之间以英文“,”区分",
                    size: "mini",
                  },
                  on: {
                    input: (e) => {
                      _vm.goodsInfo = e.replace(/[^0-9,]/g, "")
                    },
                  },
                  model: {
                    value: _vm.goodsInfo,
                    callback: function ($$v) {
                      _vm.goodsInfo = $$v
                    },
                    expression: "goodsInfo",
                  },
                }),
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "10px" },
                    attrs: { size: "mini", loading: _vm.addLoading },
                    on: { click: _vm.addGoods },
                  },
                  [_vm._v(" 立即添加 ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.goodsValue !== 0,
                    expression: "goodsValue !== 0",
                  },
                ],
              },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "list__add--list",
                    attrs: { placeholder: "请选择", size: "mini" },
                    model: {
                      value: _vm.activityValue,
                      callback: function ($$v) {
                        _vm.activityValue = $$v
                      },
                      expression: "activityValue",
                    },
                  },
                  _vm._l(_vm.activityOptions, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: { float: "left", "margin-left": "10px" },
                    attrs: { size: "mini" },
                    on: {
                      click: function ($event) {
                        return _vm.addActivityGoods(_vm.activityValue)
                      },
                    },
                  },
                  [_vm._v(" 立即添加 ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "list__select" }),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "goods__name" }, [
      _c("p", [_vm._v("商品:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }