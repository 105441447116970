var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "right-tool-paging" },
    [
      _c(
        "el-form",
        { attrs: { inline: true } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "分页:", "label-width": "80px" } },
            [
              _c("el-switch", {
                on: { change: _setup.changeItem },
                model: {
                  value: _setup.pageItem.isShowMore,
                  callback: function ($$v) {
                    _vm.$set(_setup.pageItem, "isShowMore", $$v)
                  },
                  expression: "pageItem.isShowMore",
                },
              }),
              _c("div", { staticClass: "orange" }, [
                _vm._v(" 不分页最多可展示200条数据 "),
              ]),
            ],
            1
          ),
          _setup.pageItem.isShowMore
            ? _c(
                "el-form-item",
                { attrs: { label: "分页数量:" } },
                [
                  _c("el-input-number", {
                    staticClass: "w100",
                    attrs: { min: 1, max: 9999, controls: false },
                    on: { change: _setup.changeItem },
                    model: {
                      value: _setup.pageItem.pageSize,
                      callback: function ($$v) {
                        _vm.$set(_setup.pageItem, "pageSize", $$v)
                      },
                      expression: "pageItem.pageSize",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _setup.pageItem.isShowMore
            ? _c(
                "el-form-item",
                { attrs: { label: "查看更多图片:" } },
                [
                  _c(_setup.UpImage, {
                    attrs: { "image-list": _setup.pageItem.imageList },
                    on: { change: _setup.changeItem },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }