<template>
  <div
    class="scroll-news"
    :style="{'background-color': obj.bgColor, 'margin-bottom': obj.bMargin ? '10px' : '0', 'padding': obj.bPadding ? '20px 30px' : '20px 0'}"
  >
    <div class="cont-box">
      <p
        v-for="(item, index) in list"
        :key="index"
        :style="{color: obj.remainColor}"
      >
        <span>{{ obj.beforeText }}</span>
        <span :style="{color: obj.mainColor}">【重点文案】</span>
        <span>{{ obj.afterText }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScrollNews',
  props: {
    obj: {
      type: Object,
      default: () => ({
        lineNum: 1
      })
    }
  },
  data() {
    return {
      list: []
    }
  },
  watch: {
    'obj.lineNum': 'getList'
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      let arr = []
      for (let i = 0; i < this.obj.lineNum; i++) {
        arr.push('fill')
      }
      this.list = arr
    }
  }
}
</script>

<style scoped lang="scss">
.scroll-news {
  min-height: 100px;
}
.cont-box{
  overflow: hidden;
  p {
    height: 30px;
    line-height: 30px;
  }
}
</style>
