<template>
  <div class="add_navigation_list addNavigation">
    <div class="category_title_img">
      <span>标题图片:</span>
      <CutImgUp
        :url="imageUrlList"
        :auto-crop-width="750"
        :auto-crop-height="750"
        :max-num="1"
        @change="onSuccess"
      />
    </div>
    <!-- 类目名称 -->
    <div class="category__name">
      <span>导航{{ categoryNum }}:</span>
      <el-input
        v-model.trim="goodsListInfo.name"
        placeholder="请输入导航标题，限10字以内"
        class="category__input"
        maxlength="10"
        size="mini"
      />
    </div>
    <!-- 分组 -->
    <div class="category__name">
      <span>分组:</span>
      <el-select
        v-model="goodsListInfo.group_id"
        style="width: 300px;"
        filterable
        size="mini"
      >
        <el-option
          v-for="(item, index) in groupOptions"
          :key="index"
          :label="item.groupingTitle"
          :value="item.id"
        />
      </el-select>
    </div>
    <!-- 数量 -->
    <div class="category__name">
      <span>数量:</span>
      <el-input
        v-model.trim="goodsListInfo.number"
        placeholder="请输入导航标题，限10字以内"
        class="category__input"
        maxlength="10"
        size="mini"
      />
    </div>

    <div class="nav_btn">
      <el-button
        size="mini"
        type="primary"
        :loading="addLoading"
        @click="addGoods"
      >
        立即添加商品
      </el-button>
    </div>
  </div>
</template>

<script>
/**
 * 类目组件343
 * @module AddNav
 * @example <b> addNav 组件</b>
 */
import draggable from 'vuedraggable';
import md5 from 'md5-js';
import qs from 'qs';
import { strLimit } from '@/assets/js/common';
import loadImg from '@/components/common/loadImg';
import { SELECT_OPTIONS, NOT_SHOW_INPUT } from './data';
import CutImgUp from '@/components/common/cutImgUp';

export default {
  name: 'add-navigation',
  components: { draggable, loadImg, CutImgUp },

  /**
 * 类目组件
 * @prop {Object} goodsListInfo 商品列表
 * @prop {String} categoryNum 类目序号
 */
  props: {
    goodsListInfo: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        type: '',
        categoryNum: '', // 类目顺序
        name: '', // 导航标题
        number: null, // 展示的商品数量
        goods_list: [], // 商品列表
        group_id: '', // 选择的分组id
        content: '',
        imgInfo: {
          type: 1,
          content: '',
          img: '',
          width: '',
          height: '',
        },
      },
    },
    categoryNum: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      groupOptions: [], // 分组下拉列表

      toast: '', // 输入框提示
      imgData: {
        fileList: [],
        imgCode: [],
      },
      activityOptions: [],
      goodsValue: 0,
      activityValue: '',
      goodsInfo: '',
      addLoading: false,
      canSetcouponbag: false,
      canSetSelect: false,
      imageUrlList: [],
      couponbag: '', // 不能修改的优惠券id信息
    };
  },
  watch: {
    goodsListInfo: {
      deep: true,
      immediate: true,
      handler(val) {
        let arr = [];
        val.goods_list && val.goods_list.forEach((i) => {
          arr.push(i.goodsId);
        });
        this.goodsInfo = arr.join(',');
      },
    },
    'imgData.fileList': function (val) {
      const data = { ...this.goodsListInfo.imgInfo };
      if (val.length > 0) {
        // 兼容上传组件imgCode中无url情况
        if (val[0].response && val[0].response.data) {
          if (val[0].type) {
            data.img = val[0].response.data.url;
          } else {
            data.img = val[0].response.data.url;
            data.width = val[0].response.data.width;
            data.height = val[0].response.data.height;
          }
        }
      } else {
        data.img = '';
      }
      this.goodsListInfo.imgInfo = data;
    },
  },
  mounted() {
    if (this.goodsListInfo.imgInfo.img && this.goodsListInfo.imgInfo.img !== '') {
      this.imgData.fileList.push({
        name: '图片',
        type: true,
        url: this.goodsListInfo.imgInfo.img,
        width: this.goodsListInfo.imgInfo.width,
        height: this.goodsListInfo.imgInfo.height,
      });
      this.imageUrlList = [this.goodsListInfo.imgInfo.img];
    } else {
      this.imageUrlList = [];
    }

    this.getGroupOptions();
  },

  methods: {
    // 获取分组列表
    getGroupOptions() {
      let that = this;
      that.$axios(that.$api.tradePluginTemplate.group_list, {
        params: {
          groupingType: 2,
        },
      }).then((res) => {
        if (res.code === 0) {
          that.groupOptions = res.data;
        }
      });
    },
    getGroupGoodsList() {
      let that = this;
      let params = {
        groupingId: that.goodsListInfo.group_id,
        number: that.goodsListInfo.number,
      };
      this.$axios.post(that.$api.tradePluginTemplate.group_goods_list, params).then((res) => {
        that.addLoading = false;
        if (res.code === 0) {
          if (res.data.length === 0) {
            that.$message('找不到该分组下的商品');
          }
          that.goodsListInfo.goods_list = res.data;
        }
      });
    },
    // 添加商品
    addGoods() {
      let that = this;
      if (!that.goodsListInfo.name || !that.goodsListInfo.group_id || !that.goodsListInfo.number) {
        that.$message('请选择分组或填写数量');
      } else {
        this.addLoading = true;
        that.getGroupGoodsList();
      }

      // if (this.goodsInfo === '') {
      //   that.$message('请填写商品信息')
      // } else {
      //   let array = this.goodsInfo.split(',')
      //   // 去重
      //   var temp = []
      //   for (var i = 0; i < array.length; i++) {
      //     if (temp.indexOf(array[i]) === -1) {
      //       temp.push(array[i])
      //     }
      //   }
      //   this.goodsInfo = temp.join(',')
      //   this.addLoading = true
      //   this.$axios({
      //     method: 'post',
      //     url: that.$api.tradePluginTemplate.selectGoods,
      //     data: qs.stringify({
      //       goodsIds: that.goodsInfo
      //     })
      //   }).then(function(res) {
      //     that.addLoading = false
      //     let data = res
      //     if (data.code === 0) {
      //       if (data.data.length === 0) {
      //         that.$message('找不到该id对应的商品')
      //       }
      //       that.goodsListInfo.goods_list = data.data
      //     }
      //   })
      // }
    },
    // 添加满减送活动商品
    addActivityGoods(value) {
      let that = this;
      if (this.activityValue === '') {
        this.toast = '请填写商品信息';
      } else {
        this.$axios.post(that.$api.subject.addgoodsbymjs, {
          prom_id: that.activityValue,
        }).then((res) => {
          let data = res;
          if (data.code === 0) {
            if (data.data.length === 0) {
              that.$message('找不到该id对应的商品');
            }
            if (that.goodsListInfo.goods_list.length === 0) {
              that.goodsListInfo.goods_list = data.data;
            } else {
              let arr = data.data;
              data.data.forEach((value, index) => {
                let id = value.goods_id;
                that.goodsListInfo.goods_list.forEach((val) => {
                  if (id === val.goods_id) {
                    arr.splice(index);
                    that.$message('已为你过滤掉重复商品');
                  }
                });
              });
              arr.forEach((value) => {
                that.goodsListInfo.goods_list.push(value);
              });
            }
            that.activityValue = '';
          }
        });
      }
    },
    // 删除商品
    delGoods(index) {
      this.goodsListInfo.goods_list.splice(index, 1);
      let arr = [];
      this.goodsListInfo.goods_list.forEach((i) => {
        arr.push(i.goodsId);
      });
      this.goodsInfo = arr.join(',');
    },
    // 限制显示字数
    strShow(item) {
      if (item == null) {
        //
      } else {
        return strLimit(item, 16);
      }
    },
    nameLimit(item) {
      if (item == null) {
        //
      } else {
        return strLimit(item, 10);
      }
    },
    // 筛选重复商品
    filterGoods(item) {
      let goodsRule = item.split(',');
    },
    changeContent(val) {
      console.log(val, '---------');
      console.log(this.goodsListInfo.content);
      this.goodsListInfo.content = '';
      if (val === 'coupackage') {
        this.goodsListInfo.content = this.couponbag;
      }
    },
    onSuccess(obj) {
      this.goodsListInfo.imgInfo.img = obj[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.add_navigation_list {
    width: calc(100% - 30px);
    // height: 355px;
    // margin: 0 15px;
    padding: 0 15px;
    padding-top: 15px;
    .category__name{
        & span {
          width: 78px;
          height: 36px;
          color: #8E8E8E;
          margin-right: 6px;
          text-align: right;
          display: inline-block;
        }
        .category__input {
            width: 300px;
        }
    }
    .nav_btn {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }
    .goods__list {
        overflow: hidden;
        .goods__name {
            width: 78px;
            height: 100px;
            float: left;
            margin-right: 6px;
            & p{
                text-align: right;
                color: #8E8E8E;
                overflow: hidden;
            }
        }
        .list__content{
            float: left;
            width: 360px;
            height: 140px;
            border: 1px solid #D9DDE1;
            overflow-y: scroll;
            overflow-x: hidden;
            border-radius: 4px;
            ul > li {
              display: flex;
              cursor: move;
            }
            & p {
              width: 200px;
              height: 20px;
              display: inline-block;
              margin-right: 10px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            & span{
              display: inline-block;
              cursor: pointer;
              margin-right: 10px;
              white-space: nowrap;
            }
            .item-id {
              width: 50px;
            }
        }
    }
    .goods__input {
        // height: 36px;
        width: 100%;
        .list__add{
            margin-top: 10px;
            margin-bottom: 10px;
            width: 100%;
            // height: 34px;
            .list__add--type{
                width: 90px;
                float: left;
                margin-right: 10px;
            }
            & input {
                width: 300px;
                height: 34px;
                float: left;
                border: 1px solid #979797;
            }
            & input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
                font-size: 12px;
            }
             & input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                font-size: 12px;
            }
             & input::-moz-placeholder { /* Mozilla Firefox 19+ */
                font-size: 12px;
            }
             & input:-ms-input-placeholder { /* Internet Explorer 10-11 */
                font-size: 12px;
            }
            & span {
                width: 80px;
                height: 34px;
                line-height: 34px;
                text-align: center;
                float: left;
                border: 1px solid #979797;
                border-left: none;
                background:rgba(240,240,240,1);
                cursor: pointer;
            }
        }
        .add__toast {
            color: #FF3301;
        }
        .list__add--list {
            width: 290px;
            float: left;
            margin-right: 4px;
        }

    }
}
.delet-btn {
  color: red;
  cursor: pointer;
}
.category_title_img {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  & > span {
    margin-right: 6px;
    width: 78px;
    text-align: right;
    color: #8E8E8E;
  }
}
.category_jump_type {
  & > span {
    width: 78px;
    height: 36px;
    color: #8E8E8E;
    margin-right: 6px;
    text-align: right;
    display: inline-block;
  }
}
.category_jump_content {
  display: flex;
  margin-bottom: 10px;
  & > span {
    width: 78px;
    height: 36px;
    color: #8E8E8E;
    margin-right: 6px;
    text-align: right;
    display: inline-block;
    line-height: 36px;
  }
}
.couponbag {
  position: relative;
  width: 246px;
  .annotation {
    position: absolute;
    left: -70px;
    top: 20px;
  }
}
.county-remark {
  width: 100%;
  padding-left: 30px;
  box-sizing: border-box;
  color: #666;
  margin-bottom: 30px;
}
</style>
