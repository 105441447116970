var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rich-text-pre", style: _vm.wrapSty }, [
    _c("div", {
      staticClass: "content",
      style: _vm.contentSty,
      domProps: { innerHTML: _vm._s(_vm.obj.content) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }