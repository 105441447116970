<template>
  <div
    class="navigation-goods"
    :style="{'background': obj.titleBgColor}"
  >
    <slot />
    <div
      class="cate-nav"
      :style="{'background': obj.navBgColor,'color': obj.navColor}"
    >
      <div
        v-for="(item,index) in obj.list"
        :key="index"
        :href="`#cate${index}`"
        :style="{'background': selectIndex === index ? obj.selectBgColor : obj.navBgColor, 'color': selectIndex === index ? obj.selectColor : obj.navColor}"
        @click="selectIndex=index"
      >
        <div
          v-if="item.imgInfo && item.imgInfo.img !== ''"
          class="nav-title-img"
        >
          <img
            :src="item.imgInfo.img"
            alt=""
          >
        </div>
        <p class="nav-name">
          {{ item.name }}
        </p>
      </div>
    </div>
    <div
      v-for="(item,index) in obj.list"
      :key="index"
      class="cate-content-box"
    >
      <div
        v-if="item.imgInfo && item.imgInfo.img !== ''"
        class="cate-title-img"
      >
        <img
          :src="item.imgInfo.img"
          alt=""
        >
      </div>
      <div
        v-else
        :id="`cate${index}`"
        class="cate-title"
        :style="{'color': obj.titleColor}"
      >
        {{ item.name }}
      </div>
      <div class="cate-goods-content">
        <div
          v-for="(goods, index2) in item.goods_list"
          :key="index2"
          class="cate-goods-item"
        >
          <img :src="goods.imageIds.split(',')[0]">
          <div>
            <p class="good-name">
              {{ goods.name }}
            </p>
            <p class="good-price">
              ￥ {{ goods.salesPrice }} <span class="line_throw">{{ goods.price }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="bt-drag-title">
      {{ obj.title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    obj: {
      type: Object,
      default: () => ({
        list: [{
          name: '',
          goods_list: []
        }],
        navBgColor: '#fff',
        navColor: '#000',
        selectBgColor: '#fff',
        selectColor: '#67C23A',
        titleBgColor: '#fff',
        titleColor: '#000',
        title: '商品类目'
      })
    }
  },
  data() {
    return {
      preurl: 'https://img.hzanchu.com/acimg/4c3150d4a2606a92b0788495f2a8ff0b.jpg',
      selectIndex: 0
    }
  }
}
</script>

<style scoped lang="scss">
  .navigation-goods{
    .cate-nav{
      display: flex;
      height: 80px;
      justify-content:center;
      align-items:center;
      overflow: hidden;
      overflow-x: auto;
      div{
        flex: 1;
        height:100%;
        text-align: center;
        // padding: 0 10px;
        line-height: 76px;
        .nav-title-img {
          text-align: center;
          height: 30px;
          img {
            width: 30px;
            height: 30px;
          }
        }
        .nav-name {
          height: 50px;
        }
      }
    }
    .cate-title{
      width:94%;
      background:#fff;
      margin: 0 auto;
      margin-bottom:5px;
      margin-top: 10px;
      text-align: center;
      height:40px;
      line-height:40px;
    }
    .cate-title-img {
      width: 100%;
      img {
        width: 100%;
        display: block;
      }
    }
    .cate-goods-content{
      box-sizing:border-box;
      padding:10px 15px;
      display: flex;
      flex-wrap:wrap;
      justify-content: space-between;
      .cate-goods-item{
        width:220px;
        background:#fff;
        margin-bottom:15px;
        .good-name {
          white-space: nowrap;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .good-price {
          font-size: 20px;
          color: red;
          margin-top: 10px;
          .line_throw{
            font-size: 14px;
            text-decoration:line-through;
            color: #999;
          }
        }
        img{
          width:220px;
          height:220px;
        }
        div{
          box-sizing:border-box;
          padding:10px 12px;
          p{
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            font-size:14px;
          }
          span{
            color:red
          }
        }
      }
    }
  }
</style>
