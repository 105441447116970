<template>
  <div class="tool-wrapper">
    <div class="tool">
      <!-- 尺寸 -->
      <div
        v-show="toolType.toolSize"
        class="tool-item"
      >
        <span class="type__name">尺寸:</span>
        <div class="tool__content">
          <el-select
            v-model="contentInfo.size"
            placeholder="请选择"
            class="vertical-spacing"
          >
            <el-option
              v-for="item in sizeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <!-- 行间距 -->
      <div
        v-show="toolType.toolVertical"
        class="tool-item"
      >
        <span class="type__name">行间距:</span>
        <div class="tool__content">
          <el-select
            v-model="contentInfo.bMargin"
            placeholder="请选择"
            class="vertical-spacing"
          >
            <el-option
              v-for="item in verticalSpacingOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <!-- 轮播图背景色 -->
      <div
        v-show="toolType.toolBg"
        class="tool-item"
      >
        <span class="type__name">背景色:</span>
        <div class="tool__content">
          <el-input
            v-model="contentInfo.bgColor"
            class="tool-bg"
          />
          <div class="color__show">
            <div
              class="color__content"
              :style="{background: contentInfo.bgColor}"
            ></div>
          </div>
        </div>
      </div>
      <!-- 边缘 -->
      <div
        v-show="toolType.toolBorder"
        class="tool-item"
      >
        <span class="type__name">边缘:</span>
        <div class="tool__content">
          <el-select
            v-model="contentInfo.bPadding"
            placeholder="请选择"
            class="tool-border"
          >
            <el-option
              v-for="item in borderCurrentOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <!-- 字体色 -->
      <div
        v-show="toolType.toolText"
        class="tool-item"
      >
        <span class="type__name">字体色:</span>
        <div class="tool__content">
          <el-input
            v-model="contentInfo.color"
            class="tool-bg"
          />
          <div class="color__show">
            <div
              class="color__content"
              :style="{background: contentInfo.color}"
            ></div>
          </div>
        </div>
      </div>
      <!-- 标题颜色 -->
      <div
        v-show="toolType.toolTitle"
        class="tool-item"
      >
        <span class="type__name">背景色:</span>
        <div class="tool__content">
          <el-input
            v-model="contentInfo.titleBgColor"
            class="tool-bg"
          />
          <div class="color__show">
            <div
              class="color__content"
              :style="{background: contentInfo.titleBgColor}"
            ></div>
          </div>
        </div>
      </div>
      <!-- 标题文字颜色 -->
      <div
        v-show="toolType.toolTitleText"
        class="tool-item"
      >
        <span class="type__name">字体色:</span>
        <div class="tool__content">
          <el-input
            v-model="contentInfo.titleColor"
            class="tool-bg"
          />
          <div class="color__show">
            <div
              class="color__content"
              :style="{background: contentInfo.titleColor}"
            ></div>
          </div>
        </div>
      </div>
      <!-- 类目-导航栏颜色 -->
      <div
        v-show="toolType.toolNav"
        class="tool-item"
      >
        <span class="type__name">导航栏颜色:</span>
        <div class="tool__content">
          <el-input
            v-model="contentInfo.navBgColor"
            class="tool-bg"
          />
          <div class="color__show">
            <div
              class="color__content"
              :style="{background: contentInfo.navBgColor}"
            ></div>
          </div>
        </div>
      </div>
      <!-- 类目-导航栏文字颜色 -->
      <div
        v-show="toolType.toolNavText"
        class="tool-item"
      >
        <span class="type__name">文字颜色:</span>
        <div class="tool__content">
          <el-input
            v-model="contentInfo.navColor"
            class="tool-bg"
          />
          <div class="color__show">
            <div
              class="color__content"
              :style="{background: contentInfo.navColor}"
            ></div>
          </div>
        </div>
      </div>
      <!-- 类目-选中颜色 -->
      <div
        v-show="toolType.toolSelect"
        class="tool-item"
      >
        <span class="type__name">选中颜色:</span>
        <div class="tool__content">
          <el-input
            v-model="contentInfo.selectBgColor"
            class="tool-bg"
          />
          <div class="color__show">
            <div
              class="color__content"
              :style="{background: contentInfo.selectBgColor}"
            ></div>
          </div>
        </div>
      </div>
      <!-- 类目-选中文字颜色 -->
      <div
        v-show="toolType.toolSelectText"
        class="tool-item"
      >
        <span class="type__name">文字颜色:</span>
        <div class="tool__content">
          <el-input
            v-model="contentInfo.selectColor"
            class="tool-bg"
          />
          <div class="color__show">
            <div
              class="color__content"
              :style="{background: contentInfo.selectColor}"
            ></div>
          </div>
        </div>
      </div>
      <!-- 列表-列数 -->
      <div
        v-show="toolType.toolRow"
        class="tool-item"
      >
        <span class="type__name">列数:</span>
        <div class="tool__content">
          <el-select
            v-model="contentInfo.column"
            placeholder="请选择"
            class="tool-border"
            @change="$emit('columnChange', $event)"
          >
            <el-option
              v-for="item in columnArr.filter((_, i) => !(contentInfo.type === 'liveList' && i > 1))"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <div
        v-show="toolType.toolRow2"
        class="tool-item"
      >
        <span class="type__name">列数:</span>
        <div class="tool__content">
          <el-select
            v-model="contentInfo.column"
            placeholder="请选择"
            class="tool-border"
            @change="$emit('columnChange', $event)"
          >
            <el-option
              v-for="item in 2"
              :key="item"
              :label="item + '列'"
              :value="item"
            />
          </el-select>
        </div>
      </div>
      <!-- 首页列表 -->
      <div
        v-show="toolType.homeRowTitle"
        class="tool-item"
      >
        <span class="type__name">主标题字体色:</span>
        <div class="tool__content">
          <el-input
            v-model="contentInfo.rowTitleColor"
            class="tool-bg"
          />
          <div class="color__show">
            <div
              class="color__content"
              :style="{background: contentInfo.rowTitleColor}"
            ></div>
          </div>
        </div>
      </div>
      <!-- 设置高度 -->
      <div
        v-show="toolType.toolHeight"
        class="tool-item"
      >
        <span class="type__name">模块高度(px):</span>
        <div class="tool__content">
          <el-input
            v-model="contentInfo.rowtoolHeight"
            class="tool-bg"
          />
        </div>
      </div>
      <div
        v-show="toolType.homeRowSubtitle"
        class="tool-item"
      >
        <span class="type__name">副标题字体色:</span>
        <div class="tool__content">
          <el-input
            v-model="contentInfo.subTitleColor"
            class="tool-bg"
          />
          <div class="color__show">
            <div
              class="color__content"
              :style="{background: contentInfo.subTitleColor}"
            ></div>
          </div>
        </div>
      </div>
      <!--展示更多按钮, 支持分页-->
      <Paging
        v-if="toolType.showMoreBtn || toolType.showGoodsSize"
        :item="{
          isShowMore: contentInfo.isShowMore,
          pageSize: contentInfo.pageSize,
          image: contentInfo.imgInfo?.img
        }"
        @change="changePaging"
      />
      <!--商品购买按钮图片-->
      <div
        v-if="toolType.showBuyBtn && contentInfo.type !== 'listGoodsSkill' && contentInfo.column == 1"
        class="tool-item"
      >
        <span class="type__name">售卖按钮文案:
        </span>
        <div class="tool__content">
          <el-input
            v-model="contentInfo.imgInfoOfBuy.content"
            class="tool-bg"
          />
        </div>
      </div>
      <!-- 销售价前图片 -->
      <div
        v-if="toolType.showBuyBtn && contentInfo.type !== 'listGoodsSkill' && contentInfo.column == 1"
        class="more-btn"
      >
        <span class="type__name">销售价前图片
          <el-tooltip
            class="tip-box"
            effect="dark"
            content="图片尺寸：68x32"
            placement="top-start"
            style="margin-left: 0px;"
          >
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>:</span>
        <CutImgUp
          :url="titleImgListOfSellPrice"
          :auto-crop-width="68"
          :auto-crop-height="32"
          :fixed="false"
          :max-num="1"
          @change="onSuccessOfSellPrice"
        />
      </div>
      <!-- 划线价前图片 -->
      <div
        v-if="toolType.showBuyBtn && contentInfo.type !== 'listGoodsSkill' && contentInfo.column == 1"
        class="more-btn"
      >
        <span class="type__name">划线价前图片
          <el-tooltip
            class="tip-box"
            effect="dark"
            content="图片尺寸：40x20"
            placement="top-start"
            style="margin-left: 0px;"
          >
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>:</span>
        <CutImgUp
          :url="titleImgListOfDeletePrice"
          :auto-crop-width="68"
          :auto-crop-height="32"
          :fixed="false"
          :max-num="1"
          @change="onSuccessOfDeletePrice"
        />
      </div>
      <!-- 标题图片 -->
      <div
        v-if="contentInfo.type === 'listGoodsSkill' && toolType.showTitleImg"
        class="more-btn"
      >
        <span
          class="type__name"
          style="width: 75.8px; text-align: right;"
        >标题图片:</span>
        <CutImgUp
          :url="tImgList"
          :auto-crop-width="750"
          :auto-crop-height="69"
          :fixed-box="true"
          :max-num="1"
          @change="onSuccessTImg"
        />
        <span style="transform: translateY(85px); color: #8e8e8e;">建议尺寸：750像素*69像素</span>
      </div>
      <!-- 各种背景色 -->
      <template v-if="contentInfo.type === 'listGoodsSkill' && toolType.showOtherColor">
        <div
          v-for="(item, index) in colorList"
          :key="index"
          class="tool-item"
        >
          <span
            class="type__name"
            style="width: 100px;"
          >{{ item.label }}:</span>
          <div class="tool__content">
            <el-input
              v-model="contentInfo[item.key]"
              class="tool-bg"
            />
            <div class="color__show">
              <div
                class="color__content"
                :style="{background: contentInfo[item.key]}"
              ></div>
            </div>
          </div>
        </div>
      </template>
      <!-- 自动滚动开关 -->
      <div
        v-show="toolType.toolAutoplay"
        class="tool-item"
      >
        <span class="type__name">自动滚动:</span>
        <el-switch
          v-model="contentInfo.autoplay"
          :active-value="true"
          :inactive-value="false"
        />
      </div>
      <!-- 有效时间 -->
      <div
        v-show="toolType.toolValidTime"
        class="tool-item"
      >
        <span class="type__name">有效时间:</span>
        <el-date-picker
          v-model="contentInfo.validTime"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="mini"
          value-format="timestamp"
          @change="onTimeChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
/**
* renxy
* 样式控制器模块：行间距，背景色，间隙， 尺寸
* @param(toolType) 工具栏样式类型控制器
*/
import CutImgUp from '@/components/common/cutImgUp';
import Paging from './components/Paging';

export default {
  name: 'tool',
  components: { CutImgUp, Paging },
  props: {
    // 工具样式列表
    contentInfo: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => ({
        bgColor: '#fff',
        bMargin: false,
        bPadding: false,
        size: '312px',
        navBgColor: '#fff',
        navColor: '#000',
        selectBgColor: '#fff',
        selectColor: '#000',
        titleBgColor: '#fff',
        titleColor: '#000',
        rowTitleColor: '#000',
        subTitleColor: '#999',
        rowtoolHeight: '553',
        isShowMore: false,
        pageSize: '10',
        imgInfo: {
          type: 1,
          content: '',
          img: '',
          width: '',
          height: '',
        },
        imgInfoOfBuy: { // 购买按钮数据
          type: 1,
          content: '立即购买',
          img: '',
          width: '',
          height: '',
        },
        imgBeforeSellPrice: { // 销售价前图片
          type: 1,
          content: '',
          img: '',
          width: '',
          height: '',
        },
        imgBeforeDeletePrice: { // 划线价前图片
          type: 1,
          content: '',
          img: '',
          width: '',
          height: '',
        },
      }),
    },
    // 工具样式类型控制器
    toolType: {
      type: Object,
      default: () => ({
        toolSize: false,
        toolVertical: false,
        toolBg: false,
        toolBorder: false,
        toolTitle: false,
        toolTitleText: false,
        toolText: false,
        toolNav: false,
        toolNavText: false,
        toolSelect: false,
        toolSelectText: false,
        toolHeight: false,
        showMoreBtn: false,
        showGoodsSize: false,
        showBuyBtn: true,
        toolAutoplay: false, // 自动循环切换
        toolValidTime: false, // 有效时间
      }),
    },
  },
  data() {
    return {
      // 尺寸选项
      sizeOptions: [
        { label: '大', value: '312px' },
        { label: '中', value: '262px' },
        { label: '小', value: '212px' },
      ],
      // 边缘选项
      borderCurrentOptions: [
        { label: '有', value: true },
        { label: '没有', value: false },
      ],
      // 行间距选项
      verticalSpacingOptions: [
        { label: '有', value: true },
        { label: '没有', value: false },
      ],
      // 列数
      columnArr: [{ value: 1, label: 1 }, { value: 2, label: 2 }, { value: 3, label: 3 }],
      // 秒杀横向图 背景色、文案色设置
      colorList: [
        { label: '分组选中背景色', key: 'selectBgColor' },
        { label: '分组选中文案色', key: 'selectWordColor' },
        { label: '分组默认背景色', key: 'groupBgColor' },
        { label: '分组默认文案色', key: 'groupWordColor' },
      ],
      titleImgListOfBuy: [], // 商品购买按钮图片
      titleImgListOfSellPrice: [], // 商品购买按钮图片
      titleImgListOfDeletePrice: [], // 商品购买按钮图片
      tImgList: [], // 标题图片
    };
  },
  watch: {
  },
  mounted() {
    if (this.contentInfo.type === 'listGoodsSkill') {
      if (this.contentInfo.imgInfoOfBuy.content) this.titleImgListOfBuy = [this.contentInfo.imgInfoOfBuy.content];
      if (this.contentInfo.imgBeforeSellPrice.img) this.titleImgListOfSellPrice = [this.contentInfo.imgBeforeSellPrice.img];
      if (this.contentInfo.imgBeforeDeletePrice.img) this.titleImgListOfDeletePrice = [this.contentInfo.imgBeforeDeletePrice.img];
      if (this.contentInfo.tImg.img) this.tImgList = [this.contentInfo.tImg.img];
    }
    if (this.contentInfo.imgInfoOfBuy && this.contentInfo.imgInfoOfBuy.content) {
      this.titleImgListOfBuy = [this.contentInfo.imgInfoOfBuy.content];
    } else {
      this.titleImgListOfBuy = [];
    }
    if (this.contentInfo.imgBeforeSellPrice && this.contentInfo.imgBeforeSellPrice.img) {
      this.titleImgListOfSellPrice = [this.contentInfo.imgBeforeSellPrice.img];
    } else {
      this.titleImgListOfSellPrice = [];
    }
    if (this.contentInfo.imgBeforeDeletePrice && this.contentInfo.imgBeforeDeletePrice.img) {
      this.titleImgListOfDeletePrice = [this.contentInfo.imgBeforeDeletePrice.img];
    } else {
      this.titleImgListOfDeletePrice = [];
    }
  },
  methods: {
    onSuccessOfSellPrice(obj) {
      if (this.contentInfo.imgBeforeSellPrice) this.contentInfo.imgBeforeSellPrice.img = obj[0];
      this.titleImgListOfSellPrice = obj;
    },
    onSuccessOfDeletePrice(obj) {
      if (this.contentInfo.imgBeforeDeletePrice) this.contentInfo.imgBeforeDeletePrice.img = obj[0];
      this.titleImgListOfDeletePrice = obj;
    },
    onSuccessTImg(obj) {
      if (this.contentInfo.tImg) this.contentInfo.tImg.img = obj[0];
      this.tImgList = obj;
    },
    onTimeChange(e) {
      if (e[1] < new Date().getTime()) {
        this.contentInfo.validTime = null;
        this.$message.error('结束时间不能早于当前时间');
      }
    },
    changePaging({ image, isShowMore, pageSize }) {
      this.contentInfo.imgInfo.img = image;
      this.contentInfo.isShowMore = isShowMore;
      this.contentInfo.pageSize = pageSize;
    },
  },
};
</script>

<style scoped lang="scss">
.tool-wrapper {
  width: 100%;
  border-bottom: 1px solid #ededed;

  .upload-box{width: 100px; height: 100px;}
}
.tool {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    .tool-item {
        padding:10px 0 10px 10px;
        & span {
            padding-right: 10px;
        }
        .tool__content {
            width: 145px;
            height: 40px;
            display: inline-block;
        }
        .vertical-spacing {
            width: 100px;
        }
        .tool-bg {
            width: 100px;
            display: inline-block;
        }
        .tool-border {
            width: 100px;
        }
        .color__show {
            width: 32px;
            height: 32px;
            margin-left: 4px;
            border-radius: 4px;
            display: inline-block;
            vertical-align: top;
            border: 1px solid #979797;
            background:rgba(238,238,238,1);
            .color__content {
                width: 22px;
                height: 22px;
                margin: 5px;
            }
        }
        .type__name {
            display: inline-block;
            width: 90px;
            text-align: right;
            color:rgba(142,142,142,1);
        }
    }
}
.more-btn {
  margin: 20px;
  display: flex;
  align-items: flex-start;
  .type__name {
    display: block;
    margin-right: 20px;
    color: #8e8e8e;
  }
}
</style>
