<template>
  <div
    class="live_list"
    :style="{'border-bottom': obj.bMargin?'10px solid':'','background-color': obj.bgColor,'padding': obj.bPadding?'16px 26px':'16px 0'}"
  >
    <slot />

    <img
      style="width: 100%;"
      v-if="!(obj.contentList
        && obj.contentList.length > 0)"
      src="https://img.hzanchu.com/acimg/d712ee0805c6e8cbf104198abfbe277c.png"
      alt="">

    <div v-for="(value, index) in obj.contentList" :key="index">
      <div class="live_list_title">容器{{index + 1}} : {{ getLiveState(value.liveStatus) }}列表（{{getLiveSort(value.sortKey)}}）</div>
        <div class="live_list_one" v-if="obj.column == 1" >
        <div
          class="live_card"
          v-for="(item,i) in value.list"
          :key="i"
        >
          <img class="live_img" :src="item.coverImageId" alt="">

          <div class="live_content">
            <div class="top">
              <p>{{item.title}}</p>
              <div>{{item.actorName}}</div>
            </div>
            <div class="bottom">
              <img class="header_img" :src="item.avatarImagePath" alt="">
              <div v-if="obj.appointment === 1" class="live_content_btn">立即预约</div>
            </div>
          </div>
        </div>
      </div>
      <div class="live_list_two" v-if="obj.column > 1" >
        <div
          class="live_card"
          v-for="(item,i) in value.list"
          :key="i"
        >
          <div class="live_header">
            <img class="live_img" :src="item.coverImageId" alt="">
            <img class="header_img" :src="item.avatarImagePath" alt="">
          </div>
          <div class="live_content">
            <div class="live_content_font">
              <p>{{item.title}}</p>
              <div>{{item.actorName}}</div>
            </div>
            <div v-if="obj.appointment === 1" class="live_content_btn">预约</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bt-drag-title">
      {{ obj.showTitle }}
    </div>
  </div>
</template>

<script>
import { LIVE_STATE_OPTION, LIVE_SORT_OPTION } from '../rightTool/data.js';
export default {
  name: '',
  components: {},
  props: {
    obj: {
      type: Object,
      default: () => ({

      })
    }
  },
  data () {
    return {
      liveStateList: LIVE_STATE_OPTION, // 直播状态
      sortStateList: LIVE_SORT_OPTION, // 直播排序状态
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {

  },
  methods: {
    getLiveState(num) {
      let find = this.liveStateList.find( item => item.type === num);
      return find ? find.title : '';
    },
    getLiveSort(num) {
      let find = this.sortStateList.find( item => item.type === num);
      return find ? find.title : '';
    },
  }
}
</script>

<style scoped lang="scss">
.live_list {
  max-height: 600px;
  overflow-y: auto;
  .live_list_title {
    // position: -webkit-sticky;
    // position: sticky;
    // right: 0;
    // top: 0px;
    line-height: 30px;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    // background: #fff;
    // width: 200px;
    // border-radius: 15px;
    // margin: 0 auto;
  }
  .live_list_one {
    .live_card {
      display: flex;
      background: #fff;
      border-radius: 12px;
      overflow: hidden;
      margin: 0 10px 10px;
      box-shadow: 0px 0px 5px #ccc;
      .live_img {
        width: 147px;
        height: 147px;
        border-radius: 12px;
      }
      .live_content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 12px;
        .top {
          p {
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            margin-bottom: 4px;
            font-weight: bold;
          }
          div {
            font-size: 12px;
            line-height: 17px;
            color: #666666;
          }
        }
        .bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .header_img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
          }
          .live_content_btn {
            font-size: 14px;
            line-height: 33px;
            padding: 0 12px;
            border-radius: 17px;
            color: #fff;
            background: #599C56;
          }
        }
      }
    }
  }
  .live_list_two {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;
    .live_card {
      width: 48%;
      margin-left: 7px;
      background: #fff;
      border-radius: 12px;
      overflow: hidden;
      margin-bottom: 10px;
      box-shadow: 0px 0px 5px #ccc;
      .live_header {
        position: relative;
        width: 100%;
        .live_img {
          width: 100%;
          border-radius: 12px;
        }
        .header_img {
          position: absolute;
          bottom: -20px;
          left: 6px;
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }
      .live_content {
        position: relative;
        .live_content_font {
          padding: 26px 8px 8px;
          > p {
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            font-weight: bold;
          }
          > div {
            font-size: 12px;
            line-height: 17px;
            color: #999999;
          }
        }
        .live_content_btn {
          position: absolute;
          top: 2px;
          right: 7px;
          width: 54px;
          line-height: 22px;
          border-radius: 20px;
          text-align: center;
          background: #599C56;
          font-size: 12px;
          color: #FFFFFF;
        }
      }
    }
  }
}
</style>
