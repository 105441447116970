const CONTOR_LIST = [
//   {
//   name: '秒杀横向多图模块',
//   src: 'https://img.hzanchu.com/acimg/07c0c4ef9f95df7bc8df6bbd4d2139e8.png',
//   obj: {
//     title: '秒杀横向多图模块',
//     type: 'listGoodsSkill',
//     list: [], // 商品列表
//     groupList: [], // 选中的分组列表
//     group_id: '', // 当前选中的分组id
//     groupIds: [],
//     bgColor: '',
//     bgInfo: { img: 'https://img.hzanchu.com/acimg/07c0c4ef9f95df7bc8df6bbd4d2139e8.png', type: 'index', content: '', width: '', height: '', coupon_key: '' }
//   }
// },
// {
//   name: '大促列表排序模块',
//   src: 'https://img.hzanchu.com/acimg/07c0c4ef9f95df7bc8df6bbd4d2139e8.png',
//   obj: {
//     column: 2,
//     title: '大促列表排序模块',
//     type: 'listGoodsProm',
//     bMargin: false,
//     bgColor: '',
//     bgInfo: { img: 'https://img.hzanchu.com/acimg/07c0c4ef9f95df7bc8df6bbd4d2139e8.png', type: 'index', content: '', width: '', height: '', coupon_key: '' },
//     bPadding: false,
//     pageCtrl: {
//       open: 0,
//       page_size: '10'
//     },
//     // isShowMore: false,
//     // pageSize: '10',
//     list: [],
//     groupList: [],
//     imgInfo: {
//       type: 1,
//       content: '',
//       img: '',
//       width: '',
//       height: ''
//     }
//   }
// },
// {
//   name: '大促图文导航',
//   src: 'https://img.hzanchu.com/acimg/4c3150d4a2606a92b0788495f2a8ff0b.jpg',
//   obj: {
//     column: 2,
//     title: '大促图文导航',
//     type: 'navigationGoods',
//     pageCtrl: {
//       open: 0,
//       page_size: '10'
//     },
//     list: [{
//       name: '', // 导航标题
//       number: 0, // 展示的商品数量
//       goods_list: [], // 商品列表
//       group_id: '', // 选择的分组id
//       imgInfo: {
//         type: 1,
//         content: '',
//         img: '',
//         width: '',
//         height: ''
//       }
//     }],
//     imgInfo: {
//       type: 1,
//       content: '',
//       img: '',
//       width: '',
//       height: ''
//     },
//     navbgColor: '',
//     navColor: '#000',
//     selectbgColor: '',
//     selectColor: '#67C23A',
//     titlebgColor: '',
//     titleColor: '#000',
//     isShowMore: false,
//     pageSize: '10'
//   }
// },
  {
    name: '滚动消息（公告）',
    src: 'https://img.hzanchu.com/acimg/98967e322848c8cb4f979be43187af6f.jpg',
    obj: {
      title: '滚动消息（公告）',
      type: 'scrollNews',
      bgColor: '#fffbe8',
      bMargin: false,
      bPadding: false,
      mainColor: '#000',
      remainColor: '#000',
      lineNum: 1,
      beforeText: '',
      afterText: '',
      goodsList: [],
      info: '',
      backImg: '',
    },
  },
  // {
  //   name: '首页频道模块',
  //   src: 'https://img.hzanchu.com/acimg/bad644c9a8141497c9bec68944fc0166.png',
  //   obj: {
  //     title: '首页频道模块',
  //     type: 'homeChannel',
  //     list: [
  //       { img: '', type: 'link', link: '', title: '', subTitle: '', goodId: '', label: '', titleColor: '', subTitleColor: '', labelBack: '', labelColor: '', imgType: '', imgGoodsId: '' },
  //       { img: '', type: 'link', link: '', title: '', subTitle: '', goodId: '', label: '', titleColor: '', subTitleColor: '', labelBack: '', labelColor: '', imgType: '', imgGoodsId: '' },
  //       { img: '', type: 'link', link: '', title: '', subTitle: '', goodId: '', label: '', titleColor: '', subTitleColor: '', labelBack: '', labelColor: '', imgType: '', imgGoodsId: '' },
  //       { img: '', type: 'link', link: '', title: '', subTitle: '', goodId: '', label: '', titleColor: '', subTitleColor: '', labelBack: '', labelColor: '', imgType: '', imgGoodsId: '' }
  //     ],
  //     bgColor: '',
  //     bMargin: false,
  //     bPadding: false
  //   }
  // },
  // {
  //   name: '首页列表模块',
  //   src: 'https://img.hzanchu.com/acimg/bad644c9a8141497c9bec68944fc0166.png',
  //   obj: {
  //     title: '首页列表模块',
  //     type: 'homeRow',
  //     list: [
  //       { title: '', subTitle: '', goodsList: [], unique: '0' },
  //       { title: '', subTitle: '', goodsList: [], unique: '0' },
  //       { title: '', subTitle: '', goodsList: [], unique: '0' },
  //       { title: '', subTitle: '', goodsList: [], unique: '0' }
  //     ],
  //     bgColor: '',
  //     bMargin: false,
  //     bPadding: false,
  //     rowTitleColor: '#000',
  //     subTitleColor: '#999'
  //   }
  // },
  {
    name: '轮播图',
    src: 'https://img.hzanchu.com/acimg/6f1349a8e55de0e942fb5509475f0521.jpg',
    obj: {
      title: '轮播图',
      type: 'swiper',
      list: [{
        type: 'index',
        img: '',
        content: '',
        coupon_key: '',
      }],
      bMargin: false,
      bgColor: '',
    },
  }, {
    name: '静态图',
    src: 'https://img.hzanchu.com/acimg/98967e322848c8cb4f979be43187af6f.jpg',
    obj: {
      title: '静态图',
      type: 'staticImg',
      size: '212px',
      bMargin: false,
      bgColor: '',
      bPadding: false,
      list: [{
        type: 'index',
        img: '',
        content: '',
        coupon_key: '',
      }],
    },
  }, {
    name: '自定义静态图(新)',
    src: 'https://img.hzanchu.com/acimg/98967e322848c8cb4f979be43187af6f.jpg',
    obj: {
      title: '自定义静态图(新)',
      type: 'staticSelfImg',
      size: '212px',
      bMargin: false,
      bgColor: '',
      bPadding: false,
      list: [{
        img: '',
        content: '',
        coupon_key: '',
        self: [
          {
            top: 30,
            left: 0,
            width: 20,
            height: 20,
            type: 'index',
            content: '',
          },
        ],
      }],
    },
  }, {
    name: '轮播商品',
    src: 'https://img.hzanchu.com/acimg/fe8ac09d7174f1b218e89b2c3f2bf000.jpg',
    obj: {
      title: '轮播商品',
      type: 'swiperGoods',
      list: [],
      bMargin: false,
      bgColor: '',
      bPadding: false,
    },
  }, {
    name: '列表商品',
    src: 'https://img.hzanchu.com/acimg/e21aab713b254226eb46882a65baf1c2.jpg',
    obj: {
      column: 2,
      title: '列表商品',
      type: 'listGoods',
      bMargin: false,
      bgColor: '',
      bPadding: false,
      pageCtrl: {
        open: 0,
        page_size: '10',
      },
      isShowMore: false,
      pageSize: '10',
      list: [],
      imgInfo: {
        type: 1,
        content: '',
        img: '',
        width: '',
        height: '',
      },
    },
  }, {
    name: '标题',
    src: 'https://img.hzanchu.com/acimg/a89e55ff936eb0145f42477828e6f41a.jpg',
    obj: {
      title: '标题',
      type: 'title',
      bgColor: '',
      color: '#000',
      text: '',
      link: '',
    },
  }, {
    name: '类目',
    src: 'https://img.hzanchu.com/acimg/4c3150d4a2606a92b0788495f2a8ff0b.jpg',
    obj: {
      column: 2,
      title: '类目',
      type: 'cateGoods',
      pageCtrl: {
        open: 0,
        page_size: '10',
      },
      list: [{
        name: '',
        goods_list: [],
        imgInfo: {
          type: 1,
          content: '',
          img: '',
          width: '',
          height: '',
        },
      }],
      imgInfo: {
        type: 1,
        content: '',
        img: '',
        width: '',
        height: '',
      },
      navbgColor: '',
      navColor: '#000',
      selectbgColor: '',
      selectColor: '#67C23A',
      titlebgColor: '',
      titleColor: '#000',
      isShowMore: false,
      pageSize: '10',
    },
  }, {
    name: '三图模块',
    src: 'https://img.hzanchu.com/acimg/8e51e0635894ae7e035c1de0fee90fbc.png',
    obj: {
      title: '三图模块',
      type: 'imgMode1',
      list: [
        {
          img: '', type: 'index', content: '', coupon_key: '',
        },
        {
          img: '', type: 'index', content: '', coupon_key: '',
        },
        {
          img: '', type: 'index', content: '', coupon_key: '',
        },
      ],
      bgColor: '',
    },
  }, {
    name: '左右图模块',
    src: 'https://img.hzanchu.com/acimg/c713a04c55eb58bb6bca869b29780eab.png',
    obj: {
      title: '左右图模块',
      type: 'imgMode2',
      list: [
        {
          img: '', type: 'index', content: '', width: '', height: '', coupon_key: '',
        },
        {
          img: '', type: 'index', content: '', width: '', height: '', coupon_key: '',
        },
        {
          img: '', type: 'index', content: '', width: '', height: '', coupon_key: '',
        },
        {
          img: '', type: 'index', content: '', width: '', height: '', coupon_key: '',
        },
        {
          img: '', type: 'index', content: '', width: '', height: '', coupon_key: '',
        },

      ],
      bgColor: '',
    },
  }, {
    name: '上下图模块',
    src: 'https://img.hzanchu.com/acimg/530b8a08af5e68529616136dcd1a4fde.png',
    obj: {
      title: '上下图模块',
      type: 'imgMode3',
      list: [
        {
          img: '', type: 'index', content: '', width: '', height: '', coupon_key: '',
        },
        {
          img: '', type: 'index', content: '', width: '', height: '', coupon_key: '',
        },
        {
          img: '', type: 'index', content: '', width: '', height: '', coupon_key: '',
        },
        {
          img: '', type: 'index', content: '', width: '', height: '', coupon_key: '',
        },
        {
          img: '', type: 'index', content: '', width: '', height: '', coupon_key: '',
        },
      ],
      bgColor: '',
    },
  }, {
    name: '四图模块',
    src: 'https://img.hzanchu.com/acimg/3f93d6ee094d1116273bb6dd0f169de5.png',
    obj: {
      title: '四图模块',
      type: 'imgMode4',
      list: [
        {
          img: '', type: 'index', content: '', width: '', height: '', coupon_key: '',
        },
        {
          img: '', type: 'index', content: '', width: '', height: '', coupon_key: '',
        },
        {
          img: '', type: 'index', content: '', width: '', height: '', coupon_key: '',
        },
        {
          img: '', type: 'index', content: '', width: '', height: '', coupon_key: '',
        },
      ],
      bgColor: '',
    },
  }, {
    name: '两图模块',
    src: 'https://img.hzanchu.com/acimg/93ddf9f8751891e40f20e9ec5b075970.png',
    obj: {
      title: '两图模块',
      type: 'imgMode5',
      list: [
        {
          img: '', type: 'index', content: '', width: '', height: '', coupon_key: '',
        },
        {
          img: '', type: 'index', content: '', width: '', height: '', coupon_key: '',
        },
      ],
      bgColor: '',
    },
  }, {
    name: '横向三图模块',
    src: 'https://img.hzanchu.com/acimg/1d676a1fc8dbadd0dbad8abe07bd2c53.png',
    obj: {
      title: '横向三图模块',
      type: 'imgMode12',
      list: [
        {
          img: '', type: 'index', content: '', width: '', height: '', coupon_key: '',
        },
        {
          img: '', type: 'index', content: '', width: '', height: '', coupon_key: '',
        },
        {
          img: '', type: 'index', content: '', width: '', height: '', coupon_key: '',
        },
      ],
      bgColor: '',
    },
  }, {
    name: '横向四图模块',
    src: 'https://img.hzanchu.com/acimg/bad644c9a8141497c9bec68944fc0166.png',
    obj: {
      title: '横向四图模块',
      type: 'imgMode6',
      list: [
        {
          img: '', type: 'index', content: '', width: '', height: '', coupon_key: '',
        },
        {
          img: '', type: 'index', content: '', width: '', height: '', coupon_key: '',
        },
        {
          img: '', type: 'index', content: '', width: '', height: '', coupon_key: '',
        },
        {
          img: '', type: 'index', content: '', width: '', height: '', coupon_key: '',
        },
      ],
      bgColor: '',
    },
  }, {
    name: '多图模块一',
    src: 'https://img.hzanchu.com/acimg/07c0c4ef9f95df7bc8df6bbd4d2139e8.png',
    obj: {
      title: '多图模块一',
      type: 'imgMode10',
      list: [],
      bgColor: '',
      bgInfo: {
        img: 'https://img.hzanchu.com/acimg/07c0c4ef9f95df7bc8df6bbd4d2139e8.png', type: 'index', content: '', width: '', height: '', coupon_key: '',
      },
    },
  }, {
    name: '视频模块',
    src: 'https://img.hzanchu.com/acimg/d712ee0805c6e8cbf104198abfbe277c.png',
    obj: {
      title: '视频模块',
      type: 'video',
      size: '212px',
      bMargin: false,
      bgColor: '',
      bPadding: false,
      videoId: '', // 视频id
      coverURL: '', // 封面图
      videoUrl: '', // 视频链接
      videoHeight: 190, // 视频高度 默认190用于移动端
    },
  },
  // {
  //   name: '导览跳转模块',
  //   src: 'https://wsnbh.oss-cn-hangzhou.aliyuncs.com/acimg/1636351397908.png',
  //   obj: {
  //     title: '导览跳转模块',
  //     type: 'mapButton',
  //     imageUrl: 'https://nb-img.hzanchu.com/test/76990157516013568.png',
  //     list: [],
  //     jumpType: '1', // 1:打开地图，需要输入经纬度；2：打开路线，需要输入路线id
  //     longitude: '',
  //     latitude: '',
  //     routeId: ''
  //   }
  // },
  {
    name: '搜索',
    src: 'https://img.hzanchu.com/acimg/98967e322848c8cb4f979be43187af6f.jpg',
    obj: {
      title: '搜索',
      type: 'search',
      bMargin: false,
      size: '212px',
      bgColor: '',
      bPadding: false,
      img: '',
      content: '',
      coupon_key: '',
      top: 0,
      left: 0,
      width: 20,
      height: 50,
      zIndex: 99,
      promIdList: '',
      siteIdList: [],
      storeId: localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo')).defaultStoreId ? JSON.parse(localStorage.getItem('userInfo')).defaultStoreId : localStorage.getItem('defaultStoreId'),
    },
  },
];

const TOOL_OBJ = {
  listGoodsSkill: {
    toolBg: true,
    toolVertical: true,
    toolBorder: true,
    toolRow: true,
    showMoreBtn: true,
    showGoodsSize: true,
  },
  swiper: {
    toolVertical: true,
    toolBg: true,
  },
  staticImg: {
    toolSize: true,
    toolVertical: true,
    toolBg: true,
    toolBorder: true,
  },
  staticSelfImg: {
    toolSize: true,
    toolVertical: true,
    toolBg: true,
    toolBorder: true,
  },
  swiperGoods: {
    toolBg: true,
    toolVertical: true,
    toolBorder: true,
  },
  listGoods: {
    toolBg: true,
    toolVertical: true,
    toolBorder: true,
    toolRow: true,
    showMoreBtn: true,
    showGoodsSize: true,
  },
  title: {
    toolBg: true,
    toolText: true,
  },
  cateGoods: {
    toolTitle: true,
    toolTitleText: true,
    toolNav: true,
    toolNavText: true,
    toolSelect: true,
    toolSelectText: true,
    toolRow: true,
    showMoreBtn: true,
    showGoodsSize: true,
  },
  imgMode1: {
    toolBg: true,
    toolVertical: true,
    toolBorder: true,
  },
  imgMode2: {
    toolBg: true,
    toolVertical: true,
    toolBorder: true,
  },
  imgMode3: {
    toolBg: true,
    toolVertical: true,
    toolBorder: true,
  },
  imgMode4: {
    toolBg: true,
    toolVertical: true,
    toolBorder: true,
  },
  imgMode5: {
    toolBg: true,
    toolVertical: true,
    toolBorder: true,
  },
  imgMode6: {
    toolBg: true,
    toolVertical: true,
    toolBorder: true,
  },
  imgMode10: {
    toolBg: true,
    toolVertical: true,
    toolBorder: true,
    toolHeight: true,
  },
  imgMode12: {
    toolBg: true,
    toolVertical: true,
    toolBorder: true,
  },
  video: {
    toolBg: true,
    toolVertical: true,
    toolBorder: true,
  },
  homeRow: {
    toolBg: true,
    toolVertical: true,
    toolBorder: true,
    homeRowTitle: true,
    homeRowSubtitle: true,
  },
  scrollNews: {
    toolBg: true,
    toolVertical: true,
    toolBorder: true,
  },
  homeChannel: {
    toolBg: true,
    toolVertical: true,
    toolBorder: true,
  },
  search: {
    toolVertical: true,
    toolBg: true,
    toolBorder: true,
  },
};

export {
  CONTOR_LIST,
  TOOL_OBJ,
};
