<template>
  <div
    class="home-channel"
    :style="{'background-color': obj.bgColor, 'margin-bottom': obj.bMargin ? '10px' : '0', 'padding': obj.bPadding ? '20px 10px' : '20px 0'}"
  >
    <div
      v-for="(item, index) in obj.list"
      :key="index"
      :style="{'border-right': index === 1 ? '1px solid #ccc' : 'none'}"
      class="home-row-item"
    >
      <div class="title-box">
        <h2 :style="{'color': item.titleColor}">
          {{ item.title }}
          <span
            v-if="(index === 0 || index === 2)"
            :style="{'background-color': item.labelBack, 'color': item.labelColor}"
          >
            {{ item.label }}
          </span>
        </h2>
        <p :style="{'color': item.subTitleColor}">
          {{ item.subTitle }}
        </p>
      </div>
      <div class="img-box">
        <img
          :src="item.img === ''? preurl1 : item.img"
          alt="频道图"
        >
      </div>
    </div>
    <div class="bt-drag-title">
      {{ obj.title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    obj: {
      type: Object,
      default: () => ({
        title: '首页频道模块',
        list: []
      })
    }
  },
  data() {
    return {
      preurl1: 'https://img.hzanchu.com/acimg/96730e5c698b7c713247c61023de93d2.png?x-oss-process=image/resize,w_300'
    }
  }
}
</script>

<style scoped lang="scss">
.home-channel {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  justify-content: space-around;
  .home-row-item {
    flex: 1;
    margin-left: 10px;
    .title-box {
      h2 {
        width: 100%;
        height: 20px;
        line-height: 20px;
        font-size: 16px;
        font-weight: bolder;
        margin-bottom: 5px;
        margin-right: 5px;
        position: relative;
        span {
          display: inline-block;
          padding: 0 5px;
          font-size: 10px;
          height: 16px;
          line-height: 16px;
          position: absolute;
          top: 1px;
          right: -6px;
        }
      }
      p {
        width: 100%;
        color: #999;
        height: 16px;
        line-height: 16px;
      }
    }
    .img-box {
      margin-top: 5px;
      img {
        width: 85%;
        height: auto;
      }
    }
  }
}
</style>
