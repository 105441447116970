var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "style-list-tool" },
    [
      _c(
        "el-form",
        { attrs: { model: _vm.info, "label-width": "120px" } },
        [
          _c(
            "el-form-item",
            { staticClass: "name-box", attrs: { label: "类型：" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.info.resource,
                    callback: function ($$v) {
                      _vm.$set(_vm.info, "resource", $$v)
                    },
                    expression: "info.resource",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v(" 列表 ")]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v(" 标签 ")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.info.resource == 1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "100px",
                    label: "样式：",
                    prop: "categoryId",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "90%" },
                      attrs: {
                        filterable: "",
                        remote: "",
                        "reserve-keyword": "",
                        placeholder: "请输入关键词搜索样式",
                        "remote-method": _vm.remoteMethod,
                        loading: _vm.loading,
                      },
                      on: { change: _vm.changeCategory },
                      model: {
                        value: _vm.info.categoryId,
                        callback: function ($$v) {
                          _vm.$set(_vm.info, "categoryId", $$v)
                        },
                        expression: "info.categoryId",
                      },
                    },
                    _vm._l(_vm.categoryOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.info.resource == 2
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { staticClass: "name-box", attrs: { label: "吸顶效果：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.info.ceiling,
                            callback: function ($$v) {
                              _vm.$set(_vm.info, "ceiling", $$v)
                            },
                            expression: "info.ceiling",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v(" 有 "),
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v(" 无 "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._l(_vm.info.categoryIdList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "list-item" },
                      [
                        _c("el-form-item", {
                          staticClass: "title",
                          attrs: {
                            "label-width": "100px",
                            label: "标签" + (index + 1) + "：",
                          },
                        }),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "128px",
                              label: "默认选中该标签",
                            },
                          },
                          [
                            _c("el-switch", {
                              on: {
                                change: function ($event) {
                                  return _vm.changeDefault(item, index)
                                },
                              },
                              model: {
                                value: item.isSelect,
                                callback: function ($$v) {
                                  _vm.$set(item, "isSelect", $$v)
                                },
                                expression: "item.isSelect",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "name-box",
                            attrs: {
                              "label-width": "100px",
                              label: "标签名称：",
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: item.titleType,
                                  callback: function ($$v) {
                                    _vm.$set(item, "titleType", $$v)
                                  },
                                  expression: "item.titleType",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v(" 文字 "),
                                ]),
                                _c("el-radio", { attrs: { label: 2 } }, [
                                  _vm._v(" 图片 "),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        item.titleType === 1
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "100px",
                                  label: "标签名称：",
                                  placeholder: "请输入标签名称",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "6",
                                    "show-word-limit": "",
                                  },
                                  model: {
                                    value: item.title,
                                    callback: function ($$v) {
                                      _vm.$set(item, "title", $$v)
                                    },
                                    expression: "item.title",
                                  },
                                }),
                                _c("span", { staticClass: "name-box-tip" }, [
                                  _vm._v("占位请使用$符号"),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        item.titleType === 2
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "100px",
                                  label: "标签图片：",
                                },
                              },
                              [
                                _c("loadImgOssUp", {
                                  attrs: {
                                    name: "a" + index,
                                    url: item.titleImg ? [item.titleImg] : [],
                                    "max-num": 1,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.uoload($event, item)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "100px",
                              label: "样式：",
                              prop: "categoryId",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  filterable: "",
                                  remote: "",
                                  "reserve-keyword": "",
                                  placeholder: "请输入关键词搜索样式",
                                  "remote-method": _vm.remoteMethodList,
                                  loading: _vm.loadingList,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.changeCategoryList(item)
                                  },
                                },
                                model: {
                                  value: item.categoryId,
                                  callback: function ($$v) {
                                    _vm.$set(item, "categoryId", $$v)
                                  },
                                  expression: "item.categoryId",
                                },
                              },
                              _vm._l(_vm.categoryOptionsList, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "action-move" }, [
                          index !== 0
                            ? _c(
                                "span",
                                {
                                  staticClass: "actions_up",
                                  on: {
                                    click: function ($event) {
                                      return _vm.upItem(item, index)
                                    },
                                  },
                                },
                                [_vm._v(" 上移 ")]
                              )
                            : _vm._e(),
                          index !== _vm.info.categoryIdList.length - 1
                            ? _c(
                                "span",
                                {
                                  staticClass: "actions_down",
                                  on: {
                                    click: function ($event) {
                                      return _vm.downItem(item, index)
                                    },
                                  },
                                },
                                [_vm._v(" 下移 ")]
                              )
                            : _vm._e(),
                        ]),
                        _vm.info.categoryIdList.length > 1
                          ? _c(
                              "span",
                              {
                                staticClass: "actions_b",
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteitem(item, index)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            )
                          : _vm._e(),
                        _vm.info.categoryIdList.length === index + 1
                          ? _c(
                              "span",
                              {
                                staticClass: "actions_add",
                                on: {
                                  click: function ($event) {
                                    return _vm.add()
                                  },
                                },
                              },
                              [_vm._v(" 添加 ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }