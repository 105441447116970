<!-- 预约组件 -->
<template>
  <div class="appointmentTool">

    <!-- <el-tabs v-model="activeIndex" type="card">
      <el-tab-pane
        v-for="(item,i) in info.list"
        :key="i"
        :label="'预约按钮' + (i + 1)"
        :name="String(i)"></el-tab-pane>
    </el-tabs> -->

<!-- width: {{info.list[activeIndex].style.width}}
height: {{info.list[activeIndex].style.height}}
top: {{info.list[activeIndex].style.top}}
left: {{info.list[activeIndex].style.left}} -->

    <el-row type="flex" align="middle" :gutter="20" style="margin-bottom: 20px">
      <el-col :span="5" style="text-align: left; "><span style="color: red">*</span>预约活动：</el-col>
      <el-col :span="19">
        <el-input
          v-model.trim="info.list[activeIndex].activityName"
          maxlength="20"
          show-word-limit
          style="width: 360px"
          size="small"
          placeholder="请输入活动名称"
          />
      </el-col>
    </el-row>

    <el-row type="flex" align="top" :gutter="20" style="margin-bottom: 20px">
      <el-col :span="5" style="text-align: left; "><span style="color: red">*</span>状态图片：</el-col>
      <el-col :span="19">
        <el-col :span="12" style="text-align: left; ">
          <div style="margin-bottom: 10px">预约前图片</div>
          <UpImage :imageList.sync="info.list[activeIndex].imageFront"></UpImage>
        </el-col>
        <el-col :span="12" style="text-align: left; ">
          <div style="margin-bottom: 10px">预约后图片</div>
          <UpImage :imageList.sync="info.list[activeIndex].imageBack"></UpImage></el-col>
      </el-col>
    </el-row>

    <el-row type="flex" align="middle" :gutter="20" style="margin-bottom: 20px">
      <el-col :span="8" style="text-align: left; ">需要用户关注公众号：</el-col>
      <el-col :span="16">
        <el-switch
          v-model="info.list[activeIndex].needFollowOfficialAccounts"
          active-color="#1890ff"
          inactive-color="#d1d6dd"
          :active-value="1"
          :inactive-value="0" >
        </el-switch>
      </el-col>
    </el-row>

    <el-row type="flex" align="top" :gutter="20" style="margin-bottom: 20px">
      <el-col :span="6" style="text-align: left; "><span style="color: red">&nbsp;</span>消息提醒：</el-col>
      <el-col :span="18">
        <div>
          <el-switch
            v-model="info.list[activeIndex].isNotify"
            active-color="#1890ff"
            inactive-color="#d1d6dd"
            :active-value="1"
            :inactive-value="0" >
          </el-switch>
        </div>
      </el-col>
    </el-row>

    <div v-if="info.list[activeIndex].isNotify === 1">
      <el-row type="flex" align="top" :gutter="20" style="margin-bottom: 20px">
        <el-col :span="6" style="text-align: left; "></el-col>
        <el-col :span="18">
          <el-checkbox-group v-model="myMsgType">
            <div>
              <el-checkbox label="1">站内信服务通知</el-checkbox>
              <el-checkbox label="2">push</el-checkbox>
              <div style="font-size: 14px; color: #F59A23; margin: 10px 0 15px">您预约的XX活动将于￥活动开始时间￥开始~</div>
            </div>
            <div>
              <el-checkbox label="3">
              微信公众号
              </el-checkbox>
            </div>
          </el-checkbox-group>
        </el-col>
      </el-row>

      <el-row type="flex" align="middle" :gutter="20" style="margin-bottom: 20px">
        <el-col :span="6" style="text-align: left; "><span style="color: red">*</span>活动开始时间：</el-col>
        <el-col :span="18">
          <div>
            <el-date-picker
              v-model="info.list[activeIndex].activityStartTime"
              type="datetime"
              value-format="timestamp"
              placeholder="请选择活动开始时间"
              size="mini">
            </el-date-picker>
          </div>
        </el-col>
      </el-row>

      <el-row type="flex" align="middle" :gutter="20" style="margin-bottom: 20px">
        <el-col :span="6" style="text-align: left; "><span style="color: red">*</span>提醒时间：</el-col>
        <el-col :span="18">
          <div>
            <el-date-picker
              v-model="info.list[activeIndex].notifyTime"
              type="datetime"
              value-format="timestamp"
              placeholder="请选择提醒时间"
              size="mini">
            </el-date-picker>
          </div>
        </el-col>
      </el-row>

      <el-row type="flex" align="middle" :gutter="20" style="margin-bottom: 20px">
        <el-col :span="6" style="text-align: left; "><span style="color: red">*</span>消息跳转类型：</el-col>
        <el-col :span="18">
          <el-select
            v-model="info.list[activeIndex].jumpType"
            size="mini"
            style="width: 220px"
            placeholder="请选择消息跳转类型"
          >
            <el-option
              v-for="(item, index) in selectOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        align="middle"
        :gutter="20"
        style="margin-bottom: 20px"
        v-if="!(notShowInput.includes(info.list[activeIndex].jumpType))">
        <el-col :span="6" style="text-align: left; "><span style="color: red">*</span>跳转ID或链接：</el-col>
        <el-col :span="18">
          <!--ID或链接-->
          <el-input
            style="width: 220px"
            v-model="info.list[activeIndex].jumpValue"
            size="mini"
            placeholder="请输入消息跳转ID或链接"
          />
        </el-col>
      </el-row>
    </div>

  </div>
</template>

<script>
import { SELECT_OPTIONS, NOT_SHOW_INPUT } from './data'
import UpImage from '@/components/common/upImage/index';
export default {
  name: '',
  components: {
    UpImage
  },
  props: {
    info: {
      type: Object,
      default: {
//info.list[activeIndex].msgType
      }
    },
  },
  data () {
    return {
      activeIndex: 0,
      myMsgType: [],
      selectOptions: [
        { label: '首页', value: 1 },
        { label: '逛农博', value: 2 },
        { label: '购物车', value: 3 },
        { label: '我的', value: 4 },
        { label: 'h5链接', value: 5 },
        { label: '商品', value: 6 },
        { label: '直播', value: 7 },
        { label: '消息中心', value: 8 },
      ],
      notShowInput: [
        1, 2, 3, 4, 8
      ],
    }
  },
  computed: {},
  watch: {
    myMsgType: {
      handler(val) {
        this.info.list[this.activeIndex].msgType = this.myMsgType.join(',');
      }
    }
  },
  created () {},
  mounted () {
    console.log(this.info,'预约组件工具======')
    if(this.info.list[this.activeIndex].msgType) {
      this.myMsgType = this.info.list[this.activeIndex].msgType.split(',');
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.appointmentTool {
  padding: 10px 20px;
}
</style>
