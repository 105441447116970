var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "video-info" },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "100px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "视频上传:" } },
            [
              _c("UploadVideoOss", {
                attrs: { url: _vm.info.videoUrl },
                on: { success: _vm.videoSuccess },
              }),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "视频链接:" } }, [
            _vm._v(" " + _vm._s(_vm.info.videoUrl) + " "),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "视频封面图:" } },
            [
              _c("CutImgUp", {
                attrs: {
                  url: _vm.imageUrlList,
                  "auto-crop-width": 750,
                  "auto-crop-height": 397,
                  "max-num": 1,
                },
                on: { change: _vm.onSuccess },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "视频高度:" } },
            [
              _c("el-input-number", {
                attrs: { size: "mini", min: 190 },
                model: {
                  value: _vm.info.videoHeight,
                  callback: function ($$v) {
                    _vm.$set(_vm.info, "videoHeight", $$v)
                  },
                  expression: "info.videoHeight",
                },
              }),
              _c("p", [
                _vm._v(
                  "*视频高度参数用于PC端活动页，移动端页面请使用默认值190"
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }