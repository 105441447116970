<template>
    <div>
      <div class="swiper mySwiper" :style="{'margin-bottom':obj.bMargin?'10px solid':'0','background-color':obj.bgColor, 'padding': obj.bPadding ? '10px 12px' : 0}">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item,index) in obj.list" :key="index">
            <img :src="[item.img == ''? preurl : item.img + '?x-oss-process=image/resize,w_375']"/>
          </div>
        </div>
      </div>
      <div class="bt-drag-title">
        {{ obj.showTitle }}
      </div>
    </div>
  </template>
  
  <script>
  import Swiper from 'swiper';
  
  export default {
    props:{
      obj:{
        type:Object,
        default:{
          bMargin:false,
          bgColor:'#fff',
          bPadding: false,
          title:'横向滑动广告图',
          list: [
            {
              type: '',
              content: '',
              img: ''
            }
          ],
        }
      }
    },
    watch: {
      'obj.list': {
        immediate: true,
        deep: true,
        handler(){
          this.$nextTick(() => {
            window.dispatchEvent(new Event('resize'));
          })
        }
      }
    },
    data(){
      return{
        swiper:null,
        preurl: 'https://img.hzanchu.com/acimg/98967e322848c8cb4f979be43187af6f.jpg'
      }
    },
    mounted(){
      this.swiperInit();
    },
    methods:{
      swiperInit(){
          this.swiper = new Swiper(".mySwiper", {
          slidesPerView: 3,
          spaceBetween: 5,
          pagination: {
            clickable: true
          }
        });
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
    .swiper {
      overflow: hidden;
      position: relative;
      width:100%;
    }
    .swiper-slide {
      height: 120px;
    }
    .swiper-slide img {
      width: 100%;
      height: 100%;
    }
  </style>
  