<template>
  <div class="add__goods">
    <div class="goods__name">
      <p>商品：</p>
    </div>
    <div class="goods__list">
      <div class="list__content">
        <ul
          v-for="(item, index) in addscroll.list"
          :key="index"
        >
          <li>
            <span class="item-id">{{ item.goodsId }}</span>
            <p>{{ strShow(item.name) }}</p>
            <span :class="[item.isOnSale===1?'':'red']">{{ item.isOnSale===1?'已上架':'已下架' }}</span>
            <span
              class="delet-btn"
              style="margin: 0;"
              @click="delGoods(index)"
            >删除</span>
          </li>
        </ul>
      </div>
      <div class="list__add">
        <input
          v-model="goodsInfo"
          type="text"
          placeholder="输入商品id,可输入多个商品,多个商品id之间以英文“,”区分"
          @keydown.enter="addGoods"
          @input="(e)=>{ goodsInfo = e.replace(/[^0-9,]/g, '') }"
        /> <span @click="addGoods"> 立即添加</span>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 商品列表组件
 * @module addGoods
 * @param {Object} addscroll
 */

// 限制商品名称显示字数
import qs from 'qs';
import { strLimit } from '@/assets/js/common';

export default {
  name: 'add-goods-list',

  props: {
    addscroll: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        list: {},
      },
    },
  },

  data() {
    return {
      goodsInfo: '',
      toast: '',
      is_index: 0, // 1代表是，0代表不是
    };
  },
  mounted() {
    if (this.$route.query.type === 'index') {
      this.is_index = 1;
    } else {
      this.is_index = 0;
    }
    let arr = [];
    this.addscroll.list.forEach((i) => {
      arr.push(i.goodsId);
    });
    this.goodsInfo = arr.join(',');
  },
  methods: {
    // 删除商品
    delGoods(index) {
      this.addscroll.list.splice(index, 1);
      let arr = [];
      this.addscroll.list.forEach((i) => {
        arr.push(i.goodsId);
      });
      this.goodsInfo = arr.join(',');
    },
    // 添加商品
    addGoods() {
      let that = this;
      if (this.goodsInfo === '') {
        this.$message('请填写商品信息');
      } else {
        let array = this.goodsInfo.split(',');
        // 去重
        let temp = [];
        for (let i = 0; i < array.length; i++) {
          if (temp.indexOf(array[i]) === -1) {
            temp.push(array[i]);
          }
        }
        this.goodsInfo = temp.join(',');
        this.$axios.post(
          that.$api.tradePluginTemplate.selectGoods,
          qs.stringify({
            goodsIds: that.goodsInfo,
            is_index: that.is_index,
            type: that.addscroll.type,
          }),
        ).then((res) => {
          let data = res;
          if (data.error === 1) {
            that.$message.error(data.error_msg);
          }
          if (data.code === 0) {
            if (data.data.length === 0) {
              that.$message('找不到该id对应的商品');
              return null;
            }
            that.addscroll.list = data.data;
          }
        });
      }
    },
    // 限制显示字数
    strShow(item) {
      if (item == null) {
        //
      } else {
        return strLimit(item, 16);
      }
    },
    // 筛选重复商品
    filterGoods(item) {
      let goodsRule = item.split(',');
    },
  },

};
</script>

<style lang="scss" scoped>
.add__goods {
    width: calc(100% - 30px);
    height: 310px;
    border-top: 1px solid #EDEDED;
    margin: 0 15px;
    padding-top: 15px;
    .goods__name{
        width: 100px;
        height: 100%;
        float: left;
        & p{
            text-align: right;
            color: #8E8E8E;
            overflow: hidden;
        }
    }
    .goods__list {
        float: left;
        .list__content{
            width: 380px;
            height: 220px;
            border:1px solid rgba(151,151,151,1);
            overflow-y: scroll;
            overflow-x: hidden;
            border-radius:2px;
            ul > li {
              display: flex;
            }
            & p {
              width: 200px;
              height: 20px;
              display: inline-block;
              margin-right: 10px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            & span{
              display: inline-block;
              cursor: pointer;
              margin-right: 10px;
              white-space: nowrap;
            }
            .item-id {
              width: 50px;
            }
        }
        .list__add{
            margin-top: 10px;
            width: 390px;
            height: 34px;
            & input {
                width: 300px;
                height: 34px;
                float: left;
                border: 1px solid #979797;
            }
            & input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
                font-size: 12px;
            }
             & input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                font-size: 12px;
            }
             & input::-moz-placeholder { /* Mozilla Firefox 19+ */
                font-size: 12px;
            }
             & input:-ms-input-placeholder { /* Internet Explorer 10-11 */
                font-size: 12px;
            }
            & span {
                width: 80px;
                height: 34px;
                line-height: 34px;
                text-align: center;
                float: left;
                border: 1px solid #979797;
                border-left: none;
                background:rgba(240,240,240,1);
                cursor: pointer;
            }
        }
        .add__toast {
            color: #FF3301;
        }
    }
}
.delet-btn {
  color: red;
  cursor: pointer;
}
</style>
