var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "subject-main" },
    [
      _c("page-header", [
        _c("div", { staticClass: "center" }, [
          _c(
            "div",
            [
              _c("span", { staticClass: "margin-r100" }, [
                _vm._v("活动名称：" + _vm._s(_vm.subjectName)),
              ]),
              _c("span", [_vm._v("活动链接：" + _vm._s(_vm.currentlink))]),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "20px" },
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.qrShow = true
                    },
                  },
                },
                [_vm._v(" 预 览 ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  staticStyle: { float: "right", margin: "0 20px" },
                  attrs: {
                    type: "primary",
                    size: "mini",
                    loading: _vm.subjectBtnLoading,
                  },
                  on: { click: _vm.submitSubject },
                },
                [_vm._v(" 保 存 ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { size: "mini" },
                  on: { click: _vm.backGo },
                },
                [_vm._v(" 取 消 ")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "edit-subject" },
        [
          _c("div", { staticClass: "subject-left" }, [
            _c("div", { staticClass: "sub-cont-title" }, [
              _vm._v(" 选择要添加模块拖动到预览区 "),
            ]),
            _c(
              "div",
              { staticClass: "sub-cont-content" },
              [
                _c(
                  "draggable",
                  {
                    attrs: {
                      element: "div",
                      group: { name: "description", pull: "clone", put: false },
                      sort: false,
                    },
                    model: {
                      value: _vm.contorlList,
                      callback: function ($$v) {
                        _vm.contorlList = $$v
                      },
                      expression: "contorlList",
                    },
                  },
                  _vm._l(_vm.contorlList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "sub-cont-box" },
                      [
                        _c("img", { attrs: { src: item.src, width: "100%" } }),
                        _vm._v(" " + _vm._s(item.name) + " "),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "subject-content" }, [
            _c("div", { staticClass: "preview" }, [
              _c(
                "div",
                { staticClass: "preview-title", on: { click: _vm.selectPage } },
                [
                  _vm._v(" " + _vm._s(_vm.subjectName) + " "),
                  _c("i", {
                    staticClass: "setting-btn el-icon-setting",
                    on: { click: _vm.selectPage },
                  }),
                ]
              ),
              _c(
                "div",
                { staticClass: "preview-content", style: _vm.setStyle },
                [
                  _c(
                    "draggable",
                    {
                      staticClass: "list-group",
                      attrs: {
                        element: "div",
                        animation: "200",
                        group: "description",
                        "ghost-class": "ghost",
                        move: _vm.onMove,
                      },
                      on: { add: _vm.addModel },
                      model: {
                        value: _vm.contentList,
                        callback: function ($$v) {
                          _vm.contentList = $$v
                        },
                        expression: "contentList",
                      },
                    },
                    _vm._l(_vm.contentList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: item.sort,
                          ref: "previewname",
                          refInFor: true,
                          class: [
                            "preview-item",
                            _vm.currentIndex === item.sort && "active-model",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.selectPreview(item)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "preview-item-move-box" }, [
                            _c(
                              "div",
                              [
                                _c("el-button", {
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-caret-top",
                                    size: "mini",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.movePreview(item, index, "up")
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("el-button", {
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-caret-bottom",
                                    size: "mini",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.movePreview(
                                        item,
                                        index,
                                        "down"
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("el-button", {
                                  attrs: {
                                    type: "danger",
                                    icon: "el-icon-delete",
                                    size: "mini",
                                  },
                                  on: { click: _vm.delTool },
                                }),
                              ],
                              1
                            ),
                          ]),
                          item.type === "coupon"
                            ? _c("preview-coupon", { attrs: { obj: item } })
                            : _vm._e(),
                          item.type === "swiper"
                            ? _c("w-swiper", { attrs: { obj: item } })
                            : _vm._e(),
                          item.type === "staticImg"
                            ? _c("static-img", { attrs: { obj: item } })
                            : _vm._e(),
                          item.type === "staticSelfImg"
                            ? _c("static-self-img", { attrs: { obj: item } })
                            : _vm._e(),
                          item.type === "swiperGoods"
                            ? _c("swiper-goods", { attrs: { obj: item } })
                            : _vm._e(),
                          item.type === "listGoods"
                            ? _c(
                                "list-goods",
                                { attrs: { obj: item, "b-padding": "" } },
                                [
                                  _vm.goodsListLoading
                                    ? _c(
                                        "div",
                                        { staticClass: "cateGoodsLoding" },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-loading",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          item.type === "moreGoods"
                            ? _c(
                                "more-goods",
                                { attrs: { obj: item, "b-padding": "" } },
                                [
                                  _vm.moreGoodsLoading
                                    ? _c(
                                        "div",
                                        { staticClass: "cateGoodsLoding" },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-loading",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          item.type === "groupGoods"
                            ? _c(
                                "group-goods-pre",
                                { attrs: { obj: item, "b-padding": "" } },
                                [
                                  _vm.goodsListLoading
                                    ? _c(
                                        "div",
                                        { staticClass: "cateGoodsLoding" },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-loading",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          item.type === "listGoodsProm"
                            ? _c(
                                "list-prom-goods",
                                { attrs: { obj: item, "b-padding": "" } },
                                [
                                  _vm.goodsPromListLoading
                                    ? _c(
                                        "div",
                                        { staticClass: "cateGoodsLoding" },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-loading",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          item.type === "title"
                            ? _c("sub-title", { attrs: { obj: item } })
                            : _vm._e(),
                          item.type === "cateGoods"
                            ? _c("cate-goods", { attrs: { obj: item } }, [
                                _vm.cateGoodsLoding
                                  ? _c(
                                      "div",
                                      { staticClass: "cateGoodsLoding" },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-loading",
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          item.type === "cateTab"
                            ? _c("cate-tab", { attrs: { obj: item } }, [
                                _vm.cateTabLoding
                                  ? _c(
                                      "div",
                                      { staticClass: "cateGoodsLoding" },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-loading",
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          item.type === "navigationGoods"
                            ? _c("navigation-goods", { attrs: { obj: item } }, [
                                _vm.navGoodsLoding
                                  ? _c(
                                      "div",
                                      { staticClass: "navGoodsLoding" },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-loading",
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          item.type === "imgMode1"
                            ? _c("three-img-pre", { attrs: { obj: item } })
                            : _vm._e(),
                          item.type === "imgMode2"
                            ? _c("left-right-pre", { attrs: { obj: item } })
                            : _vm._e(),
                          item.type === "imgMode3"
                            ? _c("up-down-pre", { attrs: { obj: item } })
                            : _vm._e(),
                          item.type === "imgMode4"
                            ? _c("four-img-pre", { attrs: { obj: item } })
                            : _vm._e(),
                          item.type === "imgMode5"
                            ? _c("two-img-pre", { attrs: { obj: item } })
                            : _vm._e(),
                          item.type === "imgMode6"
                            ? _c("four-img-hor-pre", { attrs: { obj: item } })
                            : _vm._e(),
                          item.type === "listGoodsSkill"
                            ? _c("list-goods-skill", {
                                key: item + "a",
                                attrs: { obj: item },
                              })
                            : _vm._e(),
                          item.type === "imgMode10"
                            ? _c("five-img", { attrs: { obj: item } })
                            : _vm._e(),
                          item.type === "imgMode12"
                            ? _c("three-img-hor-pre", { attrs: { obj: item } })
                            : _vm._e(),
                          item.type === "video"
                            ? _c("video-pre", { attrs: { obj: item } })
                            : _vm._e(),
                          item.type === "homeRow"
                            ? _c("home-row", { attrs: { obj: item } })
                            : _vm._e(),
                          item.type === "homeChannel"
                            ? _c("home-channel", { attrs: { obj: item } })
                            : _vm._e(),
                          item.type === "scrollNews"
                            ? _c("scroll-news", { attrs: { obj: item } })
                            : _vm._e(),
                          item.type === "mapButton"
                            ? _c("map-button", { attrs: { obj: item } })
                            : _vm._e(),
                          item.type === "search"
                            ? _c("Search", { attrs: { obj: item } })
                            : _vm._e(),
                          item.type === "richText"
                            ? _c("RichTextPre", { attrs: { obj: item } })
                            : _vm._e(),
                          item.type === "productModule"
                            ? _c("productModulePre", { attrs: { obj: item } })
                            : _vm._e(),
                          item.type === "styleList"
                            ? _c("styleListPre", { attrs: { obj: item } })
                            : _vm._e(),
                          item.type === "scrapeTogether"
                            ? _c("scrapeTogetherPre", { attrs: { obj: item } })
                            : _vm._e(),
                          item.type === "appointment"
                            ? _c("AppointmentPre", { attrs: { obj: item } })
                            : _vm._e(),
                          item.type === "liveList"
                            ? _c("LiveList", { attrs: { obj: item } })
                            : _vm._e(),
                          item.type === "horizontalSlideAd"
                            ? _c("horizontalSlideAd", { attrs: { obj: item } })
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm.toolShow
            ? _c(
                "div",
                { staticClass: "subject-right" },
                [
                  _c(
                    "goods-panel",
                    {
                      attrs: { info: _vm.contentInfo },
                      on: { delModuleEvent: _vm.delTool },
                    },
                    [
                      _vm.pageShow
                        ? _c("page-tool", { attrs: { info: _vm.contentInfo } })
                        : _vm._e(),
                      _c("tool-bar", {
                        attrs: {
                          "content-info": _vm.contentInfo,
                          "tool-type": _vm.toolType,
                        },
                        on: { columnChange: _vm.columnChange },
                      }),
                      _vm.contentInfo.type === "coupon"
                        ? _c("right-coupon", {
                            ref: "coupon",
                            attrs: { infos: _vm.contentInfo },
                          })
                        : _vm._e(),
                      _vm.contentInfo.type === "scrollNews"
                        ? _c("scroll-cont", {
                            attrs: { "news-data": _vm.contentInfo },
                          })
                        : _vm._e(),
                      _vm.contentInfo.type === "homeRow"
                        ? _c("page-list", {
                            attrs: { addrow: _vm.contentInfo },
                          })
                        : _vm._e(),
                      _vm.contentInfo.type === "homeChannel"
                        ? _vm._l(_vm.contentInfo.list, function (item, index) {
                            return _c(
                              "div",
                              { key: index + "a" },
                              [
                                _c("page-channel", {
                                  attrs: {
                                    addchannel: _vm.contentInfo,
                                    "img-info": item,
                                    "key-num": index,
                                  },
                                }),
                              ],
                              1
                            )
                          })
                        : _vm._e(),
                      _vm.contentInfo.type === "swiper"
                        ? _vm._l(_vm.contentInfo.list, function (item, index) {
                            return _c(
                              "div",
                              { key: item.img + index + "b" },
                              [
                                _c(
                                  "num-show",
                                  { attrs: { "pic-num": index + 1 } },
                                  [
                                    _c("img-info", {
                                      ref: "swiperinfo",
                                      refInFor: true,
                                      attrs: {
                                        cardtype: _vm.contentInfo,
                                        "img-info": item,
                                      },
                                    }),
                                    _c("num-controller", {
                                      attrs: {
                                        "show-num": index,
                                        "limit-num": 1,
                                      },
                                      on: {
                                        addGoodEvent: function ($event) {
                                          return _vm.addModule(index, "swiper")
                                        },
                                        delGoodEvent: function ($event) {
                                          return _vm.delModule(index, "swiper")
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          })
                        : _vm._e(),
                      _vm.contentInfo.type === "staticImg"
                        ? _vm._l(_vm.contentInfo.list, function (item, index) {
                            return _c(
                              "div",
                              { key: item.img + index + "c" },
                              [
                                _c(
                                  "num-show",
                                  { attrs: { "pic-num": index + 1 } },
                                  [
                                    _c("img-info", {
                                      attrs: {
                                        cardtype: _vm.contentInfo,
                                        "img-info": item,
                                      },
                                    }),
                                    _c("num-controller", {
                                      attrs: {
                                        "show-num": index,
                                        "limit-num": 1,
                                      },
                                      on: {
                                        addGoodEvent: function ($event) {
                                          return _vm.addModule(
                                            index,
                                            "staticImg"
                                          )
                                        },
                                        delGoodEvent: function ($event) {
                                          return _vm.delModule(
                                            index,
                                            "staticImg"
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          })
                        : _vm._e(),
                      _vm.contentInfo.type === "staticSelfImg"
                        ? _vm._l(_vm.contentInfo.list, function (item, index) {
                            return _c(
                              "div",
                              { key: index + "d" },
                              [
                                _c(
                                  "num-show",
                                  { attrs: { "pic-num": index + 1 } },
                                  [
                                    _c("img-Self-info", {
                                      attrs: {
                                        cardtype: _vm.contentInfo,
                                        "img-info": item,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          })
                        : _vm._e(),
                      _vm.contentInfo.type === "swiperGoods"
                        ? _c("add-goods-drag", {
                            attrs: { addscroll: _vm.contentInfo },
                          })
                        : _vm._e(),
                      _vm.contentInfo.type === "listGoods"
                        ? _c("add-list", {
                            attrs: { addlistinfo: _vm.contentInfo },
                          })
                        : _vm._e(),
                      _vm.contentInfo.type === "groupGoods"
                        ? _c("group-goods", {
                            attrs: { addlistinfo: _vm.contentInfo },
                          })
                        : _vm._e(),
                      _vm.contentInfo.type === "moreGoods"
                        ? _c("more-list", {
                            attrs: { addlistinfo: _vm.contentInfo },
                          })
                        : _vm._e(),
                      _vm.contentInfo.type === "listGoodsProm"
                        ? _c("add-goods-prom", {
                            attrs: { addlistinfo: _vm.contentInfo },
                          })
                        : _vm._e(),
                      _vm.contentInfo.type === "title"
                        ? _c("add-title", {
                            attrs: { titleinfo: _vm.contentInfo },
                          })
                        : _vm._e(),
                      _vm.contentInfo.type === "cateTab"
                        ? _vm._l(_vm.contentInfo.list, function (item, index) {
                            return _c(
                              "div",
                              { key: index + "e" },
                              [
                                _c("add-cate-tab", {
                                  attrs: {
                                    "category-num": `${index + 1}`,
                                    "goods-list-info": item,
                                    "model-list": _vm.contentList,
                                  },
                                }),
                                _c("num-controller", {
                                  attrs: { "show-num": index, "limit-num": 2 },
                                  on: {
                                    addGoodEvent: function ($event) {
                                      return _vm.addModule(index, "cateTab")
                                    },
                                    delGoodEvent: function ($event) {
                                      return _vm.delModule(index, "cateTab")
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          })
                        : _vm._e(),
                      _vm.contentInfo.type === "cateGoods"
                        ? _vm._l(_vm.contentInfo.list, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: item.imgInfo
                                  ? item.imgInfo.img + index
                                  : index + "f",
                              },
                              [
                                _c("add-cate", {
                                  attrs: {
                                    "category-num": `${index + 1}`,
                                    "goods-list-info": item,
                                  },
                                }),
                                _c("num-controller", {
                                  attrs: { "show-num": index, "limit-num": 2 },
                                  on: {
                                    addGoodEvent: function ($event) {
                                      return _vm.addModule(index, "cateGoods")
                                    },
                                    delGoodEvent: function ($event) {
                                      return _vm.delModule(index, "cateGoods")
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          })
                        : _vm._e(),
                      _vm.contentInfo.type === "navigationGoods"
                        ? _vm._l(_vm.contentInfo.list, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: item.imgInfo
                                  ? item.imgInfo.img + index
                                  : index + "f",
                              },
                              [
                                _c("add-navigation", {
                                  attrs: {
                                    "category-num": `${index + 1}`,
                                    "goods-list-info": item,
                                  },
                                }),
                                _c("num-controller", {
                                  attrs: { "show-num": index, "limit-num": 1 },
                                  on: {
                                    addGoodEvent: function ($event) {
                                      return _vm.addModule(
                                        index,
                                        "navigationGoods"
                                      )
                                    },
                                    delGoodEvent: function ($event) {
                                      return _vm.delModule(
                                        index,
                                        "navigationGoods"
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          })
                        : _vm._e(),
                      _vm.contentInfo.type === "imgMode1"
                        ? _vm._l(_vm.contentInfo.list, function (item, index) {
                            return _c(
                              "div",
                              { key: index + "g" },
                              [
                                _c("img-info", {
                                  attrs: {
                                    cardtype: _vm.contentInfo,
                                    "img-info": item,
                                  },
                                }),
                              ],
                              1
                            )
                          })
                        : _vm._e(),
                      _vm.contentInfo.type === "imgMode2"
                        ? _vm._l(_vm.contentInfo.list, function (item, index) {
                            return _c(
                              "div",
                              { key: index + "h" },
                              [
                                _c("img-info", {
                                  attrs: {
                                    cardtype: _vm.contentInfo,
                                    "img-info": item,
                                  },
                                }),
                              ],
                              1
                            )
                          })
                        : _vm._e(),
                      _vm.contentInfo.type === "imgMode3"
                        ? _vm._l(_vm.contentInfo.list, function (item, index) {
                            return _c(
                              "div",
                              { key: index + "i" },
                              [
                                _c("img-info", {
                                  attrs: {
                                    cardtype: _vm.contentInfo,
                                    "img-info": item,
                                  },
                                }),
                              ],
                              1
                            )
                          })
                        : _vm._e(),
                      _vm.contentInfo.type === "imgMode4"
                        ? _vm._l(_vm.contentInfo.list, function (item, index) {
                            return _c(
                              "div",
                              { key: index + "j" },
                              [
                                _c("img-info", {
                                  attrs: {
                                    cardtype: _vm.contentInfo,
                                    "img-info": item,
                                  },
                                }),
                              ],
                              1
                            )
                          })
                        : _vm._e(),
                      _vm.contentInfo.type === "imgMode12"
                        ? _vm._l(_vm.contentInfo.list, function (item, index) {
                            return _c(
                              "div",
                              { key: index + "k" },
                              [
                                _c("img-info", {
                                  attrs: {
                                    cardtype: _vm.contentInfo,
                                    "img-info": item,
                                  },
                                }),
                              ],
                              1
                            )
                          })
                        : _vm._e(),
                      _vm.contentInfo.type === "imgMode6"
                        ? _vm._l(_vm.contentInfo.list, function (item, index) {
                            return _c(
                              "div",
                              { key: index + "l" },
                              [
                                _c("img-info", {
                                  attrs: {
                                    cardtype: _vm.contentInfo,
                                    "img-info": item,
                                  },
                                }),
                              ],
                              1
                            )
                          })
                        : _vm._e(),
                      _vm.contentInfo.type === "imgMode5"
                        ? _vm._l(_vm.contentInfo.list, function (item, index) {
                            return _c(
                              "div",
                              { key: index + "m" },
                              [
                                _c("img-info", {
                                  attrs: {
                                    cardtype: _vm.contentInfo,
                                    "img-info": item,
                                  },
                                }),
                              ],
                              1
                            )
                          })
                        : _vm._e(),
                      _vm.contentInfo.type === "listGoodsSkill"
                        ? [
                            _c("add-goods-skill", {
                              key: _vm.contentInfo + "a",
                              attrs: { addscroll: _vm.contentInfo },
                            }),
                          ]
                        : _vm._e(),
                      _vm.contentInfo.type === "imgMode10"
                        ? [
                            _c("img-info", {
                              attrs: {
                                cardtype: _vm.contentInfo,
                                "img-info": _vm.contentInfo.bgInfo,
                              },
                            }),
                            _c("add-goods", {
                              attrs: { addscroll: _vm.contentInfo },
                            }),
                          ]
                        : _vm._e(),
                      _vm.contentInfo.type === "video"
                        ? _c("video-info", { attrs: { info: _vm.contentInfo } })
                        : _vm._e(),
                      _vm.contentInfo.type === "mapButton"
                        ? _c("mapButtonInfo", {
                            attrs: { info: _vm.contentInfo },
                          })
                        : _vm._e(),
                      _vm.contentInfo.type === "search"
                        ? _c("searchInfo", {
                            attrs: { "img-info": _vm.contentInfo },
                          })
                        : _vm._e(),
                      _vm.contentInfo.type === "richText"
                        ? _c("RichText", { attrs: { info: _vm.contentInfo } })
                        : _vm._e(),
                      _vm.contentInfo.type === "productModule"
                        ? _c("productModuleTool", {
                            attrs: { info: _vm.contentInfo },
                          })
                        : _vm._e(),
                      _vm.contentInfo.type === "styleList"
                        ? _c("styleListTool", {
                            attrs: { info: _vm.contentInfo },
                          })
                        : _vm._e(),
                      _vm.contentInfo.type === "scrapeTogether"
                        ? _c("scrapeTogetherTool", {
                            attrs: { info: _vm.contentInfo },
                          })
                        : _vm._e(),
                      _vm.contentInfo.type === "appointment"
                        ? [
                            _c("AppointmentTool", {
                              attrs: { info: _vm.contentInfo },
                            }),
                          ]
                        : _vm._e(),
                      _vm.contentInfo.type === "liveList"
                        ? _c("LiveListTool", {
                            ref: "refLiveList",
                            attrs: { info: _vm.contentInfo },
                            on: { liveListOnAdd: _vm.liveListOnAdd },
                          })
                        : _vm._e(),
                      _vm.contentInfo.type === "horizontalSlideAd"
                        ? _vm._l(_vm.contentInfo.list, function (item, index) {
                            return _c(
                              "div",
                              { key: item.img + index },
                              [
                                _c(
                                  "num-show",
                                  { attrs: { "pic-num": index + 1 } },
                                  [
                                    _c("img-info", {
                                      ref: "horizontalSlideAdInfo",
                                      refInFor: true,
                                      attrs: {
                                        cardtype: _vm.contentInfo,
                                        "img-info": item,
                                      },
                                    }),
                                    _c("num-controller", {
                                      attrs: {
                                        "show-num": index,
                                        "limit-num": 1,
                                      },
                                      on: {
                                        addGoodEvent: function ($event) {
                                          return _vm.addModule(
                                            index,
                                            "horizontalSlideAd"
                                          )
                                        },
                                        delGoodEvent: function ($event) {
                                          return _vm.delModule(
                                            index,
                                            "horizontalSlideAd"
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("QrCode", {
            attrs: { value: _vm.currentlink, "qr-show": _vm.qrShow },
            on: {
              hideQr: function ($event) {
                _vm.qrShow = $event
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }