<template>
  <div class="add__goods_skill">
    <el-button
      type="primary"
      size="small"
      @click="addGroup"
    >
      添加秒杀分组
    </el-button>
    <div class="m_b_10">
      <el-table
        :data="outerGroupTableData"
        border
      >
        <el-table-column
          prop="id"
          label="秒杀ID"
          min-width="110"
        />
        <el-table-column
          prop="groupingTitle"
          label="秒杀名称"
          min-width="110"
        />
        <el-table-column
          label="活动时间"
          min-width="110"
        >
          <template slot-scope="scope">
            {{ dayFormat(scope.row.seckillStarttime) }} - {{ dayFormat(scope.row.seckillEndtime) }}
          </template>
        </el-table-column>
        <el-table-column
          label="分组操作"
          width="110"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              :ref="'group'+scope.$index"
              type="text"
              size="mini"
              @click="toDeleteGroup(scope.$index, outerGroupTableData)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 添加分组 -->
    <el-dialog
      v-if="innerGroupVisible"
      width="50%"
      title="添加秒杀"
      :visible.sync="innerGroupVisible"
      :close-on-click-modal="false"
      append-to-body
    >
      <div>
        <el-input
          v-model.trim="promName"
          placeholder="请输入活动名称"
          size="mini"
          clearable
          style="width: 30%; marginBottom: 20px;"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getGroupList()"
          />
        </el-input>
        <el-table
          v-loading="groupLoading"
          :data="innerGroupTableData"
          border
          style="width: 100%"
          :row-key="getGroupRowKey"
          element-loading-text="拼命加载中"
          @selection-change="handleGroupSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
            :reserve-selection="true"
          />
          <el-table-column
            prop="id"
            label="秒杀ID"
            min-width="110"
          />
          <el-table-column
            prop="groupingTitle"
            label="秒杀名称"
            min-width="110"
          />
          <el-table-column
            label="活动时间"
            min-width="110"
          >
            <template slot-scope="scope">
              {{ dayFormat(scope.row.seckillStarttime) }} - {{ dayFormat(scope.row.seckillEndtime) }}
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :current-page="groupPagination.page"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="groupPagination.page_count"
          layout="total, sizes, prev, pager, next, jumper"
          :total="groupPagination.item_total"
          @size-change="handleGroupSizeChange"
          @current-change="handleGroupCurrentChange"
        />
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="onGroupSure"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/**
 * 商品列表组件
 * @module addGoods
 * @param {Object} addscroll
 */

// 限制商品名称显示字数
import { strLimit } from '@/assets/js/common'
import qs from 'qs'
import dayjs from 'dayjs'

export default {
  name: 'AddGoodsSkillList',

  props: {
    addscroll: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        list: [],
        groupList: [],
        group_id: '',
        groupIds: []
      }
    }
  },

  data() {
    return {
      promName: '', // 搜索参数
      groupLoading: false,
      groupPagination: {
        page: 1,
        page_total: 0,
        item_total: 0,
        page_count: 10
      },
      innerGroupVisible: false, // 添加分组弹框
      innerGroupTableData: [], // 接口获取活动分组列表
      multipleSelection: [], // 选中的活动分组列表
      outerGroupTableData: [] // 添加后显示的的活动分组列表

    }
  },
  mounted() {
    let arr = []
    this.addscroll.list.forEach((goods, index) => {
      arr.push(goods)
    })
    this.outerGroupTableData = arr
  },
  methods: {

    dayFormat(val) {
      return dayjs(parseInt(val)).format('YYYY/MM/DD HH:mm:ss')
    },

    // 打开分组弹框
    addGroup() {
      this.getGroupList()
      this.innerGroupVisible = true
    },

    // 活动分组分页控制
    handleGroupSizeChange(val) {
      this.groupPagination.page_count = val
      this.groupPagination.page = 1
      this.getGroupList()
    },
    handleGroupCurrentChange(val) {
      this.groupPagination.page = val
      this.getGroupList()
    },
    // 获取活动分组列表
    getGroupList() {
      let _this = this
      _this.groupLoading = true
      _this.$axios({
        method: 'get',
        url: _this.$api.tradePluginTemplate.get_prom_list,
        params: {
          currentSize: _this.groupPagination.page,
          pageSize: _this.groupPagination.page_count,
          name: _this.promName,
          type: 1
        }
      }).then(function(res) {
        if (res.code === 0) {
          const { size, total, current, pages } = res.data
          _this.groupPagination = {
            page: Number(current),
            page_count: Number(size),
            page_total: Number(pages),
            item_total: Number(total)
          }
          _this.innerGroupTableData = res.data.records
          _this.addscroll.groupList = res.data.records
        } else {
          _this.innerGroupTableData = []
          _this.groupPagination.item_total = 0
        }
        _this.groupLoading = false
      }).catch(function(error) {
        console.log(error)
      })
    },

    // 获取分组下商品
    getGroupGoodsList() {
      let that = this
      let params = that.addscroll.groupIds
      this.$axios.post(that.$api.tradePluginTemplate.get_prom_goods_list, params).then((res) => {
        if (res.code === 0) {
          that.addscroll.list = res.data
          that.addscroll.group_id = res.data[0].id
        }
      })
    },

    // 确认添加分组
    onGroupSure() {
      this.innerGroupVisible = false
      if (this.multipleSelection.length > 0) {
        this.multipleSelection.map((item, index) => {
          this.outerGroupTableData.push(item)
        })
        let obj = {}
        this.outerGroupTableData = this.outerGroupTableData.reduce((item, next) => {
          obj[next.id] ? '' : obj[next.id] = true && item.push(next)
          return item
        }, [])

        this.getIds()
      } else {
        this.$message.error('请选择分组~')
      }
    },

    // 删除已添加的活动分组
    toDeleteGroup(index, rows) {
      rows.splice(index, 1)
      this.getIds()
    },

    getIds() {
      let ids = []
      this.outerGroupTableData.map((item, index) => {
        ids[index] = item.id
      })
      this.addscroll.groupIds = ids
      this.getGroupGoodsList()
    },

    // 分组多选
    handleGroupSelectionChange(val) {
      this.multipleSelection = val
    },
    getGroupRowKey(row) {
      return row.id
    }

  }

}
</script>

<style lang="scss" scoped>
.add__goods_skill {
    width: calc(100% - 30px);
    height: 310px;
    border-top: 1px solid #EDEDED;
    margin: 0 15px;
    padding-top: 15px;

    .m_b_10 {
      margin-top: 10px;
    }

    .goods__name{
        width: 70px;
        height: 100%;
        float: left;
        & p{
            text-align: right;
            color: #8E8E8E;
            overflow: hidden;
        }
    }
    .goods__list {
        float: left;
        .list__content{
            width: 380px;
            height: 220px;
            border:1px solid rgba(151,151,151,1);
            overflow-y: scroll;
            overflow-x: hidden;
            border-radius:2px;
            ul > li {
              display: flex;
            }
            & p {
              width: 200px;
              height: 20px;
              display: inline-block;
              margin-right: 10px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            & span{
              display: inline-block;
              cursor: pointer;
              margin-right: 10px;
              white-space: nowrap;
            }
            .item-id {
              width: 50px;
            }
        }
        .list__add{
            margin-top: 10px;
            width: 390px;
            height: 34px;
            & input {
                width: 300px;
                height: 34px;
                float: left;
                border: 1px solid #979797;
            }
            & input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
                font-size: 12px;
            }
             & input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                font-size: 12px;
            }
             & input::-moz-placeholder { /* Mozilla Firefox 19+ */
                font-size: 12px;
            }
             & input:-ms-input-placeholder { /* Internet Explorer 10-11 */
                font-size: 12px;
            }
            & span {
                width: 80px;
                height: 34px;
                line-height: 34px;
                text-align: center;
                float: left;
                border: 1px solid #979797;
                border-left: none;
                background:rgba(240,240,240,1);
                cursor: pointer;
            }
        }
        .add__toast {
            color: #FF3301;
        }
    }
}
.delet-btn {
  color: red;
  cursor: pointer;
}
</style>
