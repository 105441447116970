<template>
  <div
    v-if="qrShow"
    class="qrcode-cover"
    @click="$emit('hideQr',false)"
  >
    <QrcodeVue
      :value="value"
      :size="size"
    />
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';

export default {
  name: 'qrcode',
  components: {
    QrcodeVue,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 300,
    },
    qrShow: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
	.qrcode-cover{
		position: fixed;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:100000;
		background:rgba(0,0,0,.6);
		display: flex;
		align-items: center;
		justify-content:center;
	}
</style>
