<template>
  <!-- 频道页的列表组件 数据编辑页面 -->
  <div class="style-list-tool">
    <el-form
      :model="info"
      label-width="120px"
    >
      <el-form-item
        class="name-box"
        label="类型："
      >
        <el-radio-group v-model="info.resource">
          <el-radio :label="1">
            列表
          </el-radio>
          <el-radio :label="2">
            标签
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="info.resource == 1"
        label-width="100px"
        label="样式："
        prop="categoryId"
      >
        <el-select
          v-model="info.categoryId"
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键词搜索样式"
          :remote-method="remoteMethod"
          :loading="loading"
          style="width: 90%"
          @change="changeCategory"
        >
          <el-option
            v-for="item in categoryOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <div v-if="info.resource == 2">
        <el-form-item
          class="name-box"
          label="吸顶效果："
        >
          <el-radio-group v-model="info.ceiling">
            <el-radio :label="1">
              有
            </el-radio>
            <el-radio :label="2">
              无
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <div
          v-for="(item, index) in info.categoryIdList"
          :key="index"
          class="list-item"
        >
          <el-form-item
            class="title"
            label-width="100px"
            :label="'标签' + (index + 1) + '：'"
          />
          <el-form-item
            label-width="128px"
            label="默认选中该标签"
          >
            <el-switch
              v-model="item.isSelect"
              @change="changeDefault(item, index)"
            />
          </el-form-item>
          <el-form-item
            label-width="100px"
            class="name-box"
            label="标签名称："
          >
            <el-radio-group v-model="item.titleType">
              <el-radio :label="1">
                文字
              </el-radio>
              <el-radio :label="2">
                图片
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="item.titleType === 1 "
            label-width="100px"
            label="标签名称："
            placeholder="请输入标签名称"
          >
            <el-input
              v-model="item.title"
              maxlength="6"
              show-word-limit
            />
            <span class="name-box-tip">占位请使用$符号</span>
          </el-form-item>
          <el-form-item
            v-if="item.titleType === 2"
            label-width="100px"
            label="标签图片："
          >
            <loadImgOssUp
              :name="'a' + index"
              :url="item.titleImg ? [item.titleImg] : []"
              :max-num="1"
              @change="uoload($event, item)"
            />
          </el-form-item>
          <el-form-item
            label-width="100px"
            label="样式："
            prop="categoryId"
          >
            <el-select
              v-model="item.categoryId"
              filterable
              remote
              reserve-keyword
              placeholder="请输入关键词搜索样式"
              :remote-method="remoteMethodList"
              :loading="loadingList"
              style="width: 100%"
              @change="changeCategoryList(item)"
            >
              <el-option
                v-for="item in categoryOptionsList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <div class="action-move">
            <span
              v-if="index !== 0"
              class=" actions_up"
              @click="upItem(item, index)"
            >
              上移
            </span>
            <span
              v-if="index !== info.categoryIdList.length - 1"
              class=" actions_down"
              @click="downItem(item, index)"
            >
              下移
            </span>
          </div>
          <span
            v-if="info.categoryIdList.length > 1"
            class="actions_b"
            @click="deleteitem(item, index)"
          >
            删除
          </span>
          <!-- <i
            v-if="info.categoryIdList.length > 1"
            class="el-icon-minus actions_b"
            @click="deleteitem(item, index)"
          ></i> -->
          <!-- <i
            v-if="info.categoryIdList.length === index + 1"
            class="el-icon-plus"
            @click="add()"
          ></i> -->
          <span
            v-if="info.categoryIdList.length === index + 1"
            class="actions_add"
            @click="add()"
          >
            添加
          </span>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import loadImgOssUp from '@/components/common/loadImgOssUp';

export default {
  name: 'style-list-tool',
  components: { loadImgOssUp },
  props: {
    info: {
      type: Object,
      default: () => ({
        content: '',
        richBgColor: '#fff',
      }),
    },
  },
  data() {
    return {
      loading: false,
      loadingList: false,
      categoryOptionsList: [],
      categoryOptions: [],
    };
  },
  watch: {},
  mounted() {
    // console.log(this.info);
    // 初始化样式列表
    this.getStyle();
  },
  methods: {
    // 更改默认选中项
    changeDefault(item, index) {
      // console.log(item);
      // console.log(this.info.categoryIdList);
      this.info.categoryIdList.forEach((items, indexI) => {
        if (index === indexI) {
          // 使用set
          this.$set(items, 'isSelect', item.isSelect);
        } else {
          // 使用set
          this.$set(items, 'isSelect', false);
        }
      });
    },
    // 对标签上移
    upItem(item, index) {
      const arr = this.info.categoryIdList;
      const temp = arr[index - 1];
      arr[index - 1] = item;
      arr[index] = temp;
      this.info.categoryIdList = [];
      this.info.categoryIdList = this.info.categoryIdList.concat(arr);
    },
    // 对标签下移
    downItem(item, index) {
      const arr = this.info.categoryIdList;
      const temp = arr[index + 1];
      arr[index + 1] = item;
      arr[index] = temp;
      this.info.categoryIdList = [];
      this.info.categoryIdList = this.info.categoryIdList.concat(arr);
    },
    // 添加模块
    add() {
      if (this.info.categoryIdList.length >= 10) {
        this.$message({
          type: 'warning',
          message: '最多添加10个标签',
        });
        return;
      }
      this.info.categoryIdList.push({
        titleType: 1, // 1、文字  2、图片  标签样式的时候才起作用
        title: '标题',
        titleImg: '', // 标签图片
        categoryId: '', // 样式id
      });
    },
    // 删除模块
    deleteitem(item, index) {
      this.$confirm('是否确认删除该模块?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.info.categoryIdList.splice(index, 1);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // 上传图片
    uoload(urls, item) {
      const newdata = item;
      /* item.titleImg = urls[0]; */
      [newdata.titleImg] = urls;
      // this.checkdata()
    },
    // 搜索样式
    remoteMethod(query) {
      this.getStyle(query);
    },
    // 选中样式
    changeCategory(item) {
      // console.log(item);
      // 获取样式ID对应的名字，方便前端回显查看
      this.info.categoryName = this.categoryOptions.filter((items) => {
        return items.value === item;
      })[0].label;
    },
    // 搜索样式
    remoteMethodList(query) {
      this.getStyle(query);
    },
    // 选中样式
    changeCategoryList(item) {
      // console.log(item);
      // 获取样式ID对应的名字，方便前端回显查看
      let newData = item;
      newData.categoryName = this.categoryOptionsList.filter((items) => {
        return items.value === item.categoryId;
      })[0].label;
    },
    // 获取样式的列表
    getStyle(styleNameLike) {
      this.shoploading = true;
      this.shoploadingList = true;
      this.$axios
        .get(this.$api.subjectChannel.style_page, {
          params: {
            pageIndex: 1,
            pageSize: 20,
            status: 1, // 1、启用  2、禁用
            styleNameLike,
          },
        }).then((res) => {
          if (res.code === 0) {
            this.categoryOptions = [];
            this.categoryOptionsList = [];
            let data = res.data.records.map((item) => {
              return {
                ...item,
                label: item.styleName,
                value: item.id,
              };
            });
            this.categoryOptions = this.categoryOptions.concat(data);
            this.categoryOptionsList = this.categoryOptionsList.concat(data);
            this.shoploading = false;
            this.shoploadingList = false;
            // 将已选的数据加上方便回显
            // 选中的时候查看筛选数据源种是否有这条数据  没有的话根据ID查找数据源种的数据塞进去
            let index = this.categoryOptions.findIndex((item) => item.value
            === this.info.categoryId);
            if (this.info.categoryId && index === -1) {
              this.categoryOptions = this.categoryOptions.concat({
                label: this.info.categoryName,
                value: this.info.categoryId,
              });
            }

            if (this.info.categoryIdList.length > 0 && this.info.categoryIdList[0].categoryId) {
              // 判断所选的样式是否已存在数据源里面
              let newData = this.info.categoryIdList.filter((item) => {
                return this.categoryOptionsList.findIndex((items) => items.value
                === item.categoryId) === -1;
              });
              let list = newData.map((item) => {
                return {
                  label: item.categoryName,
                  value: item.categoryId,
                };
              });
              // 去重操作
              let uniqueSourceData = [...new Set(list.map((item) => JSON.stringify(item)))]
                .map((item) => JSON.parse(item));
              this.categoryOptionsList = this.categoryOptionsList.concat(uniqueSourceData);
              // this.categoryOptionsList = this.categoryOptionsList.concat(list);
            }
          } else {
            this.$message(res.error_msg);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.style-list-tool {
  .list-item {
    position: relative;
    background: #f5f5f5;
    width: calc(100% - 120px);
    margin-left: 60px;
    padding-right: 10px;
    border-radius: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;

    .title {
      margin-bottom: 0;
    }

    .el-icon-plus {
      position: absolute;
      right: -30px;
      bottom: 10px;
      font-size: 20px;
      color: #1890ff;
      cursor: pointer;
    }
    .actions_add{
      position: absolute;
      right: -45px;
      bottom: 10px;
      font-size: 20px;
      color: #1890ff;
      cursor: pointer;
    }

    .actions_b {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 20px;
      color: #1890ff;
      cursor: pointer;
    }
    .action-move{
      position: absolute;
      right: 55px;
      top: 10px;
      font-size: 20px;
      color: #1890ff;
      cursor: pointer;
    }
    .name-box-tip{
      color: #F59A23;
    }
  }
}
</style>
