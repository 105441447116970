<template>
  <el-select
    v-model="id"
    :style="{'width': width }"
    :disabled="disabled"
    remote
    clearable
    placeholder="请选择优惠券"
    :remote-method="remoteMethod"
    :loading="loading"
    :size="size"
    @change="changeSelect"
  >
    <el-option
      v-for="(item,i) in options"
      :key="i"
      :label="`${item.couponName}（id=${item.couponId}）`"
      :value="item.couponId"
    />
  </el-select>
</template>

<script>
export default {
  name: '',
  components: {},
  model: {
    prop: 'couponId',
    event: 'couponIdInput',
  },
  props: {
    disabled: { // 是否禁用
      type: Boolean,
      default: false,
    },
    size: { // 尺寸，medium / small / mini
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    couponId: { // 优惠券id
      type: String,
      default: '',
    },
    couponType: {
      type: Number,
      default: undefined,
    },
    notQueryExchangeCoupon: { // 是否排除兑换商品券，默认所有
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      loading: false,
      options: [],
      id: '',
      canSearch: true,
    };
  },
  computed: {},
  watch: {
    couponId: {
      handler(val) {
        this.canSearch && this.remoteMethod(val);
        this.id = val;
        this.canSearch = true;
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {

  },
  methods: {
    changeSelect(e) {
      this.canSearch = false;
      this.$emit('couponIdInput', e);
      this.$emit('change', e);
    },
    async remoteMethod(query) {
      let list = await this.getCouponList(query);
      this.options = list;
    },
    getCouponList(val) {
      this.loading = true;
      const api = this.$api.coupon.template_list;
      return new Promise((resolve) => {
        this.$axios({
          method: 'post',
          url: api,
          data: {
            currentPage: 1,
            pageSize: 100,
            storeId: JSON.parse(localStorage.getItem('userInfo')).defaultStoreId,
          },
        })
          .then((res) => {
            this.loading = false;
            if (res.code === 0) {
              let list = res.data.records;
              resolve(list);
            }
          });
      });
    },
  },
};
</script>

<style scoped lang="scss">

</style>
