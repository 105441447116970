<template>
  <div class="numController">
    <i
      class="el-icon-plus add"
      @click="addGood"
    ></i>
    <i
      v-show="showNum >= limitNum"
      class="el-icon-minus reduce"
      @click="delGood"
    ></i>
  </div>
</template>

<script>
/**
 * renxy
 * name: 模块增减按钮
 * @param(showNum) 当前模块数量
 * @param(limitNum) 模块总数量
 */
export default {
  name: 'NumController',

  props: ['showNum', 'limitNum'],

  data() {
    return {

    }
  },

  methods: {
    addGood: function() {
      this.$emit('addGoodEvent')
    },
    delGood: function() {
      this.$emit('delGoodEvent')
    }
  }
}
</script>

<style scoped lang="scss">
.numController {
    height: 46px;
    width: calc(100% - 30px);
    margin: 0 15px;
    text-align: right;
    line-height: 46px;
    border-top: 1px solid #EDEDED;
    .add {
        font-size: 26px;
        color:rgba(0,153,255,1);
        cursor: pointer;
    }
    .reduce {
        font-size: 26px;
        margin-left: 15px;
        color:rgba(255,51,1,1);
        cursor: pointer;
    }
}
</style>
