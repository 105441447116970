var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "home-row",
      style: {
        "background-color": _vm.obj.bgColor,
        "margin-bottom": _vm.obj.bMargin ? "10px" : "0",
        padding: _vm.obj.bPadding ? "20px 10px" : "20px 0",
      },
    },
    [
      _vm._l(_vm.obj.list, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "home-row-item",
            style: { borderRight: index === 3 ? "none" : "1px solid #ccc" },
          },
          [
            _c("h2", { style: { color: _vm.obj.rowTitleColor } }, [
              _vm._v(" " + _vm._s(item.title) + " "),
            ]),
            _c("p", { style: { color: _vm.obj.subTitleColor } }, [
              _vm._v(" " + _vm._s(item.subTitle) + " "),
            ]),
          ]
        )
      }),
      _c("div", { staticClass: "bt-drag-title" }, [
        _vm._v(" " + _vm._s(_vm.obj.title) + " "),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }