<template>
  <div
    class="threm-img-pre"
    :style="{'background-color': obj.bgColor, 'margin-bottom': obj.bMargin ? '10px' : '0', 'padding': obj.bPadding ? '20px 10px' : '20px 0'}"
  >
    <div class="left">
      <img
        :src="[obj.list[0].img == ''? preurl1 : obj.list[0].img + '?x-oss-process=image/resize,w_200']"
        alt=""
      >
    </div>
    <div class="right">
      <img
        :src="[obj.list[1].img == ''? preurl1 : obj.list[1].img + '?x-oss-process=image/resize,w_200']"
        alt=""
      >
    </div>
    <div class="bt-drag-title">
      {{ obj.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThreeImgPre',
  props: {
    obj: {
      type: Object,
      default: {
        list: [
          { img: '' },
          { img: '' },
          { img: '' }
        ],
        bgColor: '#fff'
      }
    }
  },
  data() {
    return {
      preurl1: 'https://img.hzanchu.com/acimg/96730e5c698b7c713247c61023de93d2.png?x-oss-process=image/resize,w_300'
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.threm-img-pre{
    display: flex;
}
.left{
    width: 250px;
    height: 200px;
    img{
        width: calc(100% - 10px);
        // height: 185px;
        padding: 5px;
    }
}
.right{
    width: calc(100% - 250px);
    img{
        width: calc(100% - 10px);
        // height: 185px;
        padding:5px;
    }
}
</style>
