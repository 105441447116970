var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "static-img",
      style: {
        "border-bottom": _vm.obj.bMargin ? "10px solid" : "",
        "border-color": _vm.obj.bgColor,
        padding: _vm.obj.bPadding ? "1px 26px 1px 21px" : "1px 5px 1px 0",
      },
    },
    [
      _c(
        "div",
        { staticClass: "static-img-slide" },
        _vm._l(_vm.obj.list, function (item, index) {
          return _c("img", {
            key: index,
            style: { "min-height": _vm.obj.size },
            attrs: {
              src: [
                item.img === ""
                  ? _vm.preurl
                  : item.img + "?x-oss-process=image/resize,w_375",
              ],
            },
          })
        }),
        0
      ),
      _c("div", { staticClass: "bt-drag-title" }, [
        _vm._v(" " + _vm._s(_vm.obj.title) + " "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }